<template>
  <div>
    <div id="mySidenavProperties" class="sidenav" v-bind:class="{ active: isActive}">
      <div class="projectInfoProperties">
        <div class="project-title">Properties</div>
        <div class="col mt-2">
          <ul id="properties">
            <li v-for="property in this.properties" :key="property.UPRN" @click="getCustomProperty(property)">
              {{ property.Line1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomPropertiesList",
  data: function () {
    return {
      properties: [],
    }
  },
  methods: {
    getCustomProperty(property) {
      this.$root.$emit('setCenter', {center: property.location})
      this.$store.dispatch('Search/loadCustomUprnInfo', {uprn: property.UPRN, clear: true});
    }
  },
  computed: {
    ...mapGetters({
      project: 'Projects/selectedProject'
    }),
    isActive: {
      get() {
        return this.$store.state.State.propertiesInfoShown;
      }
    },
  },
  watch: {
    isActive: function (data) {
      const self = this;

      if (data) {
        const selectedProject = this.$store.state.Projects.selected_project.id;
        axios.get(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject + '/customproperty').then(response => {
          this.properties = response.data.data;
        })
        // this.$store.dispatch('Projects/getFullInfo', self.project.id, {root: true}).then(rest => {
        //   self.projectInfo = rest.data.data;
        // })
      }
    }
  }
}
</script>

<style scoped>
.sidenav {
  display: none;
  width: 350px;
  position: absolute;
  z-index: 9999;
  top: 80px;
  right: 0;
  overflow-x: hidden;
  height: calc(100% - 80px);
  box-shadow: 0px 20px 15px -7px #000000, 0px 5px 13px 5px rgba(0, 0, 0, 0);
}


.sidenav.active {
  display: block !important;
}

#mySidenavProperties {
  padding: 5px;
}

.projectInfoProperties {
  border: 1px solid #dad7d7;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  background-color: white;

}

.project-title {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #dad7d7;

}

#properties {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

#properties li {
  padding-left: 0;
  margin-left: 0;
  font-size: 11px;
  cursor: pointer;
}

</style>