import { render, staticRenderFns } from "./FlagSelector.vue?vue&type=template&id=226b776b&scoped=true"
import script from "./FlagSelector.vue?vue&type=script&lang=js"
export * from "./FlagSelector.vue?vue&type=script&lang=js"
import style0 from "./FlagSelector.vue?vue&type=style&index=0&id=226b776b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226b776b",
  null
  
)

export default component.exports