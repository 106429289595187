<template>

  <div>
    <div id="mySidenavProject" class="sidenav" v-bind:class="{ active: isActive}">
      <div class="projectInfoContainer">
        <div class="project-title">{{ project.name }}</div>
        <div class="col mt-2">
          <div><strong>Ducts</strong></div>
          <ul id="ducts">
            <li @click="fitDuctBounds(duct)" v-for="duct in this.networkingInfo.ducts" :key="duct.id">
              <div class="duct" v-bind:class="{ implemented: duct.is_implemented}"
                   :style="'border-color: ' +duct.color+''">
              </div>
              {{ duct.name }} <span class="cursor-pointer" @click="removeDuct(duct, $event)"><i
                class="fas fa-times fa-xs"></i></span>
            </li>
          </ul>
        </div>
        <div class="col mt-2">
          <div><strong>Objects</strong></div>
          <ul id="chambers">
            <li @click="fitObjectBounds(object)" v-for="object in this.networkingInfo.objects" :key="object.id">
              <div class="objects" v-bind:class="{ implemented: object.is_implemented}"
                   :style="'background-color: ' +object.color+''">
              </div>
              {{ object.name }} <span class="cursor-pointer" @click="removeObject(object)"><i
                class="fas fa-times fa-xs"></i></span>
            </li>
          </ul>
        </div>
        <div class="col mt-2">
          <div><strong>Polygons</strong></div>
          <div class="mt-2 d-flex justify-content-between">
            <a v-if="this.csvAllDownloadLink" @click="getAllPolygons" class="btn btn-danger btn-sm text-white"><i
                class="fas fa-file-csv"></i> CSV</a>
            <a v-if="this.kmlAllDownloadLink" @click="getAllPolygonsKml" class="btn btn-danger btn-sm text-white"><i
                class="fas fa-globe-europe"></i> KML</a>
            <a v-if="this.kmlAllOpenreachDownloadLink" @click="getAllPolygonsOpenreachKml"
               class="btn btn-danger btn-sm text-white"><i class="fas fa-globe-europe"></i> Openreach KML</a>
          </div>
          <ul id="polygons">
            <li class="float-left ml-3" @click="fitPolygonBounds(polygon)"
                v-for="polygon in this.networkingInfo.polygons"
                :key="polygon.id">

              <div class="objects"
                   :style="'border-radius:50%;background-color: ' +polygon.backgroundColor+'; opacity:'+polygon.backgroundOpacity+'; border: '+polygon.borderWidth+'px solid '+polygon.borderColor+';'">
              </div>
              <span class="removePolygon float-left" @click="removePolygon(polygon)"><i class="fas fa-times fa-xs"></i></span>

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import DuctToolBar from "../../Mixins/Draw/ToolBar/DuctToolBar";
import L from "leaflet";
import CommonTools from "../../Helpers/CommonTools";
import DownloadPropertyInfo from "../../Mixins/DownloadPropertyInfo";
import axios from "axios";

export default {

  name: "ProjectInfo",
  components: {DuctToolBar},
  data() {
    return {
      projectInfo: {},
      networkingInfo: {},
    }
  },
  mixins: [DownloadPropertyInfo],
  watch: {
    project: function (data) {

      this.downloadAllPolygonsCsv(data.id)
      this.downloadAllKML(data.id)
      this.downloadAllKMLOpenreach(data.id)
      this.$store.dispatch('Projects/getFullInfo', data.id, {root: true}).then(rest => {
        this.projectInfo = rest.data.data;
      })
      this.$store.dispatch('Projects/getNetworkingFullInfo', this.project.id, {root: true}).then(response => {
        this.networkingInfo = response.data.data;
      });
    },
    isActive: function (data) {
      if (data) {
        this.$store.dispatch('Projects/getFullInfo', this.project.id, {root: true}).then(rest => {
          this.projectInfo = rest.data.data;
        })
        this.$store.dispatch('Projects/getNetworkingFullInfo', this.project.id, {root: true}).then(response => {
          this.networkingInfo = response.data.data;
        });
      }
    }
  },
  methods: {
    getAllPolygons() {
      this.$root.$emit('showLoader', "Preparing csv for all polygons")
      axios.get(this.csvAllDownloadLink, {responseType: "blob"}).then(response => {
        this.$root.$emit('hideLoader')
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "all_polygons.csv";
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    getAllPolygonsKml() {
      this.$root.$emit('showLoader', "Preparing kml for all polygons")
      axios.get(this.kmlAllDownloadLink, {responseType: "blob"}).then(response => {
        this.$root.$emit('hideLoader')
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "all_polygons.kml";
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    getAllPolygonsOpenreachKml() {
      this.$root.$emit('showLoader', "Preparing kml for all polygons")
      axios.get(this.kmlAllOpenreachDownloadLink, {responseType: "blob"}).then(response => {
        this.$root.$emit('hideLoader')
        let blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "all_polygons_openreach.kml";
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    removeDuct(duct, e) {
      e.preventDefault()
      e.stopPropagation()
      const self = this;
      CommonTools.deleteConfirmation().then(() => {
        this.$store.dispatch("Duct/delete", duct.id).then(() => {
          self.networkingInfo.ducts.splice(self.networkingInfo.ducts.findIndex(a => a.id === duct.id), 1)
          self.$root.$emit('redrawDucts')
        });
      })
    },
    removeObject(object) {
      const self = this;
      CommonTools.deleteConfirmation().then(() => {

        this.$store.dispatch('ObjectMarker/delete', object.id).then(() => {
          self.networkingInfo.objects.splice(self.networkingInfo.objects.findIndex(a => a.id === object.id), 1)
        });
      });
    },
    removePolygon(polygon) {
      const self = this
      CommonTools.deleteConfirmation().then(() => {
        self.$store.dispatch("Polygon/delete", polygon.id, {root: true}).then(() => {
          self.networkingInfo.polygons.splice(self.networkingInfo.polygons.findIndex(a => a.id === polygon.id), 1)
        });
      });
    },
    fitDuctBounds(duct) {
      this.$store.dispatch('Duct/getDuctInfo', duct.id).then(response => {
        const geometry = JSON.parse(response.feature).geometry.coordinates[0];
        this.$root.$emit('setCenter', {center: {lat: geometry[1], lng: geometry[0]}, zoom: 18});
      });


    },
    fitObjectBounds(object) {
      this.$store.dispatch('ObjectMarker/getInfo', {id: object.id}).then(response => {
        const geometry = response.feature.geometry.coordinates
        this.$root.$emit('setCenter', {center: {lat: geometry[1], lng: geometry[0]}, zoom: 19});
      })
    },
    fitPolygonBounds(object) {
      this.$store.commit('PolygonPopup/setPolygon', null);
      this.$store.commit('PolygonPopup/setPopupShowed', false);
      this.$store.commit('PolygonPopup/setPopupLocation', {});
      this.$store.commit('PolygonPopup/setPopupContent', '');
      const self = this;
      this.$store.dispatch('Polygon/get', {id: object.id, project_id: object.project_id}).then(response => {
        const l = L.geoJSON(response.data.data.feature);
        self.$root.$emit('fitBounds', l.getBounds())
      })
    },

  },
  computed: {
    ...mapGetters({
      project: 'Projects/selectedProject'
    }),
    isActive: {
      get() {
        return this.$store.state.State.projectInfoShown;
      }
    },
    layersList: {
      get() {
        return this.$store.state.LayersList.layers;
      }
    }
  },
}
</script>

<style scoped>
.sidenav {
  display: none;
  width: 350px;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  overflow-x: hidden;
  height: 100%;
  box-shadow: 0px 20px 15px -7px #000000, 0px 5px 13px 5px rgba(0, 0, 0, 0);
}


.sidenav.active {
  display: block !important;
}

.projectInfoContainer {
  border: 1px solid #dad7d7;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  background-color: white;

}

#mySidenavProject {
  padding: 5px;
}

.project-title {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #dad7d7;

}

#ducts, #chambers, #polygons {
  padding-left: 0px;
  margin-left: 0px;
  list-style: none;
}

.objects {
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 5px;
  opacity: 0.4;
  margin-top: 4px;
  cursor: pointer;
}

.duct {
  width: 35px;
  height: 5px;
  float: left;
  padding-top: 10px;
  margin-right: 5px;
  border-style: dashed;
  border-bottom-width: 5px;
  border-top: none;
  border-left: none;
  border-right: none;
  opacity: 0.4;
  cursor: pointer;
}


.objects.implemented {
  opacity: 1;
}

.duct.implemented {
  border-bottom-style: solid;
  opacity: 1;
}

.removePolygon {
  margin-top: -5px;
  margin-left: -5px;
  cursor: pointer;
}
</style>