import Vue from "vue";

const state = {
    forUser: null,
    subAccounts: [],
};
const mutations = {
    setForUser(state, data) {
        state.forUser = data;
    },
    setSubAccounts(state, data) {
        state.subAccounts = data;
    }
};
const actions = {
    getSubAccounts(ctx, data) {
        if (ctx.state.forUser && ctx.state.forUser.id !== null && ctx.rootState.Auth.isAdmin) {
            console.log('Geting users from admin')
            return new Promise((resolve, reject) => {
                Vue.prototype.$UserManagementApiClient.getSubAccounts(ctx.state.forUser.id).then(response => {
                    state.subAccounts = response.data.data;
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
            })
        } else {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_URL + '/api/user/accounts').then(response => {
                    state.subAccounts = response.data.data;
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
            })
        }
    },
};
const getters = {
    forUser: state => {
        return state.forUser
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}