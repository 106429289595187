const StaticPopup = {
    namespaced: true,
    state: {
        latLng: null,
        content: null,
        closeEventHandler: null,
        clickEventHandler: null,
        showGeoJson: false,
        geoJson: null,

    },
    getters: {
        getLatLng(state) {
            return state.latLng
        },
        getContent(state) {
            return state.content;
        },
        available(state) {
            return state.latLng !== null && state.content !== null;
        },
        getCloseEventHandler(state) {
            return state.closeEventHandler;
        },
        getShowGeoJson(state) {
            return state.showGeoJson;
        },
        getGeoJson(state) {
            return state.geoJson;
        },
        getClickEventHandler(state) {
            return state.clickEventHandler;
        }
    },
    mutations: {
        setLatLng(state, latLng) {
            state.latLng = latLng;
        },
        setContent(state, content) {
            state.content = content;
        },
        setCloseEventHandler(state, handler) {
            state.closeEventHandler = handler;
        },
        setShowGeoJson(state, showState) {
            state.showGeoJson = showState;
        },
        setGeojson(state, geojson) {
            state.geoJson = geojson;
        },
        setClickEventHandler(state, handler) {
            state.clickEventHandler = handler;
        }
    },
    actions: {
        close({commit}) {
            commit('setContent', null);
            commit('setCloseEventHandler', null);
            commit('setClickEventHandler', null);
            commit('setShowGeoJson', false);
            commit('setGeojson', null);
            commit('setLatLng', null);
        }
    }
}
export default StaticPopup