const CustomPropertiesToolbar = {
    watch: {
        propertiesInfoShown(value) {
            const controls = document.getElementsByClassName('leaflet-right');
            if (value) {
                controls[0].style.right = '350px';
            } else {
                controls[0].style.right = '0';
            }
        },
    },
    computed: {
        propertiesInfoShown: {
            get: function () {
                return this.$store.state.State.propertiesInfoShown;
            },
            set(value) {
                this.$store.commit("State/setProjectInfoShown", false, {root: true});
                this.$store.commit("State/setPropertiesInfoShown", value, {root: true});
            }
        }
    },
    methods:{
        addCustomPropertiesInfoControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'custom_properties',
                block: 'options',
                title: 'Custom Properties',
                actions: [],
                className: 'fas fa-laptop-house project_info-control',
                onClick: function (e) {
                    self.propertiesInfoShown = !self.propertiesInfoShown;
                },
                toggle: true

            })
        },
    }
}
export default CustomPropertiesToolbar;