<template>
  <div>
    <div class="row">
      <div class="col-6 autocomplete">
        <div>
          <b-dropdown v-if="firstDuct" id="dropdown-1" :text="'Selected D-' + firstDuct.sequence_id" class="w-70">
            <b-dropdown-item @click="selectDuct(result)"
                             class="autocomplete-search-result"
                             v-for="result in options"
                             :key="result.id">
              D-{{ result.sequence_id }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <span></span>
      </div>

      <div class="col-6 autocomplete">
        <div>
          <b-dropdown v-if="secondDuct" id="dropdown-1" :text="'Selected D-' + secondDuct.sequence_id" class="w-70">
            <b-dropdown-item @click="selectSecondDuct(result)"
                             class="autocomplete-search-result"
                             v-for="result in options"
                             :key="result.id">
              D-{{ result.sequence_id }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 autocomplete">
        <ul class="tag-list " v-if="firstDuct">
          <li
              @click="selectTube(firstDuct.id, tube) "
              class="tag"
              v-bind:class="{'blue': isSelectedTube(1, tube.id ),'hidden': isHiddenTube(1, tube.id)}"
              v-for="tube in firstDuct.free_tubes"
              :key="'t1-'+ tube.id"
          >
            T-{{ tube.tube_number }}
          </li>
        </ul>
      </div>
      <div class="col-6 autocomplete">
        <ul class="tag-list" v-if="secondDuct">
          <li
              @click="connectToSecondTube(secondDuct.id, tube) "
              class="tag"
              v-bind:class="{'blue': isSelectedTube(2, tube.id ), 'hidden': isHiddenTube(2, tube.id)}"
              v-for="tube in secondDuct.free_tubes"
              :key="'t2-'+ tube.id"
          >
            T-{{ tube.tube_number }}
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SearchSelect",
  props: [
    'options'
  ],
  data() {
    return {
      firstTube: null,
      secondTube: null,
      firstDuct: null,
      secondDuct: null,
      query: ''
    }
  },

  computed: {
    ...mapGetters({
      data: 'DuctConnections/getDuctConnection',
      connectAllTubes: 'DuctConnections/getConnectAllTubes'
    }),
  },
  watch: {
    firstDuct: function (value) {
      if (value.free_tubes.length === this.secondDuct.free_tubes.length && value.id !== this.secondDuct.id) {
        this.$store.commit("DuctConnections/setConnectAllTubes", true);
      } else {
        this.$store.commit("DuctConnections/setConnectAllTubes", false);
      }
    },
    secondDuct: function (value) {
      if (value.free_tubes.length === this.firstDuct.free_tubes.length && value.id !== this.firstDuct.id) {
        this.$store.commit("DuctConnections/setConnectAllTubes", true);
      } else {
        this.$store.commit("DuctConnections/setConnectAllTubes", false);
      }
    },
  },

  methods: {
    selectDuct(result) {
      if (result.free_tubes.length === 0) {
        this.$swal({
          text: 'No tubes available',
          icon: 'warning',
          confirmButtonText: 'Ok!',
          showCloseButton: true,
        })
        return;
      }
      this.firstDuct = result;
      this.$store.commit('DuctConnections/setFirstDuct', result.id)
    },
    selectSecondDuct(result) {
      if (result.free_tubes.length === 0) {
        this.$swal({
          text: 'No tubes available',
          icon: 'warning',
          confirmButtonText: 'Ok!',
          showCloseButton: true,
        })
        return;
      }
      this.secondDuct = result;
      this.$store.commit('DuctConnections/setSecondDuct', result.id)
    },
    selectTube(ductId, tube) {
      this.firstTube = tube;
      this.$store.commit('DuctConnections/setFirstTube', this.firstTube.tube_number)

    },
    connectToSecondTube(ductId, tube) {
      this.secondTube = tube;
      this.$store.commit('DuctConnections/setSecondTube', this.secondTube.tube_number)
    },
    isHiddenTube(index, id) {
      if (index === 1) {
        return this.firstDuct.id === this.secondDuct.id && id === this.secondTube.id;
      }
      if (index === 2) {
        return this.firstDuct.id === this.secondDuct.id && id === this.firstTube.id;
      }
    },
    isSelectedTube(index, id) {
      if (index === 1) {
        return this.firstTube.id === id;
      }
      if (index === 2) {
        return this.secondTube.id === id;
      }
    }
  },

  created() {
    this.result = this.options
    for (let i = 0; i <= this.result.length; i++) {
      if (this.result[i] && this.result[i].free_tubes.length > 0) {
        this.firstDuct = this.result[i]
        this.selectDuct(this.firstDuct)
        this.firstTube = this.firstDuct.free_tubes[0]
        this.selectTube(this.firstDuct.id, this.firstDuct.free_tubes[0])
        break
      }
    }
    for (let i = this.options.length - 1; i >= 0; i--) {
      if (this.result[i] && this.result[i].free_tubes.length > 0) {
        this.secondDuct = this.result[i]
        this.selectSecondDuct(this.secondDuct)
        this.secondTube = this.secondDuct.free_tubes[this.secondDuct.free_tubes.length - 1]
        this.connectToSecondTube(this.secondDuct.id, this.secondTube)
        break
      }
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  background-color: #eff2f7;
}

@media (min-width: 50rem) {
  .wrapper {
    display: flex;
  }
}

.example {
  padding: 1rem;
}

h2 {
  margin-top: 0;
  font-size: 1rem;
}

input {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: inherit;
}

input:focus {
  border-color: #587ef5;
  outline: 0;
}

.autocomplete {
  position: relative;
  width: 20rem;
}

.autocomplete-second {
  marign: left;
  width: 20rem;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.autocomplete-dropdown-second {
  position: absolute;
  maring: left;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.autocomplete-search-results-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.autocomplete-search-result {
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.autocomplete-search-result:hover,
.autocomplete-search-result:focus,
.autocomplete-search-result:active {
  background-color: #f9fafc;
}

.tag-list-search {
  width: 20rem;
}

.tag-list {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}

.tag {
  min-width: 50px;
  max-width: 80px;
  text-align: center;
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 2px;
  background-color: #4a4a4a;
  color: #fafafa;
  cursor: pointer;
}

.blue {
  background-color: #587ef5;
}

.tag:hover,
.tag:focus,
.tag:active {
  background-color: #587ef5;
}

</style>