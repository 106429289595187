<template>
  <div>
    <b-modal id="address_matching_list" size="xl" centered title="Address Matching List">
      <template #modal-footer>
        <b-col>
          <b-pagination v-if="totalRows > 0 && totalRows > perPage" aria-controls="my-table" v-model="currentPage"
                        :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
        </b-col>
      </template>
      <template #modal-title>
        Address Matching
        <b-button class="right text-right ml-5" size="sm" variant="outline-success"
                  v-b-modal.new_address_matching_modal>Upload
          new file
        </b-button>

        <b-button class="right text-right ml-5" size="sm" variant="outline-success" @click="refreshMatchingTable">
          <b-icon icon="arrow-counterclockwise" animation="spin-reverse" font-scale="1"></b-icon>
        </b-button>
      </template>
      <b-overlay :show="showOverlay" rounded="sm">
        <h4 class="text-center text-secondary" v-if="totalRows === 0">No Records</h4>

        <b-table v-show="totalRows > 0" id="my-table" ref="my-table" :items="myProvider" :fields="fields"
                 :current-page="currentPage" :per-page="perPage" :no-provider-paging="true">


          <template #cell(date)="row">
            {{ row.item.startDate }}
          </template>
          <template #cell(status)="row">
            <span class="badge badge-danger" v-if="row.item.status === 'finished_fail'" v-b-tooltip.hover
                  :title="row.item.comment">Failed</span>
            <span class="badge badge-light" v-if="row.item.status === 'new'">New</span>
            <span class="badge badge-info" v-if="row.item.status === 'in_progress'">Processing</span>
            <span class="badge badge-success" v-if="row.item.status === 'finished_success'">Succeed</span>
          </template>
          <template #cell(more)="row">
            <b-dropdown :id="'dropdown-address_match_'+row?.item.id" size="sm" menu-class="dropdown-menu-custom"
                        toggle-class="text-decoration-none p-0"
                        no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item :href="sourceDownloadLink(row.item)">
                Download Original
              </b-dropdown-item>
              <b-dropdown-item :href="resultDownloadLink(row.item)" v-if="row.item.status === 'finished_success'">
                Download Output
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="row.item.rows_count > (row.item.succeed + row.item.skipped) && row.item.status === 'finished_success'"
                  @click="getUnmatched(row.item.id)">
                Complete Manually
              </b-dropdown-item>
              <b-dropdown-item @click="deleteRecord(row.item)"
                               v-if="row.item.status !== 'in_progress'"
                               class="text-danger">
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

      </b-overlay>
    </b-modal>
    <NewAddressMatching></NewAddressMatching>
    <b-modal title="Manual Matching" size="lg" id="manual_matching" @hidden="clearManualMatching">
      <b-table
          id="unmatched_table"
          :fields="unmatchedFields"
          :items="unmatchedLines"
          :per-page="unmatchedPerPage"
          :current-page="currentUnmatchedPage"
          small
      >
        <template #cell(line)="row">
          {{ row.item.line }} [#{{ row.item.index }} row]
        </template>
        <template #cell(index)="row">
          <SearchComponentPlug v-if="row.item.uprnInfo.length === 0"
                               :should-emitted="true" :emit-index-key="row.item.index"
                               emit-key="unmatch-search"></SearchComponentPlug>
          <span v-else>
            <span class="flex">
              <span><strong>{{
                  row.item.uprnInfo.display_text_bold
                }}</strong>{{ row.item.uprnInfo.display_text_normal }}</span>
            </span>
            <span class="flex pl-2">
              <button class="btn btn-info btn-sm" @click="setUprn(row.item.uprnInfo,row.item.index)">Confirm</button>
              <button class="btn btn-danger btn-sm" @click="row.item.uprnInfo = []">Cancel</button>
            </span>
          </span>
        </template>
      </b-table>

      <div class="pages">
        <b-pagination
            v-model="currentUnmatchedPage"
            :total-rows="this.unmatchedLines.length"
            :per-page="unmatchedPerPage"
            aria-controls="unmatched_table"
        ></b-pagination>
      </div>

    </b-modal>

  </div>
</template>

<script>
import NewAddressMatching from "@/components/modals/AddressMatch/NewAddressMatching";
import SearchComponent from "@/components/partials/SearchComponent.vue";
import SearchComponentPlug from "@/components/partials/SearchComponentPlug.vue";

export default {

  components: {SearchComponentPlug, SearchComponent, NewAddressMatching},
  name: "AddressMatching",
  mounted() {
    const self = this;
    this.$root.$on('address-matching-updated', () => {
      this.$root.$emit('bv::refresh::table', 'my-table')
    });
    this.$root.$on('unmatch-search', function (uprnInfo, key) {
      const arrayIndex = self.unmatchedLines.findIndex(item => item.index === key);
      self.unmatchedLines[arrayIndex].uprnInfo = uprnInfo;
    });
  },
  data() {
    return {
      unmatchedPerPage: 5,
      currentUnmatchedPage: 1,
      unmatchedFields: [
        {key: 'line', sortable: false, label: 'Criteria'},
        {key: 'index', sortable: false, label: 'Match'},
      ],
      selectedAddressIndex: -1,
      currentRow: 0,
      selectedMatchUprn: null,
      showNextButton: false,
      showSkipButton: false,
      postcodeAddresses: [],
      unmatchedPostcode: null,
      unmatchedLine: [],
      unmatchedLines: [],
      showAllFields: false,
      currentUnMatch: 0,
      hFooter: true,
      showOverlay: false,
      requestList: [],
      fields: [
        {key: 'date', sortable: false},
        {key: 'rows_count', sortable: false},
        {key: 'proceed', sortable: false, label: 'Processed'},
        {key: 'succeed', sortable: false, label: 'Matched'},
        {key: 'status', sortable: false},
        {key: 'more', sortable: false},
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  methods: {
    clearManualMatching() {
      this.unmatchedLines = [];
      this.currentUnMatch = 0;
      this.currentUnmatchedPage = 1;
    },
    myProvider(ctx, callback) {
      const self = this;
      const params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch' + params).then(resp => {
        self.showOverlay = false;
        self.requestList = resp.data;
        self.totalRows = resp.data.length;
        callback(self.requestList)

      }).catch(() => {
        self.showOverlay = false;
        callback([])
      });
      return null
    },

    refreshMatchingTable() {
      this.$root.$emit('bv::refresh::table', 'my-table')
    },
    sourceDownloadLink(item) {
      return process.env.VUE_APP_API_URL + '/addressmatch/' + item.id + '/download/source/' + item.secret;
    },
    resultDownloadLink(item) {
      return process.env.VUE_APP_API_URL + '/addressmatch/' + item.id + '/download/result/' + item.secret;
    },
    getUnmatched(id) {
      this.currentUnMatch = id;
      const self = this;
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch/unmatched/' + id).then(resp => {
        if (resp.data.error !== undefined && resp.data.error === 'All Records Matched') {
          self.$swal("Done", "All Records Matched");
          self.$bvModal.hide("manual_matching");
          self.refreshMatchingTable();
          return;
        }
        self.unmatchedLines = resp.data.lines;
        this.$bvModal.show("manual_matching");
      });
    },
    getPostCode(postcode) {
      this.unmatchedPostcode = postcode;
      const self = this;
      axios.post(process.env.VUE_APP_API_URL + '/api/addressmatch/unmatched/postcode', {postcode: postcode}).then(resp => {
        self.postcodeAddresses = resp.data.data;
      });
    },
    setUprn(uprnInfo, selectedAddressIndex) {
      this.selectedAddressIndex = selectedAddressIndex;
      const self = this;
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch/match/' + this.currentUnMatch + '/' + selectedAddressIndex + '/' + uprnInfo.uprn).then(resp => {
        if (resp.data.error !== undefined && resp.data.error === 'All Records Matched') {
          self.$swal("Done", "All Records Matched");
          self.$bvModal.hide("address_unmatched");
          return;
        }
        self.getUnmatched(self.currentUnMatch, self.showAllFields, false);
        self.refreshMatchingTable();

      })
    },
    skip() {
      this.setUprn(0, -1);
    },
    next() {
      this.getUnmatched(this.currentUnMatch, this.showAllFields);
      this.showNextButton = false;
      this.selectedAddressIndex = -1;
    },
    deleteRecord(item) {
      const self = this;
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you wish to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No, Keep it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          axios.delete(process.env.VUE_APP_API_URL + '/api/addressmatch/' + item.id).then(() => {
            self.refreshMatchingTable();
          });
        }
      })

    },
  }
}
</script>

<style>
table {
  font-size: 12px;
}

.postcode_addresses,
#unmatched_table {
  max-height: 400px;
  overflow: auto;
  padding-left: 5px;

}

.postcode_addresses li {
  list-style: none;
  font-size: 12px;
  cursor: pointer;
  padding: 2px;
}

.postcode_addresses li:hover {
  background-color: #cccccc;
}

.address_list_item_active {
  background-color: #cccccc;
}
</style>
