<template>
  <div class="uprnInfo object_type_modal_body" ref="draggableContainer" id="draggable-container"
       v-if="this.multiUprnInfoAvailable"
       :class="{right:menuIsOpened}">
    <div class="d-flex justify-content-between p-1">
      <i class="fas fa-expand-arrows-alt draggable" @mousedown="dragMouseDown"></i>
      <i class="fas fa-times" @click="closeUprnInfo"></i>
    </div>
    <b-tabs pills card fill content-class="mt-3" v-model="currentTab">
      <b-tab v-for="(uprnInfo, index) in this.multiUprnInfo" :key="uprnInfo.key"
             :active="uprnInfo.uprn===uprnClicked" class="pt-0">
        <template #title>
          <div class="d-flex justify-content-between">
            <span class="pt-1">{{ uprnInfo.uprn }}</span>
            <span>
              <i class="fas fa-copy" @click="copy(uprnInfo.uprn)"></i>
            <b-dropdown :id="'dropdown-'+uprnInfo.uprn" variant="link" size="sm" class="ml-2"

                        menu-class="dropdown-menu-custom"
                        toggle-class="text-decoration-none p-0"
                        no-caret>
                <template #button-content>
                  <i v-if="index === currentTab" class="fas fa-ellipsis-v text-white"></i>
                  <i v-if="index !== currentTab" class="fas fa-ellipsis-v text-primary"></i>
                </template>
                <b-dropdown-item @click="openKML(uprnInfo)">KML</b-dropdown-item>
                <b-dropdown-item
                    @click="openCreateLeadInWindow({show: true, uprn: uprnInfo.uprn})">Create LeadIn</b-dropdown-item>

                <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group id="dropdown-group-copy" header="Copy">
                <b-dropdown-item @click="copy(getPropertyUrl(uprnInfo))">Property URL</b-dropdown-item>
                <b-dropdown-item @click="copy(uprnInfo.location.lat.toFixed(6)+', '+uprnInfo.location.lng.toFixed(6))">WGS84</b-dropdown-item>
                <b-dropdown-item @click="copy(parseInt(uprnInfo.coordinates.x)+', '+parseInt(uprnInfo.coordinates.y))">X (Easting) Y (Northing)</b-dropdown-item>
                <b-dropdown-item @click="copy(getGridReference(uprnInfo))">Grid Reference</b-dropdown-item>
                <b-dropdown-item @click="copy(getW3w(uprnInfo))">What3Words</b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider v-if="$Utils.isMobile()"></b-dropdown-divider>
              <b-dropdown-item
                  @click='openAppleMap(uprnInfo)'
                  v-if="$Utils.isMobile() && $Utils.isIphone()">Apple Maps</b-dropdown-item>
              <b-dropdown-item @click="openGoogleMap(uprnInfo)"
                               v-if="$Utils.isMobile() && !$Utils.isIphone()">Google Maps</b-dropdown-item>
            </b-dropdown>
            </span>
          </div>
        </template>

        <img v-if="uprnInfo.isFlagged==1" :src="uprnInfo.flag" width="16" height="16"
             @click="removeFlagForProperty(uprnInfo)">

        <img v-else-if="uprnInfo.isFlagged==0 && loggedIn" src="/assets/img/icons/flags/flag_green.png"
             class="blackwhite"
             v-b-modal.flagSelector
             width="16" height="16">

        <br/>

        <div>{{ uprnInfo.display_text_normal }}</div>
        <strong>{{ uprnInfo.display_text_bold }}</strong>
        <table class="table table-sm table-bordered mt-1">
          <tbody>
          <tr v-if="uprnInfo.class">
            <td>Basic Description</td>
            <td>{{ uprnInfo.class }}</td>
          </tr>
          <tr v-if="uprnInfo.class_generated">
            <td>Detailed Description</td>
            <td>{{ uprnInfo.class_generated }}</td>
          </tr>
          <tr v-if="uprnInfo.country">
            <td>Country</td>
            <td>{{ uprnInfo.country }}</td>
          </tr>
          <tr v-if="uprnInfo.county">
            <td>County</td>
            <td>{{ uprnInfo.county }}</td>
          </tr>
          <tr v-if="uprnInfo.district">
            <td>District</td>
            <td>{{ uprnInfo.district }}</td>
          </tr>
          <tr v-if="uprnInfo.national_park">
            <td>National Park</td>
            <td>{{ uprnInfo.national_park }}</td>
          </tr>
          <tr v-if="uprnInfo.westminster_constituency">
            <td>Westminster Constituency</td>
            <td>{{ uprnInfo.westminster_constituency }}</td>
          </tr>
          <tr>
            <td colspan="2" v-b-toggle.collapse-openreach class="bg-secondary">
              <div class=" d-flex justify-content-between">
              <span>
                <strong class="text-white">Providers</strong>
                </span>
                <span>
                <i v-if="!openreachInfoShown" class="text-white fas fa-plus-circle"></i>
                <i v-if="openreachInfoShown" class="text-white fas fa-minus-circle"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="p-0">
              <b-collapse id="collapse-openreach" class="mt-2 p-0" @hide="openreachInfoShown=false"
                          @show="openreachInfoShown=true">
                <table class="table table-striped border-0 m-0">
                  <tr v-if="uprnInfo.open_reach.download != null">
                    <td><strong>Openreach</strong></td>
                    <td><b>{{ uprnInfo.open_reach.download }} Mbps
                      ({{ uprnInfo.open_reach.type }})</b></td>
                  </tr>
                  <tr v-else>
                    <td><strong>Openreach</strong></td>
                    <td><strong>Unknown</strong></td>
                  </tr>
                  <tr v-if="uprnInfo.hyperopticavailable != null">
                    <td><strong>Hyperoptic</strong></td>
                    <td><i v-if="uprnInfo.hyperopticavailable=='true'" class="fas fa-check-circle text-success"></i><i
                        v-else
                        class="fas fa-times-circle text-danger"></i>
                    </td>
                  </tr>
                  <tr v-if="uprnInfo.gigaclearavailable != null">
                    <td><strong>Gigaclear</strong></td>
                    <td><i v-if="uprnInfo.gigaclearavailable=='true'" class="fas fa-check-circle text-success"></i><i
                        v-else
                        class="fas fa-times-circle text-danger"></i>
                    </td>
                  </tr>

                  <tr v-if="uprnInfo.virginmediaavailable != null">
                    <td><strong>Virginmedia</strong></td>
                    <td><i v-if="uprnInfo.virginmediaavailable=='true'"
                           class="fas fa-check-circle text-success"></i><i
                        v-else
                        class="fas fa-times-circle text-danger"></i>
                    </td>
                  </tr>
                  <tr v-if="uprnInfo.cityfibreavailable != null">
                    <td><strong>CityFibre</strong></td>
                    <td><i v-if="uprnInfo.cityfibreavailable=='true'" class="fas fa-check-circle text-success"></i><i
                        v-else
                        class="fas fa-times-circle text-danger"></i>
                    </td>
                  </tr>
                </table>
              </b-collapse>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="mt-1" v-show="!additionalInfoLoaded">
          <b-overlay
              :show="showButtonLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
          >
            <br/>
            <div v-on:click='loadAdditionalLocation(uprnInfo.uprn)' class='additional_info_content_container'>
              <button class='btn btn-info btn-sm'>More information</button>
            </div>
          </b-overlay>
        </div>
        <div v-show="additionalInfoLoaded">
          <AdditionalInfo :uprn-additional-info="uprnAdditionalInfo"></AdditionalInfo>
          <FlagSelector v-if="loggedIn" v-model="flagSelectorOpened" :uprnInfo="uprnInfo"></FlagSelector>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import axios from 'axios';
import FlagSelector from "@/components/modals/Properties/FlagSelector";
import L from "leaflet";
import Helpers from "@/Mixins/Helpers";
import AdditionalInfo from "@/components/partials/AdditionalInfo.vue";

export default {
  name: "UprnInfo",
  components: {AdditionalInfo, FlagSelector},
  mixins: [Helpers],
  data() {
    return {
      additionalInfoLoaded: false,
      showButtonLoading: false,
      uprnAdditionalInfo: {},
      flagSelectorOpened: false,
      openreachInfoShown: false,
      currentTab: null,
    }
  },
  watch: {
    multiUprnInfo(value) {
      this.additionalInfoLoaded = false;
    }
  },
  computed: {
    ...mapGetters({
      'uprnInfoAvailable': 'Search/getUprnInfoAvailable',
      'multiUprnInfoAvailable': 'Search/getMultiUprnInfoAvailable',
      // 'uprnInfo': 'Search/getUprnInfo',
      'multiUprnInfo': 'Search/getMultiUprnInfo',
      'uprnInfoLoading': 'Search/getUprnInfoLoading',
      'menuIsOpened': 'LayersList/getMenuOpened',
      'loggedIn': 'Auth/isLoggedIn',
      uprnClicked: 'Search/clickedUprn'
    }),


  },
  methods: {
    openKML(uprnInfo) {
      window.open(this.getKMLDownloadAddress(uprnInfo.uprn), '_blank');
    },
    openAppleMap(uprnInfo) {
      window.open("http://maps.apple.com/?ll=" + uprnInfo.location.lat + "," + uprnInfo.location.lng + "&z=19&t=k&q=" + uprnInfo.display_text_normal, "_blank");
    },
    openGoogleMap(uprnInfo) {
      window.open('comgooglemapsurl://?q=' + uprnInfo.location.lat + ',' + uprnInfo.location.lng + '&zoom=19&t=k&', "_blank");
    },
    getGridReference(uprnInfo) {
      return uprnInfo.coordinates.reference_letter + uprnInfo.coordinates.easting + uprnInfo.coordinates.northing;
    },
    getW3w(uprnInfo) {
      return this.$WhatTreeWords.latlon2words(parseFloat(uprnInfo.location.lat),
          parseFloat(uprnInfo.location.lng))
    },
    getPropertyUrl(uprnInfo) {
      return process.env.VUE_APP_BASE_URL + '/property_uprn/' + uprnInfo.uprn + '/' + uprnInfo.location.lat + '/' + uprnInfo.location.lng
    },
    ...mapMutations({
      openCreateLeadInWindow: 'Openreach/setOpenreachLeadInWindow'
    }),
    copy(value) {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal('Copied', 'Copied successfully', 'success');

    },
    copyCoordinates(lat, lng) {
      const el = document.createElement('textarea');
      el.value = lat + "," + lng;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal('Copied', 'Coordinates copied successfully', 'success');

    },
    copyUprnUrl(uprnInfo) {
      const el = document.createElement('textarea');
      el.value = process.env.VUE_APP_BASE_URL + '/property_uprn/' + uprnInfo.uprn + '/' + uprnInfo.location.lat + '/' + uprnInfo.location.lng;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);


    },
    getKMLDownloadAddress(uprn) {
      return process.env.VUE_APP_API_URL + "/api/kml/" + uprn;
    },

    loadAdditionalLocation(uprn) {
      this.showButtonLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/api/uprnadditional/' + uprn).then(response => {
        this.uprnAdditionalInfo = response.data.data;
        this.showButtonLoading = false;
        this.additionalInfoLoaded = true;
      }).catch(() => {
        this.showButtonLoading = false;
      })
    },
    closeUprnInfo() {
      this.uprnAdditionalInfo = {};
      this.additionalInfoLoaded = false;
      this.$store.commit('Search/setMultiUprnInfoAvailable', false)
      L.Marker.stopAllBouncingMarkers();
    },
    showFlagPropertyWindow(uprnInfo) {
      this.flagSelectorOpened = true;
    },
    removeFlagForProperty(uprnInfo) {
      const self = this;
      // send remove marker request
      this.$store.dispatch('Properties/removeFlag', uprnInfo.uprn).then(() => {
        // remove flag from info window
        uprnInfo.isFlagged = 0;
        uprnInfo.flag = '';
        self.$store.commit("Properties/removeFlag", uprnInfo.uprn)
        self.$store.commit("Search/removeFlagFromMarker", uprnInfo)
        self.$store.commit('Search/setUprnInfo', uprnInfo);
        self.$root.$emit('removeMarkerIcon', uprnInfo.key)

      })


      // change marker icon on map
    },
    numberFormat(number) {
      let num = parseFloat(number);
      return num.toLocaleString('en-US', {maximumFractionDigits: 2}) // "1,234.57"
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()

      this.$refs.draggableContainer.style.top = (event.clientY - 10) + 'px'
      this.$refs.draggableContainer.style.left = (event.clientX - 60) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    }
  },
  created() {
    this.$root.$on('ACTION_HIDE_UPRN_INFO', () => {
      this.closeUprnInfo();
    })
  }
}
</script>

<style scoped>
td:nth-child(1) {
  font-weight: bold
}

.uprnInfo {
  border-radius: 10px;
  padding: 1px;
  font-size: 13px;
  margin-top: 5px;
  position: absolute;
  z-index: 998;
  float: right;
  width: 250px;
  background-color: white;
  margin-left: 50px;
  max-height: -webkit-calc(100% - 90px);
  max-height: -moz-calc(100% - 90px);
  max-height: calc(100% - 90px);
  overflow: auto;
  text-align: center;
  line-height: 1.4;
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  top: 90px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
}

.right {
  right: 10px;
}

.blackwhite {
  filter: grayscale(100%);
}

img {
  outline: none;
}

.fas {
  cursor: pointer;
}

</style>