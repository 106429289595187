<template>
  <div>
    <b-modal no-close-on-backdrop :visible="isUserLoading" centered id="loading" hide-header hide-footer
             no-close-on-esc>
      <div class="text-center">Loading Please wait</div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Loading",
  computed: {
    isUserLoading: {
      get() {
        return this.$store.state.Auth.userIsLoading
      }
    },
  }
}
</script>

<style scoped>

</style>