import Vue from 'vue';
import vue from 'vue';
import axios from 'axios';
import CommonTools from "@/Helpers/CommonTools";


const state = {
    layers: [],
    allSubLayers: [],
    layerObjects: [],
    menuOpened: false,
    showPolygons: false,
    showPolygonOutlines: false,
    showText: false,
    heatMapItems: {},

};
const mutations = {
    setLayers(state, data) {
        Vue.set(state, 'layers', data);
    },
    showPolygons(state, status) {
        Vue.set(state, 'showPolygons', status);
    },
    showPolygonOutlines(state, status) {
        Vue.set(state, 'showPolygonOutlines', status);
    },
    showText(state, status) {
        Vue.set(state, 'showText', status);
    },
    setSubLayers(state, data) {
        state.allSubLayers = [];
        data.map(function (item) {
            item.sub_layers.map(function (subLayer) {
                subLayer.baseUrl = "https://tiles.postcodegenie.com/geoserver/wms";
                state.allSubLayers.push(subLayer);
                state.layerObjects[subLayer.code] = subLayer;

            });
        })
    },
    setMenuOpened(state, data) {
        Vue.set(state, 'menuOpened', data)
    },
    setHeatMapItems(state, data) {
        Vue.set(state, 'heatMapItems', data)
    }
};
const actions = {
    list({commit, rootGetters}) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/layers').then(response => {
            let sublayers = response.data.data;

            const dbData = rootGetters['State/checkboxes'];
            sublayers.map(function (item, index) {
                item.sub_layers.map(function (subLayer, subLayerIndex) {
                    const localStatus = localStorage.getItem('layers.' + subLayer.code);
                    if (localStatus) {
                        sublayers[index].sub_layers[subLayerIndex].checked = true;
                        if (dbData !== null && dbData !== undefined && dbData[subLayer.code] !== undefined) {
                            sublayers[index].sub_layers[subLayerIndex].checked = dbData[subLayer.code]['value'];
                        }
                    }
                    if (dbData !== null && dbData !== undefined && dbData[subLayer.code] !== undefined) {
                        sublayers[index].sub_layers[subLayerIndex].checked = dbData[subLayer.code]['value'];
                    }

                })
            })
            commit('setLayers', sublayers);
            commit('setSubLayers', sublayers);

        })
    },
    activateSubLayer(ctx, code) {
        localStorage.setItem('layers.' + code, '1');
        const layer = state.layerObjects[code];
        if (layer.init_function && layer.init_function.length > 0) {
            ctx.dispatch(layer.init_function + "Activate");
            state.layerObjects[code].checked = true;
            return;
        }
        Object.keys(state.allSubLayers).map(function (item) {
            if (state.allSubLayers[item].code === code) {
                state.allSubLayers[item].checked = true;
            }
        });
    },
    deactivateLayer(ctx, code) {
        localStorage.removeItem('layers.' + code);
        const layer = state.layerObjects[code];
        if (layer === undefined || layer['checked'] === undefined) {
            return false;
        }
        if (0 === layer['checked'] || layer['checked'] === false) {
            return false;
        }
        Object.keys(state.allSubLayers).map(function (item) {
            if (state.allSubLayers[item].code === code) {
                state.allSubLayers[item].checked = false;
            }
        });

        if (layer.init_function && layer.init_function.length > 0) {
            const functionName = layer.init_function + "Deactivate";
            ctx.dispatch(functionName);
            return;
        }
        const rootLayer = state.layers;
        rootLayer.forEach(function (item) {
            item.sub_layers.forEach(function (subItem) {
                if (subItem.code === code) {
                    subItem.checked = false;
                }
            });
        })
        Vue.nextTick(function () {
            ctx.commit('setLayers', rootLayer)
        });
    },
    load_polygon_outlinesActivate({rootGetters, dispatch, commit}, data) {
        vue.nextTick(function () {
            if (isLayerChecked('polygon_outlines') === false) {
                return;
            }
            dispatch('deactivateLayer', 'polygons');
            commit('showPolygonOutlines', true);
        })
    },
    load_polygon_outlinesDeactivate({rootGetters, dispatch, commit}, data) {
        commit('showPolygonOutlines', false);
    },
    load_polygonsActivate({rootGetters, dispatch, commit}, data) {
        vue.nextTick(function () {
            if (isLayerChecked('polygons') === false) {
                return;
            }
            dispatch('deactivateLayer', 'polygon_outlines');
            commit('showPolygons', true);
        })
    },
    load_polygonsDeactivate({rootGetters, dispatch, commit}, data) {
        commit('showPolygons', false);
    },
    load_heatmapActivate({rootGetters, dispatch, commit}, data) {
        vue.nextTick(function () {
            if (isLayerChecked('opportunities_heatmap') === false) {
                return;
            }
            const bounds = rootGetters["State/getBounds"];
            const bbox = [{
                lat: bounds.getNorthWest().lat,
                lng: bounds.getNorthWest().lng
            }, {lat: bounds.getSouthEast().lat, lng: bounds.getSouthEast().lng}];
            axios.get(process.env.VUE_APP_API_URL + '/api/layers/heatmap/' + btoa(JSON.stringify(bbox))).then(function (data) {
                commit('setHeatMapItems', data.data)
            });
        });

    },
    load_heatmapDeactivate({rootGetters, dispatch, commit}, data) {
        commit('setHeatMapItems', [])
    },
    load_propertiesActivate({rootGetters, dispatch, commit}, data) {

        vue.nextTick(function () {
            const layer = state.layerObjects['properties'];
            if (isLayerChecked('properties') === false) {
                return false;
            }
            if (layer.minZoom <= rootGetters['State/currentZoom']) {
                let distance = rootGetters["State/getDistance"];
                if (!distance && data && data.distance) {
                    distance = data.distance;
                }
                if (data === undefined) {
                    data = {center: Lmap.getCenter()}
                }
                axios.get(process.env.VUE_APP_API_URL + '/api/indistance/' + data.center.lat + '/' + data.center.lng + '/km/' + distance).then(response => {
                    let propertyData = response.data.data;
                    dispatch('Search/setPropertyMarkers', propertyData, {root: true});
                    dispatch('Search/searchCustomPropertiesInDistance', data, {root: true})
                });
            } else {
                dispatch('clearProperties', null);
            }
            dispatch('deactivateLayer', 'all_uprns');
        })

    },
    load_propertiesDeactivate({rootGetters, dispatch, commit}) {
        dispatch('clearProperties', null);
    },
    load_all_propertiesActivate({rootGetters, dispatch, commit}, data) {
        vue.nextTick(function () {
            const layer = state.layerObjects['all_uprns'];
            if (isLayerChecked('all_uprns') === false) {
                return false;
            }
            if (layer.minZoom <= rootGetters['State/currentZoom']) {
                const distance = rootGetters["State/getDistance"];
                if (data === undefined) {
                    data = {center: Lmap.getCenter()}
                }
                axios.get(process.env.VUE_APP_API_URL + '/api/indistance/' + data.center.lat + '/' + data.center.lng + '/km/' + distance + '/all').then(response => {
                    let propertyData = response.data.data;
                    dispatch('Search/setPropertyMarkers', propertyData, {root: true});
                    dispatch('Search/searchCustomPropertiesInDistance', data, {root: true})
                });
            } else {
                dispatch('clearProperties', null);
            }
            dispatch('deactivateLayer', 'properties');
        })


    },
    load_all_propertiesDeactivate({rootGetters, dispatch, commit}) {
        dispatch('clearProperties', null);
    },
    load_openreachActivate({rootGetters, dispatch, commit}, data) {
        dispatch('deactivateLayer', 'openreach_usable');
        dispatch('deactivateLayer', 'openreach_unusable');
        dispatch('deactivateLayer', 'openreach_sandbox');
        dispatch('deactivateLayer', 'openreach_usable_sandbox');
        dispatch('deactivateLayer', 'openreach_unusable_sandbox');

    },
    load_openreachDeactivate({rootGetters, dispatch, commit}, data) {
        // commit('State/setOpenReachInfo', {}, {root: true});
        // commit('State/setOpenReachInfoLoaded', false, {root: true});
    },
    load_openreach_usableActivate({rootGetters, dispatch, commit}, data) {
        dispatch('deactivateLayer', 'openreach');
        dispatch('deactivateLayer', 'openreach_sandbox');
        dispatch('deactivateLayer', 'openreach_usable_sandbox');
        dispatch('deactivateLayer', 'openreach_unusable_sandbox');
        dispatch('deactivateLayer', 'openreach_unusable');
    },
    load_openreach_unusableActivate({rootGetters, dispatch, commit}, data) {
        dispatch('deactivateLayer', 'openreach');
        dispatch('deactivateLayer', 'openreach_sandbox');
        dispatch('deactivateLayer', 'openreach_usable_sandbox');
        dispatch('deactivateLayer', 'openreach_usable');
        dispatch('deactivateLayer', 'openreach_unusable_sandbox');
    },
    load_openreach_sandboxActivate({rootGetters, dispatch, commit}, data) {
        dispatch('deactivateLayer', 'openreach');
        dispatch('deactivateLayer', 'openreach_usable_sandbox');
        dispatch('deactivateLayer', 'openreach_unusable_sandbox');
        dispatch('deactivateLayer', 'openreach_unusable');
        dispatch('deactivateLayer', 'openreach_usable');

    },
    load_openreach_usable_sandboxActivate({rootGetters, dispatch, commit}, data) {
        dispatch('deactivateLayer', 'openreach');
        dispatch('deactivateLayer', 'openreach_sandbox');
        dispatch('deactivateLayer', 'openreach_usable');
        dispatch('deactivateLayer', 'openreach_unusable_sandbox');
        dispatch('deactivateLayer', 'openreach_unusable');
    },
    load_openreach_unusable_sandboxActivate({rootGetters, dispatch, commit}, data) {
        dispatch('deactivateLayer', 'openreach');
        dispatch('deactivateLayer', 'openreach_sandbox');
        dispatch('deactivateLayer', 'openreach_usable');
        dispatch('deactivateLayer', 'openreach_unusable');
        dispatch('deactivateLayer', 'openreach_usable_sandbox');
    },
    load_openreach_usableDeactivate({rootGetters, dispatch, commit}, data) {
        // commit('State/setOpenReachInfo', {}, {root: true});
        // commit('State/setOpenReachInfoLoaded', false, {root: true});
    },
    load_openreach_unusableDeactivate({rootGetters, dispatch, commit}, data) {
        // commit('State/setOpenReachInfo', {}, {root: true});
        // commit('State/setOpenReachInfoLoaded', false, {root: true});
    },
    load_openreach_usable_sandboxDeactivate({rootGetters, dispatch, commit}, data) {
        // commit('State/setOpenReachInfo', {}, {root: true});
        // commit('State/setOpenReachInfoLoaded', false, {root: true});
    },
    load_openreach_unusable_sandboxDeactivate({rootGetters, dispatch, commit}, data) {
        // commit('State/setOpenReachInfo', {}, {root: true});
        // commit('State/setOpenReachInfoLoaded', false, {root: true});
    },
    load_openreach_sandboxDeactivate({rootGetters, dispatch, commit}, data) {
        // commit('State/setOpenReachInfo', {}, {root: true});
        // commit('State/setOpenReachInfoLoaded', false, {root: true});
    },
    load_location_photosActivate({rootGetters, dispatch, commit}) {
        if (localStorage['layers.location_photos'] === undefined) {
            return false;
        }
        const layer = state.layerObjects['location_photos'];

        if (layer.minZoom <= rootGetters['State/currentZoom']) {
            dispatch('LocationPhotos/loadPhotoLocationsForPolygon', {}, {root: true});
        } else {
            commit('LocationPhotos/setLocationsWithPhoto', [], {root: true})
        }
    },
    load_location_photosDeactivate({rootGetters, dispatch, commit}, data) {
        commit('LocationPhotos/setLocationsWithPhoto', [], {root: true})
    },
    load_roadworksActivate(ctx, data) {
        console.log('Loading from load_roadworksActivate');

        if (isLayerChecked('roadworks') === false) {
            return false;
        }
        const layer = state.layerObjects['roadworks'];
        if (layer.minZoom <= ctx.rootGetters['State/currentZoom']) {
            return new Promise((resolve, reject) => {
                Vue.nextTick(function () {
                    const bounds = Lmap.getBounds()
                    ctx.dispatch('RoadWorks/getWithinEnvelope', {
                        polygon: btoa(CommonTools.createPolygonFromBounds(bounds, Lmap.getCenter())),
                        start_date: ctx.rootGetters['RoadWorks/getStartDate'],
                        end_date: ctx.rootGetters['RoadWorks/getEndDate'],
                    }, {root: true}).then(response => {
                        resolve(response)
                    }).catch(error => {
                        reject(error);
                    })
                })
            })
        } else {
            ctx.dispatch('load_roadworksDeactivate', null);
        }
    },
    load_roadworksDeactivate(ctx) {
        ctx.commit('RoadWorks/setItems', [], {root: true});
    },
    load_postcodesActivate() {
    },
    load_postcodesDeactivate(ctx) {

    },
    load_sitesActivate(ctx) {
        vue.nextTick(function () {
            const layer = state.layerObjects['sites'];
            if (isLayerChecked('sites') === false) {
                return false;
            }
            ctx.dispatch('Sites/getSites', {}, {root: true});
        });

    },
    load_sitesDeactivate(ctx) {
        ctx.commit('Sites/setSites', [], {root: true});
    },
    load_textsActivate({rootGetters, dispatch, commit}, data) {
        vue.nextTick(function () {
            if (isLayerChecked('texts') === false) {
                return;
            }
            commit('showText', true);
        })
    },
    load_textsDeactivate({rootGetters, dispatch, commit}, data) {
        commit('showText', false);
    },
    clearProperties({dispatch, commit}, data) {
        Vue.nextTick(function () {
            dispatch('Search/setPropertyMarkers', [], {root: true});
            dispatch('Search/setCustomPropertyMarkers', [], {root: true});
            commit('Search/setUprnInfoAvailable', false, {root: true});
            commit('Search/setCustomUprnInfoAvailable', false, {root: true});
        });
    },

};
const getters = {
    getActiveLayers: state => {
        return Object.keys(state.allSubLayers).map(key => state.allSubLayers[key]).filter(function (i) {
            return (i.checked === 1 || i.checked === true) && i.type === 'geoserver';
        });
    },
    getPolygonStatus: state => {
        return state.showPolygons
    },
    getPolygonOutlineStatus: state => {
        return state.showPolygonOutlines
    },
    getMenuOpened: state => {
        return state.menuOpened
    },
    getHeatMapItems: state => {
        return state.heatMapItems
    },
    getTextStatus: state => {
        return state.showText;
    },
    getAllLayers: state => {
        return state.layers
    },
    layersObject: state => {
        return state.layerObjects
    }
};

function isLayerChecked(code) {
    const layer = state.layerObjects[code];
    // console.log('checking ',layer,state.layerObjects[code]['checked'])
    if (layer === undefined) {
        return false;
    }
    const layer_checked = state.layerObjects[code]['checked'];
    if (0 === layer_checked || layer_checked === false) {
        return false;
    }
    return true;
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}