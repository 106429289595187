<template>
  <div>
    <div class="sidenav" v-bind:class="{ active: showWindow}">
      <div class="d-flex flex-column h-100">
        <div><i class="fas fa-times float-right" @click="closeWindow()"></i></div>
        <div class="flex-fill">
          <b-overlay :show="showOverlay" rounded="sm">
            <div class="p-2 include-exclude-table">
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Plant Item</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="includedItem in selectedItems"
                    :key="includedItem.properties.objectid">
                  <td @mouseover="focusItem(includedItem.properties.objectid)"><strong class="cursor-pointer"
                  >{{
                      includedItem.properties.objectid
                    }}</strong></td>
                  <td>{{ includedItem.properties.plant_item }}</td>
                  <td><i class="fas fa-times cursor-pointer" @click="removeItem(includedItem.properties.objectid)"></i>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </b-overlay>
        </div>
        <div class="d-flex justify-content-between pl-2 pr-4 pt-2">
          <button class="btn btn-success" @click="showSubmitModalWindow" :disabled="selectedItems.length===0">Continue
          </button>
          <button class="btn btn-warning" @click="closeWindow">Cancel</button>
        </div>
      </div>
    </div>
    <b-modal id="leadInModal" title="Submit LeadIn" @ok="submitNoi($event)" ok-title="Submit">
      <b-form>
        <b-form-group label="PIA NOI Number">
          <multiselect v-model="leadin.pia_noi_number" :options="this.loadedNois" :searchable="true"
                       :close-on-select="true" :show-labels="false" :multiple="false" track-by="name"
                       label="name"></multiselect>
        </b-form-group>
        <b-form-group label="Start Date">
          <b-datepicker placeholder="Start Date" :min="this.date" format="YYYY-MM-DD"
                        v-model="leadin.startDate"/>
        </b-form-group>
        <b-form-group label="Quantity">
          <b-input v-model="leadin.quantity" type="number"/>
        </b-form-group>
        <b-form-group label="Attachment Type">
          <b-select v-model="leadin.attachment_type">
            <b-select-option value="OH">Overhead</b-select-option>
            <b-select-option value="UG">Underground</b-select-option>
          </b-select>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CommonTools from "@/Helpers/CommonTools";
import L from "leaflet";
import Multiselect from "vue-multiselect";
import RelatedPartyList from "@/InternalPlugins/Openreach/RelatedParty/List/RelatedPartyList.vue";

export default {
  name: "LeadInWindow",
  components: {RelatedPartyList, Multiselect},
  data() {
    return {
      showOverlay: true,
      date: null,

      itemsLayerGroup: new L.LayerGroup(),
      itemLayers: new Map(),
      loadedNois: [],
      leadin: {
        objects: [],
        pia_noi_number: null,
        startDate: null,
        quantity: 1,
        attachment_type: 'OH',
        project_id: null,
      }

    }
  },
  watch: {
    selectedItems(val) {
      const self = this;
      if (!window.Lmap.hasLayer(this.itemsLayerGroup)) {
        window.Lmap.addLayer(this.itemsLayerGroup)
      }
      this.itemsLayerGroup.clearLayers();

      if (val.length > 0) {
        val.forEach(layer => {
          if (layer.geometry.type === "Point") {
            const marker = L.circleMarker([layer.geometry.coordinates[1], layer.geometry.coordinates[0]], {
              radius: 4,
              fillColor: "#0050cc",
              opacity: 1,
              fillOpacity: 0.8,
              id: layer.properties.objectid
            });
            self.itemsLayerGroup.addLayer(marker);
            marker.type = 'marker';
            self.itemLayers.set(layer.properties.objectid, marker);
          } else if (layer.geometry.type === 'LineString') {
            let reversedCoords = layer.geometry.coordinates.map(pair => [pair[1], pair[0]]);
            const linestring = new L.Polyline(reversedCoords)
            self.itemsLayerGroup.addLayer(linestring);
            linestring.type = 'linestring';
            self.itemLayers.set(layer.properties.objectid, linestring);
          }
        })
      }

    },
    showWindow(val) {
      if (val) {
        this.showOverlay = false;
        // Enable openreach usable layer
        this.$root.$emit('ACTION_HIDE_UPRN_INFO');
        this.$store.dispatch('LayersList/activateSubLayer', 'openreach_usable')

      } else {
        this.$store.dispatch('Openreach/emptyLeadInSelectedItems');
      }

    }
  },
  computed: {
    ...mapGetters({
      showWindow: 'Openreach/showOpenreachLeadInWindow',
      editId: 'Openreach/editId',
      project: "Projects/selectedProject",
      selectedItems: "Openreach/leadInSelectedItems",
      profile: 'Profile/getUser',
      uprn: 'Openreach/leadInUprn',
    }),
  },
  methods: {
    ...mapActions({
      removeItem: "Openreach/removeLeadInNoi",

    }),

    closeWindow() {
      this.$store.commit('Openreach/setOpenreachLeadInWindow', false)
      this.itemLayers.clear();
    },
    submitNoi($event) {
      this.leadin.objects = this.selectedItems.map(item => {
        return {type: item.geometry.type === 'Point' ? 'Structure' : 'Duct', objectid: item.properties.objectid};
      });
      if (this.leadin.objects.length === 0) {
        this.$swal("", "No object selected", "error")
        $event.preventDefault();
        return;
      }
      if (this.leadin.pia_noi_number === null) {
        this.$swal("", "PIA NOI Number should be selected", "error")
        $event.preventDefault();
        return;
      }
      if (!this.leadin.quantity || this.leadin.quantity === 0) {
        this.$swal("", "Quantity should be greater than 0", "error")
        $event.preventDefault();
        return;
      }
      if (!this.leadin.attachment_type) {
        this.$swal("", "Attachment Type should be selected", "error")
        $event.preventDefault();
        return;
      }
      this.leadin.project_id = this.project.id;
      const data = JSON.parse(JSON.stringify(this.leadin))
      data.uprn = this.uprn;
      this.showOverlay = true;
      this.$store.dispatch('Openreach/submitLeadIn', data).then(response => {
        this.$swal('Success', 'LeadIn Created successfully', 'success');
        this.closeWindow();
        this.showOverlay = false;
      }).catch(error => {
        if (!CommonTools.displayCatch(error)) {
          this.$swal('Error', 'There was an error while submitting LeadIn', 'error');
        }
        this.showOverlay = false;
      });
    },

    resetSubmitData() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const startDate = new Date(today)
      startDate.setDate(startDate.getDate())
      this.leadin.pia_noi_number = null;
      this.leadin.startDate = startDate;
      this.date = startDate;
      this.leadin.quantity = 1;
    },
    showSubmitModalWindow() {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/getOpenreachNOIItems', {project_id: this.project.id}).then(response => {
        this.showOverlay = false;
        if (response.data.length === 0) {
          this.$swal("", "There is no NOI number in system", "error")
          return;
        }

        this.loadedNois = [];
        this.resetSubmitData();
        const self = this;
        response.data.forEach(function (value) {
          self.loadedNois.push({
            value: value.id,
            name: `${value.reference} (${value.openreach_id})`,
            noi: value.openreach_id
          })
        });
        this.$bvModal.show('leadInModal')
      });
    },

    focusItem(objectId) {
      if (this.itemLayers.has(objectId)) {
        const matched = this.itemLayers.get(objectId);
        if (matched.type === 'marker') {
          window.Lmap.setView(matched._latlng)
        } else {
          window.Lmap.fitBounds(matched.getBounds())
        }
      }
    }
  }
}
</script>

<style scoped>
.sidenav {
  display: none;
  width: 350px;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  overflow-x: hidden;
  height: 100%;
  box-shadow: 0px 20px 15px -7px #000000, 0px 5px 13px 5px rgba(0, 0, 0, 0);
  background: white;
}


.sidenav.active {
  display: block !important;
}

.openreachinforcontainer {
  background: white;
}

.include-exclude-table {
  overflow: auto;
}

.table {
  overflow: auto;
}
</style>