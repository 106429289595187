import StaticPopup from "@/js/Popups/StaticPopup";

class NutsPopup extends StaticPopup {
    constructor(location, data, showGeoJson, VueStore) {
        super(location, data, showGeoJson, VueStore)
    }

    getContent() {
        const letter = this.data.features['0'].properties.nuts118cd.replace('UK', '');
        const name = 'NUTS Region: ' + letter + ' (' + this.data.features['0'].properties.nuts118nm.replace(' (England)', '') + ')';
        return name;
    }
}

export default NutsPopup;