/* eslint-disable */
const Utils = {
    isMobile: function() {
        const pattern = /(samsungbrowser|SamsungBrowser|android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i;
        return pattern.test(navigator.userAgent)
    },
    isIphone: function() {
        return navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad");
    },


}
export default Utils;