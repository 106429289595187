<template>
  <b-modal title="Edit contact" id="editSiteContact"  @ok="updateContact">
    <b-overlay :show="isLoading">
      <div v-if="contactInfo">
      <b-form-group>
        <label for="name">Name:</label>
        <b-form-input
            id="name"
            v-model="contactInfo.name"
            trim
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="phone">Phone:</label>
        <b-form-input
            id="phone"
            v-model="contactInfo.phone"
            trim
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="name">Email:</label>
        <b-form-input
            id="email"
            v-model="contactInfo.email"
            trim
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="note">Notes:</label>
        <b-form-input
            id="note"
            v-model="contactInfo.note"
            trim
        ></b-form-input>
      </b-form-group>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EditSiteContact",
  computed:{
    ...mapGetters({
      siteId:'Sites/siteIdForInfo',
      contactInfo:'Sites/contact',
      isLoading:'Sites/isLoading',

    })
  },
  methods:{
    ...mapActions({
      updateContactCtx:'Sites/updateContact'
    }),
    updateContact(e){
      e.preventDefault();
      this.updateContactCtx(this.contactInfo).then(()=>{
        this.$store.dispatch('Sites/getContacts',this.siteId);
        this.$store.commit('Sites/setContact',null);
        this.$bvModal.hide('editSiteContact');
      })
    }
  }
}
</script>

<style scoped>

</style>