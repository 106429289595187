import { render, staticRenderFns } from "./OpenreachWhereAbouts.vue?vue&type=template&id=177ede5c&scoped=true"
import script from "./OpenreachWhereAbouts.vue?vue&type=script&lang=js"
export * from "./OpenreachWhereAbouts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177ede5c",
  null
  
)

export default component.exports