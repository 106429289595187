L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
    callerObj: {},
    methodName: '',
    onAdd: function (map) {
        // Triggered when the layer is added to a map.
        //   Register a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onAdd.call(this, map);
        map.on('click', this.getFeatureInfo, this);
    },

    onRemove: function (map) {
        // Triggered when the layer is removed from a map.
        //   Unregister a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onRemove.call(this, map);
        map.off('click', this.getFeatureInfo, this);
    },

    getFeatureInfo: function (evt) {
        if (window.Lmap.pm.globalRemovalModeEnabled() || window.Lmap.pm.globalEditModeEnabled() || window.Lmap.pm.globalDrawModeEnabled()) {
            return;
        }
        // Make an AJAX request to the server and hope for the best
        let url = this.getFeatureInfoUrl(evt.latlng);
        let showResults = '';
        if (this.methodName.length > 0) {
            showResults = L.Util.bind(this.callerObj[this.methodName], this);
        } else {
            showResults = L.Util.bind(this.showGetFeatureInfo, this);
        }
        $.ajax({
            url: url,
            success: function (data, status, xhr) {
                var err = typeof data === 'string' ? null : data;
                showResults(err, evt.latlng, data);
            },
            error: function (xhr, status, error) {
                showResults(error);
            }
        });
    },

    getFeatureInfoUrl: function (latlng) {
        // Construct a GetFeatureInfo request URL given a point
        let point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
            size = this._map.getSize();
        let layer = this.wmsParams.layers;
        if (this.wmsParams.layers.startsWith('RoadsV2')) {
            layer = 'RoadsV2:street'
        }
        let params = {
            request: 'GetFeatureInfo',
            service: 'WMS',
            srs: 'EPSG:4326',
            styles: this.wmsParams.styles,
            transparent: this.wmsParams.transparent,
            version: this.wmsParams.version,
            format: this.wmsParams.format,
            bbox: this._map.getBounds().toBBoxString(),
            height: size.y,
            width: size.x,
            layers: layer,
            query_layers: layer,
            info_format: 'application/json',
            FEATURE_COUNT: 100,
        };

        params[params.version === '1.3.0' ? 'i' : 'x'] = parseInt(point.x);
        params[params.version === '1.3.0' ? 'j' : 'y'] = parseInt(point.y);


        return this._url + L.Util.getParamString(params, this._url, true);

    },

    showGetFeatureInfo: function (err, latlng, content) {
        if (err) {

            return;
        } // do nothing if there's an error

        // Otherwise show the content in a popup, or something.
        L.popup({maxWidth: 800})
            .setLatLng(latlng)
            .setContent(content)
            .openOn(this._map);
    }
});

L.tileLayer.betterWms = function (url, options, obj, clickHandler) {

    const layer = new L.TileLayer.BetterWMS(url, options);
    layer.callerObj = obj;
    layer.methodName = clickHandler;
    return layer;
};