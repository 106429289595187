import Vue from "vue";
import {BModal} from "bootstrap-vue";

const RelatedPartyEditModal = Vue.extend({
    components: {
        BModal
    },
    props: {
        title: {
            type: String,
            default: "Update Related Party",
        },
        relatedParty: {
            type: Object,
            default: {
                firstName: '',
                lastName: '',
                role: '',
                email: '',
                alternateEmail: '',
                telephone: '',
                secondaryTelephone: '',

            }
        },
        callback: {
            type: Function
        },
        usedRelatedParty: {
            type: Array,
            default: []
        },
        roles: {
            type: Array,
            required: false,
            default: ["Customer", "Agent Contact", "Alternate Contact", "DFE Contact", "Job Contact"]
        },
    },
    data() {
        return {
            relatedPartyData: this.relatedParty,
            localRoles: this.roles


        }
    },
    computed: {
        inputFirstName: {
            set(value) {
                this.relatedPartyData.firstName = value
            },
            get() {
                return this.relatedParty.firstName
            }

        },
        restRoles: function () {
            return this.roles.filter(role => !this.usedRelatedParty.includes(role))
        }
    },
    methods: {
        ok() {
            this.callback(this.relatedParty)
        }
    },
    template: `
      <b-modal id="edit_related_party" title="Update" @ok="ok">
        <b-form>
          <div class="d-flex">
            <b-form-group class="mr-1 w-100" label="First Name">
              <b-form-input v-model="relatedPartyData.firstName"/>
            </b-form-group>
            <b-form-group class="ml-1 w-100" label="Last Name">
              <b-form-input v-model="relatedPartyData.lastName"/>
            </b-form-group>
          </div>

          <b-form-group label="Role">
            <b-select v-model="relatedPartyData.role" :options="restRoles"/>
          </b-form-group>
          <div class="d-flex">
            <b-form-group class="mr-1 w-100" label="Email">
              <b-form-input v-model="relatedPartyData.email"/>
            </b-form-group>
            <b-form-group class="ml-1 w-100" label="Alternate Email">
              <b-form-input v-model="relatedPartyData.alternateEmail"/>
            </b-form-group>
          </div>
          <div class="d-flex">
            <b-form-group class="mr-1 w-100" label="Phone Number">
              <b-form-input v-model="relatedPartyData.telephone"/>
            </b-form-group>
            <b-form-group class="ml-1 w-100" label="Alternate Phone Number">
              <b-form-input v-model="relatedPartyData.secondaryTelephone"/>
            </b-form-group>
          </div>
        </b-form>
      </b-modal>
    `
});
export default {
    install: (app) => {
        app.prototype.$editRelatedParty = (config) => {
            const instance = new RelatedPartyEditModal({
                propsData: {
                    title: config.title,
                    callback: config.callback,
                    relatedParty: config.relatedParty,
                    usedRelatedParty: config.usedRelatedParty,
                    roles: config.roles
                }
            });
            instance.$mount();
            instance.$bvModal.show('edit_related_party');
            document.body.appendChild(instance.$el);
            return null;
        }
    }
}