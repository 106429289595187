const ObjectTypesEnum = {
    OT_PATCH_PANEL: "OT_PATCH_PANEL",
    OT_POLE: "OT_POLE",
    OT_STREET_CABINET: "OT_STREET_CABINET",
    OT_CHAMBER: "OT_CHAMBER",
    OT_SPLICING_DOME: "OT_SPLICING_DOME",
    OT_SPLITTER: "OT_SPLITTER",
    getModalId: function (code) {
        switch (code) {
            case ObjectTypesEnum.OT_POLE: {
                return 'PoleInfo';
                break;
            }
            case ObjectTypesEnum.OT_SPLITTER: {
                return 'splitter_info';
                break;
            }
            case ObjectTypesEnum.OT_CHAMBER: {
                return 'ChamberInfo';
                break;
            }
            case ObjectTypesEnum.OT_PATCH_PANEL: {
                return 'PatchPanelInfo';
                break;
            }
            case ObjectTypesEnum.OT_SPLICING_DOME: {
                return 'SplicingDomeInfo';
                break;
            }
            case ObjectTypesEnum.OT_STREET_CABINET: {
                return 'StreetCabinetInfo';
                break;
            }
            default: {
                throw new Error('Unknown object type');
            }
        }
    }
}

export default ObjectTypesEnum;