<template></template>
<script>
import './lib/BetterWMS';
import {findRealParent, optionsMerger, propsBinder} from 'vue2-leaflet/src/utils/utils';

import TileLayerWMS from 'vue2-leaflet/src/mixins/TileLayerWMS'
import Options from 'vue2-leaflet/src/mixins/Options';
import * as L from 'leaflet';
import {DomEvent} from 'leaflet';
import vue from "vue";
import RoadsPopupAction from "@/js/Popups/RoadsPopupAction";
import NutsPopup from "@/js/Popups/NutsPopup";
import PostcodePopup from "@/js/Popups/PostcodePopup";
import BasicPolygonPopup from "@/js/Popups/BasicPolygonPopup";

export default {
  name: "BetterWMS",
  mixins: [TileLayerWMS, Options],
  data() {
    return {
      mapObject: {},
    }
  },
  props: {
    baseUrl: {
      type: String,
      default: null
    },
    clickHandler: {
      type: String,
      default: null
    }
  },
  mounted() {
    const options = optionsMerger(this.tileLayerWMSOptions, this);
    this.mapObject = L.tileLayer.betterWms(this.baseUrl, options, this, this.clickHandler)

    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$options.props);
    this.parentContainer = findRealParent(this.$parent);

    this.parentContainer.addLayer(this, !this.visible);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit('ready', this.mapObject);
    });
  },
  methods: {
    toggleTilePlanPerimeterInfo() {
      const infoState = this.$store.getters.State.getPerimeterDetailsPolygon()
      this.$store.commit('State/setTilePlanPerimeterShow', !infoState);
    },
    postCodesClickHandler(err, latlng, data) {
      if (data.features.length > 0) {
        const popup = new PostcodePopup(latlng, data, true, this.$store);
        popup.open();
      }
    },
    // roads Click handler
    roadsClickHandler(err, latlng, data) {
      if (data.features.length > 0) {
        const roadsPopup = new RoadsPopupAction(latlng, data, true, this.$store);
        roadsPopup.open();
      }
    },
    openReachClickHandler(err, latlng, data) {
      const currentZoom = this.$store.getters['State/currentZoom']
      const openreachLayer = this.$store.getters['LayersList/layersObject']['openreach'];
      const openreachUsableLayer = this.$store.getters['LayersList/layersObject']['openreach_usable'];
      const openreach = openreachLayer['checked'];
      const openreach_usable = this.$store.getters['LayersList/layersObject']['openreach_usable']['checked'];
      if (openreach && openreachLayer.minZoom > currentZoom) {
        return;
      }
      if (openreach_usable && openreachUsableLayer.minZoom > currentZoom) {
        return;
      }
      if (data.features.length > 0) {
        this.$store.commit('OpenreachDuctsAndStructures/setAvailable', true, {root: true});
        this.$store.commit('OpenreachDuctsAndStructures/setFeatures', data.features, {root: true});
        this.$store.commit('OpenreachDuctsAndStructures/setLocation', latlng, {root: true});
      }
    },
    nutsClickHandler(err, latlng, data) {
      if (data.features.length > 0) {
        const popup = new NutsPopup(latlng, data, true, this.$store);
        popup.open();
      }
    },
    boundariesClickHandler(err, latlng, data) {
      if (data.features.length > 0) {
        let name = '';
        if (data.features['0'].properties.name !== undefined && data.features['0'].properties.name !== null) {
          name = data.features['0'].properties.name;
        } else if (data.features['0'].properties.file_name !== undefined) {
          name = data.features['0'].properties.file_name.replaceAll("_", " ").capitalize();
        }

        if (name.length > 0) {
          const popup = new BasicPolygonPopup(latlng, data, true, this.$store, name);
          popup.open();
        }
      }
    },
    mobileProviderInfoClickHandler(err, latlng, data) {
      let text = '';
      if (data.features.length > 0) {
        text = "id: " + data.features[0].id + "<br /> X : " + data.features[0].properties.x + "\n" + "Y : " + data.features[0].properties.y + " color: " + data.features[0].properties.color;

        const popup = new BasicPolygonPopup(latlng, data, true, this.$store, text);
        popup.open();
      }
    },
    tilePlansClickHandler(err, latlng, data) {
      if (data.features.length > 0) {
        this.$store.commit('TilePlan/setTilePlanIsAvailable', true, {root: true});
        this.$store.commit('TilePlan/setFeatures', data.features, {root: true});
        this.$store.commit('TilePlan/setLatLng', latlng, {root: true});
      }
    },
    countriesClickHandler(err, latlng, data) {

      if (data.features.length > 0) {
        let name = String(data.features['0'].properties.nuts118nm);

        if (name.length > 0) {
          const popup = new BasicPolygonPopup(latlng, data, true, this.$store, name);
          popup.open();
        }
      }
    },
    BuildingsClickHandler(err, latlng, data) {
      if (data.features[0] !== undefined) {
        const currentMap = vue.prototype.map;
        const zoom = currentMap.getZoom();
        const pixelsPerCluster = 20

        const metersPerPx = 156543.03392 * Math.cos(latlng.lat * Math.PI / 180) / Math.pow(2, zoom)
        const size = pixelsPerCluster * metersPerPx;
        this.$store.dispatch('Search/getBuildings', {lat: latlng.lat, lng: latlng.lng, size: size})

        L.polygon(data.features[0].properties.geomBBOX.coordinates[0]).addTo(currentMap);
      }
    },
    nationalParksClickHandler(err, latlng, data) {

      if (data.features.length > 0) {

        const content = data.features['0'].properties.name;
        // Otherwise show the content in a popup, or something.
        if (content !== undefined && content.length > 0) {
          const popup = new BasicPolygonPopup(latlng, data, true, this.$store, content);
          popup.open();
        }
      }
    }
  }
}
</script>