import axios from 'axios';

const state = {
    ductTypes: [],
    cableTypes: [],
    parentTube: null

};
const mutations = {
    setDuctTypes(state, data) {
        state.ductTypes = data;
    },
    setParentTube(state, data) {
        state.parentTube = data;
    },
    setCableTypes(state, data) {
        state.cableTypes = data;
    },
};
const getters = {
    ductTypes: state => {
        return state.ductTypes;
    },
    cableTypes: state => {
        return state.cableTypes;
    },
    parentTube: state => {
        return state.parentTube;
    }
};
const actions = {
    getDuctTypes({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/type').then(response => {
                commit('setDuctTypes', response.data.data)
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getCables({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/type').then(response => {
                commit('setCableTypes', response.data.data)
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    store({commit}, data) {
        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/type', data);
    },
    // eslint-disable-next-line no-unused-vars
    update({commit}, data) {
        return axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/type/' + data.id, data);
    },
    // eslint-disable-next-line no-empty-pattern
    remove({}, ductTypeId) {
        return new Promise((resolve, reject) => {
            axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/type/' + ductTypeId).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}