<template>
  <b-modal id="create_splitter_modal" ok-title="Save" cancel-title="Reset" @ok="onSubmit($event)"
           @cancel="onReset($event)" @close="close()" title="Create Splitter" body-class="object_type_modal_body">
    <b-overlay :show="loading">
      <div>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group id="input-group-1" label="Object name:" label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="object.name"
                placeholder="Enter object name"
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="output-ports" label="Output Ports:" label-for="output-ports">
            <b-form-input
                id="output-ports"
                v-model="object.output_ports"
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-7" label="Color" label-for="input-7">
            <v-swatches v-model="object.color" popover-x="left"
                        swatches="text-advanced"></v-swatches>
          </b-form-group>
        </b-form>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  components: {VSwatches},
  name: "Splitter",
  data() {
    return {
      loading: false,
      object: {
        name: null,
        color: null,
        output_ports: null,
        code: ObjectTypesEnum.OT_SPLITTER.toLowerCase()

      }
    }
  },
  methods: {
    close() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)
    },
    onSubmit(event) {
      this.loading = true;
      event.preventDefault()
      this.$store.dispatch('ObjectTypes/store', this.object).then(() => {
        this.loading = false;
        this.$bvModal.hide("create_splitter_modal");
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our object values
      this.object.name = null
      this.object.output_ports = null
      this.object.color = null
      // Trick to reset/clear native browser form validation state
    }
  },
  created() {
    const self = this
    this.$root.$on('OT_SPLITTER', function (event) {
      self.$bvModal.hide("create_object_type_modal");
      self.$bvModal.show("create_splitter_modal");
    })
  }
}
</script>

<style scoped>
.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}
</style>