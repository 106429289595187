import L from "leaflet";
import CommonTools from "@/Helpers/CommonTools";
import {mapGetters} from "vuex";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

const ObjectMarkerToolBar = {
    watch: {
        selectedObjectType(type) {
            this.map.pm.removalMode = false;
            this.map.pm.enableDraw('ObjectMarker', {
                markerStyle: {
                    icon: L.icon({
                        iconUrl: process.env.VUE_APP_API_URL + '/api/svg/' + type.color.replace("#", "") + "/0",
                        iconSize: [16, 16],
                    })
                }, tooltips: false, continueDrawing: true,
            });
        },
    }, data: function () {
        return {
            objectMakersLayerGroup: L.layerGroup()
        }
    }, computed: {
        ...mapGetters({
            selectedObjectType: 'ObjectMarker/selectedObjectMarker',
            selectedObjectCode: 'ObjectMarker/selectedObjectCode',
            objectMakers: 'ObjectMarker/objectMarkers',
            markerInfo: 'ObjectMarker/makerInfo',
            isInElevationSelectMode: 'Elevation/elevationShown'
        }),
    }, methods: {
        drawMarkers() {
            const self = this;
            this.$store.dispatch('ObjectMarker/list', CommonTools.createPolygonFromBounds(this.map.getBounds(), this.map.getCenter())).then(response => {
                self.objectMakersLayerGroup.clearLayers()
                response.map(function (object) {
                    const icon = L.icon({
                        iconUrl: process.env.VUE_APP_API_URL + '/api/svg/' + object.object_type.color.replace("#", "").toUpperCase() + '/' + object.is_implemented,
                        iconSize: [16, 16],
                    });
                    L.geoJSON(object.feature, {
                        onEachFeature: function (feature, layer) {
                            layer.id = object.id;
                            layer.sequence_id = object.sequence_id;
                            layer.project_id = object.project_id;
                            layer.shapeType = 'Object';
                            layer.objectType = object.object_type;
                            self.registerEventsForLayer(layer)
                            self.livePendingHover(layer, 'Object', object);
                            self.objectMakersLayerGroup.addLayer(layer);
                        }, pointToLayer: function (feature, latlng) {
                            return L.marker(latlng, {icon: icon});
                        },
                    });
                    self.objectMakersLayerGroup.addTo(self.map)
                });
            }).catch(() => {
            });
        },
        clearObjects() {
            this.objectMakersLayerGroup.clearLayers()
            this.$store.commit("ObjectMarker/setObjectMarkers", []);
        },
        objectCreatingAction(e) {
            const self = this;
            self.$store.dispatch("ObjectMarker/store", {
                point: btoa(JSON.stringify(e.layer.toGeoJSON())),
                sub_type_id: self.selectedObjectType.id,
                object_type_code: self.selectedObjectCode
            }).then(() => {
                e.layer.remove()
                self.drawMarkers()
            })
            this.$store.commit("ObjectMarker/setIsInSelectMode", false);
        },
        objectStartAction(e) {
            if (this.isInElevationSelectMode) {
                return;
            }
            this.drawShowObjectMarkerListChooseModal();
        },
        drawShowObjectMarkerListChooseModal() {
            this.$store.commit("ObjectMarker/setIsInSelectMode", true);
            this.$bvModal.show("object_type_list");
        }, changeObjectImplementationStatus(obj, status) {
            this.$store.dispatch("ObjectMarker/changeImplementedStatus", {
                id: obj.id, status: status
            }).then(() => {
                this.drawMarkers()
            });
        }, registerEventsForLayer(layer) {
            const self = this;
            layer.bindTooltip('O-' + layer.sequence_id + ' <strong>(' + layer.objectType.name + ')</strong>')
            layer.on('pm:dragend', e => {
                self.$store.dispatch("ObjectMarker/update", {
                    id: e.target.id, params: {point: btoa(JSON.stringify(e.target.toGeoJSON()))}
                });
            });
            layer.on('pm:remove', e => {
                self.$store.dispatch("ObjectMarker/delete", e.target.id, {root: true});
            });
            layer.on('click', function (e) {
                self.clickEvent(e.target, e.target.id, e.target.project_id);
            });
            layer.on('mouseover', function () {
                layer.openTooltip();
            });
            layer.on('mouseout', function () {
                layer.closeTooltip();
            });

        }, clickEvent(layer, id, projectId) {
            if (this.livePendingVisible) {
                return;
            }
            if (this.map.pm.globalRemovalModeEnabled() || this.map.pm.globalDragModeEnabled()) {
                return;
            }
            if (this.map.pm.globalDrawModeEnabled() || this.map.pm.globalEditModeEnabled()) {
                this.map.fire('click', this.map);
                return;
            }
            this.$store.dispatch('ObjectInfo/showObjectInfo', {id: id, code: layer.objectType.code}).then(response => {
                if (response) {
                    const modalId = ObjectTypesEnum.getModalId(response.object_type.code);
                    this.$bvModal.show(modalId);
                }
            });
        }, addCustomDraw() {
            const self = this;
            const actions = [{
                text: 'Finish', onClick: () => {
                    self.map.pm.disableDraw('ObjectMarker')
                    // self.map.pm.Draw.ObjectMarker.disable()
                }
            },]
            this.map.pm.Toolbar.copyDrawControl('Marker', {
                name: 'ObjectMarker',
                block: 'draw',
                title: 'New Object',
                actions: actions,
                onClick: function () {
                    self.drawShowObjectMarkerListChooseModal();
                }
            });
        },
    }
}
export default ObjectMarkerToolBar;