import axios from "axios";
import Vue from "vue";

const state = {
    pia: {},
    submittedNOIs: [],
    editId: 0,
    evidencePiaNumber: null,
    updatableWhereAbout: {},
    leadInSelectedItems: [],
    leadInUprn: null,
    showOpenreachLeadInWindow: false,
};
const actions = {
    emptyLeadInSelectedItems({commit, state}, data) {
        commit('clearLeadInItems');
    },
    updateOpenreachCredentials({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + '/api/openreach/pia', data)
                .then(resp => {
                    commit('setOpenreachPia', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getOpenreachItems(ctx, data) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/openreach/pia/items/' + data.projectId);
    },
    getOpenreachNOIItems(ctx, data) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.project_id);
    },
    submitNoi(ctx, data) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectid, data.data);
    },
    submitDraftNoi(ctx, data) {
        return axios.patch(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.project_id + '/' + data.id);
    },
    submitLeadIn(ctx, data) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/openreach/leadin', data);
    },
    deleteNoi(ctx, data) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectId + '/' + data.id);
    },
    cancelNoi(ctx, data) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectId + '/' + data.id + '/cancel');
    },
    viewNoi(ctx, data) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectId + '/' + data.id);
    },
    editNoi(ctx, data) {
        return axios.put(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectid + '/' + data.id, data.data);
    },
    importNois(ctx, data) {
        return axios.put(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectId + '/import', {nois: data.nois});
    },
    updateNoiReference(ctx, data) {
        return axios.put(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.project_id + '/' + data.id + '/reference', {reference: data.reference});
    },
    uploadEvidence(ctx, data) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/openreach/pia/evidence/' + data.piaNumber, data.form);
    },
    listEvidences(ctx, data) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/openreach/pia/evidence/' + data.piaNumber);
    },
    deleteRequest(ctx, data) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/openreach/pia/noi/' + data.projectId + '/' + data.piaNumber);
    },
    removeEvidence(ctx, data) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/openreach/pia/evidence/' + data.piaNumber + '/' + data.evidenceId);
    },
    viewEvidence(ctx, data) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/openreach/pia/evidence/' + data.piaNumber + '/' + data.evidenceId);
    },
    saveWhereAbout(ctx, data) {
        if (data.id !== undefined && data.id !== null) {
            return axios.put(process.env.VUE_APP_API_URL + '/api/openreach/pia/whereabouts/' + data.id, data);
        }
        return axios.post(process.env.VUE_APP_API_URL + '/api/openreach/pia/whereabouts', data);

    },
    getWhereAbouts(ctx, data) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/openreach/pia/whereabouts/' + data.id);
    },
    removeLeadInNoi(ctx, objectId) {
        const rest = ctx.state.leadInSelectedItems.filter(item => item.properties.objectid !== objectId);
        Vue.set(state, 'leadInSelectedItems', rest);
    },

};
const mutations = {
    setOpenreachPia(state, data) {
        state.pia = data;
    },
    setSubmittedNOIs(state, data) {
        state.submittedNOIs = data;
    },
    clearLeadInItems(state) {
        Vue.set(state, 'leadInSelectedItems', []);
    },
    addLeadInItem(state, data) {
        const current = state.leadInSelectedItems;
        if (current.filter(item => item.properties.objectid === data.properties.objectid).length === 0) {
            current.push(data);
            Vue.set(state, 'leadInSelectedItems', current);
        }
        // state.leadInSelectedItems.add(data)
    },
    setOpenreachLeadInWindow(state, data) {
        state.showOpenreachLeadInWindow = data.show;
        state.leadInUprn = data.uprn;
    },
    setEditId(state, data) {
        state.editId = data;
    },
    setEvidencePiaNumber(state, data) {
        state.evidencePiaNumber = data;
    },
    setUpdatableWhereAbout(state, data) {
        state.updatableWhereAbout = data;
    },

};
const getters = {

    isInLeadNoi: state => (objectId) => {
        const current = state.leadInSelectedItems;
        return current.filter(item => item.properties.objectid === objectId).length > 0
    },
    nois: state => {
        return state.submittedNOIs;
    },
    pia: state => {
        return state.pia;
    },
    showOpenreachLeadInWindow: state => {
        return state.showOpenreachLeadInWindow
    },
    leadInSelectedItems: state => {
        return state.leadInSelectedItems;
    },
    editId: state => {
        return state.editId
    },
    piaCredentialsValid: state => {
        return Object.keys(state.pia).length !== 0 && state.pia !== null && state.pia.duns_id !== null && state.pia.duns_id.length > 0 && state.pia.secret.length > 0 && state.pia.key.length > 0 && state.pia.state.length > 0;
    },
    evidencePiaNumber: state => {
        return state.evidencePiaNumber;
    },
    updatableWhereAbout: state => {
        return state.updatableWhereAbout;
    },
    leadInUprn: state => {
        return state.leadInUprn;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}