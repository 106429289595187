<script>

export default {
  name: "Settings",
  data() {
    return {
      loading: false,
      forms: {
        loss: {
          splice_loss: null,
          loss_per_km: null,
        }
      }
    }
  },
  methods: {
    input(data) {
      switch (data) {
        case 0:
          this.getLoss()
          break
        default:
          break
      }
    },
    getLoss() {
      this.loading = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct_and_objects/settings').then(data => {
        console.log(data);
        this.forms.loss.splice_loss = data.data.data.splice_loss;
        this.forms.loss.loss_per_km = data.data.data.loss_per_km;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      })
    },
    saveLoss() {
      this.loading = true
      axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct_and_objects/settings', this.forms.loss).then(data => {

      }).finally(() => {
        this.loading = false;
      })
    },
    resetLoss() {

    }
  }
}
</script>

<template>
  <b-modal id="settings" title="Settings" size="xl" ok-only ok-title="Cancel" ok-variant="secondary">
    <div>
      <b-tabs @input="input">
        <b-tab title="Loss" active>
          <b-card-text class="pt-3">
            <b-overlay :show="loading">
              <div>
                <b-form @submit.prevent="saveLoss">
                  <b-form-group
                      id="splice_loss"
                      label="Splice Loss (in dB)"
                      label-for="splice_loss_input"
                  >
                    <b-form-input
                        id="splice_loss_input"
                        v-model="forms.loss.splice_loss"
                        type="number"
                        step="0.01"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="loss_per_km_input" label="Loss Per KM (in dB)" label-for="loss_per_km_input">
                    <b-form-input
                        id="loss_per_km_input"
                        v-model="forms.loss.loss_per_km"
                        type="number"
                        step="0.01"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-button type="submit" variant="primary">Save Loss</b-button>
                </b-form>

              </div>
            </b-overlay>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<style scoped>

</style>