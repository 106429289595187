<template>
  <div>
    <b-modal id="bulk_flag_change" size="md" centered title="Bulk Flag Change"
             @close="close"
             @hidden="close"
             @cancel="close">
      <b-overlay :show="showOverlay" rounded="sm">

      <div class="d-flex flex-column">
        <div>
      <ul v-if="flag===null && this.result.length===0" id='flag_icons' class="col">
        <li :class="{flag_active:(flag===flagItem)}" v-for="flagItem in this.flags"
            :key="flagItem" data-placement='top' :title='flagItem' @click='flag=flagItem'>
          <img :src="'/assets/img/icons/flags/flag_'+flagItem+'.png'"/></li>
      </ul>
        </div>
        <div>
      <textarea v-if="this.flag!==null" class="form-control" rows="20" v-model="properties"></textarea>
        </div>
      <div class="mt-2 p-2 col float-left">
        <ul class="property_flag_result" style="max-height: 500px; overflow-y: auto;">
          <li v-for="uprn in this.result" :key="uprn.uprn">
            <i class="fas fa-check-circle text-success" v-if="uprn.success===1"></i>
            <i class="fas fa-times-circle text-danger" v-if="uprn.success===0"></i>
            {{ uprn.uprn }}

          </li>
        </ul>
      </div>
      </div>

      </b-overlay>
      <template #modal-footer="{ cancel, ok }">
        <b-button  v-if="result.length===0"  @click="cancel">Cancel</b-button>
        <b-button  v-else  @click="cancel">Close</b-button>
        <b-button v-if="flag!==null && properties.length>0" variant="success" @click="submit">Submit</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "BulkFlagChange",
  data: () => {
    return {
      flags: ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'],
      flag: null,
      properties: '',
      result: [],
      showOverlay:false
    }
  },
  methods: {
    setFlag(flag) {
      this.flag = flag
    },
    submit(e) {
      e.preventDefault();
      const propertiesArray = this.properties.trim().split("\n");
      if (propertiesArray.length > 0) {
        this.showOverlay = true;
        const projectId = this.$store.state.Projects.selected_project.id;
        axios.put(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/flags', {
          flag: this.flag,
          uprns: propertiesArray
        }).then(response => {
          this.showOverlay = false;

          this.properties = '';
          this.flag = null;
          this.result = response.data;
        });
      }
    },
    close() {
      this.properties = '';
      this.flag = null;
      this.result = [];
      window.location.reload();
    }
  }
}
</script>

<style scoped>
#flag_icons {
  list-style: none;
  float: left;
  margin-top: 20px;
  padding-bottom: 15px;
}

#flag_icons li {
  float: left;
  text-align: center;
  border: 1px solid lightblue;
  margin-left: 12px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
#flag_icons li img{
  vertical-align: -webkit-baseline-middle;
}
#flag_icons li:active, .flag_active {
  border: 2px solid #3ab3d9 !important;
}

.property_flag_result {
  list-style: none;
}
</style>