<template>
  <div>
    <div v-for="layer in activeGeoserverLayers">
      <l-wms-tile-layer v-if="layer.checked && layer.clickHandler===null"
                        :key="layer.code"
                        :base-url="layer.baseUrl"
                        :layers="layer.layer"
                        :transparent="true"
                        :visible="true "
                        :name="layer.name"
                        :id="layer.code"
                        format="image/png"
                        :options="{minZoom: minZoom,maxZoom:maxZoom}"
                        :zIndex="zIndex+1"
                        :opacity="layer.fillOpacity"
                        :minZoom="minZoom"
                        :maxZoom="maxZoom"
                        layer-type="single">
      </l-wms-tile-layer>
      <l-better-wms-tile-layer v-if="layer.checked && layer.clickHandler!==null"
                               :key="layer.code"
                               :base-url="layer.baseUrl"
                               :layers="layer.layer"
                               :transparent="true"
                               :visible="true "
                               :name="layer.name"
                               format="image/png"
                               :options="{minZoom: minZoom,maxZoom:maxZoom}"
                               :zIndex="zIndex+2"
                               :opacity="layer.fillOpacity"
                               :minZoom="minZoom"
                               :maxZoom="maxZoom"
                               :clickHandler="layer.clickHandler"
                               layer-type="single"
      >
      </l-better-wms-tile-layer>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {LWMSTileLayer} from 'vue2-leaflet';
import BetterWMS from "@/components/mapcomponents/BetterWMS/BetterWMS.vue";

export default {
  name: "SubLayersContainer",
  data() {
    return {
      tileSize: 256,
      zIndex: 100,
      options: {
        height: 256,
      },
    }
  },
  components: {
    'l-wms-tile-layer': LWMSTileLayer,
    'l-better-wms-tile-layer': BetterWMS
  },
  computed: {
    ...mapGetters({
      activeGeoserverLayers: 'LayersList/getActiveLayers',
      minZoom: 'State/getMinZoom',
      maxZoom: 'State/getMaxZoom',
    }),
  }
}
</script>

<style scoped>

</style>