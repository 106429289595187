import axios from "axios";
var UserManagement = /** @class */ (function () {
    function UserManagement(token) {
        this.token = token;
    }
    UserManagement.prototype.getUsers = function () {
        return axios.get("".concat(process.env.VUE_APP_API_URL, "/api/admin/users"));
    };
    UserManagement.prototype.getSubAccounts = function (userId) {
        return axios.get("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/").concat(userId, "/subAccount"));
    };
    UserManagement.prototype.updateUser = function (userId, user) {
        return axios.put("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/").concat(userId), user);
    };
    UserManagement.prototype.deleteUser = function (userId) {
        return axios.delete("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/").concat(userId));
    };
    UserManagement.prototype.addSubAccount = function (userId, userData) {
        return axios.post("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/").concat(userId, "/subAccount"), userData);
    };
    UserManagement.prototype.addMasterAccount = function (userData) {
        return axios.post("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/master"), userData);
    };
    UserManagement.prototype.changeUserStatus = function (userId, status) {
        return axios.put("".concat(process.env.VUE_APP_API_URL, "/api/user/accounts/").concat(userId, "/status/").concat(status));
    };
    return UserManagement;
}());
export default UserManagement;
