<script>
import ExportHistoryClient from '@/Api/ExportHistory/ExportHistoryClient'
import { mapGetters, mapMutations } from 'vuex'
import multiselect from 'vue-multiselect'
import draggable from "vuedraggable";

export default {
  name: 'ExportPropertiesHistory',
  components: {
    multiselect,
    draggable,
  },
  // @todo The default field order should be: UPRN, Address (Multi Field), Coordinates (Multi Field) and Description (Multi Field).
  // You should be able to add any of the following fields:
  // Address - Condensed (Multi Field), Boundaries (Multi Field), Council Tax Band and Business Rates Value (Multi Field), Fixed Line Providers (Multi Field), Mobile Providers (Multi Field)
  data: function () {
    return {
      exportFormTabIndex: 0,
      columns: {
        accepted: [
          { name: "UPRN", id: 1 },
          { name: "Address", id: 2 },
          { name: "Coordinates", id: 3 },
          { name: "Description ", id: 4 }
        ],
        available: [
          { name: "Address - Condensed", id: 5 },
          { name: "Boundaries", id: 6 },
          { name: "Council Tax Band", id: 7 },
          { name: "Business Rates", id: 8 },
          { name: "Fixed Line Providers", id: 9 },
          { name: "Mobile Providers", id: 10 },
        ]
      },
      propertiesWillBeExported: null,
      ttf: null,
      historyItemOptionsToShow: null,
      optionsLoading: true,
      historyLoading: true,
      repeatedOptions: null,
      selectedOptions: {
        class_generated: [],
        class_name: [],
        openreach_type: null,
        postcode: null,
        openreach_speed: {
          min: null,
          max: null,
        },
        polygons: [],
        mobile_coverage: [],
        property_type: 'properties',
        providers_data: [],
        counties: [],
        countries: [],
        districts: [],
        parishes: [],
        national_parks: [],
        westminster_constituency: [],
        tax_bands: [],
        business_rates_exist: { name: '', value: null },
      },
      provider_options: [
        { name: 'Virgin Media - Yes', value: { field: 'virginmediaavailable', operation: 'equals', value: true } },
        { name: 'Virgin Media - No', value: { field: 'virginmediaavailable', operation: 'exists', value: false } },
        { name: 'City Fibre - Yes', value: { field: 'cityfibreavailable', operation: 'equals', value: true } },
        { name: 'City Fibre - No', value: { field: 'cityfibreavailable', operation: 'exists', value: false } },
        { name: 'Hyperoptics - Yes', value: { field: 'hyperopticavailable', operation: 'equals', value: true } },
        { name: 'Hyperoptics - No', value: { field: 'hyperopticavailable', operation: 'exists', value: false } },
        { name: 'Gigaclear - Yes', value: { field: 'gigaclearavailable', operation: 'equals', value: true } },
        { name: 'Gigaclear - No', value: { field: 'gigaclearavailable', operation: 'exists', value: false } },

      ],
      postcodes: [],
    }

  },
  methods: {
    ...mapMutations({
      setAvailableOptions: 'ExportHistory/setAvailableOptions',
      setHistoryItems: 'ExportHistory/setHistoryItems'
    }),
    log() {

    },
    deleteHistoryItem(historyItemId) {
      ExportHistoryClient.deleteHistoryItem(historyItemId).then(() => {
        this.getHistory();
      });
    },
    getHistory() {
      this.historyLoading = true;
      ExportHistoryClient.listExportHistory().then(response => {
        this.setHistoryItems(response.data.data);
      }).finally(() => {
        this.historyLoading = false;
      });

    },
    getOptions() {
      this.optionsLoading = true;
      ExportHistoryClient.getOptions().then(response => {
        this.setAvailableOptions(response.data.data);
        this.getApproximatePropertiesCount();
      }).finally(() => {
        this.optionsLoading = false
      })
    },
    closeHistoryModal() {
      this.$bvModal.hide('export-properties-history-modal')
    },
    closeExportModal() {
      this.propertiesWillBeExported = null;
      console.log('hide called')
      this.selectedOptions = {
        class_generated: [],
        class_name: [],
        counties: [],
        countries: [],
        districts: [],
        parishes: [],
        national_parks: [],
        westminster_constituency: [],
        openreach_type: null,
        postcode: null,
        openreach_speed: {
          min: null,
          max: null,
        },
        polygons: [],
        property_type: 'properties',
        providers_data: [],
        mobile_coverage: [],
        tax_bands: [],
        business_rate_exist: null,

      };
      this.repeatedOptions = null;

    },
    postExport(vbModalEvent) {
      if (this.no_fields_filled) {
        this.$swal('No filters for export', 'There should be at least one filter option set', 'warning');
        return;
      }
      if (this.exportFormTabIndex === 0) {
        vbModalEvent.preventDefault()
        this.exportFormTabIndex = 1;
        return;
      }
      else {
        this.optionsLoading = true;
        let params = this.selectedOptions
        params.export_columns = this.columns.accepted;
        ExportHistoryClient.postExport(params).then(response => {
          this.$swal('Success', 'Export result will be delivered via email, or you can check status and download file from export history window', 'success');
          this.getHistory();
        }).finally(() => this.optionsLoading = false);
      }
    },
    viewOptions(historyItem) {
      this.historyItemOptionsToShow = historyItem;
      this.openModal('view-options-modal');
    },
    showExportModal() {
      this.openModal('export-properties-new');
      if (this.repeatedOptions !== null) {
        this.selectedOptions = this.repeatedOptions;
      }
    },
    repeat(historyItem) {
      this.repeatedOptions = historyItem;
      this.showExportModal();
    },
    downloadFile(historyItem) {
      if (historyItem.file) {
        window.location = 'https://assets.postcodegenie.com/csv/' + historyItem.file;
      }
    },
    getProgress(historyItem) {
      if (historyItem.total_records > 0) {
        const value = historyItem.processed_records / historyItem.total_records * 100;
        return value.toFixed(2);
      }
    },
    getStatusName(historyItem) {
      if (historyItem.status === 'done') return 'Done';
      if (historyItem.status === 'in_progress') return 'In Progress';
      if (historyItem.status === 'new') return 'New';
      if (historyItem.status === 'error') return 'Error';

      return 'Unknown';
    },
    getStatusVariant(historyItem) {
      if (historyItem.status === 'done') {
        return 'success';
      }
      if (historyItem.status === 'in_progress') {
        return 'warning';
      }
      return 'default';
    },
    getApproximatePropertiesCount() {
      this.optionsLoading = true;
      let params = this.selectedOptions
      params.export_columns = this.columns.accepted;
      ExportHistoryClient.getFilterPropertiesCount(params).then(response => {
        this.propertiesWillBeExported = response.data.data.count;
        this.ttf = response.data.data.ttf;
      }).finally(() => {
        this.optionsLoading = false;
      });
    },
    hasWhiteSpace(s) {
      return (/\s/).test(s);
    }
  },
  watch: {
    selectedPolygonsInStore(value) {
      this.selectedOptions.polygons.push(value)
    }
  },
  computed: {
    ...mapGetters({
      availableOptions: 'ExportHistory/availableOptions',
      historyItems: 'ExportHistory/historyItems',
      selectedPolygonsInStore: 'ExportHistory/selectedPolygons'
    }),
    exportModalButtonTitle() {
      return this.exportFormTabIndex === 0 ? 'Configure Output ' : 'Export'
    },
    class_generated() {
      return this.availableOptions.class_generated
    },
    class_name() {
      return this.availableOptions.class_name;
    },
    openreach_type() {
      return this.availableOptions.openreach_type;
    },
    mobile_coverage() {
      return this.availableOptions.mobile_coverage;
    },
    counties() {
      return this.availableOptions.counties;
    },
    countries() {
      return this.availableOptions.countries;
    },
    districts() {
      return this.availableOptions.districts;
    },
    parishes() {
      return this.availableOptions.parishes;
    },
    national_parks() {
      return this.availableOptions.national_parks;
    },
    westminster_constituency() {
      return this.availableOptions.westminster_constituency;
    },
    tax_bands() {
      return this.availableOptions.tax_bands;
    },
    business_rates() {
      return [
        { name: '', value: null },
        { name: 'Blank', value: '' },
        { name: 'Yes', value: true },
      ];
    },
    polygons() {
      return this.availableOptions.polygons;
    },
    no_fields_filled() {
      return (this.selectedOptions.class_generated === null || this.selectedOptions.class_generated.length === 0)
        && (this.selectedOptions.class_name === null || this.selectedOptions.class_name.length === 0)
        && (this.selectedOptions.postcode === null || this.selectedOptions.postcode.length === 0)
        && (this.selectedOptions.openreach_type === null || this.selectedOptions.openreach_type.length === 0)
        && (this.selectedOptions.counties === null || this.selectedOptions.counties.length === 0)
        && (this.selectedOptions.countries === null || this.selectedOptions.countries.length === 0)
        && (this.selectedOptions.districts === null || this.selectedOptions.districts.length === 0)
        && (this.selectedOptions.parishes === null || this.selectedOptions.parishes.length === 0)
        && (this.selectedOptions.national_parks === null || this.selectedOptions.national_parks.length === 0)
        && (this.selectedOptions.westminster_constituency === null || this.selectedOptions.westminster_constituency.length === 0)
        && this.oprencreach_speed_is_empty === true
        && (this.selectedOptions.mobile_coverage.length === null || this.selectedOptions.mobile_coverage.length === 0)
        && this.selectedOptions.providers_data.length === 0
        && this.selectedOptions.tax_bands.length === 0
        && this.selectedOptions.polygons.length === 0
        && (this.selectedOptions.business_rates_exist === null || this.selectedOptions.business_rates_exist.length === 0)
    },
    oprencreach_speed_is_empty: function () {
      return this.selectedOptions.openreach_speed.min === null
        && this.selectedOptions.openreach_speed.max === null
    },
    postcodeIsValid: function () {
      if (this.selectedOptions.postcode !== null && this.selectedOptions.postcode.length > 0) return true;
      return this.selectedOptions.postcode === null || this.selectedOptions.postcode.length === 0
    },
    okDisabled: function () {
      return this.propertiesWillBeExported === null || this.propertiesWillBeExported === 0
    }
  }
}
</script>

<template>
  <div>
    <b-modal id="export-properties-history-modal" @show="getHistory" @hide="closeHistoryModal" ok-only ok-title="Close"
      @ok="$bvModal.hide('export-properties-history-modal')" title="Export History">
      <template #modal-header>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex justify-content-start space-x-2 ">
            <b-button variant="primary" @click="showExportModal" class="pull-right">New Export</b-button>
            <b-button variant='outline-primary' class="ml-2" @click="getHistory"><i
                class="fas fa-sync-alt"></i></b-button>
          </div>
          <b-button variant="outline-primary" @click="closeHistoryModal" class="cursor-pointer">X</b-button>
        </div>
      </template>
      <b-overlay :show="historyLoading">
        <div style="max-height: 50vh; overflow:auto">
          <table class="table table-stripped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Properties Count</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for='historyItem in historyItems' :key='historyItem.id'>
                <td>{{ moment(historyItem.created_at).format('YYYY-MM-DD') }}</td>
                <td>
                  <b-badge :variant="getStatusVariant(historyItem)">{{ getStatusName(historyItem) }}</b-badge>
                </td>
                <td v-if="historyItem.status === 'done'">{{ historyItem.properties_number }}</td>
                <td v-else-if="historyItem.status === 'in_progress'">
                  <p class="small m-0 p-0">{{ getProgress(historyItem) }}% Completed</p>
                  <b-progress variant="primary" :value="getProgress(historyItem)">
                  </b-progress>
                </td>
                <td v-else></td>
                <td>

                  <b-dropdown :id="'dropdown-history-item-' + historyItem.id" variant="link" size="sm"
                    menu-class="dropdown-menu-custom" toggle-class="text-decoration-none p-0" no-caret>
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <b-dropdown-item :disabled="historyItem.file === null" @click="downloadFile(historyItem)">Download
                      File
                    </b-dropdown-item>
                    <b-dropdown-item @click="viewOptions(historyItem)">View Options</b-dropdown-item>
                    <b-dropdown-item @click="repeat(historyItem)">Change and Repeat</b-dropdown-item>
                    <b-dropdown-item @click="deleteHistoryItem(historyItem.id)">Delete</b-dropdown-item>
                  </b-dropdown>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal id="export-properties-new" @show="getOptions" :ok-disabled="okDisabled" @ok="postExport"
      :ok-title="exportModalButtonTitle" @hidden="closeExportModal" title="New Export">
      <b-tabs v-model="exportFormTabIndex">
        <b-tab title="Criteria" active>
          <b-overlay :show="optionsLoading">
            <b-form class="mt-2">
              <b-form-group>
                <div v-if="propertiesWillBeExported !== null">
                  <div class="alert alert-info">Properties will be exported :
                    {{ propertiesWillBeExported }}
                  </div>
                  <div class="alert alert-warning" v-if="ttf !== null">Generating the CSV file may take
                    {{ ttf }}
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="Basic Description">
                <multiselect v-model="selectedOptions.class_generated" :options="class_generated" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount"></multiselect>
              </b-form-group>
              <b-form-group label="Detailed Description">
                <multiselect v-model="selectedOptions.class_name" :options="class_name" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount"></multiselect>
              </b-form-group>
              <b-form-group label="Country">
                <multiselect v-model="selectedOptions.countries" :options="countries" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount"></multiselect>
              </b-form-group>
              <b-form-group label="County">
                <multiselect v-model="selectedOptions.counties" :options="counties" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount">
                </multiselect>
              </b-form-group>
              <b-form-group label="District">
                <multiselect v-model="selectedOptions.districts" :options="districts" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount"></multiselect>
              </b-form-group>
              <b-form-group label="Parish">
                <multiselect v-model="selectedOptions.parishes" :options="parishes" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount">
                </multiselect>
              </b-form-group>
              <b-form-group label="National Park">
                <multiselect v-model="selectedOptions.national_parks" :options="national_parks" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount"></multiselect>
              </b-form-group>
              <b-form-group label="Westminster Constituency">
                <multiselect v-model="selectedOptions.westminster_constituency" :options="westminster_constituency"
                  :searchable="true" :close-on-select="false" labels="false" :multiple="true" track-by="name"
                  label="name" @input="getApproximatePropertiesCount"></multiselect>
              </b-form-group>
              <b-form-group label="Polygons">
                <multiselect v-model="selectedOptions.polygons" :options="polygons" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount">
                </multiselect>
              </b-form-group>
              <b-form-group label="Postcode (Include any spaces, Comma separated)">
                <b-input v-model="selectedOptions.postcode" :state="postcodeIsValid ? null : false"
                  @input="getApproximatePropertiesCount"></b-input>
              </b-form-group>
              <b-form-group label="Council Tax Band">
                <multiselect v-model="selectedOptions.tax_bands" :options="tax_bands" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name"
                  @input="getApproximatePropertiesCount" label="name"></multiselect>
              </b-form-group>
              <b-form-group label="Business Rates">
                <multiselect v-model="selectedOptions.business_rates_exist" :options="business_rates" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="false" track-by="name"
                  @input="getApproximatePropertiesCount" label="name"></multiselect>
              </b-form-group>
              <b-form-group label="Openreach Type">
                <multiselect v-model="selectedOptions.openreach_type" :options="openreach_type" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="false" track-by="name"
                  @input="getApproximatePropertiesCount" label="name"></multiselect>
              </b-form-group>
              <div class="d-flex justify-content-between">
                <b-form-group label="Openreach Speed Min">
                  <b-input v-model="selectedOptions.openreach_speed.min" @input="getApproximatePropertiesCount" />
                </b-form-group>
                <b-form-group label="Openreach Speed Max">
                  <b-input v-model="selectedOptions.openreach_speed.max" @input="getApproximatePropertiesCount" />
                </b-form-group>
              </div>
              <b-form-group label="Providers">
                <multiselect v-model="selectedOptions.providers_data" :options="provider_options" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name"
                  @input="getApproximatePropertiesCount" label="name"></multiselect>
              </b-form-group>
              <b-form-group label="Mobile Coverage">
                <multiselect v-model="selectedOptions.mobile_coverage" :options="mobile_coverage" :searchable="true"
                  :close-on-select="false" labels="false" :multiple="true" track-by="name" label="name"
                  @input="getApproximatePropertiesCount"></multiselect>

              </b-form-group>
              <b-form-group label="Property Type">
                <b-select v-model="selectedOptions.property_type" @input="getApproximatePropertiesCount">
                  <b-select-option value="properties">Properties</b-select-option>
                  <b-select-option value="all_uprns">All UPRNs</b-select-option>
                </b-select>
              </b-form-group>
            </b-form>
          </b-overlay>
        </b-tab>
        <b-tab title="Output">
          <div class="col-12 pt-2">
            <h5>Included Fields</h5>
            <draggable class="list-group" :list="columns.accepted" group="fields" @change="log">
              <div class="list-group-item cursor-grab p-2" v-for="(element, index) in columns.accepted"
                :key="element.name">
                <small>{{ element.name }}</small>
              </div>
            </draggable>
          </div>
          <hr />
          <div class="col-12 pt-2">
            <h5>Excluded Fields</h5>
            <draggable class="list-group" :list="columns.available" group="fields" @change="log">
              <div class="list-group-item cursor-grab p-2" v-for="(element, index) in columns.available"
                :key="element.name">
                <small>{{ element.name }}</small>
              </div>
            </draggable>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal id="view-options-modal" title="Export Options" ok-only ok-title="Close"
      @ok="closeModal('view-options-modal')">
      <table class="table table-stripped" v-if="historyItemOptionsToShow !== null">
        <tbody>
          <tr>
            <td>Class</td>
            <td
              v-if="historyItemOptionsToShow.class_generated === null || historyItemOptionsToShow.class_generated.length === 0">
              N/A
            </td>
            <td v-else>
              <b-badge variant="success" class="ml-1"
                v-for="(class_generated, index) in historyItemOptionsToShow.class_generated" :key="index">{{
                  class_generated.value
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <td>Building Type</td>
            <td v-if="historyItemOptionsToShow.class_name === null || historyItemOptionsToShow.class_name.length === 0">
              N/A
            </td>
            <td v-else>
              <b-badge variant="success" class="ml-1" v-for="(class_name, index) in historyItemOptionsToShow.class_name"
                :key="index">{{
                  class_name.value
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <td>Postcode</td>
            <td v-if="historyItemOptionsToShow.postcode === null">N/A</td>
            <td v-else>{{ historyItemOptionsToShow.postcode }}</td>
          </tr>

          <tr>
            <td>Openreach Type</td>
            <td
              v-if="historyItemOptionsToShow.openreach_type === null || historyItemOptionsToShow.openreach_type.length === 0">
              N/A
            </td>
            <td v-else>
              <b-badge variant="success" class="ml-1"
                v-for="(openreach_type, index) in historyItemOptionsToShow.openreach_type" :key="index">{{
                  openreach_type.value
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <td>Openreach Download Speed</td>
            <td>
              <table class="table table-borderless m-0 p-0">
                <tbody>
                  <tr>
                    <td class="p-0 m-0" v-if="historyItemOptionsToShow.openreach_speed.min === null">Min : N/A</td>
                    <td class="p-0 m-0" v-else>Min : {{ historyItemOptionsToShow.openreach_speed.min }}</td>
                    <td class="p-0 m-0" v-if="historyItemOptionsToShow.openreach_speed.max === null">Max : N/A</td>
                    <td class="p-0 m-0" v-else>Min : {{ historyItemOptionsToShow.openreach_speed.max }}</td>

                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>Mobile Coverage</td>
            <td
              v-if="historyItemOptionsToShow.mobile_coverage === null || historyItemOptionsToShow.mobile_coverage.length === 0">
              N/A
            </td>
            <td v-else>
              <b-badge variant="success" class="ml-1" :key="index"
                v-for="(mobile_coverage, index) in historyItemOptionsToShow.mobile_coverage">{{
                  mobile_coverage.name
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <td>Network Coverage should cover</td>
            <td v-if="historyItemOptionsToShow.network_coverage_covers">
              <b-badge variant="success">Yes</b-badge>
            </td>
            <td v-else>
              <b-badge variant="danger">No</b-badge>
            </td>
          </tr>
          <tr>
            <td>Properties Type</td>
            <td>{{ historyItemOptionsToShow.property_type }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>

</template>
