<template>
  <div>
    <b-modal no-close-on-backdrop :visible="!isLoggedIn" title="User Login" centered id="login" ok-only no-close-on-esc
             hide-header-close
             ok-title="Login">
      <b-form>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-form-group label="Email Address" for="login_email">
            <b-form-input id="login_email" v-on:keyup.enter="login" v-model="email" type="email" autocomplete="username"
                          required></b-form-input>
          </b-form-group>
          <b-form-group label="Password" for="login_password">
            <b-form-input id="login_password" v-on:keyup.enter="login" v-model="password"
                          autocomplete="current-password"
                          type="password"
                          required></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-4">
            <b-form-checkbox v-model="remember_me">Remember me</b-form-checkbox>
          </b-form-group>
        </b-overlay>
      </b-form>
      <template #modal-footer>
        <button @click="resetPassword" class="btn btn-outline-info">Reset Password</button>
        <button @click="login" class="btn btn-primary">Login</button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      showOverlay: false,
      email: '',
      password: '',
      remember_me: true,
    }
  },
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.state.Auth.isLoggedIn;
      }
    },
  },
  methods: {
    resetModalOpened() {
      this.$bvModal.hide('login');
    },
    login(e) {
      this.showOverlay = true;
      e.preventDefault()
      this.$store.dispatch('Auth/login', {
        email: this.email,
        password: this.password,
        remember_me: this.remember_me
      }).then(resp => {
        this.showOverlay = false;
        this.$bvModal.hide('login');
        this.$store.commit('Auth/setToken', resp.data.data.token.access_token)
        this.$store.commit('Profile/setUser', resp.data.data.user.data.user, {root: true});
        this.$store.commit('Projects/setProjects', resp.data.data.user.data.projects, {root: true});
        this.$store.commit('Projects/setSelectedProject', resp.data.data.user.data.selected_project, {root: true});
        this.$store.commit('Auth/setIsLoggedIn', true, {root: true});
        this.$store.commit('Openreach/setOpenreachPia', resp.data.data.user.data.user.oprenreach_credentials, {root: true});
        this.$root.$emit('login');
        // this.$store.commit("Auth")
        // ask user how to restore
        this.restoreFromDB(resp);

      }).catch(error => {
        if (error.response.status === 403) {
          if (error.response.data.message === 'USER_DISABLED') {
            this.$swal("Account Disabled.", "A user with this email address is already registered but disabled. Please contact support.", "warning");
          } else if (error.response.data.message === 'MASTER_USER_DISABLED') {
            this.$swal("Account Disabled.", "Master account is  disabled. Please contact support.", "warning");
          } else {
            this.$swal("Email Verification Required", "A user with this email address is already registered but not activated. <br /> A new activation email has been sent.", "warning");
          }
        } else {
          this.$swal("Invalid credentials", "Invalid E-Mail Address or Password", "warning");
        }
        this.showOverlay = false;
      }).finally(() => {
        this.showOverlay = false;
        this.$store.dispatch('LayersList/list', {}, {root: true})
        this.$store.dispatch('Maps/list', {}, {root: true})
      })
    },
    restoreFromDB(resp) {

      this.$store.commit('Auth/setUserInfo', resp.data.data)
      this.$store.dispatch('State/setStateFromDB', resp.data.data.user.data.state)
    },
    resetPassword(e) {
      if (this.email.length === 0) {
        this.$swal("Warning", "Email is missing", "warning")
        return
      }
      const self = this;
      e.preventDefault()
      this.showOverlay = true;
      axios.post(process.env.VUE_APP_API_URL + '/api/user/auth/reset', {email: this.email}).then(response => {
        self.showOverlay = false;
        self.$bvModal.hide('resetPassword');

      }).finally(() => {
        self.showOverlay = false;
        self.$swal("Success", "If the emails address exists, a temporary password will be emailed.", "success")

      })

    }
  }
}
</script>

<style scoped>

</style>