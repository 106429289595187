const state = {
    popupShowed: false,
    popupLocation: {},
    popupContent: '',
    polygon: null,
    coordinates:[],
    perimeterIsHidden:true,
    perimeterHtml:''
};
const actions = {};
const mutations = {
    setPopupShowed(state, data) {
        state.popupShowed = data;
    },
    setPopupLocation(state, data) {
        state.popupLocation = data;
    },
    setPopupContent(state, data) {
        state.popupContent = data;
    },
    setPolygon(state, data) {
        state.polygon = data;
    },
    setCoordinates(state, data) {
        state.coordinates = data;
    },
    setPerimeterHidden(state, data){
        state.perimeterIsHidden = data;
    },
    setPerimeterHtml(state,data){
        state.perimeterHtml = data;
    }
};
const getters = {
    popupShowed: state => {
        return state.popupShowed;
    },
    popupLocation: state => {
        return state.popupLocation;
    },
    popupContent: state => {
        return state.popupContent;
    },
    polygon: state => {
        return state.polygon;
    },
    coordinates: state => {
        return state.coordinates;
    },
    perimeterIsHidden: state =>{
        return state.perimeterIsHidden;
    },
    perimeterHtml:state=>{
        return state.perimeterHtml;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}