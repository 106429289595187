<template>
  <div>
    <l-marker v-if="customPropertyMarker.length>0" v-for="marker in customPropertyMarker"
              :key="marker.key"
              :ref="marker.key"
              :options="{key:marker.key}"
              @ready="ready($event,marker)"
              @dragend="dragEnd($event,marker)"
              @click="customPropertyMarkerClick(marker,$event)"
              :lat-lng="[parseFloat(marker.location.lat),parseFloat(marker.location.lng)]">
      <l-icon :draggable=false v-if="marker.is_flagged" :icon-size="[16,16]" :icon-url="getFlag(marker.flag)"></l-icon>
      <l-icon :draggable=false v-else icon-url="https://assets.postcodegenie.com/assets/img/house_fam.png"
              :icon-size="[16,16]"></l-icon>

    </l-marker>
    <l-circle v-if="customPropertyMarker.length>0" v-for="marker in customPropertyMarker"
              :lat-lng="[parseFloat(marker.location.lat),parseFloat(marker.location.lng)]" :ref="'circle_'+marker.key"
              :key="'circle_'+marker.key"
              :radius="4"
              color="#000"
              fillColor="#ff3c00"
              :weight="1"
              :fillOpacity="0.5"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import vue from 'vue';
import L from 'leaflet';
import {LCircle, LIcon} from "vue2-leaflet"
import axios from "axios";

export default {
  name: "CustomPropertyMarkersGroup",
  components: {LIcon, LCircle},
  data: function () {
    return {
      propertyCircles: this.customPropertyMarker,
    }
  },
  watch: {
    customPropertyMarker(value) {
      this.propertyCircles = value;
    }
  },
  computed: {
    ...mapGetters({
      customPropertyMarker: 'Search/getCustomPropertyMarkers',
      locationInfoAvailable: 'Search/getLocationInfoAvailable',
      flagged: 'Properties/flagged',
      selectedProject: 'Projects/selectedProject',
    }),
  },
  methods: {
    ready(e, marker) {
      e.pm._layer.on('pm:dragend', (e) => {
        this.dragEnd(e, marker)
      })
      const self = this
      e.pm._layer.on('pm:drag', (e) => {
        const key = e.layer.options.key;
        const latLng = e.layer._latlng;
        const index = self.propertyCircles.findIndex(uprn => uprn.key === key);
        self.propertyCircles[index].location = latLng;

      })
    },
    getFlag(flag) {
      return process.env.VUE_APP_REMOTE_HOST + "/assets/img/icons/flags/flag_" + flag + ".png";
    },
    customPropertyMarkerClick(marker, event) {
      if (this.map.pm.globalRemovalModeEnabled() || this.map.pm.globalDrawModeEnabled() || this.map.pm.globalEditModeEnabled() || this.map.pm.globalDragModeEnabled()) {
        event.target.pm._layer.dragging.disable()
        event.target.pm._layer._allowRemoval = false;
        event.target.pm._layer._pmTempLayer = true;
        event.target.pm._layer._dragDisabled = true;
        if (this.map.pm.globalDrawModeEnabled() || this.map.pm.globalEditModeEnabled()) {
          this.map.fire('click', this.map);

        }

        return false;
      }
      // load UPRN info
      this.$store.dispatch('Search/loadCustomUprnInfo', {uprn: marker.UPRN, clear: false})
      const self = this;
      this.$refs[marker.key][0].mapObject.on('pm:dragend', function (event) {
        self.dragEnd(event, marker)
      })
      L.Marker.stopAllBouncingMarkers();
      this.$refs[marker.key][0].mapObject.bounce();

    },
    getFlagged(uprn) {
      return this.flagged.filter(item => item.uprn === uprn);
    },
    dragEnd(e, marker) {
      marker.lat = e.target._latlng.lat;
      marker.lng = e.target._latlng.lng;
      axios.put(process.env.VUE_APP_API_URL + '/api/project/' + this.selectedProject.id + '/customproperty/' + marker.id, marker).then(() => {
        this.$store.dispatch("Search/searchCustomPropertiesInDistance", {
          center: {
            lat: marker.lat,
            lng: marker.lng
          }
        }, {root: true})
        window.Lmap.pm.disableGlobalDragMode(false);


      });
    }

  },
  created() {
    const self = this;

    this.$root.$on('bounceMarker', function (data) {
      vue.nextTick(() => {
        if (self.$refs[data] !== undefined) {
          L.Marker.stopAllBouncingMarkers();
          self.$refs[data][0].mapObject.bounce();
        }
      })
    });
    this.$root.$on('changeMarkerIcon', function (data) {
      vue.nextTick(() => {
        if (self.$refs[data.key] !== undefined && self.$refs[data.key][0] !== undefined) {
          self.$refs[data.key][0].mapObject.setIcon(L.icon({
            iconUrl: 'https://assets.postcodegenie.com/assets/img/icons/flags/flag_' + data.color + '.png',
            iconSize: [16, 16]
          }))
          let marker = self.$store.state.Search.customUprnInfo;
          marker.isFlagged = 1;
          marker.flag = 'https://assets.postcodegenie.com/assets/img/icons/flags/flag_' + data.color + '.png';
          this.$store.commit("Search/setCustomUprnInfo", marker, {root: true});
        }
      })
    });
    this.$root.$on('removeMarkerIcon', function (key) {
      vue.nextTick(() => {
        if (self.$refs[key] !== undefined && self.$refs[key][0] !== undefined) {
          self.$refs[key][0].mapObject.setIcon(L.icon({
            iconUrl: 'https://assets.postcodegenie.com/assets/img/house_fam.png',
            iconSize: [16, 16]
          }))
          let marker = self.$store.state.Search.customUprnInfo;
          marker.isFlagged = 0;
          marker.flag = null;
          this.$store.commit("Search/setCustomUprnInfo", marker, {root: true});
        }
      })
    });


  }
}
</script>

<style scoped>

</style>