<template>
  <b-modal hide-footer no-close-on-backdrop xl size="md" title="Profile Update" centered id="profile_update_modal">

    <b-overlay :show="showOverlay" rounded="sm">
      <b-tabs content-class="mt-3">

        <b-tab title="Profile" active>
          <FormsUpdateProfile :account-data="profile" :show-account-limit="false" :show-allow-edit="false"
                              @ok="updateProfile"/>
        </b-tab>
        <b-tab title="Openreach">
          <FormsOpenreachCredentials :openreach-data="credentials" @ok="updateOpenreachData"/>

        </b-tab>

      </b-tabs>
    </b-overlay>
  </b-modal>
</template>

<script>

import FormsOpenreachCredentials from "@/components/Modules/OpenreachPIA/forms/FormsOpenreachCredentials.vue";
import FormsUpdateProfile from "@/components/Modules/Account/forms/FormsUpdateProfile.vue";
import {mapGetters} from "vuex";

export default {
  name: "Profile",
  components: {FormsUpdateProfile, FormsOpenreachCredentials},
  data() {
    return {
      showOverlay: false,
    }
  },
  computed: {
    credentials() {
      return this.$store.state.Openreach.pia;
    },
    profile() {
      return this.$store.state.Profile.user
    },
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
    }),
  },
  methods: {
    updateProfile(data) {
      console.log(data);
      const self = this;
      this.showOverlay = true;

      this.$store.dispatch('Profile/updateProfile', data).then(resp => {
        self.$swal("", "Profile has been updated", "success")
      }).finally(() => {
        this.showOverlay = false;
        this.$bvModal.hide('profile_update_modal')
      })
    }
    ,

    updateOpenreachData(params) {
      this.showOverlay = true;
      let data = {...params};
      this.openreachInfo = params;
      delete data.transflex_client_id;
      delete data.transflex_client_secret;
      this.$store.dispatch('Openreach/updateOpenreachCredentials', data).then(response => {
        this.showOverlay = false;
        this.$swal('', 'Openreach credentials saved', 'success');
        this.$store.dispatch('Auth/getUserInfo');
        this.$bvModal.hide('profile_update_modal')
      }).catch(error => {
        this.showOverlay = false;
        if (error.response.data.message !== undefined) {
          this.$swal("", error.response.data.message, 'error');
        }
      })
    }
  },
  mounted() {
    this.first_name_tmp = this.first_name;
    this.last_name_tmp = this.last_name;
    this.email_tmp = this.email;
    this.phone_tmp = this.phone;
  }
}
</script>

<style scoped>

</style>