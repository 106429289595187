import axios from "axios";
var WhereAboutAPIClient = /** @class */ (function () {
    function WhereAboutAPIClient() {
    }
    WhereAboutAPIClient.getUlr = function () {
        return process.env.VUE_APP_API_URL + '/api/openreach/pia/whereabouts';
    };
    WhereAboutAPIClient.list = function (id) {
        return axios.get(WhereAboutAPIClient.getUlr() + '/list/' + id);
    };
    WhereAboutAPIClient.get = function (id) {
        return axios.get(WhereAboutAPIClient.getUlr() + '/' + id);
    };
    WhereAboutAPIClient.delete = function (id) {
        return axios.delete(WhereAboutAPIClient.getUlr() + '/' + id);
    };
    return WhereAboutAPIClient;
}());
export default WhereAboutAPIClient;
