<template>
  <div id="app" class="h-100 d-flex flex-column">
    <Header v-if="isLoggedIn"></Header>
    <Login v-if="!isLoggedIn && !isUserLoading"></Login>

    <section class="section" v-if="isLoggedIn">
      <LayerSelector></LayerSelector>
      <Map></Map>
      <ProjectInfo></ProjectInfo>
      <OpenreachNOI></OpenreachNOI>
      <LeadInWindow></LeadInWindow>
      <CustomPropertiesList/>
      <ImageGroupsList/>
      <UserLocationsList/>
    </section>
  </div>
</template>

<script>
import Header from "@/components/partials/Header";
import LayerSelector from './components/partials/LayerSelector';
import ProjectInfo from "./components/partials/ProjectInfo";
import Map from './components/Map';
import CustomPropertiesList from "@/components/partials/CustomPropertiesList";
import ImageGroupsList from "./components/partials/ImageGroupsList";
import UserLocationsList from "./components/partials/UserLocationsList";
import OpenreachNOI from "@/components/Modules/OpenreachPIA/components/OpenreachNOI";
import UserManagement from "@/Api/Admin/UserManagement.ts";
import Vue from "vue";
import OpenreachPia from "@/Api/Admin/OpenreachPia.ts";
import LeadInWindow from "@/components/Modules/OpenreachPIA/components/LeadInWindow.vue";
import Login from "@/components/modals/Auth/Login.vue";


export default {
  name: 'App',
  components: {
    Login,
    LeadInWindow,
    OpenreachNOI,
    UserLocationsList,
    ImageGroupsList,
    CustomPropertiesList,
    Header,
    LayerSelector,
    Map,
    ProjectInfo
  },
  head: {
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        id: 'viewport'
      },
    ]
  },
  computed: {
    isUserLoading: {
      get() {
        return this.$store.state.Auth.userIsLoading
      }
    },
    isLoggedIn: {
      get() {
        return this.$store.state.Auth.isLoggedIn;
      }
    },
  },
  beforeCreate() {
    if (localStorage.getItem('token')) {
      this.$store.dispatch('Auth/getUserInfo').catch((error) => {
        this.$root.$emit('logout');
      }).then(() => {
        Vue.prototype.$UserManagementApiClient = new UserManagement(localStorage.getItem('token'));
        Vue.prototype.$OpenreachPiaApiClient = new OpenreachPia(localStorage.getItem('token'));

      })
    } else {
      this.$store.commit('Auth/setUserIsLoading', false);
    }

  }
}
</script>

<style lang="scss">
@import './assets/scss/app';
</style>
