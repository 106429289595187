import Vue from "vue";

const state = {
    noisWindowActive: false,
    showAddRemovePopupButtons: false,
    allItems: [],
    currentRouteItems: [],
}
const actions = {}

const mutations = {
    activateWindow(state) {
        state.noisWindowActive = true;
    },
    deactivateWindow(state) {
        state.noisWindowActive = false;
    },
    showPopupButtons(state) {
        state.showAddRemovePopupButtons = true;
    },
    hidePopupButtons(state) {
        state.showAddRemovePopupButtons = false;
    },
    setAllItems(state, data) {
        Vue.set(state, 'allItems', data);
    },
    add(state, data) {
        let allItems = state.allItems;
        allItems.push(data);
        Vue.set(state, 'allItems', allItems);
        let routeItems = state.currentRouteItems;
        routeItems.push(data);
        Vue.set(state, 'currentRouteItems', routeItems);
    },
    remove(state, data) {
        let allItems = state.allItems;
        let routeItems = state.currentRouteItems;
        let removed = allItems.filter(item => item.properties.objectid !== data.properties.objectid);
        let removedRoute = routeItems.filter(item => item.properties.objectid !== data.properties.objectid);
        Vue.set(state, 'allItems', removed);
        Vue.set(state, 'currentRouteItems', removedRoute);
    },
    removeEdit(state, data) {
        let allItems = state.allItems;
        let routeItems = state.currentRouteItems;
        let removed = allItems.find(item => item.properties.objectid === data.properties.objectid);
        removed.tag = 'removed';
        let removedRoute = routeItems.find(item => item.properties.objectid !== data.properties.objectid);
        removedRoute.tag = 'removed';
        Vue.set(state, 'allItems', allItems);
        Vue.set(state, 'currentRouteItems', routeItems);
    },
    returnBackEdit(state, data) {
        let allItems = state.allItems;
        let routeItems = state.currentRouteItems;
        let removed = allItems.find(item => item.properties.objectid === data.properties.objectid);
        removed.tag = '';
        let removedRoute = routeItems.find(item => item.properties.objectid !== data.properties.objectid);
        removedRoute.tag = '';
        Vue.set(state, 'allItems', allItems);
        Vue.set(state, 'currentRouteItems', routeItems);
    },
    reset(state) {
        Vue.set(state, 'allItems', []);
        Vue.set(state, 'currentRouteItems', []);
    },
    setRouteItems(state, data) {
        Vue.set(state, 'currentRouteItems', data);
    },
    resetRouteItems(state) {
        Vue.set(state, 'currentRouteItems', []);
    }
}

const getters = {
    windowActive: state => {
        return state.noisWindowActive;
    },
    buttonsShouldBeVisible: state => {
        return state.showAddRemovePopupButtons;
    },
    allItems: state => {
        return state.allItems;
    },
    isUsed: state => (objectId) => {
        return state.currentRouteItems.filter(item => item.properties.objectid === objectId).length > 0
    },
    isRemoved: state => (objectId) => {
        return state.currentRouteItems.filter(item => item.properties.objectid === objectId && item.tag === 'removed').length > 0
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}