import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './Modules/Auth'
import Profile from './Modules/Profile'
import Accounts from './Modules/Accounts'
import Projects from './Modules/Projects'
import DuctTypes from "./Modules/DuctTypes";
import ObjectTypes from "./Modules/ObjectTypes";
import LayersList from './Modules/LayersList';
import Maps from './Modules/Maps';
import State from './Modules/State';
import Search from './Modules/Search';
import Properties from "./Modules/Properties";
import Duct from "./Modules/Duct";
import Polygon from "./Modules/Polygon";
import ObjectMarker from "./Modules/ObjectMarker";
import PolygonPopup from "./Modules/PolygonPopup";
import Elevation from "@/stores/Modules/Elevation";
import LocationPhotos from "@/stores/Modules/LocationPhotos";
import SubDuctInfoModal from "@/stores/Modules/SubDuctInfoModal";
import NetworkConnection from "@/stores/Modules/NetworkConnection";
import RoadWorks from "@/stores/Modules/RoadWorks";
import DuctConnections from "@/stores/Modules/DuctConnections";
import Sites from "@/stores/Modules/Sites";
import Openreach from "@/components/Modules/OpenreachPIA/stores/Openreach";
import OpenreachNOIs from "@/components/Modules/OpenreachPIA/stores/OpenreachNOIs";
import TilePlan from "@/stores/Modules/TilePlan";
import MapComponentText from "@/stores/Modules/MapComponentText";
import StaticPopupStore from "@/stores/Modules/StaticPopupStore";
import Tracing from "@/stores/Modules/Tracing";
import SubAccounts from "@/stores/Modules/SubAccounts";
import StreetCabinet from "@/stores/Modules/StreetCabinet";
import ObjectInfo from "@/stores/Modules/ObjectInfo";
import OpenreachDuctsAndStructures from "@/stores/Modules/OpenreachDuctsAndStructures";
import ExportHistory from '@/stores/Modules/ExportHistory'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    Auth,
    Profile,
    Accounts,
    Projects,
    DuctTypes,
    ObjectTypes,
    LayersList,
    Maps,
    State,
    Search,
    Properties,
    Duct,
    Polygon,
    ObjectMarker,
    PolygonPopup,
    Elevation,
    LocationPhotos,
    SubDuctInfoModal,
    NetworkConnection,
    RoadWorks,
    DuctConnections,
    Sites,
    Openreach,
    OpenreachNOIs,
    TilePlan,
    MapComponentText,
    StaticPopupStore,
    Tracing,
    SubAccounts,
    StreetCabinet,
    ObjectInfo,
    OpenreachDuctsAndStructures,
    ExportHistory
  }
});
