import axios from "axios";
var LeadInsClient = /** @class */ (function () {
    function LeadInsClient() {
    }
    LeadInsClient.getUlr = function () {
        return process.env.VUE_APP_API_URL + '/api/openreach/leadin';
    };
    LeadInsClient.list = function (piaNoiNumber) {
        return axios.get(LeadInsClient.getUlr() + '/' + piaNoiNumber);
    };
    LeadInsClient.cease = function (leadInRequestId, terminationDate) {
        return axios.put(LeadInsClient.getUlr() + '/cease/' + leadInRequestId, { terminationDate: terminationDate });
    };
    LeadInsClient.resubmit = function (leadInRequestId) {
        return axios.post(LeadInsClient.getUlr() + '/resubmit/' + leadInRequestId);
    };
    LeadInsClient.delete = function (leadInRequestId) {
        return axios.delete(LeadInsClient.getUlr() + '/' + leadInRequestId);
    };
    return LeadInsClient;
}());
export default LeadInsClient;
