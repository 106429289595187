<template>
  <div>
    <b-modal :show="showModal" id="StreetCabinetInfo" :title="title" size="xl" @show="loading=true;modalOpened=true"
             @hidden="modalOpened=false">
      <b-tabs v-if="markerInfo">
        <b-tab title="Racked">
          <b-overlay rounded="sm" :show="loading">
            <div id="app" class="row">
              <div class="list col-6 justify-content-center">
                <h4 style="text-align: center">Front</h4>
                <transition-group name="flip-list" tag="div" v-for="(item) in connections['front']"
                                  :key="item['used_modules'][0]">

                  <div
                      style="text-align: center; vertical-align: center" :style="{backgroundColor: item.color}"
                      class="item"
                      :key="item['used_modules'][0]"

                  >
                    <b-container>
                      <b-row v-if="item['connected_object'] != null">
                        <b-col cols="2">
                          <b-row class="justify-content-start"
                                 :style="{marginTop: item['used_modules'].length * 3 + 'px'}"
                                 v-for="module in item['used_modules']" :key="module">
                            {{ module }}
                          </b-row>
                        </b-col>
                        <b-col cols="10" class="pr-0">
                          <div class="d-flex flex-row justify-content-between">
                            <b-row class="flex-grow-1 justify-content-center"
                                   :style="{marginTop: item['used_modules'].length * 3 + 'px'}">
                              <div class="d-flex justify-content-around">
                            <span class="cursor-pointer" @click="showChild(item['connected_object'])">O -  {{
                                item['connected_object_sequence']
                              }}
                            <br/>
                              <span class="object-type">{{ item['type'] }}</span>
                            </span>

                              </div>
                            </b-row>
                            <div class="d-flex flex-row">
                              <span class="removeConnection" @click="editConnection($event, item)"><i
                                  class="fas fa-expand-arrows-alt"></i></span>
                              <span class="removeConnection"
                                    @click="removeConnection($event, item['used_modules'], 'front')"><i
                                  class="fas fa-times"></i>
                                          </span>
                            </div>
                          </div>
                        </b-col>
                      </b-row>

                      <b-row v-else>
                        <b-col cols="2">
                          <b-row class="justify-content-start" v-for="module in item['used_modules']" :key="module">
                            {{ module }}
                          </b-row>
                        </b-col>
                        <b-col cols="10">
                          <b-row class="justify-content-end"
                                 :style="{marginTop: item['used_modules'].length * 3 + 'px'}">
                            <b-button size="sm" @click="addObject('front', item['used_modules'][0])"><i
                                class="fa fa-plus" aria-hidden="true"></i></b-button>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </transition-group>

              </div>
              <div class="list col-6">
                <h4 style="text-align: center">Rear</h4>
                <transition-group name="flip-list" tag="div" v-for="(item) in connections['rear']"
                                  :key="item['used_modules'][0]">

                  <div
                      style="text-align: center; vertical-align: center" :style="{backgroundColor: item.color}"
                      class="item"
                      :key="item['used_modules'][0]"

                  >
                    <b-container>
                      <b-row v-if="item['connected_object'] != null">
                        <b-col cols="2">
                          <b-row class="justify-content-start"
                                 :style="{marginTop: item['used_modules'].length * 3 + 'px'}"
                                 v-for="module in item['used_modules']" :key="module">
                            {{ module }}
                          </b-row>
                        </b-col>
                        <b-col cols="10" class="pr-0">
                          <div class="d-flex flex-row justify-content-between">
                            <b-row class="flex-grow-1 justify-content-center"
                                   :style="{marginTop: item['used_modules'].length * 3 + 'px'}">
                              <div class="d-flex justify-content-around">
                            <span class="cursor-pointer" @click="showChild(item['connected_object'])">O -  {{
                                item['connected_object_sequence']
                              }}
                            <br/>
                              <span class="object-type">{{ item['type'] }}</span>
                            </span>

                              </div>
                            </b-row>
                            <div class="d-flex flex-row">
                              <span class="removeConnection" @click="editConnection($event, item)"><i
                                  class="fas fa-expand-arrows-alt"></i></span>
                              <span class="removeConnection"
                                    @click="removeConnection($event, item['used_modules'], 'rear')"><i
                                  class="fas fa-times"></i>
                                          </span>
                            </div>
                          </div>
                        </b-col>
                      </b-row>

                      <b-row v-else>
                        <b-col cols="2">
                          <b-row class="justify-content-start" v-for="module in item['used_modules']" :key="module">
                            {{ module }}
                          </b-row>
                        </b-col>
                        <b-col cols="10">
                          <b-row class="justify-content-end"
                                 :style="{marginTop: item['used_modules'].length * 3 + 'px'}">
                            <b-button size="sm" @click="addObject('rear', item['used_modules'][0])"><i
                                class="fa fa-plus" aria-hidden="true"></i></b-button>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </transition-group>

              </div>
            </div>
          </b-overlay>
        </b-tab>
        <b-tab title="Unracked">
          <div class="row show-grid-empty w-100">
            <div class="p-5 w-100">
              <SubObjectsList :parent-object-id="markerInfo.id" :project-id="markerInfo.project_id"
                              :items="unracked_connections"></SubObjectsList>
              <b-button @click="addUnrackedObject" variant="primary" class="add-object-button position-absolute">Add
                object
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal id="add_child" ref="add_child" title="Add Object">
      <div v-for="(types, name) in typeCategories">
        <h3> {{ name }}</h3>
        <b-table :fields="fields" :items="types">
          <template #cell(color)="data">
            <span class='object-color' :style="{backgroundColor: data.item.color}"></span>
          </template>
          <template #cell(actions)="data">
            <div class="float-right">
              <b-button class="mr-2" size="sm" @click="storeObject(data.item)" variant="outline-info">add</b-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal id="add_unracked_child" ref="add_unracked_child" title="Add Object">
      <div v-for="(types, name) in typeCategories">
        <h3> {{ name }}</h3>
        <b-table :fields="fields" :items="types">
          <template #cell(color)="data">
            <span class='object-color' :style="{backgroundColor: data.item.color}"></span>
          </template>
          <template #cell(actions)="data">
            <div class="float-right">
              <b-button class="mr-2" size="sm" @click="storeUnrackedObject(data.item)" variant="outline-info">add
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
    <StreetCabinetMoveObject/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DuctsInfoShort from "@/components/modals/Duct/Info/DuctsInfoShort";
import AddObject from "../Object/AddObject";
import CommonTools from "../../../../Helpers/CommonTools";
import StreetCabinetMoveObject from "@/components/modals/Objects/Info/StreetCabinetMoveObject.vue";
import SubObjectsList from "@/components/modals/Objects/Object/SubObjectsList.vue";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  name: "StreetCabinetInfo",
  components: {SubObjectsList, StreetCabinetMoveObject, AddObject, DuctsInfoShort, CommonTools},
  data: () => {
    return {
      markerInfo: null,
      modalOpened: false,
      position: '',
      moduleNumber: 0,
      objectTypes: null,
      unracked_connections: [],
      loading: true,
      key: 0,
      fields: [{key: 'name', label: 'Name'}, {key: 'color', label: 'Color'}, {
        key: 'actions',
        name: 'Actions',
        class: 'text-right'
      }],
      objectFields: [{key: 'id', label: 'Object ID'}, {key: 'object_type.name', label: 'Type'}, {
        key: 'color',
        label: 'Color'
      }, {
        key: 'actions',
        name: 'Actions',
        class: 'text-right'
      }],
      over: {},
      startLoc: 0,
      dragging: false,
      dragFrom: {}
    }
  },

  watch: {
    modalOpened(value) {
      if (value) {
        this.getConnections();
      }
    },
    objectModalInfo(value) {
      if (value && value.code && value.code === ObjectTypesEnum.OT_STREET_CABINET) {
        this.loading = true;
        axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/info/' + value.id).then(response => {
          this.markerInfo = response.data.data
          const modalId = ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_STREET_CABINET);
          this.$bvModal.show(modalId);
        }).finally(() => this.loading = false);
      }
    }
  },
  computed: {
    showModal() {
      return this.markerInfo !== undefined;
    },
    typeCategories: {
      get() {
        return this.$store.state.ObjectTypes.connectableObjectTypes;
      },
    },
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
    ...mapGetters({
      connections: 'StreetCabinet/connections',
      objectModalInfo: 'ObjectInfo/objectModalInfo',
    }),
    title: {
      get() {
        if (this.markerInfo && this.markerInfo.object_type !== undefined) {
          return this.markerInfo.generated_identifier + ' (' + this.markerInfo.object_type.name + ')';
        } else {
          if (this.loading) {
            return;
          }
          return '';
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getConnectionsFromStore: 'StreetCabinet/getConnections',
    }),
    showChild(obj) {
      this.loading = true;
      this.$store.dispatch('ObjectMarker/getInfo', {id: obj}).then(response => {
        if (response.object_type.code === ObjectTypesEnum.OT_PATCH_PANEL) {
          this.$bvModal.show(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_PATCH_PANEL))
        } else {
          throw new Error('Unknown object type')
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    addObject(position, moduleNumber) {
      this.position = position
      this.moduleNumber = moduleNumber
      this.loading = true;
      this.$store.dispatch('ObjectTypes/getConnectableObjects', {
        objectId: this.markerInfo.id,
        default_type_id: this.markerInfo.object_type.default_type_id
      }).then(() => {
        this.$bvModal.show('add_child');
        this.loading = false;
      })
    },
    addUnrackedObject() {
      this.loading = true;
      this.$store.dispatch('ObjectTypes/getConnectableObjects', {
        objectId: this.markerInfo.id,
        default_type_id: this.markerInfo.object_type.default_type_id
      }).then(() => {
        this.loading = false;
        this.$bvModal.show('add_unracked_child');
      })
    },
    changeTab(tabName) {
      this.activeTab = tabName
    },
    removeConnection(event, modules, position) {
      this.loading = true;
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/ot_street_cabinet/remove_connection', {
        'modules': modules,
        'position': position
      }).then(() => {
        this.getConnections()
        this.loading = false;
      })

      event.stopPropagation()
    },
    editConnection(event, movableObject) {
      this.$store.commit('StreetCabinet/setMovableObject', movableObject)
      this.$store.commit('StreetCabinet/setFrontPorts', this.connections['front'])
      this.$store.commit('StreetCabinet/setRearPorts', this.connections['rear'])
      this.$store.commit('StreetCabinet/setStreetCabinetId', this.markerInfo.id)
      this.$bvModal.show('moveStreetCabinetObject');
      event.stopPropagation()
    },
    storeUnrackedObject(item) {
      this.loading = true;
      let data = {}
      data.child_id = item.id;
      data.child_object_code = item.code
      data.cabinet_id = this.markerInfo.id
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/ot_street_cabinet/unracked_connection', data).then(() => {
        this.loading = false;
        this.getConnections();
      })
      this.$bvModal.hide('add_unracked_child');
      this.getConnections()
    },
    storeObject(item) {
      this.loading = true;
      let data = {}
      let moduleIndex = 1230;
      this.connections[this.position].forEach((module, index) => {
        if (module['used_modules'][0] === this.moduleNumber) {
          moduleIndex = index;
        }
      });
      for (let n = moduleIndex; n <= parseInt(item.modules); n++) {
        if (this.connections[this.position][n] === undefined || this.connections[this.position][n]['connected_object'] != null) {
          this.$swal('Not enough free modules')
          this.loading = false;
          return;
        }
      }
      data.child_id = item.id
      data.position = this.position
      data.child_object_code = item.code
      data.cabinet_id = this.markerInfo.id
      data.module_number = this.moduleNumber
      data.modules = item.modules
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/ot_street_cabinet/connection', data).then(() => {
        this.getConnections()
      }).catch(() => {
        this.loading = false;
      });
      this.$bvModal.hide('add_child');
    },
    shown() {
      this.getConnections()
    },
    getConnections() {
      this.loading = true;
      this.getConnectionsFromStore({id: this.markerInfo.id})
      this.getUnrackedConnections();

    },
    getUnrackedConnections() {
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/ot_street_cabinet/unracked_connections').then(response => {
        this.unracked_connections = response.data.data;
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>


.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}

* {
  box-sizing: border-box;
}

.removeConnection {
  padding: 2px;
  cursor: pointer;
  z-index: 22
}

.list > div {
  display: flex;
  flex-direction: column;
}

.item {
  width: 200px;
  padding: 5px;
  margin: 5px auto 5px 5px;
  background: rgb(128, 128, 128);
  color: white;
  font-family: sans-serif;
  border-radius: 5px;
  display: inline-block;
}

li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-object-button {
  bottom: 5px;
  right: 25px;
}

.object-type {
  font-size: 13px;
  font-weight: bold;
}
</style>