import vue from "vue";

const actions = {
    traceWithDeviceAndFibre({commit}, data) {
        commit('setDeviceId', data.device_id);
        commit('setFibreId', data.fibre_id);
    }
};
const state = {
    fibreId: null,
    deviceId: null,

};
const getters = {
    fibreId: state => {
        return state.fibreId;
    },
    deviceId: state => {
        return state.deviceId;
    }
};
const mutations = {
    setFibreId(state, data) {
        vue.set(state, 'fibreId', data);
    },
    setDeviceId(state, data) {
        vue.set(state, 'deviceId', data);
    },

};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}