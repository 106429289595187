<template>
  <b-modal ref="customPropertyFlagSelector" centered id="customPropertyFlagSelector" hide-footer hide-header>
    <ul id='flag_icons'>
      <li data-toggle='tooltip' data-placement='top' title='Blue' @click='flagProperty("blue")'><img
          src='/assets/img/icons/flags/flag_blue.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Green' @click='flagProperty("green")'><img
          src='/assets/img/icons/flags/flag_green.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Orange' @click='flagProperty("orange")'><img
          src='/assets/img/icons/flags/flag_orange.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Pink' @click='flagProperty("pink")'><img
          src='/assets/img/icons/flags/flag_pink.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Purple' @click='flagProperty("purple")'><img
          src='/assets/img/icons/flags/flag_purple.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Red' @click='flagProperty("red")'><img
          src='/assets/img/icons/flags/flag_red.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Yellow' @click='flagProperty("yellow")'><img
          src='/assets/img/icons/flags/flag_yellow.png'/></li>
    </ul>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "CustomPropertiesFlagSelector",
  methods: {
    flagProperty(color) {
      axios.get(process.env.VUE_APP_BASE_URL + '/api/project/' + this.selectedProject.id + '/customuprn/' + this.uprnInfo.uprn + '/flag/' + color).then(() => {
        this.$bvModal.hide('customPropertyFlagSelector');
        this.$root.$emit('changeCustomMarkerIcon', {uprn: this.uprnInfo.uprn, color: color, key: this.uprnInfo.key})
      });
    }

  },
  computed: {
    ...mapGetters({
      uprnInfo: 'Search/getCustomUprnInfo',
      selectedProject: 'Projects/selectedProject'
    })
  },

}
</script>

<style scoped>
#flag_icons {
  list-style: none;
  float: left;
  margin-top: 20px
}

#flag_icons li {
  float: left;
  padding: 5px 12px;
  text-align: center;
  border: 1px solid lightblue;
  margin-left: 9px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>