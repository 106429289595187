<template>
  <b-modal id="street_cabinet" ok-title="Save" @ok="onSubmit($event)" cancel-title="Reset" @cancel="onReset($event)"
           @close="close" title="Street Cabinet" body-class="object_type_modal_body">
    <div>
      <b-form>
        <b-form-group id="input-group-1" label="Object name:" label-for="input-1">
          <b-form-input

              id="input-1"
              v-model="object.name"
              placeholder="Enter object name"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Front modules:" label-for="input-2">
          <b-form-input
              type="number"
              id="input-2"
              min="0"
              step="1"
              v-model="object.front_modules"
              placeholder="Enter row number"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Rear modules:" label-for="input-3">
          <b-form-input
              type="number"
              min="0"
              step="1"
              id="input-3"
              v-model="object.rear_modules"
              placeholder="Enter row number"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-7" label="Color" label-for="input-7">
          <v-swatches v-model="object.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";


export default {
  components: {VSwatches},
  name: "StreetCabinet",
  //OT_PATCH_PANEL
  data() {
    return {
      object: {
        name: '',
        color: null,
        rear_modules: 0,
        front_modules: 0,
        code: ObjectTypesEnum.OT_STREET_CABINET.toLowerCase()
      },
    }
  },
  methods: {
    close() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)
    },
    onSubmit(event) {
      const self = this
      event.preventDefault()
      this.$store.dispatch('ObjectTypes/store', this.object).then(() => {
        self.$bvModal.hide("street_cabinet");
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our object values
      this.object.name = ''
      this.object.color = null;
      this.object.rear_modules = 0;
      this.object.front_modules = 0;
      // Trick to reset/clear native browser form validation state
    }
  },
  created() {
    const self = this
    this.$root.$on('OT_STREET_CABINET', function (event) {
      self.$bvModal.hide("create_object_type_modal");
      self.$bvModal.show("street_cabinet");
    })
  }
}
</script>

<style scoped>
.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}
</style>