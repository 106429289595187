<template>
  <div :initialduct="initialduct" :class="{selected:hovered}" class="li" @mouseover="hovered=true"
       @mouseleave="hovered=false">
    <i @click="opened=!opened" :class="iconClasses"></i>
    <div class="d-inline" @click="opened=!opened" v-if="tube.ducts.length > 0 || tube.cables.length > 0">
      <svg width="24" height="24">
        <path
            style="fill-rule: evenodd; stroke: rgb(0, 0, 0); stroke-width: 0; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
            d="m6.1153753,9.071917382812444 c-1.3034685000000001,0 -2.3528109,1.1759 -2.3528109,2.6365 l0,0.21880000000000002 c0,1.4607 1.0493424,2.6365 2.3528109,2.6365 l10.6198167,0 c1.303468,0 2.352811,-1.1758 2.352811,-2.6365 l0,-0.21880000000000002 c0,-1.4606 -1.049343,-2.6365 -2.352811,-2.6365 l-10.6198167,0 zm10.8128197,0.6179000000000001 a1.5240276000000001,2.0647589 0 0 1 1.5233539999999999,2.0649 a1.5240276000000001,2.0647589 0 0 1 -1.5233539999999999,2.065 a1.5240276000000001,2.0647589 0 0 1 -1.525651,-2.065 a1.5240276000000001,2.0647589 0 0 1 1.525651,-2.0649 z"
            id="rect3334" inkscape:connector-curvature="0" fill-opacity="1" :fill="tube.color"/>
      </svg>
    </div>
    <img @click="opened=!opened" v-else src="/assets/img/duct/fiberCable.svg"/>
    <span @click="opened=!opened"> Tube {{ tube.tube_number }}</span>
    <b-button variant="outline-info" size="xs" class="add_fibre_button" @click="showAddFibreCableWindow" v-if="hovered">
      Add Fibre Cable
    </b-button>
    <b-button @click="addDuctToTube" variant="outline-info" size="xs" class="add_fibre_button" v-if="hovered">Add duct
    </b-button>
    <CableInTree :depth="depth" style="margin-left: 10px" v-if="opened &&  tube.cables.length>0"
                 v-for="cable in tube.cables" :key="'cable_'+'_'+depth+'_'+cable.id" :initialduct="initialduct"
                 :tube="tube"
                 :cables="tube.cables" :cable="cable"></CableInTree>

    <DuctTree v-if="opened &&  tube.ducts.length>0" :initialduct="initialduct" v-for="duct in tube.ducts"
              :key="'child_child_'+duct.id+'_'+tube.id" :duct="duct" :depth="depth"></DuctTree>
  </div>
</template>

<script>

// import DuctTree from "@/components/modals/Duct/Info/DuctTree";
import CableInTree from "./CableInTree";

export default {
  name: "TubeInTree",
  props: ['tube', 'initialduct', 'depth', 'cables'],
  components: {
    DuctTree: () => {
      return import('./DuctTree')
    },
    CableInTree
  },
  //@todo add fibre cables from api
  computed: {
    iconClasses() {
      return {
        'fas fa-caret-right': this.tube.cables.length && !this.opened || this.tube.ducts.length && !this.opened,
        'fas fa-caret-down': this.tube.cables.length && this.opened || this.tube.ducts.length && !this.openeds,
        '': !this.tube.ducts
      }
    },
  },
  data: function () {
    return {
      hovered: false,
      opened: true,
      styleObject: {
        color: 'red'
      }
    }
  },
  methods: {
    addDuctToTube() {
      this.$bvModal.show('SubDuctStandAlone')
      // set SubDuctStandaloneDuct modal params
      delete this.initialduct.parent_duct_id;
      this.initialduct.parent_tube_id = this.tube.id;
      this.$store.commit('SubDuctInfoModal/setParams', this.initialduct, {root: true});
    },
    showAddFibreCableWindow() {
      this.$store.commit("DuctTypes/setParentTube", this.tube.id);
      this.$store.dispatch('DuctTypes/getCables')
      this.$bvModal.show('FibreCableModal');
    }
  }
}
</script>

<style scoped>

.cableList {
  /*margin:0;*/
  padding: 0;
  margin-left: 5px;
}

.cableList div.li {
  list-style: none;
  font-size: 10px;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.selected {
  font-weight: bold;
  cursor: pointer;
  display: block;
}

.add_fibre_button {
  padding: 1px;
  margin-left: 10px;
  font-size: 8px;
}

.btn-xs {
  font-size: 10px;
  margin-left: 5px;
  padding: 1px 2px;
}
</style>