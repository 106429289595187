const SplitLinesToolBar = {
    data: function () {
        return {
            splitLinesActive: false,
        }
    },
    methods: {

        addSplitLinesControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'splitlines',
                block: 'custom',
                title: 'Split Ducts',
                actions: [],
                className: 'fas fa-cut splitlines-controll',
                onClick: () => {
                    self.splitLinesActive = !self.splitLinesActive;
                    self.$store.commit("Duct/setRedrawDuctsForMobile", self.splitLinesActive);
                },
                toggle: true
            });
        },
    }
}
export default SplitLinesToolBar;