<template>
  <div class="menu_item">
    <div class="d-flex justify-content-between">
      <span class="checkbox-name"> {{ name }}  <b-button target="_blank"
                                                         href="https://tiles.postcodegenie.com//geoserver/wms/kml?layers=main:user_data_new"
                                                         variant="outline-info" size="sm">Download KML</b-button></span>

      <b-form-checkbox class="checkbox" :ref="'layers'+id" @change="layerCheckBoxChanged(checkedModel,id)"
                       :checked="checked"
                       v-model="checkedModel" :id="id"
                       name="check-button" switch/>
    </div>
    <slot name="body" v-if="checked"></slot>
  </div>
</template>

<script>
export default {
  name: "MapLayerUserDataCheckbox",
  props: {
    name: String,
    id: String,
    checked: Boolean
  },
  data() {
    return {
      checkedModel: this.checked,
    }
  },
  methods: {
    layerCheckBoxChanged(state, id) {
      if (state) {
        this.$store.dispatch('LayersList/activateSubLayer', id)
      } else {
        this.$store.dispatch('LayersList/deactivateLayer', id);
      }
    }
  },
  watch: {
    checked: function (val) {
      this.checkedModel = val === 1 || val === true;
      this.$store.dispatch('State/saveSubLayerState', {
        code: this.id,
        state: this.checkedModel,
      });

    }
  }
}
</script>

<style scoped>
.menu_item {
  min-height: 35px;
  width: 100%;
  line-height: 2;
}

button {
  padding: 1px 5px;
}
</style>
