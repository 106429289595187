const LivePendingToolBar = {
    data: function () {
        return {
            livePendingVisible: false,
        }
    },
    methods: {
        livePendingHover(layer, type, obj) {
            const self = this;
            layer.on('click', function (e) {
                if (self.livePendingVisible) {
                    const status = obj.is_implemented === 1 ? 0 : 1;
                    if (type === 'Object') {
                        self.changeObjectImplementationStatus(obj, status)
                    }
                    if (type === 'Duct') {
                        self.changeDuctImplementationStatus(obj, status)
                    }
                }
            });

        },
        addLivePendingControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'livepending',
                block: 'custom',
                title: 'Change Duct / Object Status',
                actions: [],
                className: 'fas fa-pen livepending-controll',
                onClick: function (e) {
                    self.livePendingVisible = !self.livePendingVisible;
                    self.$store.commit("Duct/setRedrawDuctsForMobile", self.livePendingVisible);
                },
                toggle: true

            })
        },
    }
}
export default LivePendingToolBar;