<template>
  <b-modal v-if="this.buildings.length>0" centered v-model="showModal" title="Buildings list" @close="closeModal"
           hide-footer>
    <ul class="buildings_list">
      <li v-for="building in this.buildings" :key="building.ogc_fid">
        <a target="_blank" :href="building.hyperlink">{{ building.name }}</a>
      </li>
    </ul>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Buildings",

  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    buildings(list) {
      if (list.length > 0) {
        this.showModal = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      buildings: 'Search/buildings'
    })
  },
  methods: {
    closeModal() {
      this.showModal = false;
      // this.$store.commit("Search/setBuildings", [], {root: true});
    }
  }
}
</script>

<style scoped>
.buildings_list {
  list-style: none;
  font-size: 11px;
  padding-left: 0px;
}
</style>