import axios from "axios";
var OpenreachPia = /** @class */ (function () {
    function OpenreachPia(token) {
        this.token = token;
    }
    OpenreachPia.prototype.getCredentials = function (userId) {
        return axios.get("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/").concat(userId, "/openreach"));
    };
    OpenreachPia.prototype.saveCredentials = function (userId, credentials) {
        return axios.put("".concat(process.env.VUE_APP_API_URL, "/api/admin/users/").concat(userId, "/openreach"), credentials);
    };
    return OpenreachPia;
}());
export default OpenreachPia;
