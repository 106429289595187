<template>
<b-modal id="editLegalInstrument" v-if="legalDocument!=null" @hidden="update" title="Edit Legal Instrument" ok-title="Update">
  <b-overlay :show="showLoader">
    <b-form-group>
      <label for="document_name">Title:</label>
      <b-form-input
          id="document_name"
          v-model="legalDocument.name"
          trim
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <label>Expire Date:</label>
      <b-form-datepicker v-model="legalDocument.expires_at"></b-form-datepicker>
    </b-form-group>
  </b-overlay>
</b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "EditLegalInstrument",
  methods:{
    update(e){
      e.preventDefault()
      this.$store.dispatch('Sites/updateLegalInstrument',{
        id:this.legalDocument.id,
        name:this.legalDocument.name,
        expires_at:this.legalDocument.expires_at
      }).then(()=>{
        this.$bvModal.hide('editLegalInstrument');
      });
    }
  },
  computed:{
    ...mapGetters({
      legalDocument:'Sites/legalInstrument',
      showLoader:'Sites/isLoading',
    })
  }
}
</script>

<style scoped>

</style>