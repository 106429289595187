const Loader = {
    created: function () {

    },
    data() {
        return {
            globalLoader: false,
            globalLoaderMessage: '',
        }
    },
    methods: {
        showLoader(message) {
            this.globalLoaderMessage = message;
            this.globalLoader = true;
        },
        hideLoader() {
            this.globalLoaderMessage = '';
            this.globalLoader = false;
        }
    }
}
export default Loader;
