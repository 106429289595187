<template>
  <div>
    <b-modal size="lg" id="duct_type_list" @ok="closeDuctTypeWindow" @close="closeDuctTypeWindow"
             @cancel="closeDuctTypeWindow" ok-only
             no-close-on-backdrop no-close-on-esc ok-title="Close" title="Duct Types" centered>
      <template #modal-title>
        Duct types
        <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit == 1" v-b-modal.new_duct_type
                  class="ml-5" size="sm" variant="info">Add new Duct Type
        </b-button>
      </template>
      <b-overlay :show="showOverlay" rounded="sm">

        <b-table responsive :items="ductTypes" :fields="fields">

          <template #cell(color)="data">
            <span class='duct-color' :style="{backgroundColor: data.item.color}"></span>
          </template>
          <template #cell(actions)="data">
            <div v-if="isInSelectMode===false" class="float-right">
              <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit == 1" class="mr-2" size="sm"
                        @click="showEditDuctTypeModal(data.item)" variant="outline-info">Edit
              </b-button>
              <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit == 1" class="mr-2" size="sm"
                        @click="remove(data.item.id)" variant="outline-danger">Remove
              </b-button>
              <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit == 1" size="sm"
                        @click="showPricing(data.item.id)" variant="outline-success">Pricing
              </b-button>
            </div>
            <div v-else class="float-right">
              <b-button class="mr-2" size="sm" @click="selectDuctType(data.item)" variant="outline-info">Select
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-modal>
    <b-modal id="new_duct_type" ref="new_duct_type" ok-title="Save" title="New Duct Type" @ok="addNewDuctType" centered
             no-close-on-backdrop no-close-on-esc body-class="has-vue-swatches" @hide="clearNewDuctTypeModal">
      <b-overlay :show="showOverlayForNewDuctType" rounded="sm">
        <b-form-group label-for="new_duct_name" label="Duct Type Name">
          <b-input id="new_duct_name" v-model="newDuctType.name"></b-input>
        </b-form-group>
        <b-form-group label-for="new_duct_color" label="Duct Type Color">
          <v-swatches v-model="newDuctType.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
        <b-form-group label-for="tube_counts" label="Tube Count">
          <b-form-input id="tube_counts" v-model="newDuctType.tubes"></b-form-input>

        </b-form-group>
        <b-form-group>
          <b-button variant="primary" :disabled="parseInt(newDuctType.tubes)===0" v-b-modal.new_duct_assign_colors>
            Assign Colors
          </b-button>
        </b-form-group>
        <b-form-group
            label-for="type_name"
            label="Duct Type"
            invalid-feedback="Duct Type is required"
        >
          <b-form-select required id="type_name" v-model="newDuctType.type_name">
            <b-form-select-option value="underground">Underground</b-form-select-option>
            <b-form-select-option value="overhead">Overhead</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-row>
          <label for="manufacturer_name">Manufacturer Name</label>
          <b-form-input id="manufacturer_name" v-model="newDuctType.manufacturer_name"></b-form-input>
        </b-form-row>
        <b-form-row>
          <label for="product_code">Product Code</label>
          <b-form-input id="product_code" v-model="newDuctType.product_code"></b-form-input>
        </b-form-row>
      </b-overlay>
    </b-modal>
    <b-modal id="edit_duct_type" ref="edit_duct_type" ok-title="Save" title="Edit Duct Type" @ok="editDuctType" centered
             no-close-on-backdrop body-class="has-vue-swatches">
      <b-overlay :show="showOverlayForEditDuctType" rounded="sm">
        <b-form-group label-for="new_duct_name" label="Duct Type Name">
          <b-input id="new_duct_name" v-model="editDuctTypeModel.name"></b-input>
        </b-form-group>
        <b-form-group label-for="new_duct_color" label="Duct Type Color">
          <v-swatches v-model="editDuctTypeModel.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
        <b-form-group label-for="tube_counts" label="Tube Count">
          <b-form-input id="tube_counts" v-model="editDuctTypeModel.tubes"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary" :disabled="parseInt(editDuctType.tubes)===0" v-b-modal.edit_duct_assign_colors>
            Assign Colors
          </b-button>
        </b-form-group>
        <b-form-group
            label-for="type_name"
            label="Duct Type"
            invalid-feedback="Duct Type is required"
        >
          <b-form-select required id="type_name" v-model="editDuctTypeModel.type_name">
            <b-form-select-option value="underground">Underground</b-form-select-option>
            <b-form-select-option value="overhead">Overhead</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-row>
          <label for="manufacturer_name">Manufacturer Name</label>
          <b-form-input id="manufacturer_name" v-model="editDuctTypeModel.manufacturer_name"></b-form-input>
        </b-form-row>
        <b-form-row>
          <label for="product_code">Product Code</label>
          <b-form-input id="product_code" v-model="editDuctTypeModel.product_code"></b-form-input>
        </b-form-row>
      </b-overlay>
    </b-modal>
    <b-modal no-close-on-esc size="lg" id="duct_pricing" ref="duct_pricing" ok-title="Save"
             title="Duct Type Surface Prices"
             @ok="saveDuctTypePrices" centered no-close-on-backdrop>
      <b-overlay :show="showOverlayForDuctTypePricing" rounded="sm">
        <div class="overflow-auto" style="height: 500px;">
          <form @submit.prevent="saveDuctTypePrices" ref="duct_price_form">
            <div class="form-group row" v-for="(price,index) in this.surfacePrices" :key="price.surface_code">
              <label class="col-sm-9 surface_name col-form-label">{{ price.name }}</label>
              <div class="col-sm-3">
                <b-input-group size="sm" prepend="£" append="p/m">
                  <b-form-input type="number" step="0.01" v-model="surfacePrices[index].price"></b-form-input>
                </b-input-group>
              </div>

            </div>
          </form>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal id="new_duct_assign_colors" ok-title="Assign" title="Assign colors to tubes" body-class="has-vue-swatches">
      <div>
        <div class="d-flex justify-content-between" v-for="tubeItem in parseInt(newDuctType.tubes)">
          <span class="flex-grow-1">Tube #{{ tubeItem }}</span>
          <span><v-swatches v-model="tubeColors[tubeItem]" @input="setNewTubeColor($event,tubeItem)" popover-x="left"
                            swatches="text-advanced" shapes="circles"></v-swatches></span>
          <div style="width: 50px;" class="ml-4 my-auto align-items-center"><i
              v-if="newDuctType.tubeColors[String('tube_'+tubeItem)]"
              @click="removeNewDuctTypeTubeColor(tubeItem)"
              class="text-center cursor-pointer fas fa-times"></i>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit_duct_assign_colors" ok-title="Assign" title="Assign colors to tubes" body-class="has-vue-swatches"
    >
      <div>
        <div class="d-flex justify-content-between" v-for="tubeItem in editDuctTypeModel.tube_colors">
          <span class="flex-grow-1">Tube #{{ tubeItem.tube_index }}</span>
          <span><v-swatches :value="tubeItem.tube_color"
                            @input="setEditTubeColor($event,tubeItem)" popover-x="left"
                            swatches="text-advanced" shapes="circles"></v-swatches></span>
          <div style="width: 50px;" class="ml-4 my-auto align-items-center"><i
              v-if="tubeItem.tube_color"
              @click="removeEditedDuctTypeTubeColor(tubeItem)"
              class="text-center cursor-pointer fas fa-times"></i>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import {mapGetters} from "vuex";
import axios from "axios";
import Vue from "vue";

export default {
  name: "DuctTypeList",
  components: {VSwatches},
  data() {
    return {
      tubeColors: [],
      showOverlay: false,
      showOverlayForNewDuctType: false,
      showOverlayForEditDuctType: false,
      showOverlayForDuctTypePricing: false,
      surfacePrices: [],
      selectedDuctTypeId: 0,
      fields: [
        {key: 'name', label: 'Name'},
        {key: 'tubes', label: 'Tubes'},
        {key: 'type_name', label: 'Type'},
        {key: 'manufacturer_name', label: 'Manufacturer'},
        {key: 'product_code', label: 'Product'},
        {key: 'color', label: 'Color'},
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right'
        }],
      newDuctType: {
        tubeColors: [],
        name: null,
        color: '',
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: 0

      },
      editDuctTypeModel: {
        id: 0,
        name: null,
        color: '',
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: 0,
        tube_colors: [],
      }
    }
  },
  watch: {
    newDuctTypeTubesCount(value) {
      this.newDuctType.tubeColors = [];
      if (value > 0) {
        for (let i = 1; i <= value; i++) {
          this.newDuctType.tubeColors[String('tube_' + i)] = null;
          Vue.set(this.newDuctType.tubeColors, String('tube_' + i), null)
        }
      }
    },
    editDuctTypeTubesCount(value) {
      const currentLength = this.editDuctTypeModel.tube_colors.length;
      if (currentLength > value) {
        const remainder = currentLength - value;
        for (let i = 0; i < remainder; i++) {
          this.editDuctTypeModel.tube_colors.pop();
        }
      }
      if (currentLength < value) {
        // add new colors
        for (let i = currentLength; i < value; i++) {
          this.editDuctTypeModel.tube_colors.push({tube_color: null, tube_index: (i + 1) + ""});
        }
      }
    }
  },
  computed: {
    editDuctTypeTubesCount() {
      return this.editDuctTypeModel.tubes;
    },
    newDuctTypeTubesCount() {
      return this.newDuctType.tubes;
    },
    ductTypes: {
      get() {
        return this.$store.state.DuctTypes.ductTypes;
      }
    },
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
    ...mapGetters({
      isInSelectMode: 'Duct/isInSelectMode',
    }),
  },
  methods: {
    removeNewDuctTypeTubeColor(tubeItem) {
      Vue.set(this.newDuctType.tubeColors, String('tube_' + tubeItem), null)
      Vue.set(this.tubeColors, tubeItem, null)
    },
    removeEditedDuctTypeTubeColor(tubeItem) {
      for (let i = 0; i < this.editDuctTypeModel.tube_colors.length; i++) {
        if (this.editDuctTypeModel.tube_colors[i].tube_index === tubeItem.tube_index) {
          this.editDuctTypeModel.tube_colors[i].tube_color = null;
        }
      }
    },
    closeDuctTypeWindow() {
      const self = this;
      Vue.nextTick(function () {
        self.map.pm.disableDraw('line')
        self.$store.commit("Duct/setIsInSelectMode", false);
      });
    },
    remove(ductTypeId) {

      this.showOverlay = true;
      this.$store.dispatch('DuctTypes/remove', ductTypeId).then(() => {
        this.$store.dispatch('DuctTypes/getDuctTypes').finally(() => {
          this.showOverlay = false;
        });
      }).catch(() => {
        this.showOverlay = false;
      })
    },
    addNewDuctType(e) {
      e.preventDefault();
      this.showOverlayForNewDuctType = true;
      for (let tubeIndex of Object.keys(this.newDuctType.tubeColors)) {
        if (!this.newDuctType.tubeColors[tubeIndex]) {
          this.$swal('Color required', 'Please assign color for all tubes of duct', 'warning');
          this.showOverlayForNewDuctType = false;
          return;
        }
      }
      this.newDuctType.tube_colors = this.getTubeColors(this.newDuctType.tubeColors);
      delete this.newDuctType.tubeColors;
      this.$store.dispatch('DuctTypes/store', this.newDuctType).then(() => {
        this.$store.dispatch('DuctTypes/getDuctTypes').finally(() => {
          this.showOverlayForNewDuctType = false;
          this.$bvModal.hide('new_duct_type')
          this.newDuctType.color = null;
          this.newDuctType.type_name = null;
          this.newDuctType.name = null;
          this.newDuctType.tubes = 0;
          this.newDuctType.product_code = null;
          this.newDuctType.manufacturer_name = null;
        })
      }).finally(() => {
        this.showOverlayForNewDuctType = false;
      })
    },
    showEditDuctTypeModal(ductType) {
      this.editDuctTypeModel = {...ductType}
      this.$bvModal.show('edit_duct_type')
    },
    editDuctType(e) {
      e.preventDefault();
      this.showOverlayForEditDuctType = true;
      this.$store.dispatch('DuctTypes/update', this.editDuctTypeModel).then(() => {
        this.$store.dispatch('DuctTypes/getDuctTypes').finally(() => {
          this.showOverlayForEditDuctType = false;
          this.$bvModal.hide('edit_duct_type')
          this.editDuctTypeModel.color = null;
          this.editDuctTypeModel.type_name = null;
          this.editDuctTypeModel.name = null;
          this.editDuctTypeModel.tubes = 0;
          this.editDuctTypeModel.product_code = null;
          this.editDuctTypeModel.manufacturer_name = null;
        })
      }).finally(() => {
        this.showOverlayForEditDuctType = false;
      })
    },
    selectDuctType(ductType) {
      this.$store.commit("Duct/setIsInSelectMode", false);
      this.$store.commit("Duct/setSelectedDuctType", ductType);
      this.$bvModal.hide("duct_type_list");
    },
    showPricing(ductType) {
      this.selectedDuctTypeId = ductType;
      const self = this;
      this.showOverlayForDuctTypePricing = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/type/' + ductType + '/price').then(response => {
        self.showOverlayForDuctTypePricing = false;
        self.surfacePrices = response.data.data;
      });
      this.$bvModal.show('duct_pricing')
    },
    saveDuctTypePrices(e) {
      e.preventDefault();
      const self = this;
      self.showOverlayForDuctTypePricing = true;
      axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/type/' + this.selectedDuctTypeId + '/price', this.surfacePrices).then(response => {
        self.showOverlayForDuctTypePricing = false;
        this.$bvModal.hide('duct_pricing')
      }).catch(error => {
        this.$swal("Error", "Error while saving prices", "warning").then(() => {
          self.showOverlayForDuctTypePricing = false;
        })
      });
    },
    setNewTubeColor(value, tubeIndex) {
      Vue.set(this.newDuctType.tubeColors, String('tube_' + tubeIndex), value)
      Vue.set(this.tubeColors, tubeIndex, value)
    },
    setEditTubeColor(value, tubeIndex) {
      for (let i = 0; i < this.editDuctTypeModel.tube_colors.length; i++) {
        if (this.editDuctTypeModel.tube_colors[i].tube_index === tubeIndex.tube_index) {
          this.editDuctTypeModel.tube_colors[i].tube_color = value;
        }
      }
      // Vue.set(this.editDuctTypeModel.tubeColors, String('tube_' + tubeIndex), value)
      // Vue.set(this.tubeColors, tubeIndex, value)
    },
    getTubeColors(originalObject) {
      let newArray = [];
      for (const key in originalObject) {
        if (originalObject.hasOwnProperty(key)) {
          const index = key.split("_")[1];
          const color = originalObject[key];

          const newObj = {
            tube_index: index,
            tube_color: color
          };

          newArray.push(newObj);

        }
      }
      return newArray;
    },
    clearNewDuctTypeModal() {
      this.newDuctType = {
        tubeColors: [],
        name: null,
        color: '',
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: 0
      };
      this.tubeColors = [];
    }
  },
  mounted() {
    this.$store.dispatch('DuctTypes/getDuctTypes')
  }
}
</script>

<style scoped>
.duct-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}

.surface_name {
  font-size: 12px;
}
</style>