import vue from 'vue';

const state = {
    flagged: []
};
const mutations = {
    setFlagged(state, data) {
        vue.set(state, 'flagged', data);
    },
    addFlag(state, data) {
        state.flagged.push(data);
    },
    removeFlag(state, data) {
        const index = state.flagged.findIndex(flags => flags.uprn === data)
        state.flagged.splice(index, 1)
    }
};
const actions = {
    getFlagged({commit, rootGetters, dispatch}) {
        const selectedProject = rootGetters["Projects/selectedProject"];
        axios.get(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject.id + '/property').then(response => {
            commit('setFlagged', response.data.data);
            dispatch('Search/setFlagsOnMarkers', response.data.data, {root: true});
        });
    },
    addFlag(ctx, data) {
        const self = this;
        return new Promise(function (resolve, reject) {
            const selectedProject = ctx.rootGetters["Projects/selectedProject"];
            axios.post(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject.id + '/property', data).then(response => {
                let dispatch = 'Search/loadMultiUprnInfo';
                if (data.isCustom) {
                    dispatch = 'Search/loadCustomUprnInfo';
                }
                ctx.dispatch(dispatch, {uprn: data.uprn, all_properties: true}, {root: true}).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            }).catch(error => {
                reject(error)
            })
        })
    },
    removeFlag(ctx, uprn) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return axios.delete(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject.id + '/property/' + uprn)
    },
};
const getters = {
    flagged: state => {
        return state.flagged
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters

}