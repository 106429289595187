<template>
  <b-modal id="addObject" ref="addObject" title="Add Object">
    <div v-for="(types, name) in typeCategories">
      <h3> {{ name }}</h3>
      <b-table :fields="fields" :items="types">
        <template #cell(color)="data">
          <span class='object-color' :style="{backgroundColor: data.item.color}"></span>
        </template>
        <template #cell(actions)="data">
          <div class="float-right">
            <b-button class="mr-2" size="sm" @click="addObject(data.item.id, data.item)" variant="outline-info">add
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AddObject",
  props: {
    parentObjectId: null
  },
  data() {
    return {
      fields: [{key: 'name', label: 'Name'}, {key: 'color', label: 'Color'}, {
        key: 'actions',
        name: 'Actions',
        class: 'text-right'
      }]
    }
  },
  methods: {
    addObject(subTypeId, DefaultTypeId) {
      const self = this
      this.$bvModal.hide('addObject')
      this.$store.dispatch('ObjectMarker/storeChild', {
        'parent_id': this.parentObjectId,
        'default_type_id': DefaultTypeId.default_type_id,
        'child_id': subTypeId
      }).then(response => {
        console.log(response.data.data);
        self.$store.commit("ObjectMarker/setMakerInfo", response.data.data)
        const code = response.data.data.object_type.code;
        console.log('refresh_' + code.toLowerCase());
        self.$root.$emit('refresh_' + code.toLowerCase(), response.data.data.id);
      })
    }
  },
  computed: {
    typeCategories: {
      get() {
        return this.$store.state.ObjectTypes.connectableObjectTypes;
      },
    },
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
    ...mapGetters({
      markerInfo: 'ObjectMarker/makerInfo',
    }),
  },
}
</script>

<style scoped>
.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}
</style>