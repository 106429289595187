const HelperWindow = {
    created: function () {

    },
    data() {
        return {
            globalHelperWindow: false,
            globalHelperWindowMessage: '',
        }
    },
    methods: {
        showHelperWindow(message) {
            this.globalHelperWindowMessage = message;
            this.globalHelperWindow = true;
        },
        hideHelperWindow() {
            this.globalHelperWindowMessage = '';
            this.globalHelperWindow = false;
        }
    }
}
export default HelperWindow;
