<template>
  <div>
    <DuctInfoShort :duct="duct" v-for="duct in ducts" :key="duct.id"/>
  </div>
</template>

<script>
import DuctInfoShort from "@/components/modals/Duct/Info/DuctInfoShort";
export default {
  name: "DuctsInfoShort",
  components: {DuctInfoShort},
  props: ['ducts']
}
</script>

<style scoped>

</style>