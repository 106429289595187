import { render, staticRenderFns } from "./DuctTypeList.vue?vue&type=template&id=509c5158&scoped=true"
import script from "./DuctTypeList.vue?vue&type=script&lang=js"
export * from "./DuctTypeList.vue?vue&type=script&lang=js"
import style0 from "./DuctTypeList.vue?vue&type=style&index=0&id=509c5158&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509c5158",
  null
  
)

export default component.exports