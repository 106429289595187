<template>
  <div class="uprnInfo object_type_modal_body" ref="draggableContainerCustomUprn" id="draggable-container"
       v-if="uprnInfoAvailable"
       :class="{right:menuIsOpened}">
    <div class="d-flex justify-content-between p-1">
      <i class="fas fa-expand-arrows-alt draggable" @mousedown="dragMouseDown"></i>
      <i class="fas fa-times" @click="closeUprnInfo"></i>
    </div>
    <b-tabs pills card fill content-class="mt-3 h-100">
      <b-tab class="pt-0 h-100">
        <template #title>
          <div class="d-flex justify-content-between">
            <span class="pt-1">{{ uprnInfo.uprn }}</span>
            <span>
              <i class="fas fa-copy" @click="copy(uprnInfo.uprn)"></i>
            <b-dropdown :id="'dropdown-'+uprnInfo.uprn" variant="link" size="sm" class="ml-2"

                        menu-class="dropdown-menu-custom"
                        toggle-class="text-decoration-none p-0"
                        no-caret>
                <template #button-content>
                  <i class="fas fa-ellipsis-v text-white"></i>
                </template>

              <b-dropdown-group id="dropdown-group-copy" header="Copy">
                <b-dropdown-item @click="copy(getPropertyUrl(uprnInfo))">Property URL</b-dropdown-item>
                <b-dropdown-item @click="copy(uprnInfo.location.lat.toFixed(6)+', '+uprnInfo.location.lng.toFixed(6))">WGS84</b-dropdown-item>
                <b-dropdown-item @click="copy(parseInt(uprnInfo.coordinates.x)+', '+parseInt(uprnInfo.coordinates.y))">X (Easting) Y (Northing)</b-dropdown-item>
                <b-dropdown-item @click="copy(getGridReference(uprnInfo))">Grid Reference</b-dropdown-item>
                <b-dropdown-item @click="copy(getW3w(uprnInfo))">What3Words</b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-divider v-if="$Utils.isMobile()"></b-dropdown-divider>
              <b-dropdown-item
                  @click='openAppleMap(uprnInfo)'
                  v-if="$Utils.isMobile() && $Utils.isIphone()">Apple Maps</b-dropdown-item>
              <b-dropdown-item @click="openGoogleMap(uprnInfo)"
                               v-if="$Utils.isMobile() && !$Utils.isIphone()">Google Maps</b-dropdown-item>
              <b-dropdown-divider/>
              <b-dropdown-item v-b-modal.edit_property_information>Edit</b-dropdown-item>
              <b-dropdown-item @click="removeProperty()">Delete</b-dropdown-item>
            </b-dropdown>
            </span>
          </div>
        </template>
        <img v-if="uprnInfo.isFlagged" :src="uprnInfo.flag" width="16" height="16"
             @click="removeFlagForProperty(uprnInfo)" alt="">
        <img v-else-if="uprnInfo.isFlagged===0 && loggedIn" src="/assets/img/icons/flags/flag_green.png"
             class="blackwhite"
             v-b-modal.flagSelector
             width="16" height="16" alt="">

        <br/>

        <div>{{ uprnInfo.display_text_normal }}</div>
        <strong>{{ uprnInfo.display_text_bold }}</strong>
        <div class="mt-1" v-show="!additionalInfoLoaded">
          <b-overlay
              :show="showButtonLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
          >
            <br/>
            <div v-on:click='loadAdditionalLocation(uprnInfo.uprn)' class='additional_info_content_container'>
              <button class='btn btn-info btn-sm'>More information</button>
            </div>
          </b-overlay>
        </div>
        <div v-show="additionalInfoLoaded">
          <AdditionalInfo :uprn-additional-info="uprnAdditionalInfo"></AdditionalInfo>

          <FlagSelector v-if="loggedIn" v-model="flagSelectorOpened" :uprnInfo="uprnInfo"></FlagSelector>
        </div>
      </b-tab>
    </b-tabs>

    <CustomPropertiesFlagSelector v-if="loggedIn" v-model="flagSelectorOpened"></CustomPropertiesFlagSelector>

    <b-modal v-if="uprnInfoAvailable" id="edit_property_information" centered title="Property Information"
             @ok="editCustomProperty" ok-title="Update" @show="beforeEditPropertyModalShow">

      <b-form-group label="Organisation Name" label-for="Organisation-Name">
        <b-form-input id="Organisation-Name" v-model="customProperty.ORGANISATION_NAME" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Building Name" label-for="Building-Name">
        <b-form-input id="Building-Name" v-model="customProperty.BUILDING_NAME" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Building Number" label-for="Building-Number">
        <b-form-input id="Building-Number" v-model="customProperty.BUILDING_NUMBER" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Dependant Thoroughfare" label-for="Dependant-Thoroughfare">
        <b-form-input id="Dependant-Thoroughfare" v-model="customProperty.DEPENDENT_THOROUGHFARE" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Throughfare" label-for="Throughfare">
        <b-form-input id="Throughfare" v-model="customProperty.THOROUGHFARE" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Double Dependant Locality" label-for="Double-Dependant-Locality">
        <b-form-input id="Double-Dependant-Locality" v-model="customProperty.DOUBLE_DEPENDENT_LOCALITY" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Locality" label-for="Locality">
        <b-form-input id="Locality" v-model="customProperty.DEPENDENT_LOCALITY" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Post Town" label-for="Post-Town">
        <b-form-input id="Post-Town" v-model="customProperty.POST_TOWN" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Postcode" label-for="Postcode">
        <b-form-input id="Postcode" v-model="customProperty.POSTCODE" type="text"
                      required></b-form-input>
      </b-form-group>
    </b-modal>
    <CustomPropertiesFlagSelector v-if="loggedIn" v-model="flagSelectorOpened"
                                  :uprnInfo="uprnInfo"></CustomPropertiesFlagSelector>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from 'axios';
import L from "leaflet";
import Helpers from "@/Mixins/Helpers";
import CustomPropertiesFlagSelector from "@/components/modals/Properties/CustomPropertiesFlagSelector";
import CommonTools from "@/Helpers/CommonTools";
import FlagSelector from "@/components/modals/Properties/FlagSelector.vue";
import $Utils from "@/assets/js/libs/Utils";
import AdditionalInfo from "@/components/partials/AdditionalInfo.vue";
import uprnInfo from "../mapcomponents/UprnInfo.vue";

export default {
  name: "CustomUprnInfo",
  components: {AdditionalInfo, FlagSelector, CustomPropertiesFlagSelector},
  mixins: [Helpers],
  data() {
    return {
      additionalInfoLoaded: false,
      showButtonLoading: false,
      uprnAdditionalInfo: {},
      flagSelectorOpened: false,
      customProperty: {
        ORGANISATION_NAME: '',
        BUILDING_NAME: '',
        BUILDING_NUMBER: '',
        DEPENDENT_THOROUGHFARE: '',
        THOROUGHFARE: '',
        DOUBLE_DEPENDENT_LOCALITY: '',
        DEPENDENT_LOCALITY: '',
        POST_TOWN: '',
        project_id: null,
        lat: null,
        lng: null,
        POSTCODE: null,
        UPRN: null
      },
    }
  },
  watch: {
    uprnInfo() {
      this.additionalInfoLoaded = false;
    }
  },
  computed: {
    uprnInfo() {
      return uprnInfo
    },
    $Utils() {
      return $Utils
    },
    ...mapGetters({
      'uprnInfoAvailable': 'Search/getCustomUprnInfoAvailable',
      'uprnInfo': 'Search/getCustomUprnInfo',
      'uprnInfoLoading': 'Search/getCustomUprnInfoLoading',
      'menuIsOpened': 'LayersList/getMenuOpened',
      'loggedIn': 'Auth/isLoggedIn',
      selectedProject: 'Projects/selectedProject',
    }),
    getKMLDownloadAddress: {
      get() {
        return process.env.VUE_APP_API_URL + "/api/kml/" + this.uprnInfo.uprn;
      }
    }

  },
  methods: {
    getFlag(flag) {
      return process.env.VUE_APP_REMOTE_HOST + "/assets/img/icons/flags/flag_" + flag + ".png";
    },
    loadAdditionalLocation(uprn) {
      this.showButtonLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/api/customuprnadditional/' + uprn).then(response => {
        this.uprnAdditionalInfo = response.data.data;
        this.showButtonLoading = false;
        this.additionalInfoLoaded = true;
      });
    },
    closeUprnInfo() {
      // console.log(uprn)
      this.uprnAdditionalInfo = {};
      this.additionalInfoLoaded = false;
      this.$store.commit('Search/setCustomUprnInfoAvailable', false)
      L.Marker.stopAllBouncingMarkers();

    },
    removeFlagForProperty(uprnInfo) {
      const self = this
      axios.delete(process.env.VUE_APP_API_URL + '/api/project/' + self.selectedProject.id + '/customuprn/' + uprnInfo.uprn + '/flag').then(() => {
        uprnInfo.isFlagged = 0;
        uprnInfo.flag = '';
        self.$store.commit("Properties/removeFlag", uprnInfo.uprn)
        self.$store.commit("Search/removeFlagFromCustomMarker", uprnInfo)
        self.$root.$emit('removeMarkerIcon', uprnInfo.key)
        self.$root.$emit('removeCustomMarkerIcon', uprnInfo.key)
      });
    },
    numberFormat(number) {
      let num = parseFloat(number);
      return num.toLocaleString('en-US', {maximumFractionDigits: 2})
    },
    removeProperty() {
      const self = this;
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to delete this property?').then(() => {
        axios.delete(process.env.VUE_APP_API_URL + '/api/project/' + self.selectedProject.id + '/customproperty/' + self.uprnInfo.id).then(() => {
          self.$store.commit('Search/setCustomUprnInfoAvailable', false)
          self.$store.dispatch("Search/searchCustomPropertiesInDistance", {
            center: {
              lat: self.uprnInfo.location.lat,
              lng: self.uprnInfo.location.lng
            }
          }, {root: true});
        });
      });
    },
    editCustomProperty() {
      const self = this;
      axios.put(process.env.VUE_APP_API_URL + '/api/project/' + this.selectedProject.id + '/customproperty/' + this.uprnInfo.id, this.customProperty).then(() => {
        this.$store.dispatch('Search/loadCustomUprnInfo', {uprn: self.customProperty.UPRN});

      });
    },
    beforeEditPropertyModalShow() {
      this.customProperty.POSTCODE = this.uprnInfo.postcode;
      this.customProperty.ORGANISATION_NAME = this.uprnInfo.organisationname;
      this.customProperty.BUILDING_NAME = this.uprnInfo.buildingname;
      this.customProperty.BUILDING_NUMBER = this.uprnInfo.buildingnumber;
      this.customProperty.DEPENDENT_THOROUGHFARE = this.uprnInfo.dependentthoroughfare;
      this.customProperty.THOROUGHFARE = this.uprnInfo.thoroughfare;
      this.customProperty.DEPENDENT_LOCALITY = this.uprnInfo.locality;
      this.customProperty.DOUBLE_DEPENDENT_LOCALITY = this.uprnInfo.double_depend_locality;
      this.customProperty.POST_TOWN = this.uprnInfo.towncity;
      this.customProperty.UPRN = this.uprnInfo.uprn;
      this.customProperty.project_id = this.selectedProject.id;
      this.customProperty.lat = this.uprnInfo.location.lat;
      this.customProperty.lng = this.uprnInfo.location.lng;
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()

      this.$refs.draggableContainerCustomUprn.style.top = (event.clientY - 10) + 'px'
      this.$refs.draggableContainerCustomUprn.style.left = (event.clientX - 60) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    openKML(uprnInfo) {
      window.open(this.getKMLDownloadAddress, '_blank');
    },
    copy(value) {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal('Copied', 'Copied successfully', 'success');

    },
    getGridReference(uprnInfo) {
      return uprnInfo.coordinates.reference_letter + uprnInfo.coordinates.easting + uprnInfo.coordinates.northing;
    },
    getW3w(uprnInfo) {
      return this.$WhatTreeWords.latlon2words(parseFloat(uprnInfo.location.lat),
          parseFloat(uprnInfo.location.lng))
    },
    getPropertyUrl(uprnInfo) {
      return process.env.VUE_APP_BASE_URL + '/property_uprn/' + uprnInfo.uprn + '/' + uprnInfo.location.lat + '/' + uprnInfo.location.lng
    },
    openAppleMap(uprnInfo) {
      window.open("https://maps.apple.com/?ll=" + uprnInfo.location.lat + "," + uprnInfo.location.lng + "&z=19&t=k&q=" + uprnInfo.display_text_normal, "_blank");
    },
    openGoogleMap(uprnInfo) {
      window.open('comgooglemapsurl://?q=' + uprnInfo.location.lat + ',' + uprnInfo.location.lng + '&zoom=19&t=k&', "_blank");
    },
  }
}
</script>

<style scoped>
.uprnInfo {
  border-radius: 10px;
  padding: 5px;
  margin-top: 5px;
  position: absolute;
  z-index: 998;
  float: right;
  width: 265px;
  background-color: white;
  margin-left: 50px;
  max-height: -webkit-calc(100% - 90px);
  max-height: -moz-calc(100% - 90px);
  max-height: calc(100% - 90px);
  overflow: auto;
  text-align: center;
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  top: 90px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
}

.right {
  right: 10px;
}

.blackwhite {
  filter: grayscale(100%);
}

img {
  outline: none;
}

</style>