<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AdditionalInfo",
  props: {
    uprnAdditionalInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    numberFormat(number) {
      let num = parseFloat(number);
      return num.toLocaleString('en-US', {maximumFractionDigits: 2})
    },
  }
})
</script>

<template>
  <div>

    <div v-if="uprnAdditionalInfo.tags !== undefined && uprnAdditionalInfo.tags.length > 0"
         class='result_item'><b>Tags</b></div>
    <div v-for="(tag,index) in uprnAdditionalInfo.tags" :key="index">
      <div class='badge badge-info ml-1'>{{
          tag
        }}
      </div>
    </div>

    <hr class="border-bottom"/>
    <div class='result_item'><b>Flooding </b></div>

    <table class="table table-bordered table-sm">
      <tbody>
      <tr>
        <td>Previously Flooded</td>
        <td v-if="uprnAdditionalInfo.flooded===0">No</td>
        <td v-else>Yes</td>
      </tr>
      <tr>
        <td>Flood Defence Protected</td>
        <td v-if="uprnAdditionalInfo.defence===0">No</td>
        <td v-else>Yes</td>
      </tr>
      <tr>
        <td>Zone</td>
        <td>{{ uprnAdditionalInfo.zone }}</td>
      </tr>
      </tbody>
    </table>
    <hr class="border-bottom"/>


    <div v-if="uprnAdditionalInfo.inspireId" class='result_item'>
      <b>Land Registry Title Inspire ID:</b>
      <b v-for="inspireId in uprnAdditionalInfo.inspireId">{{ inspireId }} <br/></b></div>
    <br/>
    <div v-if="uprnAdditionalInfo.buildings !== undefined && uprnAdditionalInfo.buildings.length > 0"
         class='result_item'><b>Listed Buildings</b></div>
    <div v-for="(building,index) in uprnAdditionalInfo.buildings" :key="index" class='result_item'><a
        :href='"https://historicengland.org.uk/listing/the-list/list-entry/"+building.listentry' target='_blank'>{{
        building.name
      }}</a>
    </div>
    <b v-if="uprnAdditionalInfo.companies!==undefined && uprnAdditionalInfo.companies.length>0">Companies
      : </b>
    <br/>
    <div v-for="company in uprnAdditionalInfo.companies" :key="company.CompanyNumber" class='result_item'>
      <a
          :href='"https://find-and-update.company-information.service.gov.uk/company/"+company.CompanyNumber'
          target='_blank'>{{
          company.CompanyName
        }}</a>
    </div>
    <b v-if="uprnAdditionalInfo.pricedata!==undefined && uprnAdditionalInfo.pricedata.length>0">Prices
      : </b>
    <br/>
    <div v-for="price in uprnAdditionalInfo.pricedata" :key="price._id" class='result_item'>
      {{ price.DateOfTransfer }}: £{{ numberFormat(price.Price) }}<br/>
    </div>
  </div>

</template>

<style scoped>

</style>