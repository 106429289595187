<script>

import {mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {email, minLength, required} from "@vuelidate/validators";

export default {
  name: "FormsUpdateProfile",
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        first_name: {required, minLength: minLength(3)},
        last_name: {required, minLength: minLength(3)},
        email: {required, email},
        password_confirmation: {},

      }
    }
  },
  props: {
    accountData: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Boolean,
      required: false,
    },
    showAccountLimit: {
      type: Boolean,
      required: false
    },
    showAllowEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCompany: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      form: this.accountData
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
    }),
    buttonText() {
      return this.isCreate ? 'Create' : 'Update';
    }
  },
  methods: {
    async emitUpdate() {

      const result = await this.v$.$validate()
      if (result) {
        if (this.isCreate) {
          if (null === this.form.password) {
            this.$swal("Password", "Password is required while creating account", "warning");
            return;
          }
        }

        if (this.form.password && this.form.password.length === 0) {
          this.form.password = null;
        }
        if (this.form.password_confirmation && this.form.password_confirmation === 0) {
          this.form.password_confirmation = null;
        }
        if (null !== this.form.password && this.form.password !== this.form.password_confirmation) {
          console.log(this.form);
          this.$swal("Password", "New Password does not match repeated", "warning");
          return;
        }
        console.log(this.form);
        // delete this.form.password_confirmation
        delete this.form.parent_user_id;
        delete this.form.sub_account_count;
        delete this.form.full_name;
        delete this.form.disabled;
        delete this.form.disabled_at;
        delete this.form.email_verified_at;
        delete this.form.updated_at;
        delete this.form.is_admin;
        delete this.form.is_master;
        if (!this.isAdmin || !this.form.sub_account_limit) {
          delete this.form.sub_account_limit;
        }
        if (!this.showCompany) {
          delete this.form.company;
        }
        this.$emit('ok', this.form);
      }
    }
  },
  mounted() {
    // this.form.first_name = null;
    // this.form.last_name = null;
    // this.form.email = null;
    // this.form.phone = null;
    // this.form.password = null;
    // this.form.password_confirmation = null;
    console.log('resseting form');
  }
}
</script>

<template>
  <b-form autocomplete="off">
    <div class="d-flex flex-row justify-content-between">
      <b-form-group class="flex-fill mr-2" id="input-group-eUserFirstName" label="First Name:"
                    label-for="eUserFirstName">
        <b-form-input
            id="eUserFirstName"
            v-model="form.first_name"
            placeholder="Enter first name"
            :class="{'is-invalid': v$.form.first_name.$error}"
            required
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.form.first_name.$error">
          <p v-for="message in v$.form.first_name.$errors">{{ message.$message }}</p>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="flex-fill ml-2" id="input-group-eUserLastName" label="Last Name:" label-for="eUserLastName">
        <b-form-input
            id="eUserLastName"
            name="eUserLastName"
            v-model="form.last_name"
            placeholder="Enter last name"
            :class="{'is-invalid': v$.form.last_name.$error}"

            required
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.form.last_name.$error">
          <p v-for="message in v$.form.last_name.$errors">{{ message.$message }}</p>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div class="d-flex flex-row justify-content-between">

      <b-form-group class="flex-fill mr-2"
                    id="input-group-eEmail"
                    label="Email address:"
                    label-for="eUserEmail"
      >
        <b-form-input
            id="eUserEmail"
            v-model="form.email"
            type="email"
            name="email"
            placeholder="Enter email"
            :class="{'is-invalid': v$.form.email.$error}"

            required
        ></b-form-input>
        <b-form-invalid-feedback v-if="v$.form.email.$error">
          <p v-for="message in v$.form.email.$errors">{{ message.$message }}</p>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="flex-fill ml-2" id="input-group-eUserAccountPhone" label="Account Phone:"
                    label-for="eUserAccountPhone">
        <b-form-input
            id="eUserAccountPhone"
            name="eUserAccountPhone"
            v-model="form.phone"
            placeholder="Enter Account Phone"
            autocomplete="off"
            required
        ></b-form-input>
      </b-form-group>
    </div>
    <b-form-group v-if="showCompany" id="input-group-eUserCompany"
                  label="Company:"
                  label-for="eUserCompany">
      <b-form-input
          id="eUserCompany"
          v-model="form.company"
          placeholder="Enter Account Company"
          autocomplete="off"
          required
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="showAccountLimit" id="input-group-eUserAccountLimit"
                  label="Account Limit:"
                  label-for="eUserAccountLimit">
      <b-form-input
          id="eUserAccountLimit"
          name="eUserAccountLimit"
          v-model="form.sub_account_limit"
          placeholder="Enter Account Limit"
          autocomplete="false"
          required
      ></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-eUserAccountAllowEdit"
                  label-for="eUserAccountAllowEdit" v-if="showAllowEdit">
      <b-form-checkbox
          id="checkbox-1"
          v-model="form.allow_edit"
          name="checkbox-1"
          :value="true"
          :switch="true"
          :unchecked-value="false"
      >
        Allow type create/delete/edit
      </b-form-checkbox>
    </b-form-group>

    <hr/>
    <div class="d-flex justify-content-between">

      <b-form-group class="flex-fill mr-2" id="input-group-eUserPassword" label="Account Password:"
                    label-for="eUserAccountPassword">
        <b-form-input
            id="eUserAccountPassword"
            name="eUserAccountPassword"
            v-model="form.password"
            autocomplete="new-password"
            type="password"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group class="flex-fill ml-2" id="input-group-eUserAccountPasswordConfirmation"
                    label="Repeat Account password:"
                    label-for="eUserAccountPasswordConfirmation">
        <b-form-input
            id="eUserAccountPasswordConfirmation"
            name="eUserAccountPasswordConfirmation"
            v-model="form.password_confirmation"
            autocomplete="new-password"
            type="password"
            required
        ></b-form-input>
      </b-form-group>

    </div>
    <b-form-group>

      <b-button @click="emitUpdate" variant="success">{{ buttonText }}</b-button>
    </b-form-group>

  </b-form>
</template>

<style scoped>

</style>