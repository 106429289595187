<script>

import XLSX from "xlsx";
import draggable from "vuedraggable";
import CommonTools from "@/Helpers/CommonTools";

export default {
  name: "NewAddressMatching",
  components: {draggable},
  data() {
    return {
      step: 1,
      matchType: 'uprn',
      matchingFile: null,
      fileColumns: [],
      hFooter: true,
      isHeader: 1,
      hasError: true,
      inputs: {
        uprn: {
          name: "UPRN",
          columns: [],
          selectedColumns: [],
          hasError: true,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        property: {
          name: "Property Name / Number / Street",
          columns: [],
          selectedColumns: [],
          hasError: true,
          minItems: 1,
          maxItems: this.fileColumns,
          selected: null,
        },
        company: {
          name: "Company Name",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        postcode: {
          name: "Postcode",
          columns: [],
          selectedColumns: [],
          hasError: true,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        latitude: {
          name: "Latitude",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        longitude: {
          name: "Longitude",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 1,
          maxItems: 1,
          selected: null,
        }
      },
      exportFormTabIndex: 0,
      columns: {
        accepted: [
          {name: "UPRN", id: 1, "type": "native"},
          {name: "Address", id: 2, "type": "native"},
          {name: "Coordinates", id: 3, "type": "native"},
          {name: "Description", id: 4, "type": "native"}
        ],
        available: [
          {name: "Address - Condensed", id: 1},
          {name: "Boundaries", id: 2},
          {name: "Council Tax Band", id: 3},
          {name: "Business Rates", id: 4},
          {name: "Fixed Line Providers", id: 5},
          {name: "Mobile Providers", id: 6},
        ]
      },
    }
  },
  computed: {
    modalTitle() {
      return 'Matching Type';
    },
    modalSize() {
      return 'md';
    },
    availableColumns() {
      let all = [].concat(this.inputs.property.selectedColumns)
      all = all.concat(this.inputs.company.selectedColumns)
      all = all.concat(this.inputs.postcode.selectedColumns)
      all = all.concat(this.inputs.uprn.selectedColumns)
      all = all.concat(this.inputs.latitude.selectedColumns)
      all = all.concat(this.inputs.longitude.selectedColumns)
      return this.fileColumns.filter(x => !all.includes(x));
    },
    selectedUprnColumn() {
      return this.inputs.uprn.selected;
    },
    uprnColumns() {
      return this.inputs.uprn.selectedColumns
    },
    propertyColumns() {
      return this.inputs.property.selectedColumns
    },
    companyColumns() {
      return this.inputs.company.selectedColumns
    },
    postcodeColumns() {
      return this.inputs.postcode.selectedColumns
    },
    latitudeColumns() {
      return this.inputs.latitude.selectedColumns
    },
    longitudeColumns() {
      return this.inputs.longitude.selectedColumns
    },

    selectedPropertyColumn() {
      return this.inputs.property.selected;
    },
    selectedCompanyColumn() {
      return this.inputs.company.selected;
    },
    selectedPostcodeColumn() {
      return this.inputs.postcode.selected;
    },
    selectedLatitudeColumn() {
      return this.inputs.latitude.selected;
    },
    selectedLongitudeColumn() {
      return this.inputs.longitude.selected;
    }
  },
  watch: {
    inputs: {
      handler(newValue, oldValue) {
        let inputError = true;
        if (this.matchType === 'uprn') {
          inputError = newValue.uprn.hasError
        }
        if (this.matchType === 'latlng') {
          inputError = newValue.latitude.hasError
              || newValue.longitude.hasError;
        }
        if (this.matchType === 'address') {
          inputError = newValue.company.hasError
              || newValue.postcode.hasError
              || newValue.property.hasError;
        }
        this.hasError = inputError || this.step < 3 || this.matchingFile === null;
      },
      deep: true,
    },
    matchingFile(value) {

      if (value) {
        const self = this;
        for (let i = 0; i < self.fileColumns.length; i++) {
          if (CommonTools.containsSpecialCharacters(self.fileColumns[i])) {
            self.$swal("Error", "Column names should not contain special characters", "error");
            return
          }
        }
        const reader = new FileReader();
        if ('text/csv' === value.type) {

          reader.onload = function (e) {
            self.fileColumns = e.target.result.split("\n")[0].split(",");
            for (let i = 0; i < self.fileColumns.length; i++) {
              self.columns.accepted.push({
                name: CommonTools.trimSpecialCharacters(self.fileColumns[i]),
                id: i + 10,
                "type": "file"
              })
            }
            self.hFooter = false;
          };
          reader.readAsText(value)
          this.step = 3;

        } else {

          reader.onload = function (e) {
            /* read workbook */
            const workbook = XLSX.read(e.target.result, {type: 'binary'});
            const ws = workbook.Sheets[workbook.SheetNames[0]];
            const file_csv = XLSX.utils.sheet_to_csv(ws)
            const file = new File([file_csv], value.name + '.csv', {
              type: 'text/csv'
            })
            self.matchingFile = file;
          };

          reader.readAsBinaryString(value);
          this.step = 3;
        }

      }
    },
    selectedUprnColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.uprn.selectedColumns.push(value);
      this.inputs.uprn.selected = null;
    },
    uprnColumns(value) {
      this.inputs.uprn.columns = this.fileColumns.filter(x => !value.includes(x));
      if (value.length < this.inputs.uprn.minItems || value.length > this.inputs.uprn.maxItems) {
        this.inputs.uprn.hasError = true;
        this.inputs.company.minItems = 1;
      } else {
        this.inputs.uprn.hasError = false;
        this.inputs.company.hasError = false;
        this.inputs.postcode.hasError = false;
        this.inputs.property.hasError = false;
        this.inputs.latitude.hasError = false;
        this.inputs.longitude.hasError = false;
        this.inputs.company.minItems = 0;
        this.inputs.postcode.minItems = 0;
        this.inputs.property.minItems = 0;
        this.inputs.latitude.minItems = 0;
        this.inputs.longitude.minItems = 0;
      }
    },
    propertyColumns(value) {
      this.inputs.company.columns = this.fileColumns.filter(x => !value.includes(x));
      if (value.length < this.inputs.property.minItems || value.length > this.inputs.property.maxItems) {
        this.inputs.property.hasError = true;
        this.inputs.company.minItems = 1;
      } else {
        this.inputs.property.hasError = false;
        this.inputs.company.hasError = false;
        this.inputs.company.minItems = 0;
        this.inputs.uprn.minItems = 0;
        this.inputs.uprn.hasError = false;
      }
    },
    companyColumns(value) {
      if (this.fileColumns.length !== this.inputs.property.columns.length) {
        const cols = this.propertyColumns.concat(this.companyColumns);
        this.inputs.postcode.columns = this.fileColumns.filter(x => !cols.includes(x));
      }
      if (value.length < this.inputs.company.minItems || value.length > this.inputs.company.maxItems) {
        this.inputs.company.hasError = true;
        this.inputs.property.minItems = 1;
      } else {
        this.inputs.company.hasError = false;
        this.inputs.property.hasError = false;
        this.inputs.property.minItems = 0;
      }
    },
    postcodeColumns(value) {
      this.inputs.postcode.hasError = value.length < this.inputs.postcode.minItems || value.length > this.inputs.postcode.maxItems;
    },
    latitudeColumns(value) {
      if (value.length < this.inputs.latitude.minItems || value.length > this.inputs.latitude.maxItems) {
        this.inputs.latitude.hasError = true;
        return;
      }
      this.inputs.latitude.hasError = false;

      if (this.inputs.longitude.selectedColumns.length === 0) {
        this.inputs.longitude.hasError = true
      }
    },
    longitudeColumns(value) {
      if (value.length < this.inputs.longitude.minItems || value.length > this.inputs.longitude.maxItems) {
        this.inputs.longitude.hasError = true;
        return;
      }
      this.inputs.longitude.hasError = false;

      if (this.inputs.latitude.selectedColumns.length === 0) {
        this.inputs.latitude.hasError = true
      } else {
        this.inputs.property.hasError = false;
        this.inputs.company.hasError = false;
        this.inputs.postcode.hasError = false;
        this.inputs.postcode.minItems = 0;
        this.inputs.company.minItems = 0;
        this.inputs.uprn.minItems = 0;
        this.inputs.uprn.hasError = false;
      }
    },
    selectedPropertyColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.property.selectedColumns.push(value);
      this.inputs.property.selected = null;
    },
    selectedCompanyColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.company.selectedColumns.push(value);
      this.inputs.company.selected = null;
    },
    selectedPostcodeColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.postcode.selectedColumns.push(value);
      this.inputs.postcode.selected = null;
    },
    selectedLatitudeColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.latitude.selectedColumns.push(value);
      this.inputs.latitude.selected = null;

    },
    selectedLongitudeColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.longitude.selectedColumns.push(value);
      this.inputs.longitude.selected = null;
    }
  },
  methods: {
    checkMove: function (evt) {
      return (evt.draggedContext.element.name !== 'UPRN' && evt.draggedContext.type !== 'native');
    },
    tagValidator(tag) {
      return this.fileColumns.findIndex(key => key === tag) !== -1;
    },
    clearModalData() {
      this.step = 1;
      this.matchType = 'uprn';
      this.matchingFile = null;
      this.fileColumns = [];
      this.isHeader = 1;
      this.matchingFile = null;
      this.inputs.property.selectedColumns = [];
      this.inputs.company.selectedColumns = [];
      this.inputs.postcode.selectedColumns = [];
      this.inputs.uprn.selectedColumns = [];
      this.inputs.latitude.selectedColumns = [];
      this.inputs.longitude.selectedColumns = [];
      this.inputs.uprn.hasError = true;
      this.columns = {
        accepted: [
          {name: "UPRN", id: 1, "type": "native"},
          {name: "Address", id: 2, "type": "native"},
          {name: "Coordinates", id: 3, "type": "native"},
          {name: "Description", id: 4, "type": "native"}
        ],
        available: [
          {name: "Address - Condensed", id: 5},
          {name: "Boundaries", id: 6},
          {name: "Council Tax Band", id: 7},
          {name: "Business Rates", id: 8},
          {name: "Fixed Line Providers", id: 9},
          {name: "Mobile Providers", id: 10},
        ]
      };
    },
    uploadMatchingFile(e) {
      e.preventDefault()
      if (this.hasError) {
        this.$swal("Property Name / Number / Street and Postcode must have at least one field mapped to them.");
        if (this.inputs.property.selectedColumns.length === 0) {
          this.inputs.property.hasError = 1;
        }
        if (this.inputs.postcode.selectedColumns.length === 0) {
          this.inputs.postcode.hasError = 1;
        }
        return;
      }
      const self = this;
      this.showOverlay = true;
      let formData = new FormData();
      formData.append('csv', this.matchingFile);
      formData.append('is_header', this.isHeader);
      formData.append('uprn_columns', this.inputs.uprn.selectedColumns);
      formData.append('property_columns', this.inputs.property.selectedColumns);
      formData.append('company_columns', this.inputs.company.selectedColumns);
      formData.append('postcode_columns', this.inputs.postcode.selectedColumns);
      formData.append('latitude_columns', this.inputs.latitude.selectedColumns);
      formData.append('longitude_columns', this.inputs.longitude.selectedColumns);
      formData.append('columns', JSON.stringify(this.columns));
      formData.append('match_type', this.matchType);

      axios.post(process.env.VUE_APP_API_URL + "/api/addressmatch", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        self.$swal("Uploaded", "You will get email notification when match finished", "success").then(() => {
          self.showOverlay = false;

        });
      }).catch(() => {
        self.showOverlay = false;
      }).finally(() => {
        self.$bvModal.hide('new_address_matching_modal');
        self.$root.$emit('address-matching-updated');
      })
    },

  }
}
</script>

<template>
  <div>
    <b-modal id="new_address_matching_modal" :size="modalSize" centered :title="modalTitle" ok-title="submit"
             :ok-disabled="hasError" @show="clearModalData" @ok="uploadMatchingFile">
      <b-tabs v-model="exportFormTabIndex">
        <b-tab title="Criteria" active>
          <div class="py-2">
            <div v-if="step===1">
              <div class="d-flex flex-column justify-content-between px-5">
                <b-button size="sm" variant="info"
                          @click="matchType='uprn';step=2">
                  Using UPRN
                </b-button>
                <b-button class="mt-2" size="sm" variant="info" @click="matchType='latlng';step=2">
                  Using Latitude and Longitude
                </b-button>
                <b-button class="mt-2" size="sm" variant="info" @click="matchType='address';step=2">
                  Using Address
                </b-button>
              </div>
            </div>
            <div v-if="step===2">
              <b-form-group id="address_matching-group" label="File for matching"
                            label-for="address_matching-group">
                <b-form-file v-model="matchingFile" accept="text/csv, .xls, .xlsx" :state="Boolean(matchingFile)"
                             placeholder="Choose a file or drop it here..."
                             drop-placeholder="Drop file here..."></b-form-file>

              </b-form-group>
            </div>
            <div v-if="step===3">
              <b-form-group v-if="fileColumns.length > 0">
                <b-form-checkbox id="checkbox-1" v-model="isHeader" name="checkbox-1" value="1"
                                 unchecked-value="0"
                                 switch>
                  First line contains column headers
                </b-form-checkbox>
              </b-form-group>
              <b-form-group v-if="matchType==='uprn' && fileColumns.length > 0" description="UPRN">
                <div class="row col-12">
                  <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.uprn.hasError }" input-id="tags-pills"
                               tag-variant="primary" placeholder="" tag-pills size="lg"
                               v-model="inputs.uprn.selectedColumns"
                               :tag-validator="tagValidator"></b-form-tags>
                  <div class="col-6">
                    <select class="form-control" v-model="inputs.uprn.selected">
                      <option v-for="column in availableColumns" :value="column">{{ column }}</option>
                    </select>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="matchType==='address' && fileColumns.length > 0" description="Company">
                <div class="row col-12">
                  <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.company.hasError }" input-id="tags-pills"
                               tag-variant="primary" placeholder="" tag-pills size="lg"
                               v-model="inputs.company.selectedColumns"
                               :tag-validator="tagValidator"></b-form-tags>
                  <div class="col-6">
                    <select class="form-control" v-model="inputs.company.selected">
                      <option v-for="column in availableColumns" :value="column">{{ column }}</option>
                    </select>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="matchType==='address' && fileColumns.length > 0"
                            description="Property Name / Number / Street *">
                <div class="row col-12">
                  <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.property.hasError }" input-id="tags-pills"
                               tag-variant="primary" placeholder="" tag-pills size="lg"
                               v-model="inputs.property.selectedColumns"
                               :tag-validator="tagValidator"></b-form-tags>
                  <div class="col-6">
                    <select class="form-control" v-model="inputs.property.selected">
                      <option v-for="column in availableColumns" :value="column">{{ column }}</option>
                    </select>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="matchType==='address' && fileColumns.length > 0" description="Postcode *">
                <div class="row col-12">
                  <b-form-tags class="col-6" input-id="tags-pills" tag-variant="primary"
                               :class="{ 'is-invalid': inputs.postcode.hasError }" placeholder="" tag-pills size="lg"
                               v-model="inputs.postcode.selectedColumns" :tag-validator="tagValidator"></b-form-tags>
                  <div class="col-6">
                    <select class="form-control" v-model="inputs.postcode.selected">
                      <option v-for="column in availableColumns" :value="column">{{ column }}</option>
                    </select>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="matchType==='latlng' && fileColumns.length > 0" description="Latitude">
                <div class="row col-12">
                  <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.latitude.hasError }" input-id="tags-pills"
                               tag-variant="primary" placeholder="" tag-pills size="lg"
                               v-model="inputs.latitude.selectedColumns"
                               :tag-validator="tagValidator"></b-form-tags>
                  <div class="col-6">
                    <select class="form-control" v-model="inputs.latitude.selected">
                      <option v-for="column in availableColumns" :value="column">{{ column }}</option>
                    </select>
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="matchType==='latlng' && fileColumns.length > 0" description="Longitude">
                <div class="row col-12">
                  <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.longitude.hasError }" input-id="tags-pills"
                               tag-variant="primary" placeholder="" tag-pills size="lg"
                               v-model="inputs.longitude.selectedColumns"
                               :tag-validator="tagValidator"></b-form-tags>
                  <div class="col-6">
                    <select class="form-control" v-model="inputs.longitude.selected">
                      <option v-for="column in availableColumns" :value="column">{{ column }}</option>
                    </select>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
        </b-tab>
        <b-tab title="Output" :disabled="this.hasError !== false || step < 3">
          <div class="col-12 pt-2">
            <h5>Included Fields</h5>
            <draggable class="list-group" :list="columns.accepted" group="fields" :move="checkMove">
              <div :class="[element.type==='file' ? 'bg-info':'','list-group-item', 'cursor-grab', 'p-2']"
                   v-for="(element, index) in columns.accepted"
                   :key="element.name+element.id">
                <small v-if="element.type === 'file'">{{ element.name }} (From File)</small>
                <small v-else>{{ element.name }}</small>
              </div>
            </draggable>
          </div>
          <hr/>
          <div class="col-12 pt-2">
            <h5>Excluded Fields</h5>
            <draggable class="list-group" :list="columns.available" group="fields">
              <div :class="[element.type==='file' ? 'bg-info':'','list-group-item', 'cursor-grab', 'p-2']"
                   v-for="(element, index) in columns.available"
                   :key="element.name+element.id">
                <small v-if="element.type === 'file'">{{ element.name }} (From File)</small>
                <small v-else>{{ element.name }}</small>
              </div>
            </draggable>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<style scoped>

</style>