import StaticPopup from "@/js/Popups/StaticPopup";

class PostcodePopup extends StaticPopup {
    constructor(location, data, showGeoJson, VueStore) {
        super(location, data, showGeoJson, VueStore)
    }

    getContent() {
        let html = '<div style="cursor: pointer;text-align: center;">';
        html += this.data.features[0].properties.postcode;
        if (this.data.features[0].properties.ru11ind !== null) {
            html += '<p>Rural Classification: ' + this.data.features[0].properties.ru11ind + '</p>';
        }
        html += '</div>';
        return html;
    }

    click(store, data) {
        store.dispatch('Search/loadByPostcode', {postcode: data.features[0].properties.postcode, all_properties: true})
    }
}

export default PostcodePopup;