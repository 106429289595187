import Vue from 'vue'
import axios from 'axios';

const state = {
    center: [53.4291738804146, -1.3732910156250002],
    zoom: {
        current: 7,
        min: 1,
        max: 21,
        propertyView: 17,
        ductsMinLevel: 15,
        cityZoom: 13,
    },
    bounds: null,
    distance: 0,
    showVectorLayer: false,
    topLayer: '',
    projectInfoShown: false,
    propertiesInfoShown: false,
    imageGroupsShown: false,
    userLocationsShown: false,
    saveLocationShown: false,
    tilePlanPerimeterShow: false,
};
const getters = {
    projectInfoShown: state => {
        return state.projectInfoShown;
    },
    propertiesInfoShown: state => {
        return state.propertiesInfoShown;
    },
    saveLocationShown: state => {
        return state.saveLocationShown;
    },
    imageGroupsShown: state => {
        return state.imageGroupsShown;
    },
    userLocationsShown: state => {
        return state.userLocationsShown;
    },
    currentZoom: state => {
        const zoom = localStorage.getItem('state.zoom');
        if (zoom) {
            state.zoom.current = zoom
        }
        return parseFloat(state.zoom.current)
    },
    getMinZoom: state => {
        return state.zoom.min;
    },
    getMaxZoom: state => {
        return state.zoom.max;
    },
    getDistance: state => {
        return state.distance;
    },
    getCenter: state => {
        const lat = localStorage.getItem('state.center.lat');
        const lng = localStorage.getItem('state.center.lng');
        if (lat !== 'undefined' && lat !== undefined && lng !== undefined) {
            state.center = [lat, lng];
        }
        return state.center;
    },
    showVectorLayer: state => {
        return state.showVectorLayer
    },
    topLayer: state => {
        return state.topLayer;
    },
    getBounds: state => {
        return state.bounds
    },
    checkboxes: state => {
        return state.checkboxes
    },
    tilePlanPerimeterShow: state => {
        return state.tilePlanPerimeterShow
    }


};
const actions = {
    setActiveMap({state}, map) {
        if (localStorage.getItem('token')) {
            axios.post(process.env.VUE_APP_API_URL + '/api/user/state', {topLayer: map.code});
        }
        localStorage.setItem('state.topLayer', map.code);
        state.topLayer = map.code;
    },
    saveState({state}, data) {
        if (localStorage.getItem('token')) {
            axios.post(process.env.VUE_APP_API_URL + '/api/user/state', data);
        }
    },
    setStateFromDB({state, commit, dispatch}, data) {

        // data.center = JSON.parse(data.center);
        if (data.center && data.center.lat && data.center.lng) {
            commit("setCenterFromDB", {lat: data.center.lat, lng: data.center.lng});
        }
        if (data.topLayer) {
            if (data.wms_layer_name && data.wms_layer_name.length > 0) {
                commit('setShowVectorLayer', true)
            }
            localStorage.setItem('state.topLayer', data.topLayer)
        }
        if (data.zoom) {
            commit('setZoomFromDB', parseInt(data.zoom))
        }
        Vue.nextTick(function () {
            window.location.reload();
        })
    },
    saveSubLayerState({state, commit}, data) {
        if (localStorage.getItem('token')) {
            axios.post(process.env.VUE_APP_API_URL + '/api/user/state/sublayer', data).then(d => {
                commit('setCheckBoxes', d.data.data)
            });
        }
    }


};
const mutations = {
    setZoomMinMax(state, data) {
        Vue.set(state.zoom, 'min', parseInt(data.min));
        Vue.set(state.zoom, 'max', parseInt(data.max));
    },
    setState(state, data) {
        if (data !== undefined) {
            if (data.center.lat !== 0 && data.center.lng !== 0) {
                state.center = [data.center.lat, data.center.lng];
                localStorage.setItem('state.center.lat', data.center.lat);
                localStorage.setItem('state.center.lng', data.center.lng);
                if (localStorage.getItem('token')) {
                    axios.post(process.env.VUE_APP_API_URL + '/api/user/state', {
                        center: {
                            lat: data.center.lat,
                            lng: data.center.lng
                        }
                    });
                }
            }
            Vue.set(state.zoom, 'current', data.zoom);
            Vue.set(state, 'checkboxes', data.checkboxes);
        }
    },
    setCenter(state, data) {
        if (data.lat !== 0 && data.lng !== 0) {
            localStorage.setItem('state.center.lat', data.lat);
            localStorage.setItem('state.center.lng', data.lng);
            if (localStorage.getItem('token')) {
                axios.post(process.env.VUE_APP_API_URL + '/api/user/state', {
                    center: {
                        lat: data.lat,
                        lng: data.lng
                    }
                });
            }
        }

    },
    setZoom: function (state, data) {
        if (data) {
            if (data < 1) {
                data = 1
            }
            localStorage.setItem('state.zoom', data);
            if (localStorage.getItem('token')) {
                axios.post(process.env.VUE_APP_API_URL + '/api/user/state', {
                    zoom: parseInt(data)
                });
            }
        }
        Vue.set(state.zoom, 'current', parseInt(data));

    },
    setZoomFromDB(state, data) {
        localStorage.setItem('state.zoom', data);
        Vue.set(state.zoom, 'current', parseInt(data));
    },
    setCenterFromDB(state, data) {
        localStorage.setItem('state.center.lat', data.lat);
        localStorage.setItem('state.center.lng', data.lng);
        Vue.set(state, 'center', [data.lat, data.lng]);
    },
    setDistance(state, data) {
        Vue.set(state, 'distance', data);
    },
    setShowVectorLayer(state, data) {
        Vue.set(state, 'showVectorLayer', data);
    },
    setProjectInfoShown(state, data) {
        Vue.set(state, 'projectInfoShown', data);
    },
    setPropertiesInfoShown(state, data) {
        Vue.set(state, 'propertiesInfoShown', data);
    },
    setImageGroupsShown(state, data) {
        Vue.set(state, 'imageGroupsShown', data);
    },
    setUserLocationsShown(state, data) {
        Vue.set(state, 'userLocationsShown', data);
    },
    setSaveLocationShown(state, data) {
        Vue.set(state, 'saveLocationShown', data);
    },
    setBounds(state, data) {
        Vue.set(state, 'bounds', data);
    },
    setCheckBoxes(state, data) {
        Vue.set(state, 'checkboxes', data);
    },
    setTilePlanPerimeterShow(state, data) {
        Vue.set(state, 'tilePlanPerimeterShow', data);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}