import vue from "vue";

const state = {
    params:{},
    type:null,
};
const actions = {
    saveSubDuct(ctx,data){
        const newParams = {
            ...ctx.state.params,
            ...data
        }

        return ctx.dispatch('Duct/store',newParams,{root:true});
    }
};
const getters = {};
const mutations = {
    setParams(state,data){
        vue.set(state,'params',data);
    },
    setType: (state,data)=>{
        vue.set(state,'params',data);
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}