<template>
  <b-modal title="Polygon customisation" id="polygon_customisation" ref="polygon_customisation" @ok="savePolygon"
           centered body-class="has-vue-swatches">

    <b-form-group label-for="backgroundColor" label="Background Color">
      <v-swatches v-model="backgroundColor" row-length="6" :swatch-style="{ width: '16px', height: '16px' }"
                  shapes="circles" popover-x="left"
                  :swatches="colors"></v-swatches>
    </b-form-group>
    <b-form-group label-for="borderColor" label="Border Color">
      <v-swatches row-length="6" :swatch-style="{ width: '16px', height: '16px' }"
                  shapes="circles" id="borderColor" v-model="borderColor" popover-x="left"
                  :swatches="colors"></v-swatches>
    </b-form-group>
    <b-form-group label-for="backgroundOpacity" label="Background Opacity">
      <b-form-input id="backgroundOpacity" v-model="backgroundOpacity" type="range" step="0.1" min="0"
                    max="1"></b-form-input>
    </b-form-group>
    <b-form-group label-for="borderWidth" label="Border Width">
      <b-form-input id="borderWidth" v-model="borderWidth" type="range" min="0" max="10"></b-form-input>
    </b-form-group>
  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import {mapGetters} from "vuex";

export default {
  name: "Customize",
  components: {VSwatches},
  data: () => {
    return {
      colors: ["", "#000000", "#434343", "#666666", "#999999", "#b7b7b7", "#cccccc", "#d9d9d9", "#efefef", "#f3f3f3",
        "#ffffff", "#980000", "#ff0000", "#ff9900", "#ffff00", "#00ff00", "#00ffff", "#4a86e8", "#0000ff", "#9900ff",
        "#e6b8af", "#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#c9daf8", "#cfe2f3", "#d9d2e9", "#ead1dc",
        "#dd7e6b", "#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#a4c2f4", "#9fc5e8", "#b4a7d6", "#d5a6bd",
        "#cc4125", "#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6d9eeb", "#6fa8dc", "#8e7cc3", "#c27ba0",
        "#a61c00", "#cc0000", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3c78d8", "#3d85c6", "#674ea7", "#a64d79",
        "#85200c", "#990000", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#1155cc", "#0b5394", "#351c75", "#741b47",
        "#5b0f00", "#660000", "#783f04", "#7f6000", "#274e13", "#0c343d", "#1c4587", "#073763", "#20124d", "#ff00ff"],
    }
  },
  computed: {
    ...mapGetters({
      style: 'Polygon/currentStyle',
      currentPolygon: 'Polygon/currentPolygon',
      currentStyle: 'Polygon/currentStyle',
    }),
    backgroundColor: {
      get() {
        return this.$store.state.Polygon.currentLayer.backgroundColor;
      }, set(data) {
        this.$store.commit('Polygon/setBackgroundColor', data);
      }
    },
    backgroundOpacity: {
      get() {
        return this.$store.state.Polygon.currentLayer.backgroundOpacity;
      }, set(data) {
        this.$store.commit('Polygon/setBackgroundOpacity', data);
      }
    },
    borderColor: {
      get() {
        return this.$store.state.Polygon.currentLayer.borderColor;
      }, set(data) {
        this.$store.commit('Polygon/setBorderColor', data);
      }
    },
    borderWidth: {
      get() {
        return this.$store.state.Polygon.currentLayer.borderWidth;
      }, set(data) {
        this.$store.commit('Polygon/setBorderWidth', data);
      }
    }
  },
  methods: {
    savePolygon() {

      this.$store.dispatch('Polygon/update', {
        id: this.currentPolygon.id,
        params: {...this.currentStyle, geometry: btoa(JSON.stringify(this.currentPolygon.toGeoJSON()))}
      });
    }
  }
}
</script>

<style scoped>

</style>