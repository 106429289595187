<template>
  <div>
    <b-card class="vh-100  flex-column sidenav" v-bind:class="{ active: showWindow || showWindowSilent}"
            body-class="flex-1" footer-class="px-3">
      <template #header>
        <div class="d-flex justify-content-between">
          <div>{{ getViewTitle }}</div>
          <div><i class="fas fa-times float-right" @click="closeWindow()"></i></div>
        </div>
      </template>
      <div class="d-flex flex-column h-100">
        <div class="flex-fill">
          <b-overlay :show="showOverlay" rounded="sm" class="h-100">
            <b-button variant="success" v-if="isRoutesView && noi.routes.length >0" @click="addNewRoute" size="sm"
                      class="my-1 float-right "><i
                class="fas fa-plus"></i> Add New Route
            </b-button>
            <div class="h-100 d-flex justify-content-center align-items-center"
                 v-if="isRoutesView && noi.routes.length === 0 ">

              <b-button class="mt-5 text-center" variant="success"
                        size="sm" @click="addNewRoute"><i
                  class="fas fa-plus"></i> Add Route
              </b-button>
            </div>
            <div v-if="isRoutesView && noi.routes.length > 0">
              <table class="table table-striped">
                <tbody>
                <tr v-for="(route, routeIndex) in noi.routes">
                  <td>Route #{{ parseInt(routeIndex) + 1 }}</td>
                  <td>{{ route.items.length }} Items</td>
                  <td>
                    <span class="text-primary cursor-pointer" @click="editRoute(routeIndex)">Edit</span>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
            <div v-if="isNoisListView" class="h-100">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Type</th>
                  <th></th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(noi, noiIndex) in tmp.currentNoiList" @mouseover="focusItem(noi.properties.objectid)">
                  <td>
                    <div><strong>{{ noi.properties.objectid }}</strong></div>
                  </td>
                  <td>
                    <div>{{ noi.properties.type }}</div>
                  </td>
                  <td>
                    <span class="badge badge-primary text-white"
                          v-if="noi.tag !== undefined && noi.tag === 'new'">New</span>
                    <span class="badge badge-danger text-white" v-if="noi.tag !== undefined && noi.tag === 'removed'">Removed</span>
                  </td>
                  <td>
                    <span v-if="noi.tag!=='removed'" class="text-danger cursor-pointer" @click="removeFromList(noi)">Remove</span>
                    <span v-else class="text-success cursor-pointer" @click="returnBack(noi)">Return</span>
                  </td>
                </tr>
                </tbody>
              </table>

              <div v-if="tmp.currentNoiList.length === 0"
                   class="h-100 d-flex justify-content-center align-items-center text-sm-center text-muted">List is
                empty, start
                adding Duct/Structures
              </div>
            </div>
          </b-overlay>
        </div>

      </div>
      <template #footer>
        <div class="d-flex justify-content-between" v-if="view.currentView === views.routesList">
          <div>
            <button :disabled="routesAreEmpty" class="btn btn-success btn-sm"
                    @click="buttonSubmitNoi">Submit
            </button>
            <button v-if="mode===modes.create" :disabled="routesAreEmpty"
                    class="btn btn-primary ml-1 btn-sm" @click="buttonSaveAsDraft">Save as Draft
            </button>
          </div>

          <button class="btn btn-secondary btn-sm" @click="closeWindow">Cancel</button>
        </div>
        <div class="d-flex justify-content-between" v-if="view.currentView === views.noisList">
          <button class="btn btn-primary ml-1 btn-sm" :disabled="tmp.currentNoiList.length === 0" @click="saveToRoute">
            Save to Route
          </button>
          <button class="btn btn-secondary ml-1 btn-sm" @click="cancelRouteList">Cancel</button>
        </div>
      </template>
    </b-card>

    <b-modal id="noi_submission_modal" @ok="submitNoi($event)" :ok-title="submitButtonTitle" title="Submit NOI"
             @shown="showSubmitModalWindow">
      <b-form>
        <b-form-group>
          <b-input placeholder="Reference" v-model="noi.reference"/>
        </b-form-group>
        <b-form-group label="Start Date">
          <b-datepicker placeholder="Requested Start Date" :min="this.minDate" :max="this.maxDate"
                        v-model="noi.startDate"/>
        </b-form-group>
        <b-form-group label="Completion Date">
          <b-datepicker placeholder="Requested Completion Date" :min="this.minDate" :max="this.maxDate"
                        v-model="noi.endDate"/>
        </b-form-group>
        <hr/>
        <b-form-group>
          <label>Agent</label>
        </b-form-group>
        <RelatedPartyList :related-parties="[noi.agent]" :is-created-allowed="false"
                          :allowed-roles='["Agent"]'/>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CommonTools from "@/Helpers/CommonTools";
import L from "leaflet";
import RelatedPartyList from "@/InternalPlugins/Openreach/RelatedParty/List/RelatedPartyList.vue";

export default {
  name: "OpenreachNOI",
  components: {RelatedPartyList},
  data() {
    return {
      showOverlay: true,
      showWindowSilent: false,
      mode: 'create',
      modes: {
        create: 'create',
        edit: 'edit',
      },
      minDate: null,
      maxDate: null,
      views: {
        routesList: 'routesList',
        noisList: 'noisList',
      },
      view: {
        currentView: 'routesList',
        currentRouteIndex: 0,
      },
      noi: {
        startDate: null,
        endDate: null,
        reference: '',
        agent: {
          id: null,
          firstName: null,
          lastName: null,
          role: 'Agent',
          email: null,
          telephone: null
        },
        routes: [],
        isDraft: true,
      },
      tmp: {
        flatNoiList: [],
        currentNoiList: [],
        allRouteItems: [],
      },
      itemsLayerGroup: new L.LayerGroup(),
      itemLayers: new Map(),
      limits: {
        ducts: 50,
        structures: 50,
        total: 1500
      }
    }
  },
  watch: {
    editId(val) {

      if (val > 0) {
        this.mode = this.modes.edit;
        this.showOverlay = true;
        this.showWindowSilent = true;
        this.$store.dispatch('Openreach/viewNoi', {projectId: this.project.id, id: val}).then(response => {
          const agentName = response.data.data.agents.name.split(' ');
          const firstName = agentName[0];
          const lastName = agentName[1];
          this.noi.agent = {
            firstName: firstName,
            lastName: lastName,
            role: 'Agent',
            email: response.data.data.agents.email,
            telephone: response.data.data.agents.phone

          };
          this.noi.reference = response.data.data.reference;
          this.noi.startDate = response.data.data.requestedStartDate;
          this.noi.endDate = response.data.data.requestedCompletionDate;
          this.noi.routes = response.data.data.routes;
          this.$store.dispatch('LayersList/activateSubLayer', this.layerName)
          // Get Openreach Usable and rest
          // this.$store.dispatch('Openreach/getOpenreachItems', {projectId: this.project.id}).then(response => {
          //
          this.storeSetAllItems(this.flatRoutes);
          this.noi.isDraft = false;
          this.showRoutesListWindow()
          this.showOverlay = false;
        })
      } else {
        this.mode = this.modes.create;

      }
    },
    showWindow(val) {
      if (val) {
        this.showOverlay = false;
        // Enable openreach usable layer
        this.$store.dispatch('LayersList/activateSubLayer', this.layerName)
        // Get Openreach Usable and rest

        this.noi.agent = {
          firstName: this.$store.state.Profile.user.first_name,
          lastName: this.$store.state.Profile.user.last_name,
          role: 'Agent',
          email: this.$store.state.Profile.user.email,
          telephone: this.$store.state.Profile.user.phone
        }
      } else {
        this.noi.agent = {};
      }

    },
    storeAllItems(val) {
      const self = this;
      if (!window.Lmap.hasLayer(this.itemsLayerGroup)) {
        window.Lmap.addLayer(this.itemsLayerGroup)
      }
      this.itemsLayerGroup.clearLayers();

      if (val.length > 0) {
        val.forEach(layer => {
          if (layer.geometry.type === "Point") {
            const marker = L.circleMarker([layer.geometry.coordinates[1], layer.geometry.coordinates[0]], {
              radius: 4,
              fillColor: "#0050cc",
              opacity: 1,
              fillOpacity: 0.8,
              id: layer.properties.objectid
            });
            self.itemsLayerGroup.addLayer(marker);
            marker.type = 'marker';
            self.itemLayers.set(layer.properties.objectid, marker);
            layer.center = marker._latlng;
          } else if (layer.geometry.type === 'LineString') {
            let reversedCoords = layer.geometry.coordinates.map(pair => [pair[1], pair[0]]);
            const linestring = new L.Polyline(reversedCoords)
            self.itemsLayerGroup.addLayer(linestring);
            linestring.type = 'linestring';
            self.itemLayers.set(layer.properties.objectid, linestring);
            layer.center = linestring.getBounds().getCenter();
          }
        })
      }

    },
  },
  computed: {
    ...mapGetters({
      showWindow: 'OpenreachNOIs/windowActive',
      editId: 'Openreach/editId',
      project: "Projects/selectedProject",
      storeAllItems: "OpenreachNOIs/allItems",
      storeIsUsed: 'OpenreachNOIs/isUsed',
      profile: 'Profile/getUser',
      pia: 'Openreach/pia'
    }),
    layerName() {
      let layerName = 'openreach_usable';
      if ('sandbox' === this.pia.state) {
        layerName = 'openreach_usable_sandbox';
      }
      return layerName;
    },
    flatRoutes() {
      return this.noi.routes.reduce((acc, obj) => [...acc, ...obj.items], []);
    },
    routesAreEmpty() {
      return this.noi.routes.length === 0 || this.noi.routes[0].length === 0;
    },
    submitButtonTitle() {
      return this.noi.isDraft ? 'Save as Draft' : 'Submit';
    },
    isRoutesView() {
      return this.view.currentView === this.views.routesList;
    },
    isNoisListView() {
      return this.view.currentView === this.views.noisList;
    },
    getViewTitle() {
      let title = 'Routes List';
      if (this.isNoisListView) {
        title = 'NOIs for Route # ' + (this.view.currentRouteIndex + 1);
      }
      return title;
    }
  },
  methods: {
    ...mapMutations({
      hideWindow: 'OpenreachNOIs/deactivateWindow',
      showNoiWindow: 'OpenreachNOIs/activateWindow',
      storeSetAllItems: 'OpenreachNOIs/setAllItems',
      storeAdd: 'OpenreachNOIs/add',
      storeRemove: 'OpenreachNOIs/remove',
      storeReset: 'OpenreachNOIs/reset',
      storeRemoveEdit: 'OpenreachNOIs/removeEdit',
      storeReturnBackEdit: 'OpenreachNOIs/returnBackEdit',
      storeResetRouteItems: 'OpenreachNOIs/resetRouteItems',
      storeSetRouteItems: 'OpenreachNOIs/setRouteItems',
      showPopupButtons: 'OpenreachNOIs/showPopupButtons',
      hidePopupButtons: 'OpenreachNOIs/hidePopupButtons',
    }),
    buttonSaveAsDraft() {
      this.noi.isDraft = true;
      this.$bvModal.show('noi_submission_modal')

    },
    buttonSubmitNoi() {
      this.noi.isDraft = false;
      this.$bvModal.show('noi_submission_modal')
    },
    /**
     * Show routes list window
     */
    showRoutesListWindow() {
      this.noi.routes = this.noi.routes.filter(item => item.items.length > 0)
      if (this.noi.routes.length === 1 && this.noi.routes[0].items.length === 0) {
        this.noi.routes = [];
      }
      this.tmp.currentNoiList = [];
      this.hidePopupButtons();
      this.view.currentView = this.views.routesList;
    },
    showNoisListWindow(index) {
      this.tmp.currentNoiList = JSON.parse(JSON.stringify(this.noi.routes[index].items))
      this.view.currentView = this.views.noisList;
      this.showNoiWindow();
      this.showPopupButtons();
    },
    deleteRoute(index) {
      this.noi.routes[index] = [];
      this.showRoutesListWindow();
      this.storeSetAllItems(this.flatRoutes);

    },
    cancelRouteList() {
      this.storeSetAllItems(this.flatRoutes);
      this.showRoutesListWindow()
    },
    editRoute(index) {
      this.view.currentRouteIndex = index;
      this.storeSetRouteItems(this.noi.routes[index].items);
      this.showNoisListWindow(index);
    },
    saveToRoute() {
      this.noi.routes[this.view.currentRouteIndex].items = JSON.parse(JSON.stringify(this.tmp.currentNoiList));
      this.storeResetRouteItems();
      this.showRoutesListWindow()
    },
    addNewRoute() {
      const currentLength = this.noi.routes.length;
      if (this.noi.routes[currentLength] === undefined) {
        this.noi.routes[currentLength] = {id: null, items: []};
      }
      this.view.currentRouteIndex = currentLength;
      this.showNoisListWindow(currentLength);
    },
    closeWindow() {
      this.showWindowSilent = false;
      // this.$store.commit('State/setOpenReachInfoLoaded', false)
      this.hideWindow();
      if (this.editId > 0) {
        this.$store.commit('Openreach/setEditId', 0);
      }
    },
    submitNoi($event) {
      if (this.noi.reference.length === 0) {
        this.$swal("", "Reference cannot be empty", "error")
        $event.preventDefault();
        return;
      }

      if (this.routesAreEmpty) {
        this.$swal("", "Routes are empty", "error");
        $event.preventDefault();
        return;
      }
      let data = {};
      data.projectid = this.$store.getters['Projects/selectedProject'].id;
      data.data = {
        reference: this.noi.reference,
        requestedStartDate: this.noi.startDate,
        requestedCompletionDate: this.noi.endDate,
        agent: {
          name: this.noi.agent.firstName + ' ' + this.noi.agent.lastName,
          email: this.noi.agent.email,
          phone: this.noi.agent.telephone,
        },
        routes: this.noi.routes,
        isDraft: this.noi.isDraft,
      };

      this.showOverlay = true;
      if (this.mode === 'create') {

        this.$store.dispatch('Openreach/submitNoi', data).then(response => {
          this.$swal('Success', 'NOI Created successfully', 'success');
          this.noi.routes = [];
          this.tmp.currentNoiList = [];
          this.storeReset();
          this.closeWindow();
          this.$bvModal.show('OpenreachPia');
          this.showOverlay = false;
        }).catch(error => {
          if (!CommonTools.displayCatch(error)) {
            this.$swal('Error', 'There was an error while editing NOI', 'error');
          }
          this.noi.routes = [];
          this.tmp.currentNoiList = [];
          this.storeReset();
          this.closeWindow();
          this.$bvModal.show('OpenreachPia');
          this.showOverlay = false;
        });
      } else {
        data.id = this.editId;
        this.$store.dispatch('Openreach/editNoi', data).then(response => {
          this.$swal('Success', 'NOI updated successfully', 'success');
          this.closeWindow();
          this.$bvModal.show('OpenreachPia');
          this.showOverlay = false;
        }).catch(error => {
          if (!CommonTools.displayCatch(error)) {
            this.$swal('Error', 'There was an error while editing NOI', 'error');
          }
          this.showOverlay = false;

        });
      }
    },
    restSubmitData() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const startDate = new Date(today)
      const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)
      startDate.setDate(startDate.getDate() + 1)
      endDate.setDate(endDate.getDate())
      this.noi.startDate = startDate;
      this.noi.endDate = endDate
      this.minDate = startDate;
      this.noi.reference = null;
      this.maxDate = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate())
    },
    showSubmitModalWindow() {
      // this.restSubmitData();
    },
    focusItem(objectId) {
      if (this.itemLayers.has(objectId)) {
        const matched = this.itemLayers.get(objectId);
        if (matched.type === 'marker') {
          window.Lmap.setView(matched._latlng)
        } else {
          window.Lmap.setView(matched.getBounds().getCenter())
        }
      }
    },
    removeFromList(noi) {
      if (this.mode === this.modes.edit && noi.tag !== 'new') {
        const foundObject = this.tmp.currentNoiList.find(obj => obj.properties.objectid === noi.properties.objectid);
        foundObject.tag = 'removed';
        this.storeRemoveEdit(noi);
      } else {
        this.tmp.currentNoiList = this.tmp.currentNoiList.filter(item => item.properties.objectid !== noi.properties.objectid);
        this.storeRemove(noi);
      }
    },
    returnBack(noi) {
      const foundObject = this.tmp.currentNoiList.find(obj => obj.properties.objectid === noi.properties.objectid);
      foundObject.tag = '';
      this.storeReturnBackEdit(noi);

    }
  },
  created() {
    this.$root.$on('add-noi-to-route', noi => {
      noi.tag = 'new';
      const structuresCount = this.tmp.currentNoiList.filter(item => item.properties.type === 'Structure').length;
      const ductsCount = this.tmp.currentNoiList.filter(item => item.properties.type === 'Duct').length;


      const totalCount = this.flatRoutes.length

      if (!this.storeIsUsed(noi)) {

        if (noi.id.startsWith('openreach_duct')) {
          if (ductsCount >= this.limits.ducts) {
            this.$swal('Error', 'You have reached the limit of Ducts inside route, current limit is ' + this.limits.ducts, 'error');
            return;
          }
          noi.properties.type = 'Duct';
        } else {
          if (structuresCount >= this.limits.structures) {
            this.$swal('Error', 'You have reached the limit of Structures inside route, current limit is ' + this.limits.structures, 'error');
            return;
          }
          noi.properties.type = 'Structure';
        }
        if ((totalCount + ductsCount + structuresCount) >= this.limits.total) {
          this.$swal('Error', 'You have reached the limit of total items per noi request, current limit is ' + this.limits.total, 'error');
          return;
        }
        this.storeAdd(noi);
        this.tmp.currentNoiList.push(noi);
      }
    })
    this.$root.$on('remove-noi-from-route', noi => {
      this.removeFromList(noi);
    })
    this.$root.$on('return-back-noi-to-route', noi => {
      this.returnBack(noi);
    })
  }
}
</script>

<style scoped>
.sidenav {
  display: none;
  width: 350px;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  overflow-x: hidden;
  height: 100%;
  box-shadow: 0px 20px 15px -7px #000000, 0px 5px 13px 5px rgba(0, 0, 0, 0);
  background: white;
}


.sidenav.active {
  display: flex;
}

.table {
  overflow: auto;
}
</style>