const state = {
    isLoading:false,
    site:null,
    sites:[],
    sitesList:[],
    contacts:[],
    images:[],
    image:null,
    currentSiteIdForModal:null,
    contact:null,
    legalInstruments:[],
    legalInstrument:null,

}
const mutations = {
    setIsLoading(ctc,data){
        state.isLoading = data;
    },
    setSite(ctx,data){
      state.site = data;
    },
    setSites(ctc,data){
        state.sites = data;
    },
    setSitesList(ctc,data){
        state.sitesList = data;
    },
    setModalSite(ctx,data){
        state.currentSiteIdForModal = data;
    },
    setContacts(ctx,data){
        state.contacts = data;
    },
    setImages(ctx,data) {
        ctx.images = data;
    },
    setContact(ctx,data){
        ctx.contact = data;
    },
    setLegalInstruments(ctx,data){
        ctx.legalInstruments = data;
    },
    setLegalInstrument(ctx,data){
        ctx.legalInstrument = data;
    }
}
const actions = {
    saveSite({commit},data){
        commit('setIsLoading',true)
        axios.put(process.env.VUE_APP_API_URL+'/api/sites',data).finally(()=>{
        }).finally(()=>{
            commit('setIsLoading',false);
        });
    },
    getSites({commit}){
        commit('setIsLoading',true)
        axios.get(process.env.VUE_APP_API_URL+'/api/sites').then(response=>{
            commit('setSites',response.data);
        }).finally(()=>{
            commit('setIsLoading',false);
        });
    },
    listSites({commit}){
        commit('setIsLoading',true)
        axios.get(process.env.VUE_APP_API_URL+'/api/sites/list').then(response=>{
            commit('setSitesList',response.data);
        }).finally(()=>{
            commit('setIsLoading',false);
        });
    },
    getContacts({commit},siteId){
        commit('setIsLoading',true)
        return axios.get(process.env.VUE_APP_API_URL+'/api/sites/'+siteId+'/contacts').then(response=>{
            commit('setContacts',response.data);

        }).finally(()=>{
            commit('setIsLoading',false);
        });
    },
    deleteContact({commit,dispatch},data){
        commit('setIsLoading',true)
        return axios.delete(process.env.VUE_APP_API_URL+'/api/sites/'+data.siteId+'/contacts/'+data.contactId).finally(()=>{
            commit('setIsLoading',false);
            dispatch('getContacts',data.siteId)
        });
    },
    getSiteInfo({commit},id){
        return new Promise((success) => {
            commit('setIsLoading', true)
            return axios.get(process.env.VUE_APP_API_URL + '/api/sites/' + id).then(response => {
                commit('setSite', response.data)
                success(response);
            }).finally(() => {
                commit('setIsLoading', false);
            });
        })
    },
    updateContact({commit,dispatch,getters},data){
        commit('setIsLoading',true)
        return axios.put(process.env.VUE_APP_API_URL+'/api/sites/'+getters.siteIdForInfo+'/contacts/'+data.id,data).finally(()=>{
            commit('setIsLoading',false);
        });
    },
    getImages(ctx){
        this.page = 'images';
        ctx.commit('setIsLoading',true)
        return axios.get(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/images').then(response=>{
            ctx.commit('setImages',response.data)
        }).finally(()=>{
            ctx.commit('setIsLoading',false);
        });
    },
    uploadImage(ctx,data){
        ctx.commit('setIsLoading',true)
        return axios.post(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/images',data).finally(()=>{
            ctx.commit('setIsLoading',false);
        });
    },
    updateImage(ctx,data){
        ctx.commit('setIsLoading',true)
        return axios.put(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/images/'+data.id,data).finally(()=>{
            ctx.commit('setIsLoading',false);
            ctx.dispatch('getImages')
        });
    },
    deleteImage(ctx,id){
        ctx.commit('setIsLoading',true)
        return axios.delete(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/images/'+id).finally(()=>{
            ctx.commit('setIsLoading',false);
            ctx.dispatch('getImages')
        });
    },
    getLegalInstruments(ctx){
        return new Promise((success) => {
            ctx.commit('setIsLoading', true);
            return axios.get(process.env.VUE_APP_API_URL + '/api/sites/' + ctx.getters.siteIdForInfo + '/legals').then(response => {
                ctx.commit('setLegalInstruments',response.data);
                success(response.data);
            }).finally(()=>{
                ctx.commit('setIsLoading', false);
            });
        });
    },
    getLegalInstrument(ctx,id){
        return new Promise((success)=>{
            ctx.commit('setIsLoading',true);
            return axios.get(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/legals/'+id).then(response=>{
                ctx.commit('setLegalInstrument',response.data);
                success(response.data);
            }).finally(()=>{
                ctx.commit('setIsLoading',false);
            })
        });
    },
    addLegalInstrument(ctx,data){
        return new Promise((success)=> {
            ctx.commit('setIsLoading', true);
            axios.post(process.env.VUE_APP_API_URL + '/api/sites/' + ctx.getters.siteIdForInfo + '/legals',data).then(() => {
                ctx.dispatch('getLegalInstruments');
                success();
            }).finally(()=>{
                ctx.commit('setIsLoading', false);
            });
        });
    },
    updateLegalInstrument(ctx,data){
        return new Promise((success)=>{
            ctx.commit('setIsLoading',true);
            axios.put(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/legals/'+data.id,data).then(()=>{
                ctx.dispatch('getLegalInstruments');
                success();
            }).finally(()=>{
                ctx.commit('setIsLoading',false);
            });
        });
    },
    deleteLegalInstrument(ctx,id){
        return new Promise((success)=>{
            ctx.commit('setIsLoading',true);
            axios.delete(process.env.VUE_APP_API_URL+'/api/sites/'+ctx.getters.siteIdForInfo+'/legals/'+id).then(()=>{
                ctx.dispatch('getLegalInstruments');
                success();
            }).finally(()=>{
                ctx.commit('setIsLoading',false);
            });
        });
    }
}
const getters = {
    isLoading:state=>{
      return state.isLoading;
    },
    site:state=>{
        return state.site;
    },
    sites:state=>{
        return state.sites;
    },
    sitesList:state=>{
        return state.sitesList;
    },
    siteIdForInfo:state=>{
        return state.currentSiteIdForModal;
    },
    contacts:state=>{
        return state.contacts;
    },
    images:state=>{
        return state.images;
    },
    contact:state=>{
        return state.contact
    },
    legalInstruments:state=>{
        return state.legalInstruments;
    },
    legalInstrument:state=>{
        return state.legalInstrument;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}