<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import * as L from "leaflet";


export default {
  name: "OpenreachDuctsAndStructuresPopup",
  data() {
    return {
      geometry: null,
    }
  },
  computed: {
    ...mapGetters({
      available: 'OpenreachDuctsAndStructures/available',
      features: 'OpenreachDuctsAndStructures/features',
      location: 'OpenreachDuctsAndStructures/location',
      isLeadIn: 'Openreach/showOpenreachLeadInWindow',
      isInLeadIn: 'Openreach/isInLeadNoi',
      isNoiSelectMode: 'OpenreachNOIs/windowActive',
      isInNoiList: 'OpenreachNOIs/isUsed',
      isRemoved: 'OpenreachNOIs/isRemoved',
      showNoisAddRemoveButtons: 'OpenreachNOIs/buttonsShouldBeVisible',
    })
  },
  watch: {
    location(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.OpenreachDuctAndStructuresPopupLayerGroup) {
            this.$refs.OpenreachDuctAndStructuresPopupLayerGroup.mapObject.openPopup([this.location.lat, this.location.lng]);
            this.$refs.OpenreachDuctAndStructuresPopupLayerGroup.mapObject.getPopup().on('remove', () => {
              this.close();
            })
            this.makeDraggable(this.$refs.OpenreachDuctAndStructuresPopupLayerGroup.mapObject.getPopup());
          }
        });

      }
    }
  },
  methods: {
    ...mapMutations({
      addLeadIn: 'Openreach/addLeadInItem',
      addNoiToRoute: 'Openreach/addNoiToRoute',
      removeNoiFromRoute: "Openreach/removeNoiFromRoute",
    }),
    ...mapActions({
      removeItem: "Openreach/removeLeadInNoi"
    }),
    getTabTitle(feature) {
      let title = 'Duct: ';
      if (feature.id.startsWith('openreach_structure')) {
        title = 'Structure: ';
      }
      title += feature.properties.objectid;

      return title;
    },

    popupContent(feature) {
      let html = '<div style="cursor: pointer;text-align: center;">';
      html += '<table class="table table-sm table-bordered">';
      for (let key in feature.properties) {
        html += '<tr  class="result_item">';
        html += '<td style="background-color: #e5e3e3;">' + key + '</td>';
        if (feature.properties[key]) {
          html += '<td>' + feature.properties[key] + '</td>';
        } else {
          html += '<td></td>';
        }
        html += '</tr>';
      }
      html += '</table>';
      html += '</div>';
      return html;
    },
    input(index) {
      const feature = this.features[index];
      if (this.geometry) {
        this.$refs.OpenreachDuctAndStructuresPopupLayerGroup.mapObject.removeLayer(this.geometry);
      }
      this.geometry = L.geoJSON(feature)
      this.geometry.addTo(this.$refs.OpenreachDuctAndStructuresPopupLayerGroup.mapObject);
    },
    close() {
      this.$store.commit('OpenreachDuctsAndStructures/setAvailable', false);
      this.$store.commit('OpenreachDuctsAndStructures/setLocation', {lat: null, lng: null});
    },
    makeDraggable(popup) {
      const pos = Lmap.latLngToLayerPoint(popup.getLatLng());
      L.DomUtil.setPosition(popup._wrapper.parentNode, pos);
      const draggable = new L.Draggable(popup._container, popup._wrapper);
      draggable.enable();

      draggable.on('dragend', function () {
        const pos = Lmap.layerPointToLatLng(this._newPos);
        popup.setLatLng(pos);
      });
    }
  },
}
</script>

<template>
  <l-layer-group @close="close" v-if="available"
                 :latLng="[location.lat,location.lng]"
                 ref="OpenreachDuctAndStructuresPopupLayerGroup">

    <l-popup @update:visible="close" @close="close" v-if="available" ref="OpenreachDuctAndStructuresPopup"
             min-width="300"
             :latLng="[location.lat,location.lng]" v-show="true"
             :options="{ autoClose: false, closeOnClick: false }">
      <i class="fas fa-expand-arrows-alt draggable"></i>

      <b-tabs pills card fill content-class="mt-3" @input="input">
        <b-tab :title="getTabTitle(feature)" v-for="feature in features"
               class="w-100"
               :key="feature.properties.objectid"
        >
          <div v-html="popupContent(feature)" class="table-window mt-3"></div>
          <div class="table-window">
            <table class="table">


            </table>
            <div v-if="isLeadIn">
              <b-button variant="success" size="sm" v-if="!isInLeadIn(feature.properties.objectid)"
                        @click="addLeadIn(feature)">
                Add to LeadIn
              </b-button>
              <b-button v-else variant="danger" size="sm" @click="removeItem(feature.properties.objectid)">Remove
                from LeadIn
              </b-button>
            </div>
            <div v-if="isNoiSelectMode && showNoisAddRemoveButtons">
              <b-button variant="success" size="sm" v-if="!isInNoiList(feature.properties.objectid)"
                        @click="$root.$emit('add-noi-to-route',feature)">
                Add to Route
              </b-button>
              <b-button v-else-if="isRemoved(feature.properties.objectid)" variant="success" size="sm"
                        @click="$root.$emit('return-back-noi-to-route',feature)">Return back to Route
              </b-button>
              <b-button v-else variant="danger" size="sm" @click="$root.$emit('remove-noi-from-route',feature)">Remove
                from Route
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </l-popup>
  </l-layer-group>
</template>

<style scoped>
.tab-pane {
  padding: 0;
}

.draggable {
  position: absolute;
  left: 5px;
  top: 5px;
  cursor: pointer;
}
</style>