<template>
  <div>
    <b-modal id="import_polygons" no-close-on-esc no-close-on-backdrop @ok="uploadFile" :ok-disabled="!Boolean(kmlFile)"
             ok-title="Upload">
      <b-file :state="Boolean(kmlFile)"
              placeholder="Choose a KML file or drop it here..."
              drop-placeholder="Drop file here..." v-model="kmlFile" accept=".kml"></b-file>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: function () {
    return {
      kmlFile: null,
    }
  },
  name: "ImportPolygons",
  methods: {
    uploadFile(e) {
      const self = this;
      e.preventDefault();
      let formData = new FormData();
      formData.append('file', this.kmlFile);
      axios.post(process.env.VUE_APP_API_URL + '/api/project/' + this.project.id + '/polygon/upload/kml',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function () {
        self.$swal("Upload Successfully", "Polygons successfully imported form KML", "success");
        self.kmlFile = null;
        self.$bvModal.hide('import_polygons');
      }).catch(function (error) {
        self.$swal("Upload Error", "there was an error while importing form KML", "error");
      });
    }
  },
  computed: {
    ...mapGetters({
      project: 'Projects/selectedProject'
    }),
  }
}
</script>

<style scoped>

</style>