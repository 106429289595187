import vue from 'vue';

const state = {
    selectedDuct: {},
    isInSelectMode: false,
    ducts: [],
    newDuctTmpInfo: {},
    ductInfo: {},
    current_request_id: null,
    redrawDuctsForMobile: false,
};
const actions = {
    list(ctx, bounds) {
        if (this.current_request_id) {
            axios.cancel(this.current_request_id);
        }
        const self = this;
        this.current_request_id = 'load_project_duct_list';
        return new Promise((resolve, reject) => {
            const selectedProject = ctx.rootGetters["Projects/selectedProject"];
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + selectedProject.id + '/duct/search/' + btoa(bounds), {
                // requestId: self.current_request_id
            }).then(response => {
                ctx.commit('setDuctsList', response.data.data);
                self.current_request_id = null;
                resolve(response.data.data);
            }).catch(error => {
                self.current_request_id = null;
                reject(error);
            })
        })
    },
    store(ctx, data) {
        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + data.project_id + '/duct', data);
    },
    update(ctx, data) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + selectedProject.id + /duct/ + data.id, data.params);
    },
    delete(ctx, ductId) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return new Promise((resolve, reject) => {
            axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + selectedProject.id + /duct/ + ductId).then((data) => {
                resolve(data);
            }).catch(error => {
                reject(error)
            });
        })
    },
    changeImplementedStatus(ctx, data) {
        let selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/' + data.id + '/status/' + data.status);
    },
    getDuctInfo(ctx, ductId) {
        let selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + selectedProject.id + '/duct/' + ductId).then(response => {
                ctx.commit('setDuctInfo', response.data.data);
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            })
        })
    },
    getDuctsForMerging(ctx, ductId) {
        let selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/' + ductId + '/merge/info').then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            })
        })
    },
    mergeDucts(ctx, {ductId, secondDuctId}) {
        let selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/' + ductId + '/merge/' + secondDuctId).then(response => {
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            })
        })
    }
};
const getters = {
    selectedDuct: state => {
        return state.selectedDuct;
    },
    redrawDuctsForMobile: state => {
        return state.redrawDuctsForMobile;
    },
    isInSelectMode: state => {
        return state.isInSelectMode;
    },
    ducts: state => {
        return state.ducts;
    },
    newDuctTmpInfo: state => {
        return state.newDuctTmpInfo;
    },
    ductInfo: state => {
        return state.ductInfo;
    }
};
const mutations = {
    setDuctsList(state, data) {
        state.ducts = data;
    },
    setRedrawDuctsForMobile(state, data) {
        state.redrawDuctsForMobile = data;
    },
    setSelectedDuctType(state, ductType) {
        vue.set(state, 'selectedDuct', ductType);
    },
    setIsInSelectMode(state, mode) {
        state.isInSelectMode = mode;
    },
    setNewDuctTmpInfo(state, info) {
        state.newDuctTmpInfo = info;
    },
    setDuctInfo(state, info) {
        state.ductInfo = info;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}