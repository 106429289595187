<template>
  <div>
    <l-layer-group @close="close" v-if="this.polygonInfoAvailable"
      :latLng="[this.popupLocation.lat, this.popupLocation.lng]" ref="polygonLayerGroup">
      <l-popup @update:visible="close" @close="close" v-if="this.polygonInfoAvailable" ref="polygonPopup"
        :latLng="[this.popupLocation.lat, this.popupLocation.lng]" v-show="true"
        :options="{ autoClose: false, closeOnClick: false, closeButton: false }">
        <div class="d-flex justify-content-end">
          <b-dropdown id="dropdown_location_info" menu-class="dropdown-menu-class pull-right" variant="default"
            toggle-class="text-decoration-none p-0" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v text-primary"></i>
            </template>
            <b-dropdown-item class="small" @click="showCustomiseButton"><small
                class="pl-2">Customise</small></b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group>
              <b-dropdown-header>Export</b-dropdown-header>
              <b-dropdown-item class="small" @click="setPolygonForCSVExport"><small class="pl-2">As
                  CSV</small></b-dropdown-item>
            </b-dropdown-group>



            <b-dropdown-group>
              <b-dropdown-header>Download</b-dropdown-header>
              <b-dropdown-item class="small" v-show="this.kmlDownloadLink !== null" :href="this.kmlDownloadLink"><small
                  class="pl-2">KML</small></b-dropdown-item>
              <b-dropdown-item class="small" v-show="this.postcodesDownloadLink !== null"
                :href="this.postcodesDownloadLink"><small class="pl-2">Postcodes</small>
              </b-dropdown-item>
              <b-dropdown-item class="small" v-show="this.kmlOpenreachDownloadLink !== null"
                :href="this.kmlOpenreachDownloadLink"><small class="pl-2">Openreach
                  KML</small></b-dropdown-item>
              <b-dropdown-item class="small" @click="getShapeFileLink"><small class="pl-2">Qgis
                  (Shape)</small></b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item class="small" @click="closePopup"><small>Close</small></b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-html="popupContent" class="table-window">

        </div>
        <div class="table-window">
          <table class="table">
            <tr>
              <td style="white-space:nowrap" colspan=1>perimeter Details</td>
              <td>
                <button class="btn btn-primary btn-sm float-right text-right" @click="setPerimeterHidden">{{
                  this.showHideButton
                }}
                </button>
              </td>
            </tr>
            <tr v-if="!this.$store.state.PolygonPopup.perimeterIsHidden">
              <td style="white-space:nowrap" colspan=2>
                <div class="text-right" v-html="perimeterHtml"></div>
              </td>
            </tr>
          </table>
        </div>
        <table class="table-striped table">
          <tbody>
            <tr>
              <td>Properties</td>
              <td>
                <b-overlay :show="!propertyCountLoaded" :spinner-small="true" rounded="sm"></b-overlay>
                <span class="float-right text-right" v-if="propertyCountLoaded">{{ this.propertySum }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="customize-window">
          <div class="alert alert-danger my-3" v-if="roadWorkCount > 0">
            Operator Roadworks Recently in Area
          </div>
        </div>
      </l-popup>
    </l-layer-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DownloadPropertyInfo from "@/Mixins/DownloadPropertyInfo";

export default {
  name: "PolygonPopup",
  mixins: [DownloadPropertyInfo],
  data: function () {
    return {
      propertySum: 0,
      propertyCountLoaded: false,
      polygonPropertiesOptions: {
        condense_address: false,
        include_providers: false,
        include_coverage: false,
      }
    }
  },
  watch: {

    polygon: function (value) {
      if (value !== null) {
        this.updatePopupWindow();
      }
    }
  },
  computed: {
    ...mapGetters({
      polygonInfoAvailable: 'PolygonPopup/popupShowed',
      popupLocation: 'PolygonPopup/popupLocation',
      popupContent: 'PolygonPopup/popupContent',
      polygon: 'PolygonPopup/polygon',
      coordinates: 'PolygonPopup/coordinates',
      perimeterHtml: 'PolygonPopup/perimeterHtml',
      perimeterButtonState: 'PolygonPopup/perimeterIsHidden'
    }),
    showHideButton: {
      get() {
        return this.perimeterButtonState ? 'Show' : 'Hide';
      }
    }
  },
  methods: {
    setPolygonForCSVExport() {
      this.$store.commit('ExportHistory/setSelectedPolygons', { name: 'Polygon #' + this.polygon, value: this.polygon });
      this.openModal('export-properties-new')
    },
    showCustomiseButton() {
      this.$bvModal.show('polygon_customisation');
    },

    close() {
      this.$store.commit('PolygonPopup/setPopupShowed', false);
      this.$store.commit('PolygonPopup/setPolygon', null);
      this.$store.commit('PolygonPopup/setPopupLocation', []);
      this.$store.commit('PolygonPopup/setPopupContent', '');
      this.$store.commit('PolygonPopup/setCoordinates', []);
      this.$store.commit('PolygonPopup/setPerimeterHtml', '');
      this.$store.commit('PolygonPopup/setPerimeterHidden', true);
      this.clear();
    },
    clear() {
      this.kmlOpenreachDownloadLink = null;
      this.csvDownloadLink = null;
      this.kmlDownloadLink = null;
      this.postcodesDownloadLink = null;
      this.propertySum = '-';
    },
    updatePopupWindow() {
      let self = this;
      this.clear();
      this.$nextTick(() => {
        this.$refs.polygonLayerGroup.mapObject.openPopup([this.popupLocation.lat, this.popupLocation.lng]);
        this.$refs.polygonLayerGroup.mapObject.getPopup().on('remove', function () {
          self.close();
        })
        if (this.polygon !== null) {
          this.$store.dispatch('Polygon/properties', this.polygon).then(response => {
            const selectedProject = self.$store.state.Projects.selected_project.id;
            this.downloadCsv(selectedProject, self.polygon);
            this.downloadKML(selectedProject, self.polygon);
            this.downloadKMLOpenreach(selectedProject, self.polygon)
            this.getRoadWorkCount(self.coordinates);
            this.downloadPostcodes(selectedProject, self.polygon, self.coordinates);
            this.propertySum = response.data.sum;
            this.propertyCountLoaded = true;
          });
        }
      })
    },
    getShapeFileLink() {
      const selectedProject = this.$store.state.Projects.selected_project.id;
      const self = this;
      this.$root.$emit('showLoader', 'Generating shape files');
      axios.get(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject + '/polygon/' + this.polygon + '/shapeOpenreach').then(response => {
        self.$root.$emit('hideLoader');
        window.open(process.env.VUE_APP_ASSETS_URL + response.data.zip);
      })
    },
    setPerimeterHidden() {
      const show = this.$store.state.PolygonPopup.perimeterIsHidden;
      this.$store.commit('PolygonPopup/setPerimeterHidden', !show);
    },
    closePopup() {
      this.$refs.polygonLayerGroup.mapObject.closePopup();
    },
  }
}
</script>

<style scoped></style>
