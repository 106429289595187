<template>
  <div>
    <l-layer-group v-if="popupInfoAvailable" ref="roadWorkPopupLayerGroup"
                   :latLng="[this.popupLocation['0'],this.popupLocation['1']]">
      <l-popup v-if="this.popupInfoAvailable" v-show="true" ref="roadWorkPopup"
               :latLng="[this.popupLocation['0'],this.popupLocation['1']]"
               :options="{ autoClose: false, closeOnClick: false }" @close="closePopup"
               @update:visible="closePopup">
        <div v-html="popupHtml"></div>
      </l-popup>
    </l-layer-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import L from "leaflet";
import dayjs from "dayjs";

export default {
  name: "RoadWorks",
  data: function () {
    return {
      popupInfoAvailable: false,
      layerMeta: null,
      popupLocation: [0, 0],
      layerGroup: L.layerGroup(),

    }
  },
  watch: {
    items(value) {
      if (value.length > 0) {
        this.layerGroup.clearLayers();
        this.showItems(value)
      } else {
        this.removeItems();
        if (this.$refs['roadWorkPopupLayerGroup'] !== undefined) {
          this.$refs['roadWorkPopupLayerGroup'].mapObject.closePopup();

        }
        this.layerGroup.closePopup()

      }
    }
  },
  computed: {
    ...mapGetters({
      items: 'RoadWorks/getItems'
    }),
    popupHtml: function () {
      return 'Reference: ' + this.layerMeta.promoter_works_ref + '<br />' +
          'Operator: ' + this.layerMeta.promoter + '<br />' +
          'Duration: ' + this.formatDate(this.layerMeta.start_date) + ' - ' + this.formatDate(this.layerMeta.end_date) + '<br />' +
          'Description: ' + this.layerMeta.works_desc
    }

  },
  methods: {
    showItems(items) {
      const self = this;
      items.map(function (item) {
        L.geoJSON(item.geojson_wgs84, {
          onEachFeature: function (feature, layer) {
            layer.meta = item
          },
          pointToLayer(feature, latlng) {
            return L.marker(latlng, {
              icon: L.divIcon({
                html: '<i class="roadwork_icon ' + feature.properties.roadwork_icon + '"></i>',
                className: 'point-marker'
              })
            })
          }
        }).addTo(self.layerGroup)
      })
      this.layerGroup.eachLayer(function (layer) {
        layer.on('click', function (e) {
          self.popupInfoAvailable = true;
          self.popupLocation = [e.latlng.lat, e.latlng.lng];

          self.layerMeta = e.sourceTarget.meta;
          self.$nextTick(() => {
            self.$refs['roadWorkPopupLayerGroup'].mapObject.openPopup(self.popupLocation);

          })
        })
      });
      this.layerGroup.addTo(Lmap);
    },
    removeItems() {
      this.layerGroup.clearLayers();
    },
    closePopup() {
      this.popupInfoAvailable = false;
      this.layerMeta = null;
      this.popupLocation = null;
    },
    formatDate(dateString) {
      return dayjs(dateString).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>

</style>