<template>
  <div>
    <l-layer-group @close="close" v-if="tilePlanIsAvailable"
                   :latLng="[location.lat,location.lng]"
                   ref="tilePlanLayerGroup">
      <l-popup @update:visible="close" @close="close" v-if="this.tilePlanIsAvailable" ref="tilePlanPopup"
               width="300"
               :latLng="[location.lat,location.lng]" v-show="true"
               :options="{ autoClose: false, closeOnClick: false }">
        <b-tabs pills card fill content-class="mt-3" @input="input" v-model="selectedTab">
          <b-tab :title="String(feature.properties.inspireid)" v-for="feature in features"
                 class="w-100"
                 :key="feature.properties.inspireid"

          >
            <div>Inspire ID {{ feature.properties.inspireid }}</div>
            <div v-html="popupContent(feature)" class="table-window mt-3"></div>
            <div class="table-window">
              <table class="table">
                <tr>
                  <td style="white-space:nowrap" colspan=1>perimeter Details</td>
                  <td>
                    <button class="btn btn-primary btn-sm float-right text-right"
                            @click="togglePerimeterData(feature.properties.inspireid)">
                      {{ perimeterButton(feature.properties.inspireid) }}
                    </button>
                  </td>
                </tr>
                <tr v-if="currentPerimeterInspireId === feature.properties.inspireid">
                  <td style="white-space:nowrap" colspan=2>
                    <div class="text-right" v-html="perimeterHtml(feature)"></div>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </l-popup>
    </l-layer-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import * as L from "leaflet";
import {Polygon} from "leaflet";
import AreaPopup from "@/Helpers/AreaPopup";

export default {
  name: "TilePlan",
  data: () => {
    return {
      inspireIdList: [],
      currentPerimeterInspireId: null,
      polygon: null,
      selectedTab: 0,
    }
  },
  computed: {
    ...mapGetters({
      tilePlanIsAvailable: 'TilePlan/tilePlanIsAvailable',
      location: 'TilePlan/tileLocations',
      features: 'TilePlan/features',
    }),

  },
  watch: {
    tilePlanIsAvailable(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.tilePlanLayerGroup.mapObject.openPopup([this.location.lat, this.location.lng]);
          this.$refs.tilePlanLayerGroup.mapObject.getPopup().on('remove', () => {
            this.close();
          })

        });
      }
    },
    features(value) {
      if (value) {
        let smallest = Infinity;
        this.selectedTab = 0;
        let i = 0;
        const self = this;
        value.forEach((item, index) => {
          const polygon = new Polygon(item.geometry.coordinates);
          const size = self.geodesicArea(polygon.getLatLngs()[0]);
          if (size < smallest) {
            smallest = size;
            i = index
          }
          if (index === value.length - 1) {
            setTimeout(() => {
              self.selectedTab = i;
              if (self.polygon) {
                self.$refs.tilePlanLayerGroup.mapObject.removeLayer(self.polygon);
              }
              self.polygon = L.geoJSON(self.features[i])
              self.polygon.addTo(self.$refs.tilePlanLayerGroup.mapObject);
            }, 50)

          }
        })
      }
    }
  },
  methods: {
    input(index) {
      const feature = this.features[index];
      if (this.polygon) {
        this.$refs.tilePlanLayerGroup.mapObject.removeLayer(this.polygon);
      }
      this.polygon = L.geoJSON(feature)
      this.polygon.addTo(this.$refs.tilePlanLayerGroup.mapObject);
    },
    close() {
      this.$store.commit('TilePlan/setTilePlanIsAvailable', false);
      this.$store.commit('TilePlan/setLatLng', {lat: null, lng: null});
    },
    togglePerimeterData(inspireId) {
      if (inspireId === this.currentPerimeterInspireId) {
        this.currentPerimeterInspireId = null;
        return;
      }
      this.currentPerimeterInspireId = inspireId;
    },
    popupContent(feature) {

      const geometry = L.geoJSON(feature.geometry)
      return AreaPopup.getPopupContent(geometry.pm._layers[0]);
    },
    perimeterHtml(feature) {
      const polygon = L.geoJSON(feature.geometry)
      return AreaPopup.getPerimeterDetailsPolygon(polygon.pm._layers[0])
    },
    showPerimeter(inspireId) {
      return this.currentPerimeterInspireId === inspireId;
    },
    perimeterButton(inspireId) {
      if (this.showPerimeter(inspireId)) {
        return 'Hide';
      }
      return 'Show';
    },
    geodesicArea(t) {
      let e, i, o = t.length,
          n = 0,
          r = Math.PI / 180;
      if (2 < o) {
        for (var a = 0; a < o; a++) e = t[a], n += ((i = t[(a + 1) % o]).lng - e.lng) * r * (2 + Math.sin(e.lat * r) + Math.sin(i.lat * r));
        n = 6378137 * n * 6378137 / 2
      }
      return Math.abs(n)
    }
  }
}
</script>

<style scoped>

</style>