<template>
  <b-modal id="locationImagesGeoTagList" @hidden="hideLocationPhotos" centered title="Location Photos With Geo Tag">
    <div class="photo_storage">
      <b-overlay class="photo_storage_overlay" :show="this.loading" rounded="sm">

      </b-overlay>
      <b-form-file multiple
                   v-model="image"
                   accept="image/jpeg, image/png, image/gif, application/zip"
                   placeholder="Choose a file or drop it here..."
                   drop-placeholder="Drop file here..."
      >
        <template slot="file-name" slot-scope="{ names }">
          <b-badge variant="dark">{{ names[0] }}</b-badge>
          <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
            + {{ names.length - 1 }} More files
          </b-badge>
        </template>
      </b-form-file>
      <button v-if="image != null && image.length > 0" @click="uploadPhoto" class="mt-2 pull-right btn btn-success">Upload</button>
      <div class="row">
            <div v-viewer="{options, movable: false, title:[1, this.getImageTitle]}" class="images" >
          <div class="col-3 p-2 mt-3" v-for="(src,index) in this.photos" :key="index">
          <img class="img-fluid img-thumbnail"  :alt="src.comment" :data-src="src.source" :src="src.source" >
          <i @click="removeLocationImage(src.id)" class="delete_location_image fas fa-times-circle text-danger"></i>
          <i  v-b-modal.locationImage @click="selectPhoto(src)" class="edit_location_image fas fa-pen text-warning"></i>
          <i  @click="goToLocation(src)" class="go_to_location_image fas fa-map-marker text-info"></i>
        </div>
        </div>
      </div>
    </div>
    <b-modal id="locationImage"  centered title="Edit Photo">
      <div class="column">
        <b-row class="my-1">
          <b-col>
            <b-form-input  v-if="Boolean(selectedPhoto)"  v-model="selectedPhoto.comment" id="input-large" size="lg"  placeholder="photo"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <button  @click="editLocationImage(selectedPhoto.comment)" class="pull-right col-3 p-2 mt-3 btn btn-success">Submit</button>
      </template>
    </b-modal>
    <div   v-if="Boolean(progress)">
        <vue-ellipse-progress style="position:absolute;top:40%;left:40%" dot="5% blue" :size="100":progress="progress"/>
      </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import VueEllipseProgress from 'vue-ellipse-progress';
import Vue from "vue";

Vue.use(VueEllipseProgress);
export default {
  name: "GeoTagPhotoList",
  watch:{

  },
  computed:{
    ...mapGetters({
      progress: 'LocationPhotos/getProgress',
      currentLocation:'LocationPhotos/currentLocation',
      photos:'LocationPhotos/locationPhotos',
      loading:'LocationPhotos/loading'
    })
  },
  data:function(){
    return {
      options: {
        ready: () => {
          this.$viewer = this.$el.querySelector(".images").$viewer;
        },
        url: "data-src",
        toolbar: {
          oneToOne: true,
          prev: () => {
            this.$viewer.prev(true);
          },
          play: true,
          next: () => {
            this.$viewer.next(true);
          },
        }
      },

      image:[],
      selectedPhoto: null,
      comment: null
    }
  },
  methods:{
    getImageTitle(image,imagedata){
      return image.alt
    },
    uploadPhoto(){
      let formData = new FormData();
      for( var i = 0; i < this.image.length; i++ ){
        let file = this.image[i];
        formData.append('images[' + i + ']', file);
      }
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      formData.append('project_id', selectedProject.id);
      this.$store.dispatch('LocationPhotos/uploadGeoTaggedPhotos',formData)
      this.image = null;
    },
    selectPhoto(image) {
      this.selectedPhoto = image
    },
    editLocationImage(comment) {
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      this.$store.dispatch('LocationPhotos/editImage',{
        comment: comment,
        id:this.selectedPhoto.id,
        project_id: selectedProject.id
      })
      this.$bvModal.hide('locationImage');

    },
    getImagePath(image) {
      return process.env.VUE_APP_LOCATION_IMAGES_URL+image;
    },
    removeLocationImage(id){
      this.$store.dispatch('LocationPhotos/deleteImage',{
        lat:this.currentLocation[0],
        lng:this.currentLocation[1],
        id:id
      })
    },
    hideLocationPhotos() {
      this.$store.commit('LocationPhotos/setCurrentLocationsPhotos')
    },
    goToLocation(image) {
      this.$root.$emit('setCenter', {
        center: {lat: image['lat'], lng: image['lon']},
        zoom: process.env.VUE_APP_LOCATION_SEARCH_ZOOM_LEVEL
      });
    }
  }
}
</script>

<style scoped>
.photo_storage{
  min-height: 300px;
}
.photo_storage_overlay{
  top:150px;
}
.location_image{
  min-height: 64px;
  min-width: 64px;
}
.delete_location_image{
  position: absolute;
  right: -2px;
  top:-2px;
  cursor: pointer;
}

.go_to_location_image{
  position: absolute;
  right: 35px;
  top:-2px;
  cursor: pointer;
}

.edit_location_image{
  position: absolute;
  right: 15px;
  top:-2px;
  cursor: pointer;
}
.images {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
</style>