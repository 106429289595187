<template>
  <b-modal id="sites_list" title="Sites" size="lg" @show="loadSitesList">
    <b-overlay :show="isLoading">
      <b-table striped hover responsive sticky-header="400px" :items="filtered" :fields="fields" class="h-50">
        <template slot="top-row" slot-scope="{ fields }">
          <td v-for="field in fields" :key="field.key">
            <input class="form-control form-control-sm" v-if="filters[field.key]!==undefined"
                   v-model="filters[field.key]" :placeholder="field.label">
          </td>
        </template>
        <template v-slot:cell(name)="data"> <!-- `data` -->
          <a href="#" @click="goToSite(data.item)">{{ data.item.name }}</a>
        </template>
      </b-table>
    </b-overlay>

  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import L from "leaflet";

export default {
  name: "SitesList",
  data: () => {
    return {
      filters: {
        name: '',
        county: '',
        postcode: ''
      },
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'postcode',
          label: 'Postcode',
          sortable: true
        },
        {
          key: 'county',
          label: 'County',
          sortable: true
        },
        {
          key: 'latest_document.expires_at',
          label: 'Expires At',
          sortable: true,

        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'Sites/isLoading',
      sites: 'Sites/sitesList'
    }),
    filtered() {
      if (this.sites.length > 0) {
        const filtered = this.sites.filter(item => {
          return Object.keys(this.filters).every(key =>
              String(item[key].toLowerCase()).includes(this.filters[key].toLowerCase())
          );
        });
        return filtered.length > 0
            ? filtered
            : [
              Object.keys(this.sites[0]).reduce(function (obj, value) {
                obj[value] = '';
                return obj;
              }, {})
            ];
      }
    }
  },
  methods: {
    ...mapActions({
      loadSitesList: 'Sites/listSites'
    }),
    goToSite(site) {
      this.$bvModal.hide('sites_list')
      this.$store.commit('Sites/setModalSite', site.id);
      this.$bvModal.show('siteInfo')
      let center = {}
      center.zoom = 17;
      center.center = L.latLng(site.lat, site.lng)
      this.$root.$emit("setCenter", center);
    },

  }
}
</script>

<style scoped>

</style>