import CommonTools from "@/Helpers/CommonTools";
import * as L from "leaflet";
import LineChart from "@/components/LineChart";
import {mapGetters} from "vuex";

const ElevationControl = {
    components:{LineChart},
    data: function () {
        return {
            elevationSelected: false,
            propertiesPoint:null,
            point1:null,
            point2:null,
            marker1:null,
            marker2:null,
            propertyLocationMarker:null,
            line:null,
            point1Loader:false,
            point2Loader:false,

        }
    },
    computed:{
        ...mapGetters({
            lineColour:'Elevation/lineColour',
            isInPointSelectMode:'Elevation/isInPointSelectMode',
        })
    },
    watch: {
        lineColour:function(value)
        {

            this.line.setStyle({
                color: value
            });
        },
        elevationSelected:function(value)
        {
            this.$store.commit('Elevation/setElevationShown',value);
            if(!value)
            {
                this.point1 = null;
                this.point2 = null;
                this.marker1 = null;
                this.marker2 =  null;
                this.line = null;
            }


        },
        point1:function(value){
          if(value && this.point2!==null)
          {
              this.line = L.polyline([[value.lat,value.lng],[this.point2.lat,this.point2.lng]]
              ).addTo(window.Lmap);
              this.line.setStyle({
                  color: this.$store.state.Elevation.lineColour
              });
              this.getElevationForLineString()

          }
        },
        marker2:function (value){
            if (value!==null)
            {
                this.$store.commit('Elevation/setElevationLoader',true)
                this.line = L.polyline([[this.point1.lat,this.point1.lng],[this.point2.lat,this.point2.lng]]
                    ).addTo(window.Lmap);
                this.line.setStyle({
                    color: this.$store.state.Elevation.lineColour
                });
                this.getElevationForLineString()
            }
            else{
                this.line = null;
            }
        },
        isInPointSelectMode:function(value){
            if(!value)
            {
                this.propertyLocationMarker.remove()
            }
        }
    },
    methods: {
        addElevationControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'elevation',
                block: 'custom',
                title: 'Get Elevation',
                actions: [],
                className: 'fas fa-route elevation-control',
                onClick: function (e) {
                    self.elevationSelected = !self.elevationSelected;
                },
                toggle: true

            })
            window.Lmap.on('click', function (e) {
                if (self.elevationSelected) {
                    if(self.isInPointSelectMode)
                    {
                        self.propertiesPoint = e.latlng;
                        self.propertyLocationMarker = new L.Marker(self.propertiesPoint).addTo(window.Lmap)
                        self.$store.commit('Elevation/setPropertiesPointLocation',self.propertiesPoint);
                        return;
                    }
                    if(self.point1 === null)
                    {
                        self.$store.commit('Elevation/setPoint1Loader',true)
                        self.getElevation(e.latlng).then(response=>{
                            self.$store.commit('Elevation/setPoint1Loader',false)
                            if(response.data.elevation!==false)
                            {
                                self.point1 = e.latlng;
                                self.marker1 = new L.Marker(self.point1).addTo(window.Lmap)
                                self.$store.commit('Elevation/setPoint1Location',e.latlng)
                                self.$store.commit('Elevation/setPoint1Elevation',response.data.elevation)
                            }
                            else{
                                self.$swal('No Elevation','There is no height data for this point','error');
                            }

                        });

                    }
                    else if(self.point2 === null)
                    {
                        self.$store.commit('Elevation/setPoint2Loader',true)
                        self.getElevation(e.latlng).then(response=>{
                            self.$store.commit('Elevation/setPoint2Loader',false)
                            if(response.data.elevation!==false)
                            {
                                self.point2 = e.latlng;
                                self.marker2 = new L.Marker(self.point2).addTo(window.Lmap)
                                self.$store.commit('Elevation/setPoint2Location',e.latlng)
                                self.$store.commit('Elevation/setPoint2Elevation',response.data.elevation)
                            }
                            else{
                                self.$swal('No Elevation','There is no height data for this point','error');
                            }

                        });
                    }
                    else{
                        return;
                    }
                }
            })
        },
        getElevation(latLng) {
            return axios.get(process.env.VUE_APP_API_URL+'/api/elevation/'+latLng.lng+'/'+latLng.lat)
        },
        getElevationForLineString() {
            this.$store.commit('Elevation/setElevationLoader',true)
            axios.get(process.env.VUE_APP_API_URL+'/api/elevation_for_line/'+btoa(JSON.stringify(this.line.toGeoJSON()))).then(response=>{
                if(response.data.length>0)
                {
                    this.$store.commit('Elevation/setElevations',response.data)
                }
                this.$store.commit('Elevation/setElevationLoader',false)
            });
            return true
        }
    },
    created() {
        const self = this;
        this.$root.$on('resetPoint1',function (){
            if(self.marker1!==undefined)
            {
                self.point1 = null;
                self.marker1.removeFrom(window.Lmap);
                self.$store.commit('Elevation/setPoint1Location',{lat:0,lng:0})
                self.$store.commit('Elevation/setPoint1Elevation',0)

            }
            if(self.line)
            {
                self.line.removeFrom(window.Lmap);
            }
        })
        this.$root.$on('resetPoint2',function (){
            if(self.marker2!==undefined)
            {
                self.point2 = null;
                self.marker2.removeFrom(window.Lmap);
                self.$store.commit('Elevation/setPoint2Location',{lat:0,lng:0})
                self.$store.commit('Elevation/setPoint2Elevation',0)

            }
            if(self.line)
            {
                self.line.removeFrom(window.Lmap);
            }
        })
    }
};
export default ElevationControl;