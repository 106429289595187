<template>
  <b-modal title="Splicing Dome" ok-title="Save" cancel-title="Reset" @ok="onSubmit($event)" @cancel="onReset()"
           @show="onReset"

           id="SplicingDome" @close="close()" body-class="object_type_modal_body">
    <div>
      <b-form>
        <b-form-group id="input-group-1" label="Object name:" label-for="object_name">
          <b-form-input
              id="object_name"
              v-model="object.name"
              placeholder="Enter object name"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-7" label="Ports Count" label-for="object_ports">
          <b-form-input type="number" id="object_ports" v-model="object.ports" min="0" step="1" required/>

        </b-form-group>
        <b-form-group id="input-group-7" label="Color" label-for="object_color">
          <v-swatches v-model="object.color" popover-x="left" id="object_color"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
      </b-form>
    </div>

  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";


export default {
  components: {VSwatches},
  name: "SplicingDome",
  data() {
    return {
      object: {
        name: '',
        color: null,
        ports: 0,
        code: ObjectTypesEnum.OT_SPLICING_DOME.toLowerCase()
      },
    }
  },
  methods: {
    close() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)
    },
    onSubmit(event) {
      const self = this
      event.preventDefault()
      this.$store.dispatch('ObjectTypes/store', this.object).then(() => {
        self.$bvModal.hide("SplicingDome");
      })
    },
    onReset() {
      this.object.name = ''
      this.object.ports = 0
      this.object.color = null
      // Trick to reset/clear native browser form validation state
    }
  },
  created() {
    const self = this
    this.$root.$on('OT_SPLICING_DOME', function () {
      self.$bvModal.hide("create_object_type_modal");
      self.$bvModal.show("SplicingDome");
    })
  }
}
</script>

<style scoped>
</style>