import axios from "axios";

class ObjectTypes {
    static getChilds(objectId) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/childs/' + objectId);
    }

    static getObjectsByTypeCode(objectTypeCode) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + objectTypeCode.toLowerCase());
    }

    static getObjectTypeInfo(objectTypeCode, id) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + objectTypeCode.toLowerCase() + '/' + id);
    }

    static addChildObject(projectId, data) {

        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/add_child_object', data);
    }
}

export default ObjectTypes;