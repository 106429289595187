import axios from "axios";
var PatchPanel = /** @class */ (function () {
    function PatchPanel() {
    }
    PatchPanel.prototype.getApiUrl = function () {
        return process.env.VUE_APP_NETWORKING_API_URL + '/api';
    };
    PatchPanel.prototype.getConnections = function (patchPanelId) {
        return axios.get(this.getApiUrl() + '/object/' + patchPanelId + '/ot_patch_panel/connections');
    };
    PatchPanel.prototype.storeFibreConnection = function (data) {
        return axios.post(this.getApiUrl() + '/ot_patch_panel/connections/fibre_cable_to_port', data);
    };
    PatchPanel.prototype.storeFibreConnectionBulk = function (data) {
        return axios.post(this.getApiUrl() + '/ot_patch_panel/connections/fibre_cable_to_port_bulk', data);
    };
    PatchPanel.prototype.disconnectPort = function (patchPanelId, portId) {
        return axios.delete(this.getApiUrl() + '/object/' + patchPanelId + '/ot_patch_panel/disconnect/port/' + portId);
    };
    return PatchPanel;
}());
export default PatchPanel;
