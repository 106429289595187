<template>
  <div>
    <b-modal id="OpenreachPiaWhereAbout" :title="modalTitle" @shown="loadNois" @hidden="formReset" :ok-title="okTitle"
             @ok="onSubmitForm">
      <b-overlay :show="showOverlay">
        <b-form ref="whereAboutForm">

          <b-form-group label="Activities">
            <multiselect v-model="form.activities" :options="options.activities" :searchable="false"
                         :class="{'is-invalid': this.v$.form.activities.$error}"
                         :close-on-select="false" :show-labels="false" :multiple="true"></multiselect>
            <b-form-invalid-feedback v-if="this.v$.form.activities.$error">
              <p v-for="message in this.v$.form.activities.$errors">{{ message.$message }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="PIA NOI Number" v-if="isCreate">
            <multiselect v-model="form.noi" :options="options.nois" :searchable="true"
                         :class="{'is-invalid': this.v$.form.noi.$error}"
                         :close-on-select="true" :show-labels="false" :multiple="false" track-by="name"
                         label="name"></multiselect>
            <b-form-invalid-feedback v-if="this.v$.form.noi.$error">
              <p v-for="message in this.v$.form.noi.$errors">{{ message.$message }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex justify-content-between">
            <b-form-group label="Start DateTime">
              <date-picker v-model="form.startDate" type="datetime" value-type="YYYY-MM-DD H:mm:ss"
                           format="YYYY-MM-DD H:mm:ss"
                           :disabled-date="disabledStartDate"
                           :class="{'is-invalid': this.v$.form.startDate.$error}"></date-picker>
              <b-form-invalid-feedback v-if="this.v$.form.startDate.$error">
                <p v-for="message in this.v$.form.startDate.$errors">{{ message.$message }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="End DateTime">
              <date-picker :disabled="form.startDate==null" v-model="form.endDate" value-type="YYYY-MM-DD H:mm:ss"
                           format="YYYY-MM-DD H:mm:ss"
                           type="datetime"
                           :disabled-date="disabledEndDate"
                           :class="{'is-invalid': this.v$.form.endDate.$error}"></date-picker>
              <b-form-invalid-feedback v-if="this.v$.form.endDate.$error">
                <p v-for="message in this.v$.form.endDate.$errors">{{ message.$message }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="d-flex justify-content-between">
            <b-form-group label="Street Name">
              <b-input v-model="form.streetName" :class="{'is-invalid': this.v$.form.streetName.$error}"></b-input>
              <b-form-invalid-feedback v-if="this.v$.form.streetName.$error">
                <p v-for="message in this.v$.form.streetName.$errors">{{ message.$message }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Postcode">
              <b-input v-model="form.postCode" :class="{'is-invalid': this.v$.form.postCode.$error}"></b-input>
              <b-form-invalid-feedback v-if="this.v$.form.postCode.$error">
                <p v-for="message in this.v$.form.postCode.$errors">{{ message.$message }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <b-form-group label="Permit Number">
            <b-input v-model="form.permitNumber"></b-input>
          </b-form-group>
          <b-form-group label="Emergency Reason">
            <b-input v-model="form.emergencyReason"></b-input>
          </b-form-group>
          <b-form-group>
            <table class="table-striped table">
              <b-form-group>
                <label>Related Party</label>
              </b-form-group>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>

                <tr>
                  <td>{{ this.form.relatedParty.name }}</td>
                  <td>{{ this.form.relatedParty.phone }}</td>
                  <td><i class="fas fa-pen cursor-pointer" @click="$bvModal.show('edit_agent_modal_whereabout')"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </table>
          </b-form-group>
        </b-form>
      </b-overlay>
    </b-modal>
    <b-modal id="edit_agent_modal_whereabout" title="Edit Related Party">
      <b-form>
        <b-form-group>
          <b-form-input placeholder="Name" v-model="form.relatedParty.name"/>
        </b-form-group>
        <b-form-group>
          <b-form-input placeholder="Phone Number" v-model="form.relatedParty.phone"/>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import {mapGetters} from "vuex";
import {minLength, required} from '@vuelidate/validators'
import useVuelidate from "@vuelidate/core";
import Multiselect from "vue-multiselect";
import WhereAboutAPIClient from "@/Api/Openreach/WhereAboutAPIClient";

export default {
  components: {DatePicker, Multiselect},
  name: "OpenreachWhereAbouts",
  data() {
    return {
      showOverlay: false,
      options: {
        activities: ["Survey - Underground", "Survey - Overhead"],
        nois: []
      },
      form: {
        activities: [],
        noi: null,
        startDate: null,
        endDate: null,
        streetName: null,
        postCode: null,
        permitNumber: null,
        emergencyReason: null,
        relatedParty: {
          name: null,
          phone: null,
        },
        // location: {lat: this.locationInfo.latitude, lng: this.locationInfo.longitude},

      }
    }
  },
  watch: {
    startDate(value) {
      // this.form.endDate = null;
    },
    updatableWhereAbout(value) {
      if (value !== null && value.id !== undefined) {
        this.$bvModal.show('OpenreachPiaWhereAbout');
      }
    }
  },
  validations() {
    return {
      form: {
        activities: {required},
        noi: {required},
        startDate: {required},
        endDate: {required},
        streetName: {required, minLength: minLength(3)},
        postCode: {required, minLength: minLength(5)}
      }
    }
  },
  computed: {
    ...mapGetters({
      locationInfo: 'Search/getLocationInfo',
      updatableWhereAbout: "Openreach/updatableWhereAbout"
    }),
    startDate() {
      return this.form.startDate;
    },
    modalTitle() {
      let title = 'Add Openreach Whereabout'
      if (!this.isCreate) {
        title = 'Update Openreach Whereabout';
      }
      return title;
    },
    okTitle() {
      let title = 'Add'
      if (!this.isCreate) {
        title = 'Update';
      }
      return title;
    },
    isCreate() {
      return this.updatableWhereAbout.id === undefined;
    },
    selectedProject() {
      return this.$store.state.Projects.selected_project.id
    }

  },
  setup: () => ({v$: useVuelidate()}),
  methods: {
    disabledStartDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let maxDate = new Date(today);
      maxDate.setDate(maxDate.getDate() + 1);
      return date <= today || date > maxDate;
    },
    disabledEndDate(date) {
      const startDate = new Date(this.form.startDate);
      const endDate = new Date(date);
      let maxDate = new Date(startDate);
      maxDate.setDate(maxDate.getDate() + 1);
      return endDate <= startDate || endDate > maxDate;
    },
    async onSubmitForm(e) {
      e.preventDefault();
      const result = await this.v$.$validate()
      if (result) {
        let data = this.form;
        data.location = {};
        data.location.latitude = this.locationInfo.latitude;
        data.location.longitude = this.locationInfo.longitude;
        this.showOverlay = true;
        if (!this.isCreate) {
          data.location = null;
          data.id = this.updatableWhereAbout.id;
        }
        this.$store.dispatch('Openreach/saveWhereAbout', data).then(() => {
          let message = 'Where about created successfully';
          if (!this.isCreate) {
            message = 'Where about updated successfully';
          }
          this.$swal('Success', message, 'success').then(() => {
            this.$bvModal.hide('OpenreachPiaWhereAbout');
          });

        }).finally(() => {
          this.showOverlay = false;
        });
      }
    },
    formReset() {
      console.log("Resetting")
      this.$store.commit('Openreach/setUpdatableWhereAbout', {});
      this.form = {
        id: null,
        activities: [],
        noi: null,
        startDate: null,
        endDate: null,
        streetName: null,
        postCode: null,
        permitNumber: null,
        emergencyReason: null,
        relatedParty: {
          name: null,
          phone: null,
        }
      };
    },
    loadNois() {
      this.showOverlay = true
      this.form.streetName = this.locationInfo.street;
      this.form.postCode = this.locationInfo.postcode;
      this.$store.dispatch('Openreach/getOpenreachNOIItems', {project_id: this.selectedProject}).then(response => {
        this.showOverlay = false;
        this.form.noi = null;
        this.options.nois = [];
        const self = this;
        response.data.forEach(function (value) {
          self.options.nois.push({
            value: value.id,
            name: `${value.reference} (${value.openreach_id})`,
            noi: value.openreach_id
          })
        });
        if (!this.isCreate) {
          this.showOverlay = true
          WhereAboutAPIClient.get(this.updatableWhereAbout.id).then(data => {
            this.form.postCode = data.data.relatedPlace.postcode;
            this.form.streetName = data.data.relatedPlace.streetName;
            this.form.activities = data.data.activity;
            this.form.emergencyReason = data.data.emergencyWheraboutsReason === 'null' ? null : data.data.emergencyWheraboutsReason
            this.form.permitNumber = data.data.emergencyWheraboutsReason === 'null' ? null : data.data.permitNumber
            this.form.noi = this.options.nois.find(item => item.noi === data.data.relatedEntity['0'].id);

            let startDate = data.data.validFor.startDateTime.replace('T', ' ');
            this.form.endDate = data.data.validFor.endDateTime.replace('T', ' ');
            this.form.startDate = startDate;
            this.form.relatedParty = {
              name: data.data.relatedParty['1'].name,
              phone: data.data.relatedParty['1'].telephoneNumber

            };
          }).finally(() => {
            this.showOverlay = false;
          });
        }
      });
      this.form.relatedParty = {
        name: this.$store.state.Profile.user.first_name + ' ' + this.$store.state.Profile.user.last_name,
        phone: this.$store.state.Profile.user.phone
      }
    }
  }
}
</script>

<style scoped>

</style>