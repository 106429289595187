<template>
  <div>
    <b-modal centered ok-only title="Chamber connections"
             id="DuctChamberConnection"
    >
      <div v-if="this.connections.start!==null">Start Chamber: O-{{ this.connections.start.id }}</div>
      <div v-if="this.connections.end!==null">End Chamber: O-{{ this.connections.end.id }}</div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "DuctChamberConnection",
  data: () => {
    return {
      connections: {
        start: null,
        end: null,
      }
    }
  },
  created() {
    const self = this;
    this.$root.$on('showDuctChamberConnectionModal', function (data) {
      self.connections.start = data.start;
      self.connections.end = data.end;
      // @todo suspiciously deleted chamber modal
      if (data.start !== null || data.end !== null) {
        this.$bvModal.show('DuctChamberConnection');
      }
    })
  }
}
</script>

<style scoped>

</style>