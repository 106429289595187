<template>
  <div>
    <b-modal centered id="object_type_list" @close="closeObjectTypeModal" @cancel="closeObjectTypeModal"
             @show="getObjectTypes" scrollable
             @ok="closeObjectTypeModal" no-close-on-esc no-close-on-backdrop ok-only ok-title="Close"
             title="Object Types" size="xl">
      <b-overlay :show="typeListOverlay" rounded="sm">
        <b-tabs pills card fill content-class="mt-3">
          <b-tab :title=name v-for="(item,name) in objectTypes" :key="name"
                 v-if="!isInSelectMode || permissions[name]['is_direct'] === 1">

            <b-table :fields="objectTypes[name]['fields']" :items="objectTypes[name]['data']">
              <template #cell(color)="data">
                <span class='object-color' :style="{backgroundColor: data.item.color}"></span>
              </template>
              <template #cell(actions)="data">
                <div class="float-right" v-if="!isInSelectMode">
                  <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit === 1" class="mr-2"
                            size="sm" @click="edit(JSON.parse(JSON.stringify(data.item)))" variant="outline-info">Edit
                  </b-button>
                  <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit === 1" size="sm"
                            class="mr-2" @click="remove(data.item, data.index)" variant="outline-danger">Remove
                  </b-button>
                  <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit === 1" size="sm"
                            @click="showPricing(data.item)" variant="outline-success">Pricing
                  </b-button>
                </div>
                <div class="float-right" v-else>
                  <b-button class="mr-2" size="sm" @click="setSelectedObjectType(data.item)" variant="outline-info">
                    Select
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-button class="float-right" size="sm" variant="success" @click="createObject(item, name)"><i
                class="fas fa-plus"/> Create New {{
                name
              }}
            </b-button>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-modal>
    <b-modal id="edit_object_type_modal" title="Edit Object Type" centered @ok="update" ok-title="save"
             @hidden="closeEditModal()" @close="closeEditModal()" @cancel="closeEditModal()"
             body-class="object_type_modal_body"
             no-close-on-backdrop>
      <b-overlay :show="showOverlayForEditObjectType" rounded="sm">
        <div v-for="field in editFields">
          <b-form-group :label-for="'new_object_' + field.key" :label="field.label">
            <div v-if="field.key === 'port_direction'">
              <b-form-group id="input-group-5" label-for="input-5">
                <b-form-select v-model="editObjectTypeModel[field.key]" :options="directions"></b-form-select>
              </b-form-group>
            </div>
            <div v-else>
              <b-input :id="'new_object_' + field.key" v-model="editObjectTypeModel[field.key]"></b-input>
            </div>
          </b-form-group>
        </div>
        <b-form-group label-for="new_object_color" label="Object Type Color">
          <v-swatches v-model="editObjectTypeModel.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
      </b-overlay>
    </b-modal>
    <b-modal no-close-on-esc size="lg" id="object_pricing" ref="object_pricing" ok-title="Save"
             title="Object Type Surface Prices"
             @ok="saveObjectTypePrices" centered no-close-on-backdrop>
      <b-overlay :show="showOverlayForObjectTypePricing" rounded="sm">
        <div class="overflow-auto" style="height: 500px;">
          <form @submit.prevent="saveObjectTypePrices" ref="object_price_form">
            <div class="form-group row" v-for="(price,index) in this.surfacePrices" :key="price.surface_code">
              <label class="col-sm-9 surface_name col-form-label">{{ price.name }}</label>
              <div class="col-sm-3">
                <b-input-group size="sm" prepend="£" append="p/item">
                  <b-form-input type="number" step="0.01" v-model="surfacePrices[index].price"></b-form-input>
                </b-input-group>

              </div>

            </div>
          </form>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import {mapGetters} from "vuex";
import axios from "axios";
import Vue from "vue";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  components: {VSwatches},
  name: "ObjectTypeList",
  data() {
    return {
      directions: ['Left to Right', 'Top to Bottom'],
      objectCode: null,
      selected: null,
      showOverlay: false,
      showOverlayForEditObjectType: false,
      showOverlayForAddObjectType: false,
      showOverlayForObjectTypePricing: false,
      surfacePrices: [],
      selectedObjectType: null,
      editObjectTypeModel: {
        id: 0,
        name: null,
        color: null,
      },
      objectTypeModel: {
        name: '',
        color: ''
      },
      editFields: [],
      fields: [{key: 'name', label: 'Name'}, {key: 'color', label: 'Color'}, {
        key: 'actions',
        name: 'Actions',
        class: 'text-right'
      }]
    }
  },
  methods: {
    createObject(objectItem, name) {
      const code = this.items.filter(item => item.name === name)[0].code;
      this.$store.commit('ObjectTypes/setTypeListOverlay', true)
      this.$root.$emit(code)
    },
    onChange(type) {
      this.$store.commit('ObjectTypes/setTypeListOverlay', true)
      this.$root.$emit(type.code)
    },
    closeObjectTypeModal() {
      const self = this;
      Vue.nextTick(function () {
        self.map.pm.disableDraw('ObjectMarker')
        self.$store.commit("ObjectMarker/setIsInSelectMode", false);
      });
    },
    closeEditModal() {
      this.editFields = []
      this.$bvModal.hide('edit_object_type_modal')
    },
    edit(objectType) {
      this.editObjectTypeModel = objectType
      Object.keys(objectType).forEach(key => {
        if (objectType === ObjectTypesEnum.OT_PATCH_PANEL.toLowerCase() && key === 'id') {
          this.editFields.id = 2;
        }
        if (!['id', 'code', 'color', 'is_final', 'default_type_id'].includes(key)) {
          this.editFields.push({key: key, label: ' ' + key.replace(/(^|_)./g, s => ' ' + s.slice(-1).toUpperCase())})
        }
      });
      this.$bvModal.show('edit_object_type_modal');
    },
    update() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', true)
      const self = this
      this.$store.dispatch('ObjectTypes/update', this.editObjectTypeModel).then(() => {
        this.$store.dispatch('ObjectTypes/getObjectTypes', this.editObjectTypeModel.code).then(response => {
          this.$store.commit('ObjectTypes/setTypeListOverlay', false)
          // self.showOverlayForEditObjectType = false;
          self.editObjectTypeModel = false
          self.editFields = []
          self.$bvModal.hide('edit_object_type_modal');
        })
      }).catch(() => {
        this.showOverlayForEditObjectType = false;
      }).finally(() => {
        this.$store.commit('ObjectTypes/setTypeListOverlay', false)
      })

    },
    remove(objectType, index) {
      const self = this
      self.$store.commit('ObjectTypes/setTypeListOverlay', true)
      this.$store.dispatch('ObjectTypes/delete', objectType).then(() => {
        self.$store.dispatch('ObjectTypes/getObjectTypes')
        self.$store.commit('ObjectTypes/setTypeListOverlay', false)
      }).catch(() => {
        self.$store.commit('ObjectTypes/setTypeListOverlay', false)
      });
    },
    setSelectedObjectType(objectMarker) {
      this.$store.commit("ObjectMarker/setSelectedObjectMarker", objectMarker);
      this.$store.commit("ObjectMarker/setSelectedObjectCode", objectMarker.code);
      this.$bvModal.hide("object_type_list");
      this.$store.commit("ObjectMarker/setIsInSelectMode", false);
    },
    showPricing(type) {
      this.selectedObjectType = type;
      const self = this;
      this.showOverlayForObjectTypePricing = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/type/' + type.code.toLowerCase() + '/' + type.id + '/price').then(response => {
        self.showOverlayForObjectTypePricing = false;
        self.surfacePrices = response.data.data;
      });
      this.$bvModal.show('object_pricing')
    },
    saveObjectTypePrices(e) {
      e.preventDefault();
      const self = this;
      self.showOverlayForObjectTypePricing = true;
      axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/type/' + this.selectedObjectType.code.toLowerCase() + '/' + this.selectedObjectType.id + '/price', this.surfacePrices).then(response => {
        self.showOverlayForObjectTypePricing = false;
        this.$bvModal.hide('object_pricing')
      }).catch(error => {
        this.$swal("Error", "Error while saving prices", "warning").then(() => {
          self.showOverlayForObjectTypePricing = false;
        })
      });
    },
    getObjectTypes() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', true)

      this.$store.dispatch('ObjectTypes/getObjectTypes')
          .finally(() => {
            this.$store.commit('ObjectTypes/setTypeListOverlay', false)
          })
      this.$store.dispatch('ObjectTypes/getPermissions')
      this.$store.dispatch('ObjectTypes/list')
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.state.ObjectTypes.defaultObjectTypes;
      }
    },
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
    ...mapGetters({
      isInSelectMode: "ObjectMarker/isInSelectMode",
      objectTypes: "ObjectTypes/getObjectTypes",
      typeListOverlay: "ObjectTypes/getTypeListOverlay",
      permissions: "ObjectTypes/getPermissions"
    }),
  },
  watch: {
    objectTypes() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)
    }
  },


}
</script>

<style>
.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}

.object_type_modal_body {
  overflow: visible !important;
}
</style>