const PhotoLocationToolbar = {
    watch: {
        imageGroupsShown(value) {
            const controls = document.getElementsByClassName('leaflet-right');
            if (value) {
                controls[0].style.right = '350px';
            } else {
                controls[0].style.right = '0';
            }
        },
    },
    computed: {
        imageGroupsShown: {
            get: function () {
                return this.$store.state.State.imageGroupsShown;
            },
            set(value) {
                this.$store.commit("State/setImageGroupsShown", value, {root: true});
            }
        }
    },
    methods: {
        addImageGroupControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'image_groups',
                block: 'options',
                title: 'Photo Groups',
                actions: [],
                className: 'fas fa fa-camera project_info-control',
                onClick: function (e) {
                    self.imageGroupsShown = !self.imageGroupsShown;
                },
                toggle: true

            })
        },
    }
}
export default PhotoLocationToolbar;