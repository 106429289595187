const TextColor = {
    getRGB: function (c) {
        return parseInt(c, 16) || c
    },

    getsRGB: function (c) {
        return this.getRGB(c) / 255 <= 0.03928
            ? this.getRGB(c) / 255 / 12.92
            : Math.pow((this.getRGB(c) / 255 + 0.055) / 1.055, 2.4)
    },

    getLuminance: function (hexColor) {
        return (
            0.2126 * this.getsRGB(hexColor.substr(1, 2)) +
            0.7152 * this.getsRGB(hexColor.substr(3, 2)) +
            0.0722 * this.getsRGB(hexColor.substr(-2))
        )
    },

    getContrast: function (f, b) {
        const L1 = this.getLuminance(f)
        const L2 = this.getLuminance(b)
        return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
    },

    getTextColor: function (bgColor) {
        if (!bgColor) {
            bgColor = '#ffffff';
        }
        const whiteContrast = this.getContrast(bgColor, '#ffffff')
        const blackContrast = this.getContrast(bgColor, '#000000')

        return whiteContrast > blackContrast ? '#ffffff' : '#000000'
    }
}
export default TextColor;