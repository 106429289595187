<template>

  <div style="height: 100%; width: 100%;">
    <div class="header-toolbar d-flex justify-content-center">

    </div>
    <div v-if="this.globalLoader" ref="loader" class="loader">
      <div class="loader_image"><img alt="loader" src="/assets/img/loader.gif" width="64"></div>
      <div class="loader_text">{{ this.globalLoaderMessage }}</div>
    </div>
    <div v-if="this.globalHelperWindow && this.mergeDuctsActive" ref="helper_window" class="helper_window">
      <div class="helper_window_text">{{ this.globalHelperWindowMessage }}</div>
    </div>
    <l-map ref="map"
           id="map"
           v-if="showMap"
           @ready="mapReady"
           @contextmenu="contextMenu"
           :center="this.getCenter"
           :options="mapOptions"
           @update:bounds="boundsUpdated">
      <PolygonPopup></PolygonPopup>
      <TilePlan></TilePlan>
      <OpenreachDuctsAndStructuresPopup></OpenreachDuctsAndStructuresPopup>
      <LocationInfoPopup></LocationInfoPopup>
      <l-tile-layer @load="tileLoadEnd" @tileloadstart="tileLoadStart" :options="mapOptions"
                    :zIndex="1"
                    v-if="url!==undefined && url.length>0 && !showVectorLayer"
                    :url="url"/>
      <l-wms-tile-layer @load="tileLoadEnd" @tileloadstart="tileLoadStart"
                        v-if="showVectorLayer && activeMap.wmsUrl!==undefined  && activeMap.wmsUrl!==null"
                        :options="{minZoom: this.minZoom,maxZoom:this.maxZoom}"
                        :base-url="activeMap.wmsUrl"
                        :layers="activeMap.wmsLayerName"
                        :visible="true"
                        :name="activeMap.name"
                        format="image/png"
                        :tileSize="256"
                        :zIndex="2"
                        :transparent="true"
                        layer-type="base">
      </l-wms-tile-layer>

      <l-wms-tile-layer ref="postcodes_layer" @load="tileLoadEnd" @tileloadstart="tileLoadStart" @remove="tileRemoved"
                        v-if="postcodeSearchIsActive && postcode.length>0"
                        :options="{minZoom: this.minZoom,maxZoom:this.maxZoom,viewparams:postcodeViewParams}"
                        :fake="fake"
                        :viewparams="postcodeViewParams"
                        :base-url="wmsUrl"
                        layers="main:postcode_search"
                        :visible="true"
                        name="postcode_search"
                        format="image/png"
                        :tileSize="256"
                        :transparent="true"
                        :opacity="0.4"
                        :zIndex="3"
                        layer-type="base">
      </l-wms-tile-layer>
      <SubLayersContainer></SubLayersContainer>
      <PropertyMarkersGroup></PropertyMarkersGroup>
      <CustomPropertyMarkersGroup></CustomPropertyMarkersGroup>
      <LocationPhotoMarkerGroup></LocationPhotoMarkerGroup>
      <PatchPanel></PatchPanel>
      <Chamber></Chamber>
      <StreetCabinet></StreetCabinet>
      <Pole></Pole>
      <Splitter></Splitter>
      <SplicingDome></SplicingDome>
      <RoadWorks/>
      <SitesOnLocationMarkers></SitesOnLocationMarkers>
      <StaticPopup/>
    </l-map>
    <UprnInfo></UprnInfo>
    <CustomUprnInfo></CustomUprnInfo>
    <Buildings></Buildings>
    <Customize></Customize>
    <SiteInfoModal/>
  </div>
</template>

<script>
import {LMap, LTileLayer} from "vue2-leaflet";
import {mapActions, mapGetters} from 'vuex'
import SubLayersContainer from "@/components/mapcomponents/SubLayersContainer";
import PropertyMarkersGroup from "@/components/mapcomponents/PropertyMarkersGroup";
import UprnInfo from "@/components/mapcomponents/UprnInfo";
import Loader from "@/Mixins/Loader";
import Draw from "@/Mixins/Draw/Draw.js";
import 'leaflet-pegman';
import L from "leaflet";
import vue from "vue";
import Buildings from "@/components/modals/Buildings";
import CustomPropertyMarkersGroup from "@/components/mapcomponents/CustomPropertyMarkersGroup";
import CommonTools from "@/Helpers/CommonTools";
import Customize from "@/components/modals/Polygon/Customize";
import CustomUprnInfo from "@/components/Popups/CustomUprnInfo"
import LocationInfoPopup from "@/components/Popups/LocationInfoPopup"
import PolygonPopup from "@/components/Popups/PolygonPopup";
import LocationPhotoMarkerGroup from "@/components/mapcomponents/LocationPhotoMarkerGroup";
import RoadWorks from "@/components/mapcomponents/RoadWorks";
import HelperWindow from "../Mixins/HelperWindow";
import "./../assets/js/libs/DownloadMapImage/Control.DownloadMapImage";
import SitesOnLocationMarkers from "@/components/mapcomponents/SitesOnLocationMarkers";
import SiteInfoModal from "@/components/modals/Site/SiteInfoModal";
import PatchPanel from "./modals/Objects/Types/PatchPanel";
import Chamber from "./modals/Objects/Types/Chamber";
import Pole from "./modals/Objects/Types/Pole";
import SplicingDome from "./modals/Objects/Types/SplicingDome";
import StreetCabinet from "./modals/Objects/Types/StreetCabinet";

import "./../assets/js/libs/Location/Control.PCLocation";
import Splitter from "@/components/modals/Objects/Types/Splitter.vue";
import TilePlan from "@/components/Popups/TilePlan.vue";
import StaticPopup from "@/components/Popups/StaticPopup.vue";
import OpenreachDuctsAndStructuresPopup from "@/components/Popups/OpenreachDuctsAndStructuresPopup.vue";

export default {
  name: "Map",
  metaInfo: {
    title: process.env.VUE_APP_TITLE
  },
  watch: {
    heatMap(value) {

      if (this.heatMapObject !== null) {
        this.heatMapObject.remove();
        this.heatMapObject = null;
      }
      if (value.points !== undefined) {
        this.heatMapObject = L.heatLayer(value.points, {
          radius: 25,
          blur: 15,
          max: value.max,
          gradient: {0.9: 'green', 0.95: 'orange', 1: 'red'}
        });
        this.heatMapObject.addTo(this.map);
      }
    },
    latlngs(items) {
      let self = this
      if (items.length > 0) {
        if (this.$refs.map != undefined) {
          this.$refs.map.mapObject.fitBounds(items, {
            maxZoom: self.maxZoom
          });
        }
      }
    },
    postcodeViewParams(value) {
      this.fake = Date.now();
      if (this.$refs['postcodes_layer'] != undefined) {
        this.$refs['postcodes_layer'].mapObject.setParams({viewparams: value});
      }
    },
    mapIsReady(state) {
      const self = this;
      if (state === true) {
        this.interval = setInterval(function () {
          // check token
          self.$store.dispatch('Auth/checkUser').catch(error => {
            if ({...error}.response.status == 401) {
              self.$swal({
                title: 'This account has logged in elsewhere.',
                text: 'Each account can only be active in one location at once.',
                icon: 'info',
                timer: 4000,
                buttons: false,
              }).then(() => {
                self.$root.$emit('logout');
              })
            }
            if ({...error}.response.status == 403) {
              self.$swal({
                title: 'Account Disabled.',
                text: 'A user with this email address is already registered but disabled. Please contact support.',
                icon: 'info',
                timer: 4000,
                buttons: false,
              }).then(() => {
                self.$root.$emit('logout');
              })
            }
          });
        }, 10000);
      } else {
        clearInterval(this.interval)
      }
      if (state === true && this.mapIsReady === true) {

      }
    },
    selectedProject(state) {
      if (this.$store.state["Auth/isLoggedIn"]) {
        this.$store.dispatch("Search/searchCustomPropertiesInDistance", {center: this.$store.state["State/center"]}, {root: true});
      }
    }
  },
  data() {
    return {
      firstRun: true,
      interval: null,
      fake: Date.now(),
      showMap: true,
      mapIsReady: false,
      heatMapObject: null,
      currentBounds: null,
      locationControl: null,
      followMeControl: null,
      silent: false,
    };
  },
  mixins: [Loader, Draw, HelperWindow],

  methods: {
    ...mapActions({}),
    mapReady(map) {
      map.options.bounceAtZoomLimits = false;
      map.options.preferCanvas = false;
      map.setZoom(this.currentZoom)
      const self = this;
      this.map = map;
      window.Lmap = map;
      this.$store.commit('Maps/setMapLoaded', true);
      this.registerControls(map);
      this.mapIsReady = true;
      vue.prototype.map = map;
      map.on('silentEnabled', function () {
        self.silent = true;
      })
      map.on('silentDisabled', function () {
        self.silent = false;
      })
      this.$root.$store.commit('State/setBounds', map.getBounds(), {root: true});

      this.map.on('pm:globaleditmodetoggled pm:globaldragmodetoggled pm:globaldrawmodetoggled pm:globalremovalmodetoggled', function (e) {
        if ((map.pm.globalRemovalModeEnabled() || map.pm.globalEditModeEnabled() || map.pm.globalDragModeEnabled())) {
          self.disableZoom(map);
        } else {
          self.enableZoom(map);
        }
      });
      if (this.$route.name == 'Site Location') {
        this.$store.commit('Sites/setModalSite', this.$route.params.id);
        this.$bvModal.show('siteInfo')
        this.$store.dispatch('Sites/getSiteInfo', this.$route.params.id).then(response => {
          let center = {}
          center.zoom = 17;
          center.center = L.latLng(response.data.site.lat, response.data.site.lng)
          this.$root.$emit("setCenter", center);
          this.$router.push('/')
        });
      }
      if (this.$route.name == 'Set Location') {
        let center = {}
        center.zoom = this.$route.params.zoom;
        center.center = L.latLng(this.$route.params.lat, this.$route.params.lon)
        this.$root.$emit("setCenter", center);
        this.$router.push('/')
        this.$root.$emit('showLoader', 'Loading info for location');
        this.$store.dispatch('Search/loadLocationInfo', center.center);
      } else if (this.$route.name == 'Copy uprn url') {
        let center = {}
        center.zoom = 19
        center.center = L.latLng(this.$route.params.lat, this.$route.params.lon)
        let uprn = this.$route.params.uprn
        this.$root.$emit("setCenter", center);
        this.$store.dispatch('Search/loadMultiUprnInfo', {
          uprn: this.$route.params.uprn,
          clear: true,
          all_properties: true
        })
        this.$router.push('/')
      }
    },
    registerControls(map) {
      const pegmanControl = new L.Control.Pegman({
        position: 'topleft', // position of control inside the map
        theme: "leaflet-pegman-v3-small", // or "leaflet-pegman-v3-small"
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        mutant: {
          attribution: '',
          pane: "overlayPane",
          type: null,
        },
        debug: true
      });
      pegmanControl.addTo(map);
      this.followMeControl = L.control.pclocate({
        position: 'bottomright',
        flyTo: true,
        drawMarker: true,
        drawCircle: true,
        showCompass: true,
        markerStyle: {fillColor: 'red'},
        followMarkerStyle: 'fa fa-pen',
        icon: 'fa fa-crosshairs',
        strings: {
          location: {title: "Get Location"},
          followMe: {title: "Follow Me"},
        }
      }).addTo(this.map);
      window.Lmap.invalidateSize();
      // }
      L.Control.reset({caller: this}).addTo(this.map)
      L.Control.printVector({caller: this}).addTo(this.map)
      L.Control.BrowserPrint.Utils.registerRenderer(L.SVG, 'L.SVG');
      L.Control.downloadMapImage({caller: this}).addTo(this.map);

      L.control.browserPrint({
        printModes: [L.control.browserPrint.mode.landscape()]

      }).addTo(this.map)

    },
    tileLoadStart() {
      if (!this.silent) {
        this.showLoader("Loading tiles");
      }
    },
    tileLoadEnd() {
      this.hideLoader();
    },
    tileRemoved() {
      this.hideLoader();
    },
    contextMenu(e) {
      this.$root.$emit('showLoader', 'Loading info for location');
      this.$store.dispatch('Search/loadLocationInfo', e.latlng).catch(() => {
        this.$root.$emit('hideLoader');
      });
    },
    boundsUpdated(bounds) {
      this.currentBounds = bounds;
      const self = this;
      this.updateAttributes()
      this.$store.commit('State/setCenter', window.Lmap.getCenter(), {root: true});
      this.$store.commit('State/setZoom', window.Lmap.getZoom(), {root: true});
      // this.$root.$store.commit('State/setBounds', bounds, {root: true});
      this.calculateDistance().then(distance => {
        if (!this.firstRun) {
          vue.nextTick(function () {
            self.$store.dispatch('LayersList/load_propertiesActivate', {
              center: window.Lmap.getCenter(),
              zoom: window.Lmap.getZoom(),
              distance: distance
            }, {root: true});
            self.$store.dispatch('LayersList/load_all_propertiesActivate', {
              center: window.Lmap.getCenter(),
              zoom: window.Lmap.getZoom()
            }, {root: true});
            self.$store.dispatch('Maps/activateVectorLayer', {map: self.activeMap})
            self.$store.dispatch('LayersList/load_heatmapActivate')
            // self.$store.dispatch('LayersList/load_roadworksActivate')
            self.$store.dispatch('LayersList/load_sitesActivate')
            self.drawProjectPolygon();
          })
        }
        if (this.firstRun) {
          this.$store.dispatch('LayersList/list', {}, {root: true}).then(() => {
            this.$root.$emit("layersListLoaded");
          })
          this.$store.dispatch('Maps/list', {}, {root: true})
        }
        self.firstRun = false;
        self.updateDrawControls();
        self.drawProjectPolygon();
      });
      if (!this.firstRun) {
        self.$store.dispatch('LayersList/load_location_photosActivate');
      }
    },
    calculateDistance() {
      return new Promise((resolve, reject) => {
        let mapBoundNorthEast = this.$refs.map.mapObject.getBounds().getNorthEast();
        let mapDistance = mapBoundNorthEast.distanceTo(this.$refs.map.mapObject.getCenter());
        this.$store.commit('State/setDistance', mapDistance / 1000, {root: true})
        resolve(mapDistance);
      })
    },
    updateAttributes() {
      if (this.map) {
        const zoom = window.Lmap.getZoom();
        window.Lmap.attributionControl._attributions = {};
        window.Lmap.attributionControl.addAttribution(CommonTools.round(this.map.getCenter().lat, 6) + '/' + CommonTools.round(this.map.getCenter().lng, 6) + ' | Zoom Level:' + zoom);
        window.Lmap.attributionControl._update();
      }
    },
    disableZoom(map) {
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      map.removeControl(map.zoomControl);
    },
    enableZoom(map) {
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
      map.addControl(map.zoomControl);
    }

  },
  computed: {
    mapOptions: function () {
      return {
        zoomSnap: 1,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        maxNativeZoom: this.maxZoom,
        detectRetina: false,
        updateWhenIdle: false,
        keepBuffer: 10,
        crs: L.CRS.EPSG3857
      }
    },
    ...mapGetters({
      allLayers: 'LayersList/getAllLayers',
      currentZoom: 'State/currentZoom',
      url: 'Maps/getActiveMapUrl',
      minZoom: 'State/getMinZoom',
      maxZoom: 'State/getMaxZoom',
      getLocationInfo: 'Search/getLocationInfo',
      locationInfoAvailable: 'Search/getLocationInfoAvailable',
      activeMap: 'Maps/getActiveMap',
      showVectorLayer: 'State/showVectorLayer',
      getCenter: 'State/getCenter',
      postcodeSearchIsActive: 'Search/postcodeSearchIsActive',
      postcode: 'Search/postcode',
      loggedIn: 'Auth/isLoggedIn',
      latlngs: 'Search/getLatLngForBounds',
      heatMap: 'LayersList/getHeatMapItems'

    }),
    wmsUrl: {
      get() {
        return process.env.VUE_APP_WMS_URL
      }
    },
    postcodeViewParams: {
      get() {
        return 'postcode:' + this.postcode;
      }
    },
    selectedProject: {
      get() {
        return this.$store.state["Projects/selected_project"];
      }
    }

  },
  components: {
    OpenreachDuctsAndStructuresPopup,
    TilePlan,
    Splitter,
    StreetCabinet,
    Chamber,
    PatchPanel,
    SiteInfoModal,
    SitesOnLocationMarkers,
    LocationPhotoMarkerGroup,
    PolygonPopup,
    Customize,
    CustomUprnInfo,
    CustomPropertyMarkersGroup,
    Buildings,
    UprnInfo,
    PropertyMarkersGroup,
    SubLayersContainer,
    LMap,
    LTileLayer,
    LocationInfoPopup,
    RoadWorks,
    Pole,
    SplicingDome,
    StaticPopup
  },
  created() {
    const self = this;
    this.$root.$on('fitBounds', function (latLongs) {
      if (self.$refs.map != undefined) {
        self.$refs.map.mapObject.fitBounds(latLongs)
      }
    })
    this.$root.$on('logout', function () {
      self.allLayers.map(function (item, index) {
        item.sub_layers.map(function (subLayer, subLayerIndex) {
          localStorage.removeItem('layers.' + subLayer.code);
        })
      })
      self.$store.dispatch('Auth/logout').then(() => {
        self.$store.dispatch('LayersList/list');
        self.$store.dispatch('Maps/list');
        self.$store.commit("Duct/setDuctsList", [], {root: true});
        self.$store.commit("ObjectMarker/setObjectMarkers", [], {root: true});
        self.$store.commit("ObjectTypes/setObjectTypes", [], {root: true});
        window.location.reload();

      })
    })
    this.$root.$on('activeMapChanged', function () {

      if (self.currentZoom > self.maxZoom) {
        Lmap.setZoom(self.maxZoom)
        self.$store.commit("State/setZoom", self.maxZoom, {root: true});
      }
    })
    this.$root.$on('setCenter', function (center) {
      if (self.$refs.map != undefined) {
        let zoom = window.Lmap.getZoom();
        if (center.zoom != undefined) {
          zoom = center.zoom;
        }
        if (center.center != undefined) {
          window.Lmap.setView(new L.LatLng(center.center.lat, center.center.lng), zoom)
          self.$store.commit("State/setCenter", center.center, {root: true});
        }
      }
    })

    this.$root.$on('selected_project_changed', function () {
      this.$store.dispatch("Search/searchCustomPropertiesInDistance", {center: window.Lmap.getCenter()}, {root: true});
      self.drawDucts();
      self.drawMarkers();
      self.drawProjectPolygon();
      self.drawProjectText();
    })
    this.$root.$on('showLoader', message => {
      self.showLoader(message);
    });
    this.$root.$on('hideLoader', () => {
      self.hideLoader();
    })
    this.$root.$on('redrawDucts', () => {
      self.drawDucts(true);
    })
  },


}
</script>

<style scoped>

</style>