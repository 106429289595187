<template>
<div>
  <b-modal centered size="lg" @show="getElevationCalculationRequest" id="elevations_list">
    <template #modal-title>
      Elevation calculation requests


      <b-button class="right text-right ml-5" size="sm" variant="outline-success" @click="getElevationCalculationRequest">
        <b-icon icon="arrow-counterclockwise" animation="spin-reverse" font-scale="1"
        ></b-icon>
      </b-button>
    </template>
    <b-table
        :items="items"
        :fields="headers"
    >
      <template #cell(action)="row">
        <a :href="downloadElevationResult(row.item)" v-if="row.item.status==='Success'"
           class="btn btn-success btn-sm">
          Download
        </a>
        <div @click="deleteElevationResult(row.item)" v-if="row.item.status!=='In Progress' && row.item.status!=='New'"
             class="btn btn-danger btn-sm ml-1">
          Delete
        </div>
      </template>

    </b-table>
  </b-modal>
</div>
</template>

<script>
export default {
  name: "ElevationCalculations",
  data:function(){
    return {
      headers:[
        {key: 'created_at', sortable: false,label: 'Create Date'},
        {key: 'lat', sortable: false},
        {key: 'lon', sortable: false},
        {key: 'propertiesCount', sortable: false, label: 'Total Properties'},
        {key: 'status', sortable: false, label: 'Status'},
        {key: 'action', sortable: false},
      ],
      items:[]
    };
  },
  methods:{
    getElevationCalculationRequest: function ()
    {
      axios.get(process.env.VUE_APP_API_URL+'/api/calculateelevations').then(response=>{
          this.items = response.data.data;
      });
    },

    downloadElevationResult(item){
      return process.env.VUE_APP_API_URL+'/api/calculateelevations/download/'+item.id+'/'+item.hash
    },
    deleteElevationResult(item){
      axios.delete(process.env.VUE_APP_API_URL+'/api/calculateelevations/'+item.id).then(response=>{
        this.getElevationCalculationRequest();
      });
    }

  }
}
</script>

<style scoped>

</style>