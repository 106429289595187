<template>
  <div class="menu_item">
    <div class="d-flex justify-content-between">
      <span class="checkbox-name"> {{ name }}  </span>
      <b-form-checkbox class="checkbox" :ref="'layers'+id" @change="layerCheckBoxChanged(checkedModel,id)"
                       :checked="checked"
                       v-model="checkedModel" :id="id"
                       name="check-button" switch/>
    </div>
    <b-form-group v-if="checked" class="my-2">
      <b-form-datepicker @change="updateRoadWork" size="sm" id="road_work_start_date" v-model="startDate"
                         class="mb-2 col-12"></b-form-datepicker>
      <b-form-datepicker @change="updateRoadWork" size="sm" id="road_work_end_date" v-model="endDate"
                         class="mb-2"></b-form-datepicker>
    </b-form-group>

  </div>
</template>

<script>

export default {
  name: 'MapLayerRoadWorkCheckbox',
  data() {
    return {
      checkedModel: this.checked,
    }
  },
  computed: {
    startDate: {
      get() {
        const store = this.$store.getters["RoadWorks/getStartDate"]
        const dbData = this.$store.getters["State/checkboxes"];
        if (dbData !== null && dbData[this.id] !== undefined && dbData[this.id]['data'] !== undefined && dbData[this.id]['data']['startDate'] !== undefined) {
          return dbData[this.id]['data']['startDate'];
        }
        return store;
      },
      set(value) {
        this.$store.dispatch('State/saveSubLayerState', {
          code: this.id,
          state: this.checkedModel,
          data: {startDate: value, endDate: this.endDate}
        });
        this.$store.commit('RoadWorks/setStartDate', value, {root: true})
        this.updateRoadWork();
      }
    },
    endDate: {
      get() {
        const store = this.$store.getters["RoadWorks/getEndDate"]
        const dbData = this.$store.getters["State/checkboxes"];
        if (dbData !== null && dbData[this.id] !== undefined && dbData[this.id]['data'] !== undefined && dbData[this.id]['data']['endDate'] !== undefined) {
          return dbData[this.id]['data']['endDate'];
        }
        return store;
      },
      set(value) {
        this.$store.dispatch('State/saveSubLayerState', {
          code: this.id,
          state: this.checkedModel,
          data: {startDate: this.startDate, endDate: value}
        });
        this.$store.commit('RoadWorks/setEndDate', value, {root: true})
        this.updateRoadWork();
      }
    }
  },
  watch: {
    checked: function (val) {
      this.checkedModel = val === 1 || val === true;
      this.$store.dispatch('State/saveSubLayerState', {
        code: this.id,
        state: this.checkedModel,
        data: {startDate: this.startDate, endDate: this.endDate}
      });

    },
    startDate: function (val) {
      this.updateRoadWork();
    },
    endDate: function (val) {
      this.updateRoadWork();
    }
  },
  props: {
    name: String,
    id: String,
    checked: Boolean
  },
  methods: {
    layerCheckBoxChanged(state, id) {
      if (state) {
        this.$store.dispatch('LayersList/activateSubLayer', id)
        this.$store.dispatch('LayersList/load_roadworksActivate', {})
      } else {
        this.$store.dispatch('LayersList/deactivateLayer', id);
      }
    },
    updateRoadWork() {
      console.log('Update Road Work');
      this.$root.$emit('showLoader', "Loading RoadWorks");
      const self = this;
      this.$store.dispatch('LayersList/load_roadworksActivate', {}).then(() => {
        self.$root.$emit('hideLoader');
      })
    }
  },
  mounted() {
    if (this.checkedModel === true) {
      this.layerCheckBoxChanged(this.checkedModel, this.id)
    }
  }
}
</script>
<style>
.menu_item {
  min-height: 35px;
  width: 100%;
  line-height: 2;
}

.checkbox-name {
  font-size: 12px;
}

.checkbox-body {
  padding-bottom: 10px;
}
</style>