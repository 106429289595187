import axios from "axios";

export default class Objects {

    static destroy(projectId, objectId) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/object/' + objectId);
    }


    static freeFibresInObject(objectId) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + objectId + '/free_fibres_in_object');
    }

    static freeFibresForSplitter(objectId) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_splitter/' + objectId + '/fibres');
    }
}