import Vue from "vue";
import CommonTools from "@/Helpers/CommonTools";

const state = {
    currentLatLng: [],
    locationsWithPhoto: [],
    showLocationPhotosModal: false,
    currentLocationPhotos: [],
    loading: false,
    progress: false
}
const mutations = {
    showLocationModal(state, data) {
        Vue.set(state, 'showLocationPhotosModal', true);
    },
    hideLocationModal(state, data) {
        Vue.set(state, 'showLocationPhotosModal', false);
    },
    setLocationsWithPhoto(state, data) {
        Vue.set(state, 'locationsWithPhoto', data);
    },
    setCurrentLocationsPhotos(state, data) {
        Vue.set(state, 'currentLocationPhotos', data);
    },
    setCurrentLocation(state, data) {
        Vue.set(state, 'currentLatLng', data);
    },
    setProgress(state, data) {
        Vue.set(state, 'progress', data);
    },
    showLoader(state, data) {
        Vue.set(state, 'loading', data);
    }
}
const actions = {
    loadPhotoLocationsForPolygon(ctx) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        if (selectedProject.id === undefined) {
            return false;
        }
        const bounds = btoa(CommonTools.createPolygonFromBounds(Lmap.getBounds(), Lmap.getCenter()));
        axios.get(process.env.VUE_APP_API_URL + '/api/location_images/project/' + selectedProject.id + '/search/' + bounds).then(response => {
            ctx.commit('setLocationsWithPhoto', response.data)
        });

    },
    getPhotosForLocation(ctx, data) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        ctx.commit('showLoader', true)
        axios.get(process.env.VUE_APP_API_URL + '/api/location_images/location/' + selectedProject.id + '/' + data.lat + '/' + data.lng).then(response => {
            ctx.commit('setCurrentLocationsPhotos', response.data)
            ctx.commit('showLoader', false)
        });
    },
    uploadGeoTaggedPhotos(ctx, data) {
        return axios.post(process.env.VUE_APP_API_URL + "/api/location_images/geo_tag", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: e => {
                if (e.lengthComputable) {
                    this.progress = parseFloat(((e.loaded / e.total) * 100).toFixed(2));
                    ctx.commit('setProgress', this.progress);
                }
            }
        }).then(response => {
            ctx.commit('setCurrentLocationsPhotos', response.data)
            ctx.dispatch('loadPhotoLocationsForPolygon');
            setTimeout(function () {
                ctx.commit('setProgress', false)
            }, 1000)
        })
    },
    uploadPhotoForLocations(ctx, data) {
        return axios.post(process.env.VUE_APP_API_URL + "/api/location_images", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: e => {
                if (e.lengthComputable) {
                    this.progress = parseFloat(((e.loaded / e.total) * 100).toFixed(2));
                    ctx.commit('setProgress', this.progress);
                }
            }

        }).then(response => {
            ctx.dispatch('getPhotosForLocation', {lat: data.get('lat'), lng: data.get('lng')})
            ctx.dispatch('loadPhotoLocationsForPolygon');
            setTimeout(function () {
                ctx.commit('setProgress', false)
            }, 1000)
        })
    },
    deleteImages(ctx, data) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        ctx.commit('showLoader', true)
        axios.delete(process.env.VUE_APP_API_URL + '/api/location_images/project/' + selectedProject.id + '/lat/' + data.lat + '/lon/' + data.lng).then(response => {
            ctx.dispatch('getPhotosForLocation', data)
            ctx.dispatch('loadPhotoLocationsForPolygon');
            ctx.commit('showLoader', false)
        })
        ctx.commit('showLoader', false)
    },
    deleteImage(ctx, data) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        ctx.commit('showLoader', true)
        CommonTools.deleteConfirmation().then(() => {
            axios.delete(process.env.VUE_APP_API_URL + '/api/location_images/location/' + selectedProject.id + '/' + data.id).then(response => {
                ctx.dispatch('getPhotosForLocation', data)
                ctx.dispatch('loadPhotoLocationsForPolygon');
                ctx.commit('showLoader', false)
            });
        });
        ctx.commit('showLoader', false)
    },
    editImage(ctx, data) {
        ctx.commit('showLoader', true);
        return axios.post(process.env.VUE_APP_API_URL + "/api/location_images/edit", data).then(response => {
            ctx.dispatch('loadPhotoLocationsForPolygon');
            ctx.commit('showLoader', false)
        })
    }
}
const getters = {
    getProgress: state => {
        return state.progress;
    },
    showLocationPhotosModal: state => {
        return state.showLocationPhotosModal;
    },
    locationsWithPhoto: state => {
        return state.locationsWithPhoto;
    },
    currentLocation: state => {
        return state.currentLatLng
    },
    locationPhotos: state => {
        const sourceImages = [];
        let images = state.currentLocationPhotos
        if (images != undefined) {
            images.forEach(function (image) {
                image.source = process.env.VUE_APP_ASSETS_URL + '/locationimages/' + image.image_path
                image.thumbnail = process.env.VUE_APP_ASSETS_URL + '/locationimages/' + image.image_path
                sourceImages.push(image)
            })
            return sourceImages;
        }
        return state.currentLocationPhotos;
    },
    loading: state => {
        return state.loading;
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}