<template>
  <div class="search-form ">
    <div class="rounded overlay-object input-group has-search">
      <input type="text" @keyup="searchTimeOut" v-model="searchText" id="query"
             class="form-control search-input border-0 shadow-none" placeholder="Search">
      <div class="input-group-append">
        <button id="search_button" class="btn bg-white text-dark search-button" @click="searchWithClick"
                type="button">
          <b-icon-search></b-icon-search>
        </button>
      </div>
    </div>
    <div v-if="showSearch" class="search-container" :style="`--height: ${boxCurrentHeight}px`"
         :class="{ search_container_openedo: (showSearch && !hideSearch), 'd-none': (!showSearch) }">
      <b-overlay :show="showOverlay" class="h-100" rounded="lg">
        <div class="close_search_result float-right mb-2">
          <b-icon icon="x-circle" @click="hideSearchAction"></b-icon>
        </div>
        <div v-if="postcodes.length > 0" class="search_list_result">
          <div class="search_list_title">Postcode</div>
          <ul class="search_list_body">
            <li class="searched-list" v-for="postcodeItem in postcodes" :key="postcodeItem.key"
                @click="showUprnInfoFromList(postcodeItem)">
              <div v-if="postcodeItem.display_text_normal.length > 0">
                <strong>{{ postcodeItem.display_text_normal }}</strong>
                {{
                  postcodeItem.display_text_bold
                }}
              </div>
              <div v-else>
                <strong>{{ postcodeItem.uprn }}</strong>
                {{
                  postcodeItem.postcode
                }}
              </div>
            </li>
          </ul>
        </div>
        <div v-if="cities.length > 0" class="search_list_result">
          <div class="search_list_title">Locations</div>
          <ul class="search_list_body">
            <li @click="searchCity(city)" class="searched-list" v-for="city in cities" :key="city.id"><b>{{
                city.content
              }}</b></li>
          </ul>
        </div>
        <div v-if="streets.length > 0" class="search_list_result ">
          <div class="search_list_title">Streets</div>
          <ul class="search_list_body">
            <li class="searched-list" v-for="street in streets" :key="street.street"
                @click="searchStreet({ thoroughfare: street.thoroughfare, towncity: street.post_town, postcode: street.postcode_part })">
              <b>{{ street.street }}</b>
            </li>
          </ul>
        </div>
        <div v-if="fuzzy.length > 0" class="search_list_result">
          <div class="search_list_title">Properties</div>
          <ul class="search_list_body">
            <li class="searched-list" v-for="item in fuzzy" :key="item.key"
                @click="showUprnInfoFromList(item)">
              <b>{{ item.display_text_normal }}</b> {{ item.display_text_bold }}
            </li>
          </ul>
        </div>
      </b-overlay>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Utils from "@/assets/js/libs/Utils";

export default {
  name: "SearchComponentPlug",
  props: {
    shouldEmitted: {
      type: Boolean,
      default: false
    },
    emitKey: {
      type: String,
      default: ''
    },
    emitIndexKey: {
      type: Number,
      default: null
    }
  },
  watch: {
    showSearch(value) {
      if (value) {
        this.openSearchBox();
      }
    },
    searchText(value) {
      if (value.length === 0) {
        this.hideSearchAction();
      }
    }
  },
  data() {
    return {
      searchText: '',
      all_properties: false,
      hideSearch: false,
      boxSize: 400,
      boxSizeStep: 20,
      boxCurrentHeight: 0,
      timer: null,
      postcodePolygon: null,
      showSearch: false,
      cities: [],
      streets: [],
      fuzzy: [],
      postcodes: [],
    }
  },
  methods: {
    ...mapActions({
      'searchStreet': 'Search/searchStreet',
    }),
    openSearchBox() {
      let self = this;
      (function myLoopOpen(i) {
        setTimeout(function () {
          self.boxCurrentHeight = i * self.boxSizeStep;
          if (++i <= (self.boxSize / self.boxSizeStep)) myLoopOpen(i);
        }, 1)
      })(0);


    },
    hideSearchAction() {
      let self = this;
      (function myLoopClose(i) {
        setTimeout(function () {
          self.boxCurrentHeight = i * self.boxSizeStep;
          if (--i) myLoopClose(i);
          if (i === 0) {
            self.searchText = '';
          }
        }, 1)
      })((self.boxSize / self.boxSizeStep));
      this.showSearch = false;
    },
    onEnter: function () {
      this.search();
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 300);
    },
    searchWithClick() {
      this.all_properties = false;
      this.search();
    },
    isUprn(query) {
      return /^\d+$/.test(query);
    },
    isPostCode(query) {
      const regexp = new RegExp(process.env.VUE_APP_POSTCODE_PATTERN);
      return regexp.test(query);
    },
    search() {
      const self = this;
      const query = this.searchText.trim();
      if (query.length >= 3) {
        // UPRN changed to MULTIUPRN
        if (this.isUprn(query)) {
          this.$store.dispatch('Search/loadMultiUprnInfoRaw', {
            uprn: query,
          }).then(resp => {
            if (resp.data.data) {
              self.fuzzy = resp.data.data;
            } else {
              self.fuzzy = resp.data;
            }
            self.showSearch = true;
          });
          return;
        }
        if (this.isPostCode(query)) {
          this.$store.dispatch('Search/loadByPostcodeRaw', {
            postcode: query,
            all_properties: 1
          }).then(data => {
            self.fuzzy = data.data;
            this.showSearch = true
          });
          return;
        }
        this.$store.dispatch('Search/autocompleteRaw', query).then(response => {
          self.cities = response.data.data.cityData;
          self.streets = response.data.data.streetData;
          self.fuzzy = response.data.data.fuzzy;
          self.showSearch = true;
        }).catch(err => console.log(err));
      }
    },


    //change this
    showUprnInfoFromList(uprnInfo) {
      this.$root.$emit(this.emitKey, uprnInfo, this.emitIndexKey);
      this.showSearch = false;

    },
  },
  computed: {
    Utils() {
      return Utils
    },
    ...mapGetters({
      showOverlay: 'Search/showOverlay'
    })
  },

}
</script>

<style scoped>
.search-container {
  position: absolute;
  margin-top: 80px;
  margin-right: 60px;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 5px;

  overflow-x: auto;
  z-index: 1005;

  top: 0;
  max-height: 400px;
  --height: 0%;
  height: var(--height);
}

@media (min-width: 961px) {
  .search-container {
    min-width: 500px;
    max-width: 500px;
    right: 0;

  }
}

@media (max-width: 960px) {
  .search-container {
    width: 90% !important;
    left: 0;
    right: 0;
  }
}

@-webkit-keyframes animateThis {
  0% {
    height: 0;
  }

  100% {
    height: 100%;

  }
}

@-webkit-keyframes animateClose {
  0% {
    height: 400px;
  }

  25% {
    height: 300px;
  }

  50% {
    height: 200px;
  }

  75% {
    height: 100px;
  }

  100% {
    height: 0;
    display: none;
    visibility: hidden;

  }
}

.close_search_result {
  padding-top: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.search_list_title {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px 5px 20px;
}

.search_list_body {
  padding-inline-start: 0 !important;
  font-size: 12px;
}

.searched-list {
  font-weight: 100;
  text-decoration: none;
  color: #4d4db3;
  cursor: pointer;
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  list-style: none;
  border-bottom: 1px solid #ced4da;
  padding: 5px 10px;


}
</style>
