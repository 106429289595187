import Vue from "vue";
import {BModal} from "bootstrap-vue";

const selectFromListModal = Vue.extend({
    components: {
        BModal
    },
    props: {
        title: {
            type: String,
            default: "Select",
        },
        list: Array,
        meth: {
            type: Function
        },
    },
    data() {
        return {
            selectedItem: null
        }
    },
    methods: {
        ok() {
            this.meth(this.selectedItem)
        }
    },
    template: `
      <b-modal @ok="ok" :ok-disabled="list===null" :title="title" id="single-select-from-list-modal">
        <select class="form-control" v-model="selectedItem">
          <option :value="item.key" v-for="item in list">{{ item.value }}</option>
        </select>
      </b-modal>
    `
});
export default {
    install: (app, options) => {
        app.prototype.$selectFromList = (config) => {
            // retrieve a nested property in `options`
            // using `key` as the path
            const instance = new selectFromListModal({
                propsData: {
                    title: config.title,
                    list: config.list,
                    meth: config.meth
                }
            });
            instance.$mount();
            instance.$bvModal.show('single-select-from-list-modal');
            document.body.appendChild(instance.$el);
            return null;
        }
    }
}