import axios from "axios";
import Vue from 'vue'

const state = {
    token: localStorage.getItem('token') || '',
    projects: {},
    state: {},
    isLoggedIn: false,
    userIsLoading: true,
    isAdmin: false
}
const mutations = {
    setToken(state, data) {
        Vue.set(state, 'token', data);
        localStorage.setItem('token', data)
        Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + data

    },
    setUserInfo(state, data) {
        Vue.set(state, 'state', data.state);
        Vue.set(state, 'isLoggedIn', true);
        Vue.set(state, 'isAdmin', data.user.is_admin);

    },
    setIsLoggedIn(state, data) {
        Vue.set(state, 'isLoggedIn', data);
    },
    auth_error(state) {
        Vue.set(state, 'token', '');
        Vue.set(state, 'state', {});
        Vue.set(state, 'isLoggedIn', false);
    },
    logout(state) {
        Vue.set(state, 'token', '');
        Vue.set(state, 'state', {});
        Vue.set(state, 'isLoggedIn', false);
    },
    setUserIsLoading(state, data) {
        Vue.set(state, 'userIsLoading', data)
    }
}
const getters = {
    isLoggedIn: state => {
        return state.isLoggedIn;
    },
    isUserLoading: state => {
        return state.userIsLoading;
    },
    isAdmin: state => {
        return state.isAdmin;
    },
    token: state => {
        return state.token;
    }
}
const actions = {

    login({commit, dispatch}, data) {

        return new Promise((resolve, reject) => {
            axios({url: process.env.VUE_APP_API_URL + '/api/user/auth', data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },
    logout({commit}) {
        return new Promise((resolve) => {
            commit('logout')
            localStorage.removeItem('token')
            delete axios.defaults.headers.common['Authorization']

            resolve()
        })
    },
    checkUser() {
        return new Promise((resolve, reject) => {

            if (!localStorage.getItem('token')) {
                reject("LOCALSTORAGE_EMPTY");
            }
            axios({url: process.env.VUE_APP_API_URL + '/api/user/check'})
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    getUserInfo({commit, dispatch}) {
        commit('setUserIsLoading', true);
        return new Promise((resolve, reject) => {

            if (!localStorage.getItem('token')) {
                commit('setUserIsLoading', false);
                reject("LOCALSTORAGE_EMPTY");
            }
            axios({url: process.env.VUE_APP_API_URL + '/api/user/info'})
                .then(resp => {
                    commit('setUserIsLoading', false);
                    commit('setUserInfo', resp.data.data)
                    commit('Profile/setUser', resp.data.data.user, {root: true});
                    commit('Projects/setProjects', resp.data.data.projects, {root: true});
                    commit('Projects/setSelectedProject', resp.data.data.selected_project, {root: true});
                    commit('State/setState', resp.data.data.state, {root: true});
                    commit('Openreach/setOpenreachPia', resp.data.data.openreach, {root: true});

                    dispatch('Properties/getFlagged', {}, {root: true})


                    resolve(resp)
                })
                .catch(err => {
                    localStorage.removeItem('token');
                    commit('setUserIsLoading', false);
                    reject(err)
                })
        })
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}