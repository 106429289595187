const Vue = require("vue");
L.Control.DownloadMapImage = L.Control.extend({
    options: {
        position: 'topleft',
        homeTitle: 'Download current view of Map',
        leafletExport: require("@/assets/js/libs/LeafletExport")
    },

    onAdd: function (map) {


        // Create toolbar
        let controlName = 'leaflet-control-download',
            container = L.DomUtil.create('div', controlName + ' leaflet-bar');

        // Add toolbar buttons
        this._homeButton = this._createButton(this.options.homeTitle, controlName, container, this._download);

        // Set initial view to home


        return container;
    },

    onRemove: function (map) {
    },

    _download: function () {
        this.caller.$root.$emit('showLoader','Loading map view for export');
        this.options.leafletExport.default(Lmap).then(response=>{
            const blob = response.image;
            const a = document.createElement("a"); //Create <a>
            a.href = URL.createObjectURL(blob)
            a.download = "Export_MapView_"+Date.now()+".png"; //File name Here
            a.click();
            a.remove();
            this.caller.$root.$emit('hideLoader');

        })

    },
    _createButton: function (title, className, container, fn) {
        // Modified from Leaflet zoom control

        var link = L.DomUtil.create('a', className, container);
        link.href = '#';
        link.title = title;

        L.DomEvent
            .on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this)
            .on(link, 'click', this._refocusOnMap, this);

        return link;
    },

});
L.Control.downloadMapImage = function (options) {

    const mapImage =  new L.Control.DownloadMapImage(options);
    mapImage.caller = options.caller;
    return mapImage;
};