<template>
  <b-overlay rounded="sm" :show="loading">
    <table v-if="items.length > 0" class="table table-striped">
      <tbody>
      <tr v-for="item in items">
        <td @click="showObjectInfo(item)" class="cursor-pointer">O-{{ item.sequence_id }}
          <strong>({{ item.object_type.name }})</strong></td>
        <td>
          <b-button class="float-right" size="sm" @click="deleteObject(item)"
                    variant="outline-danger"> Remove
          </b-button>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-else>
      <p class="text-muted pt-5 text-center">No child devices</p>
    </div>
  </b-overlay>
</template>
<script>

import CommonTools from "@/Helpers/CommonTools";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  name: "SubObjectsList",
  data() {
    return {
      loading: false,
    }
  },
  props: {
    parentObjectId: {type: Number},
    items: {type: Array},
  },
  methods: {
    showObjectInfo(item) {
      this.$store.dispatch('ObjectInfo/showObjectInfo', {id: item.id, code: item.object_type.code}).then(response => {
        if (response) {
          const modalId = ObjectTypesEnum.getModalId(item.object_type.code);
          this.$bvModal.show(modalId);
        }
      });
    },
    deleteObject(item) {
      const self = this
      CommonTools.deleteConfirmation().then(() => {
        this.loading = true;
        this.$store.dispatch('ObjectMarker/delete', item.id).then(() => {
          self.items.splice(self.items.findIndex(a => a.id === item.id), 1)
          self.loading = false;
        });
      }).catch(() => {
      });
    }
  }
}
</script>
<style scoped>

</style>