import {mapGetters} from "vuex";
import CommonTools from "@/Helpers/CommonTools";
import L from "leaflet";
import AreaPopup from "@/Helpers/AreaPopup";

const PolygonToolBar = {
    data: function () {
        return {
            polygonLayersGroup: L.layerGroup()
        }
    },
    watch: {
        backgroundColor: function (value) {
            if (this.currentPolygon != null) {
                this.currentPolygon.setStyle({fillColor: value});
            }
        },
        backgroundOpacity: function (value) {
            if (this.currentPolygon != null) {
                this.currentPolygon.setStyle({fillOpacity: value});
            }
        },
        borderColor: function (value) {
            if (this.currentPolygon != null) {
                this.currentPolygon.setStyle({color: value});
            }
        },
        borderWidth: function (value) {
            if (this.currentPolygon != null) {
                this.currentPolygon.setStyle({weight: value});
            }
        },
        showPolygon: function (value) {
            if (value) {
                this.drawProjectPolygon(false)
            } else {
                this.clearProjectPolygon()
            }
        },
        showPolygonOutline: function (value) {
            if (value) {
                this.drawProjectPolygon(false, true)
            } else {
                this.clearProjectPolygon()
            }
        },
        redrawPolygons: function (value) {
            if (value) {
                this.drawProjectPolygon(false, true)
            } else {
                this.clearProjectPolygon()
            }
        }
    },
    computed: {
        ...mapGetters({
            polygons: 'Polygon/polygon',
            style: 'Polygon/currentStyle',
            backgroundColor: 'Polygon/backgroundColor',
            backgroundOpacity: 'Polygon/backgroundOpacity',
            borderColor: 'Polygon/borderColor',
            borderWidth: 'Polygon/borderWidth',
            currentPolygon: 'Polygon/currentPolygon',
            polygonId: 'PolygonPopup/polygon',
            showPolygon: 'LayersList/getPolygonStatus',
            showPolygonOutline: 'LayersList/getPolygonOutlineStatus',
            redrawPolygons: 'Polygon/redrawPolygons'
        }),
    },
    methods: {
        drawProjectPolygonStartAction(e) {
            this.$store.dispatch('LayersList/activateSubLayer', 'polygons', {root: true}).then(() => {

            })
        },
        drawProjectPolygonCreateAction(e) {
            const self = this;
            self.$store.dispatch("Polygon/store", {
                backgroundOpacity: 0.2,
                borderWidth: 2,
                geometry: btoa(JSON.stringify(e.layer.toGeoJSON())),
                project_id: self.$store.state.Projects.selected_project.id,
            }).then(response => {
                e.layer.id = response.data.data.id;
                e.layer.feature = response.data.data.feature;
                e.layer.shapeType = 'Polygon';
                self.polygonLayersGroup.addLayer(e.layer)
                e.layer.remove()
                self.drawProjectPolygon(true)
            })
        },
        drawProjectPolygon(force = false, outline = false) {
            // console.trace('drawProjectPolygon')
            if (this.polygonId !== null && !force) {
                return;
            }
            if (!this.showPolygon && !this.showPolygonOutline) {
                return;
            }
            const self = this;
            self.$store.dispatch('Polygon/list',
                CommonTools.createPolygonFromBounds(self.map.getBounds(), self.map.getCenter())).then(response => {
                self.polygonLayersGroup.clearLayers()
                response.map(function (polygon) {
                    if (self.showPolygonOutline) {
                        L.geoJSON(polygon.feature, {
                            style: function () {
                                return {
                                    weight: 2
                                }
                            },
                            onEachFeature: function (feature, layer) {
                                if (layer instanceof L.Polygon) {
                                    let latLngs = layer.getLatLngs();
                                    latLngs[0].push(latLngs[0][0]);
                                    let polyline = new L.Polyline(latLngs);
                                    polyline.id = polygon.id;
                                    polyline.feature = polygon.feature;
                                    polyline.shapeType = 'Polygon';
                                    self.setEvents(polyline)
                                    self.polygonLayersGroup.addLayer(polyline)
                                }
                            }
                        })
                    } else {
                        L.geoJSON(polygon.feature, {
                            style: function (feature, layer) {
                                return {
                                    fillColor: polygon.style.backgroundColor,
                                    fillOpacity: polygon.style.backgroundOpacity,
                                    color: polygon.style.borderColor,
                                    weight: polygon.style.borderWidth,
                                }
                            },
                            onEachFeature: function (feature, layer) {
                                layer.id = polygon.id;
                                layer.shapeType = 'Polygon';
                                layer.feature = polygon.feature
                                self.polygonLayersGroup.addLayer(layer)
                                self.setEvents(layer)

                            }
                        })
                    }
                });
                self.polygonLayersGroup.addTo(self.map);
                self.$store.commit('Polygon/setRedrawPolygons', false);
            }).catch(() => {
            })


        },
        clearProjectPolygon() {
            this.polygonLayersGroup.removeFrom(this.map);
            this.polygonLayersGroup.eachLayer((layer) => {
                layer.removeFrom(this.map)
                layer.remove()
            });
            this.$store.commit("Polygon/setPolygon", []);

        },
        setEvents(layer) {
            const self = this;
            layer.on('pm:update pm:dragend pm:markerdragend', function (e) {
                self.$store.dispatch("Polygon/update", {
                    id: e.layer.id,
                    params: {
                        geometry: btoa(JSON.stringify(e.layer.toGeoJSON()))
                    }

                });
            });
            layer.on('click', e => {
                if (self.map.pm.globalRemovalModeEnabled()) {
                    return;
                }
                L.DomEvent.stopPropagation(e);
                self.$store.commit('Polygon/setBackgroundColor', layer.options.fillColor);
                self.$store.commit('Polygon/setBackgroundOpacity', layer.options.fillOpacity);
                self.$store.commit('Polygon/setBorderWidth', layer.options.weight);
                self.$store.commit('Polygon/setBorderColor', layer.options.color);
                self.$store.commit('Polygon/setCurrentPolygon', layer);

                self.$store.commit("PolygonPopup/setPopupShowed", true);
                self.$store.commit("PolygonPopup/setCoordinates", JSON.stringify(layer.feature))
                self.$store.commit("PolygonPopup/setPolygon", layer.id)
                self.$store.commit("PolygonPopup/setPopupLocation", layer.getCenter());
                self.$store.commit("PolygonPopup/setPopupContent", AreaPopup.getPopupContent(layer));
                self.$store.commit("PolygonPopup/setPerimeterHtml", AreaPopup.getPerimeterDetailsPolygon(layer));
                // layer.bindPopup(AreaPopup.getPopupContent(layer)).openPopup();

            })
            layer.on('pm:remove', function (e) {
                L.DomEvent.stopPropagation(e);
                self.$store.dispatch("Polygon/delete", e.layer.id, {root: true});

            });
        }

    },

}
export default PolygonToolBar;