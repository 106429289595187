<template>
  <div>
    <l-marker v-if="propertyMarkers.length>0" v-for="marker in propertyMarkers"
              :key="marker.key"
              :ref="marker.key"

              @click="propertyMarkerClick(marker,$event,true)"
              @ready="bounce(marker, $event)"
              :lat-lng="[marker.location.lat,marker.location.lng]">
      <l-icon v-if="marker.isFlagged"><img :src="marker.flag" width="16" height="16"/></l-icon>
      <l-icon v-else><img src="https://assets.postcodegenie.com/assets/img/house_fam.png" width="16" height="16"/>
      </l-icon>
    </l-marker>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import vue from 'vue';
import L from 'leaflet';
import {LIcon} from "vue2-leaflet"

export default {
  name: "PropertyMarkersGroup",
  components: {LIcon},
  computed: {
    ...mapGetters({
      markerToBounce: 'Search/getMarkerToBounce',
      'propertyMarkers': 'Search/getPropertyMarkers',
      locationInfoAvailable: 'Search/getLocationInfoAvailable',
      flagged: 'Properties/flagged',
    }),
  },
  watch: {
    markerToBounce(markerToBounce) {
      const self = this
      this.propertyMarkers.forEach(function (marker) {
        if (marker.key == markerToBounce) {
          self.$store.commit('Search/setMarkerToBounce', null)
          self.$refs[markerToBounce][0].mapObject.bounce();
        }
      });

    }
  },
  methods: {
    bounce(marker, event) {
      if (marker.key == this.markerToBounce) {
        event.bounce()
        this.$store.commit('Search/setMarkerToBounce', null)
      }
    },
    propertyMarkerClick(marker, event) {
      if (this.map.pm.globalRemovalModeEnabled() || this.map.pm.globalDrawModeEnabled() || this.map.pm.globalEditModeEnabled() || this.map.pm.globalDragModeEnabled()) {

        event.target.pm._layer._allowRemoval = false;
        event.target.pm._layer._pmTempLayer = true;
        event.target.pm._layer._dragDisabled = true;
        if (this.map.pm.globalDrawModeEnabled() || this.map.pm.globalEditModeEnabled()) {
          this.map.fire('click', this.map);
        }
        return event.originalEvent;
      }
      // load UPRN info
      this.$store.dispatch('Search/loadMultiUprnInfo', {
        uprn: marker.uprn,
        clear: false,
        all_properties: true
      });
      L.Marker.stopAllBouncingMarkers();
      this.$refs[marker.key][0].mapObject.bounce();
      const markerLatLng = L.latLng(marker.latitude, marker.longitude);
      const markerPoint = window.Lmap.latLngToContainerPoint(markerLatLng);
      const yOffset = window.innerHeight / 2 - 80;
      const newCenter = window.Lmap.containerPointToLatLng(L.point(markerPoint.x, markerPoint.y - yOffset));
      window.Lmap.setView(newCenter);
    },
    getFlagged(uprn) {
      return this.flagged.filter(item => item.uprn === uprn);
    },
  },

  created() {
    const self = this;

    this.$root.$on('bounceMarker', function (data) {
      vue.nextTick(() => {
        if (self.$refs[data] !== undefined) {
          L.Marker.stopAllBouncingMarkers();
          self.$refs[data][0].mapObject.bounce();
        }
      })
    });
    this.$root.$on('changeMarkerIcon', function (data) {
      vue.nextTick(() => {
        if (self.$refs[data.key] !== undefined && self.$refs[data.key][0] !== undefined) {
          self.$refs[data.key][0].mapObject.setIcon(L.icon({
            iconUrl: 'https://assets.postcodegenie.com/assets/img/icons/flags/flag_' + data.color + '.png',
            iconSize: [16, 16]
          }))
        }
      })
    });
    this.$root.$on('removeMarkerIcon', function (key) {
      vue.nextTick(() => {
        if (self.$refs[key] !== undefined && self.$refs[key][0] !== undefined) {
          self.$refs[key][0].mapObject.setIcon(L.icon({
            iconUrl: 'https://assets.postcodegenie.com/assets/img/house_fam.png',
            iconSize: [16, 16]
          }))
        }
      })
    });
  }
}
</script>

<style scoped>

</style>