<template>
  <div>
    <l-marker v-if="locationPhotoMarkers.length>0" v-for="marker in locationPhotoMarkers"
              :key="marker.location[0]+''+marker.location[1]"
              @click="locationPhotoMarkerClick(marker)"
              @ready="componentReady($event, marker)"
              :lat-lng="[marker.location[1],marker.location[0]]">
      <l-icon  :icon-size="[16,16]" icon-url="https://assets.postcodegenie.com/assets/photo_icon.png"></l-icon>
    </l-marker>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "LocationPhotoMarkerGroup",
  watch:{
  },
  computed:{
    ...mapGetters({
      locationPhotoMarkers:'LocationPhotos/locationsWithPhoto',
      currentLocation:'LocationPhotos/currentLocation'
    })
  },
  methods:{
    componentReady(event, marker) {
      const layer = event.pm._layer
      const self = this
      layer.on('pm:dragend', event => {
        axios.put(process.env.VUE_APP_API_URL+'/api/location_images/update_image_location',
            {
              'from': marker.location,
              'to': [event.target._latlng.lng,event.target._latlng.lat],
              'project_id': this.$store.state.Projects.selected_project.id
            }).then(() => {
                self.$store.dispatch('LocationPhotos/loadPhotoLocationsForPolygon');
        })
      });
    },

    locationPhotoMarkerClick:function(marker){
      if(!window.Lmap.pm.globalDragModeEnabled()) {
        this.$store.commit('LocationPhotos/setCurrentLocation', [marker.location[1], marker.location[0]])
        this.$bvModal.show('locationImagesList')
      }
    }
  }
}
</script>

<style scoped>

</style>