import Vue from "vue";
import CommonTools from "@/Helpers/CommonTools";

const state = {
  isInPointSelectMode: false,
  propertiesPointLocation: { lat: 0, lng: 0 },
  elevationShown: false,
  point1loader: false,
  point1Elevation: 0,
  point1Location: { lat: 0, lng: 0 },
  point2loader: false,
  point2Elevation: 0,
  point2Location: { lat: 0, lng: 0 },
  elevationLoader: false,
  elevationLinestring: null,
  elevations: [],
  lineColour: '#bd0404'

}
const actions = {}
const getters = {
  elevationShown: state => {
    return state.elevationShown
  },
  elevationData: state => {
    return state.elevations;
  },
  lineColour: state => {
    return state.lineColour;
  },
  isInPointSelectMode: state => {
    return state.isInPointSelectMode;
  },
  propertiesPointLocation: state => {
    return state.propertiesPointLocation;
  }
}
const mutations = {

  setElevationShown(state, data) {
    Vue.set(state, 'elevationShown', data);
  },
  setPoint1Loader(state, data) {
    Vue.set(state, 'point1loader', data)
  },
  setPoint2Loader(state, data) {
    Vue.set(state, 'point2loader', data)
  },
  setPoint1Elevation(state, data) {
    Vue.set(state, 'point1Elevation', CommonTools.round(data, 2));
  },
  setPoint2Elevation(state, data) {
    Vue.set(state, 'point2Elevation', CommonTools.round(data, 2));
  },
  setPoint1Location(state, data) {
    data.lat = CommonTools.round(data.lat, 6);
    data.lng = CommonTools.round(data.lng, 6);
    Vue.set(state, 'point1Location', data);
  },
  setPoint2Location(state, data) {
    data.lat = CommonTools.round(data.lat, 6);
    data.lng = CommonTools.round(data.lng, 6);
    Vue.set(state, 'point2Location', data);
  },
  setElevationLoader(state, data) {
    Vue.set(state, 'elevationLoader', data)
  },
  setElevations(state, data) {
    Vue.set(state, 'elevations', data);
  },
  setLineColour(state, data) {
    Vue.set(state, 'lineColour', data)
  },
  setPointSelection(state, data) {
    Vue.set(state, 'isInPointSelectMode', data)
  },
  setPropertiesPointLocation(state, data) {
    data.lat = CommonTools.round(data.lat, 6);
    data.lng = CommonTools.round(data.lng, 6);
    Vue.set(state, 'propertiesPointLocation', data);
  }
}
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
