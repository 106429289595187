<template>
  <b-dropdown v-if="maps.length>0" id="navbardrop" :text="dropDownTitle" variant="white" bvariant="outline-danger"
              toggle-class="overlay-object bg-white" class="d-block mr-2">
    <b-dropdown-item class="dropdown-item mapselector" @click="chooseMapMainLayer(index,item)"
                     v-for="(item,index) in maps"
                     :key="item.code">{{ item.name }}
      <b-icon-check v-if="item.active"></b-icon-check>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import {mapGetters} from 'vuex'

export default {

  data() {
    return {}
  },
  watch: {
    activeMap: function (value) {
      this.$store.dispatch('Maps/activateVectorLayer', {map: value})
      Lmap.fireEvent('activeMapChanged', value);
      this.$root.$emit('activeMapChanged', value);
    }
  },
  computed: {
    ...mapGetters({
      maps: 'Maps/getMaps',
      activeMap: 'Maps/getActiveMap',
    }),
    dropDownTitle: function () {
      if (Utils.isMobile()) {
        return '';
      }
      if (this.activeMap === undefined) {
        this.chooseMapMainLayer(0, this.maps['0'])
      }
      return this.activeMap.name;
    },

  },
  methods: {

    chooseMapMainLayer(i, item) {
      if (item.active === true) {
        return;
      }
      const self = this;
      this.$store.commit('State/setZoomMinMax', {min: item.minZoom, max: item.maxZoom})
      this.$store.state.Maps.maps.map(function (item, x) {
            if (x === i) {
              self.maps[x].active = true
              self.$store.dispatch('State/setActiveMap', self.maps[x], {root: true});
              if (self.maps[x].wmsLayerName !== null && self.maps[x].wmsLayerName.length > 0) {
                self.$store.commit('State/setShowVectorLayer', true, {root: true});
              } else {
                self.$store.commit('State/setShowVectorLayer', false, {root: true});
              }
            } else {
              self.maps[x].active = false
            }
          }
      )

    },

  },
}
</script>
<style>
#navbardrop {
  padding-top: 0 !important;
}
</style>