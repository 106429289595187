const ProjectInfoToolBar = {
    watch: {
        projectInfoShown(value) {
            const controls = document.getElementsByClassName('leaflet-right');
            if (value) {
                controls[0].style.right = '350px';
            } else {
                controls[0].style.right = '0';
            }
        },
    },
    computed: {
        projectInfoShown: {
            get: function () {
                return this.$store.state.State.projectInfoShown;
            },
            set(value) {
                this.$store.commit("State/setProjectInfoShown", value, {root: true});
                this.$store.commit("State/setPropertiesInfoShown", false, {root: true});

            }
        }
    },
    methods: {
        addProjectInfoControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'project_info',
                block: 'options',
                title: 'Project Information',
                actions: [],
                className: 'fas fa-list project_info-control',
                onClick: function (e) {
                    self.projectInfoShown = !self.projectInfoShown;
                },
                toggle: true

            })
        },
    }
}

export default ProjectInfoToolBar;