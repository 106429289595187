import StaticPopup from "@/js/Popups/StaticPopup";

class RoadsPopupAction extends StaticPopup {
    constructor(location, data, showGeoJson, VueStore) {
        super(location, data, showGeoJson, VueStore)
    }

    getContent() {
        let html = '';
        if (this.data.features.length > 0) {
            for (let feature of this.data.features) {
                if (feature.id.startsWith('street')) {
                    html += '<div class="flex">';
                    html += '<div><strong>USRN: ' + feature.properties.usrn.substring(4) + '</strong></div>';
                    html += this.getStreet(feature.properties)
                    html += this.getClassification(feature.properties)
                    html += this.getStreetState(feature.properties)
                    html += "<hr />"
                    html += "</div>";
                }

            }
        }
        return html;
    }

    getStreet(property) {
        const name = property.designated_name
        const authority = property.naming_authority;
        const descriptor = property.descriptor;
        let content = '';
        let html = '<div><strong>';
        if (name) {
            content += name;
        }
        if (content.length > 0 && authority && authority.length > 0) {
            content += ', ' + authority;
        } else if (authority && authority.length > 0) {
            content += authority;
        }
        if (content.length > 0 && descriptor && descriptor.length > 0) {
            content += ', ' + descriptor;
        } else if (descriptor && descriptor.length > 0) {
            content += descriptor;
        }
        html += content;
        html += '</strong></div>';
        console.log(html);
        return html
    }

    getClassification(property) {
        let html = '<div>Classification: None</div>';
        if (property.road_classification) {
            html = '<div>Classification: ' + property.road_classification + '</div>';
        }
        return html;
    }

    getStreetState(property) {
        let html = '';
        if (property.operational_state) {
            html = '<div>Street State: ' + property.operational_state + '</div>';
        }
        return html;
    }

}

export default RoadsPopupAction;