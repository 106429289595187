<template>
<div>
  <b-modal id="SubDuctStandAlone" title="Duct Info" centered ok-only no-close-on-backdrop no-close-on-esc
           @show="resetModal"
           @hidden="resetModal"
           @ok="ok"
  >
    <form ref="duct_info_modal_form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Duct Type"
          label-for="duct_type_id"
          invalid-feedback="Duct Type is required"
          :state="tubesState"
      >
          <multiselect v-model="ductTypeObject"  label="name" track-by="title" :options="ductTypes" :option-height="104"  :show-labels="false">
            <template slot="singleLabel" slot-scope="props">
              <div class="option__desc"><i class="ductTypeColor" :style="`background-color:${props.option.color}`"></i><span class="option__title">{{ props.option.name }}</span></div>

            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc"><i class="ductTypeColor" :style="`background-color:${props.option.color}`"></i><span class="option__title">{{ props.option.name }}</span></div>
            </template>
          </multiselect>
      </b-form-group>
    </form>
  </b-modal>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect'
export default {
  name: "SubDuctStandAlone",
  components:{Multiselect},
  data:function(){
    return {
      ductTypeObject:null,
      tubesCount:null,
      tubesState:null,
      ductType:null,
      ductTypeState:null,
      manufacturerName:null,
      productCode:null,
    }
  },
  props:{
    parentDuct:{
      type: Object
    },
  },
  methods:{
    ok(bvModalEvt){
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit()

    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      const params = {
        tubes_count:this.tubesCount,
        duct_type:this.ductType,
        manufacturer_code:this.manufacturerName,
        product_code:this.productCode,
        duct_type_id:this.ductTypeObject.id,
      };
      this.$store.dispatch('SubDuctInfoModal/saveSubDuct',params).then(response=>{
        this.$store.commit('SubDuctInfoModal/setParams',{},{root:true})
        this.$bvModal.hide('SubDuctStandAlone')
        this.$root.$emit('updateDuctInfo')
      });
    },
    checkFormValidity() {
      const valid = this.$refs.duct_info_modal_form.checkValidity()
      this.tubesState = valid
      this.ductTypeState = valid
      return valid
    },
    resetModal() {
      this.tubesCount = null
      this.nameState = null
      this.ductType = null
      this.ductTypeState = null
      this.manufacturerName = null
      this.productCode = null
      this.ductTypeObject = null;
    },
  },
  computed:{
    ...mapGetters({
      ductTypes:'DuctTypes/ductTypes'
    })
  }
}
</script>

<style scoped>
.ductTypeColor{
  width: 16px;
  height: 16px;
  float:left;
  margin-right: 10px;
}
</style>