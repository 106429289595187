import {mapGetters} from "vuex";
import DuctToolBar from "@/Mixins/Draw/ToolBar/DuctToolBar";
import ObjectMarkerToolBar from "@/Mixins/Draw/ToolBar/ObjectMarkerToolBar";
import ProjectInfoToolBar from "@/Mixins/Draw/ToolBar/ProjectInfoToolBar";
import LivePendingToolBar from "@/Mixins/Draw/ToolBar/LivePendingToolBar";
import SplitLinesToolBar from "@/Mixins/Draw/ToolBar/SplitLinesToolBar";
import MergeDuctsToolbar from "@/Mixins/Draw/ToolBar/MergeDuctsToolbar";
import Default from "@/Helpers/Default";
import PolygonToolBar from "@/Mixins/Draw/ToolBar/PolygonToolBar";
import CustomPropertiesToolbar from "@/Mixins/Draw/ToolBar/CustomPropertiesToolbar";
import ElevationControl from "@/Mixins/Draw/ToolBar/ElevationControl";
import CommonTools from "@/Helpers/CommonTools";
import PhotoLocationToolbar from "./ToolBar/PhotoLocationToolbar";
import UserLocationToolbar from "./ToolBar/UserLocationToolbar";
import saveLocationToolbar from "./ToolBar/saveLocationToolBar";
import TextToolbar from "@/Mixins/Draw/ToolBar/TextToolbar";

const Draw = {
    components: {},
    mixins: [DuctToolBar,
        ObjectMarkerToolBar,
        ProjectInfoToolBar,
        LivePendingToolBar,
        SplitLinesToolBar,
        PolygonToolBar,
        CustomPropertiesToolbar,
        MergeDuctsToolbar,
        ElevationControl,
        PhotoLocationToolbar,
        UserLocationToolbar,
        saveLocationToolbar,
        TextToolbar
    ],

    data: function () {
        return {
            drawControlLoaded: false,
            map: null,
            circles: [],
        }
    },
    computed: {
        ...mapGetters({
            loggedIn: 'Auth/isLoggedIn',
        }),
    },
    methods: {
        addDrawControl() {
            const self = this;
            if (this.loggedIn && !this.drawControlLoaded) {
                this.drawControlLoaded = true;
                window.Lmap.pm.addControls(Default.ToolBar);
                window.Lmap.pm.Toolbar.buttons.drawPolyline._button.title = 'New Duct'
                window.Lmap.pm.Toolbar.buttons.drawPolygon._button.title = 'New Polygon'
                window.Lmap.pm.Toolbar.buttons.drawText._button.title = 'New Text'
                window.Lmap.pm.Toolbar.buttons.editMode._button.title = 'Edit Duct'
                window.Lmap.pm.Toolbar.buttons.dragMode._button.title = 'Move Object'
                window.Lmap.pm.Toolbar.buttons.removalMode._button.title = 'Remove Duct or Object'
                this.correctDuctDrawControl();
                this.addCustomDraw()
                this.addLivePendingControl();
                this.addSplitLinesControl();
                this.addMergeDuctsControl();
                this.addSaveLocationControl();
                this.addProjectInfoControl();
                this.addCustomPropertiesInfoControl();
                this.addImageGroupControl();
                this.addUserLocationControl();
                this.drawEventCreated();
                this.drawEventStart();
                window.Lmap.pm.Toolbar.changeControlOrder([
                    'drawPolyline', 'ObjectMarker', 'drawPolygon'
                ])
                window.Lmap.on('pm:globaldragmodetoggled', e => {
                    if (window.Lmap.pm.globalDragModeEnabled()) {
                        this.$store.commit("Duct/setRedrawDuctsForMobile", true);
                        window.Lmap.eachLayer(e => {
                            if (e.options.preventDragging !== undefined && e.options.preventDragging === true) {
                                e.pm.disableLayerDrag();
                                return;
                            }
                        })
                    } else {
                        this.$store.commit("Duct/setRedrawDuctsForMobile", false);
                    }
                });
            }

        },
        updateDrawControls() {
            if (this.loggedIn) {
                if (!this.drawControlLoaded) {
                    this.addDrawControl();
                }
                if (window.Lmap.getZoom() > 13) {
                    this.setStateForToolBarItems(false);
                    this.drawMarkers();
                    this.drawDucts();
                    this.drawProjectText()

                }
                if (window.Lmap.getZoom() <= 13) {
                    this.setStateForToolBarItems(true);
                    this.clearDucts();
                    this.clearObjects();
                    this.clearProjectText();
                }
            }
        },
        drawEventCreated() {
            let self = this;
            window.Lmap.on('pm:create', function (e) {
                if (e.shape === 'Line') {
                    self.ductCreatingAction(e);
                }
                if (e.shape === 'ObjectMarker') {
                    self.objectCreatingAction(e);
                }
                if (e.shape === 'Polygon') {
                    self.drawProjectPolygonCreateAction(e)
                }
                if (e.shape === 'Text') {
                    e.layer.on('pm:edit', function (x) {
                        self.saveText(x)
                    });
                }
            });
        },
        drawEventStart() {
            const self = this;

            window.Lmap.on('pm:drawstart', function (e) {
                if (e.shape === 'Line') {
                    self.ductStartAction(e);
                    self.map.pm.Draw.Line._syncHintMarker = function (e) {
                        if (this.enabled()) {
                            // move the cursor marker
                            this._hintMarker.setLatLng(e.latlng);

                            // if snapping is enabled, do it
                            if (this.options.snappable) {
                                const fakeDragEvent = e;
                                fakeDragEvent.target = this._hintMarker;
                                this._handleSnapping(fakeDragEvent);
                            }

                            // if self-intersection is forbidden, handle it
                            if (!this.options.allowSelfIntersection) {
                                this._handleSelfIntersection(true, e.latlng);
                            }
                            let coordinates = this._layer.toGeoJSON().geometry.coordinates;
                            coordinates.push([e.latlng.lng, e.latlng.lat]);
                            const length = CommonTools.calculateLength(coordinates);
                            this._hintMarker.setTooltipContent("Length : <strong>" + CommonTools.round(length, 2) + "</strong> Meters");
                        }
                    };
                } else if (e.shape === 'Marker') {
                    self.objectStartAction(e);
                } else if (e.shape === 'Polygon') {
                    self.drawProjectPolygonStartAction(e);
                }
            });
        },
        setStateForToolBarItems(state) {
            window.Lmap.pm.Toolbar.setButtonDisabled('Polyline', state)
            window.Lmap.pm.Toolbar.setButtonDisabled('ObjectMarker', state)
            window.Lmap.pm.Toolbar.setButtonDisabled('livepending', state)
            window.Lmap.pm.Toolbar.setButtonDisabled('splitlines', state)
            window.Lmap.pm.Toolbar.setButtonDisabled('Text', state)
        },
    }
}
export default Draw;

function stopPropagation(e) {

    if (e.stopPropagation) {
        e.stopPropagation();
    } else if (e.originalEvent) {  // In case of Leaflet event.
        e.originalEvent._stopped = true;
    } else {
        e.cancelBubble = true;
    }
    skipped(e);

    return this;
}