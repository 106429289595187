import Vue from "vue";
import {BModal} from "bootstrap-vue";

const SelectDateModal = Vue.extend({
    components: {
        BModal
    },
    props: {
        title: {
            type: String,
            default: "Select Date",
        },
        minDate: null,
        format: 'YYYY-MM-DD',
        placeholder: 'Start Date',
        meth: {
            type: Function
        },
    },
    data() {
        return {
            selectedDate: null
        }
    },
    methods: {
        ok() {
            this.meth(this.selectedDate)
        }
    },
    template: `
      <b-modal @ok="ok" :title="title" id="select-date-modal">
        <b-datepicker :placeholder="placeholder" :min="minDate" :format="format"
                      v-model="selectedDate"/>
      </b-modal>
    `
});
export default {
    install: (app, options) => {
        app.prototype.$selectDate = (config) => {
            // retrieve a nested property in `options`
            // using `key` as the path
            const instance = new SelectDateModal({
                propsData: {
                    title: config.title,
                    minDate: config.minDate,
                    placeholder: config.placeholder,
                    format: config.format,
                    meth: config.meth
                }
            });
            instance.$mount();
            instance.$bvModal.show('select-date-modal');
            document.body.appendChild(instance.$el);
            return null;
        }
    }
}