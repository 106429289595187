import dayjs from "dayjs";

const DownloadPropertyInfo= {
    data: ()=>{
      return {
          csvDownloadLink:null,
          csvAllDownloadLink:null,
          postcodesDownloadLink:null,
          kmlAllDownloadLink:null,
          kmlDownloadLink:null,
          kmlOpenreachDownloadLink:null,
          kmlAllOpenreachDownloadLink:null,
          hash:null,
          roadWorkCount:0,
      }
    },
    methods:{
        getHash(type,projectId,polygonId=0) {
            if(polygonId!==0){
                return axios.get(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/polygon/' + polygonId + '/' + type + '_link');
            }
            return axios.get(process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/polygon/' + type + '_link');
        },
        downloadCsv(projectId,polygonId=0) {
            this.getHash('csv',projectId,polygonId).then(response=>{
                this.hash = response.data.hash;
                if(polygonId===0){
                    this.csvDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/csv/'+this.hash;
                    return;
                }
                this.csvDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/polygon/' + this.polygon+'/csv/'+this.hash;
            });
        },
        downloadAllPolygonsCsv(projectId) {
            this.getHash('csv',projectId).then(response=>{
                this.hash = response.data.hash;
                this.csvAllDownloadLink = process.env.VUE_APP_API_URL + '/api/project/' + projectId +'/all_polygons/csv/'+this.hash;
            });
        },
        downloadAllKML(projectId) {
            this.getHash('kml',projectId).then(response=>{
                this.hash = response.data.hash;
                this.kmlAllDownloadLink = process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/all_polygons/kml/'+this.hash;
            });
        },
        downloadKML(projectId,polygonId=0) {
            this.getHash('kml',projectId,polygonId).then(response=>{
                this.hash = response.data.hash;
                if(polygonId===0){
                    this.kmlDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/kml/'+this.hash;
                    return;
                }
                this.kmlDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/polygon/' + this.polygon+'/kml/'+this.hash;
            });
        },
        downloadKMLOpenreach(projectId,polygonId){
            this.getHash('kmlOpenreach',projectId,polygonId).then(response=>{
                this.hash = response.data.hash;
                if(polygonId===0){
                    this.kmlOpenreachDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/kmlOpenreach/'+this.hash;
                    return;
                }
                this.kmlOpenreachDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/polygon/' + this.polygon+'/kmlOpenreach/'+this.hash;
            });
        },
        downloadPostcodes(projectId,polygonId){
            this.getHash('postcodes',projectId,polygonId).then(response=>{
                this.hash = response.data.hash;
                if(polygonId===0){
                    return;
                }

                this.postcodesDownloadLink = process.env.VUE_APP_API_URL + '/project/' + projectId + '/polygon/' +polygonId+'/postcodes_link/'+this.hash;
            });
        },
        downloadAllKMLOpenreach(projectId){
            this.getHash('kmlOpenreach',projectId).then(response=>{
                this.hash = response.data.hash;
                this.kmlAllOpenreachDownloadLink = process.env.VUE_APP_API_URL + '/api/project/' + projectId + '/all_polygons/openreach/kml/'+this.hash;
            });
        },
        getAreaLink(projectId,type) {
            return process.env.VUE_APP_API_URL + '/project/' + projectId + '/'+type+'/'+this.hash;
        },
        getRoadWorkCount(polygon) {
            return axios.post(process.env.VUE_APP_API_URL + '/api/layer/roadwork/count',{
                polygon: btoa(polygon),
                start_date: dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'),
                end_date: dayjs().format('YYYY-MM-DD'),
            }).then(response=>{
                this.roadWorkCount = response.data.count;
            })
        }
    }
}
export default DownloadPropertyInfo;