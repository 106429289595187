<template>

  <div class="d-print-none">
    <div class="panel-toggle" :style="cssProps" id="nav_toggle" v-bind:class="{ active: isActive}"
         @click="showHideNav()">
      <div class="toggle-handle" aria-label="Toggle panel">
        <b-icon-chevron-left></b-icon-chevron-left>
      </div>
    </div>
    <div id="mySidenav" class="sidenav" v-bind:class="{ active: isActive}">
      <div class="h-100">
        <div v-for="(layers,index) in this.allLayers" :key="layers.id">
          <b-button v-b-toggle="layers.code" class="menu_header" @click="toggle(index)">
            {{ layers.name }}
          </b-button>
          <b-collapse v-model="collapseStates[index]" :id="layers.code" class="mt-2">
            <b-card>
            <span v-for="subLayers in layers.sub_layers">
              <MapLayerRoadWorkCheckbox v-if="subLayers.code==='roadworks'" :key="subLayers.id" :name="subLayers.name"
                                        :id="subLayers.code"
                                        :checked="subLayers.checked === 1 || subLayers.checked === true">
            </MapLayerRoadWorkCheckbox>
              <MapLayerUserDataCheckbox v-else-if="subLayers.code==='user_data_files'" :key="subLayers.id"
                                        :name="subLayers.name"
                                        :id="subLayers.code"
                                        :checked="subLayers.checked === 1 || subLayers.checked === true">
            </MapLayerUserDataCheckbox>
            <MapLayerCheckbox v-else :key="subLayers.id" :name="subLayers.name"
                              :id="subLayers.code" :checked="subLayers.checked === 1 || subLayers.checked === true">
            </MapLayerCheckbox>
              </span>
            </b-card>
          </b-collapse>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MapLayerCheckbox from './MapLayerCheckbox'
import {mapGetters} from "vuex";
import MapLayerRoadWorkCheckbox from "@/components/partials/CheckBoxTypes/MapLayerRoadWorkCheckbox";
import MapLayerUserDataCheckbox from "@/components/partials/CheckBoxTypes/MapLayerUserDataCheckbox";

export default {

  name: "LayerSelector",
  data() {
    return {
      isActive: false,
      collapseStates: [true, false, false, false, false, false],
      cssProps: {
        backgroundImage: `url(${require('@/assets/img/nav_arrow.svg')})`
      }
    }
  },
  methods: {
    showHideNav() {
      this.isActive = !this.isActive;
      this.$store.commit('LayersList/setMenuOpened', this.isActive);
    },
    toggle(i) {
      const self = this;
      this.collapseStates.map(function (item, x) {
        if (x === i) {
          self.collapseStates[x] = true
        } else {
          self.collapseStates[x] = false
        }
      })
    },
  },
  components: {
    MapLayerUserDataCheckbox,
    MapLayerRoadWorkCheckbox,
    MapLayerCheckbox
  },
  computed: {
    ...mapGetters({
      allLayers: 'LayersList/getAllLayers',
      checkoxes: 'State/checkboxes',
    }),
    layersList: {
      get() {
        return this.$store.state.LayersList.layers;
      }
    }
  },
  created() {
    // this.$root.$on('layersListLoaded', () => {
    //   if (Object.keys(this.checkoxes).length > 0) {
    //     for (let checkbox in this.checkoxes) {
    //       console.log(checkbox);
    //     }
    //   }
    // })
  }
}
</script>

<style scoped>
.section {
  height: calc(100% - 80px);
}

.bg-light {
  background-color: #f8f9fa !important;
}

.toggle-handle {
  position: absolute;
  top: calc((100% - 120px) / 2);
  width: 34px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
}

.panel-toggle {
  position: absolute;
  width: 38px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  pointer-events: none;
  z-index: 99999;

}

.sidenav {
  display: none;
  width: 350px;
  position: absolute;
  z-index: 9999;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  box-shadow: 6px 0px 11px -11px #000000, 0px 5px 13px 5px rgba(0, 0, 0, 0);

}

.menu_header {
  margin-top: 1px;
  font-size: 14px !important;
  background-color: #2c3e50 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: white !important;
  cursor: pointer;
  width: 100%;
  border-radius: 0 !important;
}

#nav_toggle {
  margin-left: 0px;
}

#nav_toggle.active {
  margin-left: 350px;
}

#mySidenav.active {
  display: block !important;
}

/* Desktop */
@media (min-width: 961px) {
  .panel-toggle {
    top: 50%;
  }

  .sidenav {
    height: calc(100% - 70px);
  }
}

/* Mobile */
@media (max-width: 960px) {
  .panel-toggle {
    top: calc(100% - 200px);
  }

  .sidenav {
    height: calc(100% - 56px);
  }
}
</style>