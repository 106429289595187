import L from "leaflet";
import 'leaflet-geometryutil';

export default class SplitLineString {
    constructor(pointsArr, latLng) {
        if (pointsArr.length < 2) throw new Error('Invalid Line String');
        if (!this.location instanceof L.LatLng) throw new Error('Invalid Location');
        for (let i = 0; i < pointsArr.length; i++) {
            if (!pointsArr[i] instanceof L.LatLng) throw new Error('Invalid Line String');
        }
        this.pointsArr = pointsArr;
        this.location = latLng;

    }


    splitLine() {
        let ALineCoordinates = [];
        let BLineCoordinates = [];
        for (let index = 0; index < this.pointsArr.length - 1; index++) {
            const ALatLng = this.pointsArr[index];
            const BLatLng = this.pointsArr[index + 1];
            const belongsToAB = L.GeometryUtil.belongsSegment(this.location, ALatLng, BLatLng, 0.01)
            ALineCoordinates.push(ALatLng);
            if (belongsToAB) {
                ALineCoordinates.push(this.location);
                BLineCoordinates.unshift(this.location);
                for (let bIndex = index + 1; bIndex < this.pointsArr.length; bIndex++) {
                    BLineCoordinates.push(this.pointsArr[bIndex]);
                }
                break;
            }
        }
        return {A: ALineCoordinates, B: BLineCoordinates};
    }
}
