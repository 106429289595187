import dayjs from "dayjs";

const state = {
    items: [],
    start_date: localStorage.getItem('roadwork_start_date') !== null ? localStorage.getItem('roadwork_start_date') : dayjs().subtract('5', 'month').startOf('month').format('YYYY-MM-DD'),
    end_date: localStorage.getItem('roadwork_end_date') !== null ? localStorage.getItem('roadwork_end_date') : dayjs().format('YYYY-MM-DD'),
}
const actions = {
    getWithinEnvelope(ctx, data) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/layer/roadwork', data).then(response => {
            ctx.commit('setItems', response.data);
        })
    }
}
const getters = {
    getItems(state) {
        return state.items;
    },
    getStartDate(state, getters, rootState, rootGetters) {
        const dbData = rootGetters["State/checkboxes"];

        if (dbData !== null && dbData !== undefined) {

            const roadWork = dbData['roadworks'];
            if (roadWork !== undefined && roadWork['data'] !== undefined && roadWork['data']['startDate'] !== undefined) {
                return roadWork['data']['startDate'];
            }
        }
        return state.start_date;
    },
    getEndDate(state, getters, rootState, rootGetters) {
        const dbData = rootGetters["State/checkboxes"];
        if (dbData) {
            const roadWork = dbData['roadworks'];
            if (roadWork !== undefined && roadWork['data'] !== undefined && roadWork['data']['endDate'] !== undefined) {
                return roadWork['data']['endDate'];
            }
        }
        return state.end_date;
    }
}
const mutations = {
    setItems(state, data) {
        state.items = data;
    },
    setStartDate(state, data) {
        state.start_date = data;
        localStorage.setItem('roadwork_start_date', data);
    },
    setEndDate(state, data) {
        state.end_date = data;
        localStorage.setItem('roadwork_end_date', data);
    }
}
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}