<template>
<div>
  <b-modal id="ductInfoCreateModal" title="Duct Info" centered ok-only no-close-on-backdrop no-close-on-esc
           @show="resetModal"
           @hidden="resetModal"
           @ok="handleOk"
  >
    <form ref="duct_info_modal" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label="Tubes Count"
          label-for="tubes_count"
          invalid-feedback="Tubes Count is required"
          :state="tubesState"
      >
        <b-form-input required id="tubes_count" v-model="tubesCount" title="Tubes Count"></b-form-input>
      </b-form-group>
      <b-form-group
      label-for="duct_type"
      label="Duct Type"
      invalid-feedback="Duct Type is required"
      :state="ductTypeState"
      >
        <b-form-select required id="duct_type" v-model="ductType">
          <b-form-select-option value="underground">Underground</b-form-select-option>
          <b-form-select-option value="overhead">Overhead</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-row>
        <label for="manufacturer_name">Manufacturer Name</label>
        <b-form-input id="manufacturer_name" v-model="manufacturerName"></b-form-input>
      </b-form-row>
      <b-form-row>
        <label for="product_code">Product Code</label>
        <b-form-input id="product_code" v-model="productCode"></b-form-input>
      </b-form-row>
    </form>
  </b-modal>
</div>
</template>

<script>
export default {
  name: "DuctInfoCreate",
  data:function(){
    return {
      tubesCount:null,
      tubesState:null,
      ductType:null,
      ductTypeState:null,
      manufacturerName:null,
      productCode:null,
    }
  },
  methods:{
    checkFormValidity() {
      const valid = this.$refs.duct_info_modal.checkValidity()
      this.tubesState = valid
      this.ductTypeState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit(){
// Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      const self = this;
      this.$nextTick(() => {
        self.$store.commit('Duct/setNewDuctTmpInfo',{
          tubesCount:this.tubesCount,
          ductType:this.ductType,
          manufacturerName:this.manufacturerName,
          productCode:this.productCode,
        },{root:true})
        self.$bvModal.hide('ductInfoCreateModal')
      })
    },
    resetModal() {
      this.tubesCount = null
      this.nameState = null
      this.ductType = null
      this.ductTypeState = null
      this.manufacturerName = null
      this.productCode = null
    },
    setDuctInfo(){

    }
  }
}
</script>

<style scoped>

</style>