const state = {
    streetCabinetId: null,
    movableObject: null,
    frontPorts: [],
    rearPorts: [],
    connections: [],
};
const actions = {
    getConnections(context, data) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.id + '/ot_street_cabinet/connections').then(response => {
            context.commit('setConnections', response.data.data)
        })
    }
};
const getters = {
    streetCabinetId: state => state.streetCabinetId,
    front: state => state.frontPorts,
    rear: state => state.rearPorts,
    movableObject: state => state.movableObject,
    connections: state => state.connections,
};
const mutations = {
    setConnections(state, data) {
        state.connections = data;
    },
    setStreetCabinetId(state, data) {
        state.streetCabinetId = data;
    },
    setMovableObject(state, data) {
        state.movableObject = data;
    },
    setFrontPorts(state, data) {
        state.frontPorts = data;
    },
    setRearPorts(state, data) {
        state.rearPorts = data;
    }

};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}