<template>
  <div>
    <div class="ducts-list">

      <span class="duct-list-item row col" @click="showDuctInfo(duct)"><img
          src="../../../../assets/img/fiberCable.svg"/><div>D-{{ duct.id }}</div><b-overlay :spinner-small="true"
                                                                                            rounded="sm"
                                                                                            :show="loading"
                                                                                            class="ml-3"></b-overlay>
      </span>
      <div v-if="this.ductInfo!==null && this.ductInfo.tubes!==null" class="tubes-info">
        <div class="tubes-list">
          <span class="tubes-list-item row col" v-for="tube in this.ductInfo.tubes">
            <TubesListItem :tube="tube"/>
          </span>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import TubesListItem from "@/components/modals/Duct/Info/TubesListItem";

export default {
  name: "DuctInfoShort",
  components: {
    TubesListItem,
    DuctsInfoShort: () => {
      return import('./DuctsInfoShort')
    }
  },
  props: ['duct'],
  data: () => {
    return {
      ductInfo: null,
      tubeInfo: null,
      loading: false,
      showConnectionButton: true,
    }
  },
  methods: {
    showDuctInfo(duct) {
      if (this.ductInfo !== null) {
        this.ductInfo = null;
        return;
      }
      this.loading = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + duct.project_id + '/duct/' + duct.id).then(response => {
        this.loading = false;
        this.ductInfo = response.data.data;
      })
    },


  }
}
</script>

<style scoped>
.duct-list-item {
  cursor: pointer;
  padding-left: 10px;
  float: left;
}

.duct-list-item img {
  float: left;
}

.ducts-list {
  list-style: none;
}

.tubes-info {
  margin-left: 30px;
  font-size: 10px;
}

.tubes-list {
  margin-left: 20px;
}

.tubes-list-item {
  cursor: pointer;
  margin-top: 2px;
}

</style>