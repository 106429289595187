import axios from 'axios';

const state = {
    defaultObjectTypes: [],
    current_request_id: null,
    connectableObjectTypes: [],
    objectTypes: null,
    typeListOverlay: false,
    permissions: null
};
const mutations = {
    setDefaultObjectTypes(state, data) {
        state.defaultObjectTypes = data;
    },
    setConnectableObjectTypes(state, data) {
        state.connectableObjectTypes = data;
    },
    setObjectTypes(state, data) {
        state.objectTypes = data;
    },
    setTypeListOverlay(state, data) {
        state.typeListOverlay = data;
    },
    setPermissions(state, data) {
        state.permissions = data;
    }
};
const actions = {
    getObjects(ctx, data) {
        if (this.current_request_id) {
            axios.cancel(this.current_request_id);
        }
        this.current_request_id = 'load_object_types';
        const self = this
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.toLowerCase(), {
            requestId: self.current_request_id
        }).catch(() => {
            self.current_request_id = null;
        })
    },
    getConnectableObjects(ctx, data) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.objectId + '/connectable/' + data.default_type_id).then(response => {
            ctx.commit('setConnectableObjectTypes', response.data.data)
        })
    },
    list({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/default').then(response => {
                commit('setDefaultObjectTypes', response.data.data)
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        })
    },
    getObjectTypes(ctx) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/type/all').then(response => {
            ctx.commit('setObjectTypes', response.data);
        })
    },
    getPermissions(ctx) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/type/permissions').then(response => {
            ctx.commit('setPermissions', response.data)
        })
    },
    store(ctx, data) {
        const self = this
        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.code.toLowerCase(), data).then(() => {
            self.dispatch('ObjectTypes/getObjectTypes')

        });
    },

    update(ctx, data) {
        return axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.code.toLowerCase() + '/' + data.id, data);
    },
    delete(ctx, data) {
        return axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.code.toLowerCase() + '/' + data.id);
    },
};
const getters = {
    getConnectableObjects() {
        return state.connectableObjectTypes;
    },
    getObjectTypes() {
        return state.objectTypes;
    },
    getTypeListOverlay() {
        return state.typeListOverlay;
    },
    getDefaultObjectType() {
        return state.defaultObjectTypes;
    },
    getPermissions() {
        return state.permissions;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
