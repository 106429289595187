import axios from "axios";

const state = {
    secondTube: null,
    firstTube: null,
    secondDuct: null,
    firstDuct: null,
    connectAllTubes: false
};
const actions = {
    updateDuctConnection({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.put(process.env.VUE_APP_API_URL + '/api/ducts/connect', data)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};
const mutations = {
    setConnectAllTubes(state, data) {
        state.connectAllTubes = data;
    },
    setFirstDuct(state, data) {
        state.firstDuct = data;
    },
    setSecondDuct(state, data) {
        state.secondDuct = data;
    },
    setFirstTube(state, data) {
        state.firstTube = data;
    },
    setSecondTube(state, data) {
        state.secondTube = data;
    }
};
const getters = {
    getConnectAllTubes: state => {
        return state.connectAllTubes;
    },
    getDuctConnection: state => {
        return {
            'firstDuct': state.firstDuct,
            'secondDuct': state.secondDuct,
            'firstTube': state.firstTube,
            'secondTube': state.secondTube
        };
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}