// noinspection CommaExpressionJS

let AreaPopup = {
    autoUnits: function (t, e = 'metric') {
        let i = "meters";
        if ("metric" !== e) {
            const o = this.metersToMiles(t);
            i = "miles", o < 1 ? (t = this.metersToYards(t), i = "yards") : t = o
        } else 1e4 < t && (t = this.metersToKm(t), i = "km");
        return {
            distance: t.toFixed(0),
            units: i
        }
    },
    getAutoUnitsAsText: function (t, e = "metric") {
        const i = this.autoUnits(t, e);
        return i.distance + " " + i.units
    },
    getAutoUnitsAsHtml: function (t, e) {
        const i = this.autoUnits(t, e);
        return '<td style="text-align:right">' + i.distance + '</td><td>' + i.units + "</td>"
    },
    degToRad: function (t) {
        return t * Math.PI / 180
    },
    radToDeg: function (t) {
        return 180 * t / Math.PI
    },
    kmToMiles: function (t) {
        return t / 1.609344
    },
    metersToYards: function (t) {
        return 1.0936133 * t
    },
    metersToMiles: function (t) {
        return t / 1609.344
    },
    kmToYards: function (t) {
        return 1093.6133 * t
    },
    milesToYards: function (t) {
        return 1760 * t
    },
    kmToMeters: function (t) {
        return 1e3 * t
    },
    metersToKm: function (t) {
        return t / 1e3

    },

    getAreas: function (t) {
        const e = {};
        return t = parseFloat(t), e.sqm = t.toFixed(0), e.sqkm = (t / 1e6).toFixed(3), e.sqmiles = (3.8610215854245365e-7 * t).toFixed(3), e.sqyards = (1.1959900463010182 * t).toFixed(0), e.sqft = (10.763910416709576 * t).toFixed(0), e.acres = (.0002471053814672524 * t).toFixed(3), e.hectares = (t / 1e4).toFixed(3), e
    },
    getHTML: function (t) {
        let e = '<table class="table table-striped">';
        e += '<tr>' +
            '<td style="white-space:nowrap">Area in Square meters</td><td>' + t.sqm + '</td></tr>',
            e += '<tr><td style="white-space:nowrap">Area in Square kilometers</td><td>' + t.sqkm + '</td></tr>',
            e += '<tr><td style="white-space:nowrap">Area in Square feet</td><td>' + t.sqft + '</td></tr>',
            e += '<tr><td style="white-space:nowrap">Area in Square yards</td><td>' + t.sqyards + '</td></tr>',
            e += '<tr><td style="white-space:nowrap">Area in Square miles</td><td>' + t.sqmiles + '</td></tr>',
            e += '<tr><td style="white-space:nowrap">Area in Acres</td><td>' + t.acres + '</tr>',
            e += '<tr><td style="white-space:nowrap">Area in Hectares</td><td>' + t.hectares + '</td></tr>';
        e += "</table>";
        return e;

    },

    geodesicArea: function (t) {
        let e, i, o = t.length,
            n = 0,
            r = Math.PI / 180;
        if (2 < o) {
            for (var a = 0; a < o; a++) e = t[a], n += ((i = t[(a + 1) % o]).lng - e.lng) * r * (2 + Math.sin(e.lat * r) + Math.sin(i.lat * r));
            n = 6378137 * n * 6378137 / 2
        }
        return Math.abs(n)
    },
    getAreaText: function (t) {
        const i = t._defaultShape ? t._defaultShape() : t.getLatLngs(),
            o = this.geodesicArea(i),
            n = this.getAreas(o);
        return this.getHTML(n) + this.getDetailsPolygon(t);
    },

    getDetailsPolygon: function (t) {
        let e, i = "",
            n = 0,
            r = t.getLatLngs();
        r = r[0];
        for (let a = 0; a < r.length - 1; a++) {
            n += e = r[a].distanceTo(r[a + 1]), i += this.getAutoUnitsAsText(e) + " x " + (a % 2 === 0 ? '<br />' : '');
        }
        n += r[r.length - 1].distanceTo(r[0]);
        return '<table class="table"><tr><td style="white-space:nowrap">perimeter Length</td> <td style="text-align:right">' + this.getAutoUnitsAsText(n) + '</td></tr> ';
    },
    getPerimeterDetailsPolygon: function (t) {
        let e, i = "",
            n = 0,
            r = t.getLatLngs();
        r = r[0];
        for (let a = 0; a < r.length - 1; a++) {
            n += e = r[a].distanceTo(r[a + 1]), i += this.getAutoUnitsAsText(e) + " x " + (a % 2 === 0 ? '<br />' : '');
        }
        n += r[r.length - 1].distanceTo(r[0]);
        return '<tr><td style="white-space:nowrap" colspan=2><div class="text-right">' + (i += this.getAutoUnitsAsText(e)) + "</div></tr>"
    },
    getPopupContent: function (t, e) {
        return t.feature.geometry.type === 'Polygon' ? this.getAreaText(t) : '';
    },
};

export default AreaPopup;