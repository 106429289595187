<template>
  <div>
    <l-layer-group v-if="locationInfoAvailable" :latLng="[getLocationInfo.latitude,getLocationInfo.longitude]"
                   ref="features">
      <l-popup v-if="locationInfoAvailable"
               :latLng="[getLocationInfo.latitude,getLocationInfo.longitude]" v-show="true"
               :options="{ autoClose: false, closeOnClick: false, closeButton: false }">
        <div ref="draggableContainerLocationPopup">

          <div class="d-flex justify-content-between p-1">
            <i class="fas fa-expand-arrows-alt draggable" @mousedown="dragMouseDown"></i>
            <i class="fas fa-times" @click="closePopup"></i>
          </div>
          <div class="d-flex justify-content-end">
            <b-dropdown id="dropdown_location_info" menu-class="dropdown-menu-class pull-right" variant="default"
                        toggle-class="text-decoration-none p-0" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v text-primary"></i>
              </template>
              <b-dropdown-item class="small"
                               @click="copyCoordinates(getLocationInfo.latitude.toFixed(6),getLocationInfo.longitude.toFixed(6))">
                <small>Copy Coordinates</small>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item class="small" :disabled="!this.$Utils.isIphone()" :href="appleMapsUrl"><small>Apple
                Maps</small>
              </b-dropdown-item>
              <b-dropdown-item class="small" :disabled="!this.$Utils.isMobile() || this.$Utils.isIphone()"
                               :href="googleMapsUrl"><small>Google
                Maps</small>
              </b-dropdown-item>
              <b-dropdown-item :href='kmlUrl' class='small' target=''><small>KML</small></b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="small" @click="copyURL"><small>Copy Location Url</small></b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="small" @click="closestProperty"><small>Closest Property</small></b-dropdown-item>
              <b-dropdown-item class="small" v-b-modal.add_sites><small>Add Site</small></b-dropdown-item>
              <b-dropdown-item class="small" @click="showLocationPhotoModal"><small>Upload Photo</small>
              </b-dropdown-item>
              <b-dropdown-item class="small" v-b-modal.add_property_information><small>Add New Property</small>
              </b-dropdown-item>
              <b-dropdown-item class="small" v-b-modal.OpenreachPiaWhereAbout><small>Openreach Whereabouts</small>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="popup-window">
            <img v-if="getLocationInfo.isFlagged==1" :src="getLocationInfo.flag" width="16" height="16">
            <br/>
            <table class="table table-bordered table-sm">
              <tr>
                <td class="font-weight-bold">Latitude</td>
                <td>{{ this.round(getLocationInfo.latitude, 6) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Longitude</td>
                <td>{{ this.round(getLocationInfo.longitude, 6) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">X (Easting)</td>
                <td>{{ parseInt(getLocationInfo.x_coordinate) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Y (Northing)</td>
                <td>{{ parseInt(getLocationInfo.y_coordinate) }}</td>
              </tr>
              <tr>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Grid Reference</td>
                <td>{{ getLocationInfo.reference_letter }}{{ getLocationInfo.easting }}{{
                    getLocationInfo.northing
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">What3Words</td>
                <td> {{
                    this.$WhatTreeWords.latlon2words(parseFloat(getLocationInfo.latitude), parseFloat(getLocationInfo.longitude))
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Postcode</td>
                <td>{{ getLocationInfo.postcode }}</td>
              </tr>
            </table>
            <div class="w-100 mt-2" v-show="!additionalInfoLoaded">
              <b-overlay
                  :show="showButtonLoading"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block w-100"
              >
                <div v-on:click='loadAdditionalLocation' class='additional_info_content_container'>
                  <button class='row col btn btn-danger'>Load additional information</button>
                </div>

              </b-overlay>
            </div>
            <div v-show="additionalInfoLoaded">
              <AdditionalInfo :uprn-additional-info="additionalInfo"></AdditionalInfo>
            </div>
          </div>
        </div>
      </l-popup>
    </l-layer-group>
    <b-modal v-if="locationInfoAvailable" id="add_property_information" centered title="New Property Information"
             @ok="saveCustomProperty" @show="beforeNewPropertyModalShow">

      <b-form-group label="Organisation Name" label-for="Organisation-Name">
        <b-form-input id="Organisation-Name" v-model="customProperty.ORGANISATION_NAME" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Building Name" label-for="Building-Name">
        <b-form-input id="Building-Name" v-model="customProperty.BUILDING_NAME" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Building Number" label-for="Building-Number">
        <b-form-input id="Building-Number" v-model="customProperty.BUILDING_NUMBER" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Dependant Thoroughfare" label-for="Dependant-Thoroughfare">
        <b-form-input id="Dependant-Thoroughfare" v-model="customProperty.DEPENDENT_THOROUGHFARE" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Throughfare" label-for="Throughfare">
        <b-form-input id="Throughfare" v-model="customProperty.THOROUGHFARE" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Double Dependant Locality" label-for="Double-Dependant-Locality">
        <b-form-input id="Double-Dependant-Locality" v-model="customProperty.DOUBLE_DEPENDENT_LOCALITY" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Locality" label-for="Locality">
        <b-form-input id="Locality" v-model="customProperty.DEPENDENT_LOCALITY" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Post Town" label-for="Post-Town">
        <b-form-input id="Post-Town" v-model="customProperty.POST_TOWN" type="text"
                      required></b-form-input>
      </b-form-group>
      <b-form-group label="Postcode" label-for="Postcode">
        <b-form-input id="Postcode" v-model="customProperty.POSTCODE" type="text"
                      required></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal title="Add site on the current location" @ok="saveSite" ok-title="Save"
             :ok-disabled="siteName===null || siteName.length<3" id="add_sites" v-if="loggedIn">
      <label for="input-live">Site name:</label>
      <b-form-input
          id="input-live"
          v-model="siteName"
          aria-describedby="input-live-help input-live-feedback"
          trim
      ></b-form-input>
    </b-modal>
    <LocationsPhotoList/>
    <OpenreachWhereAbouts/>
  </div>
</template>

<script>
import {LPopup} from "vue2-leaflet";
import {mapGetters} from 'vuex'
import axios from 'axios';
import Helpers from "@/Mixins/Helpers";
import LocationsPhotoList from "@/components/modals/LocationPhoto/LocationsPhotoList";
import CommonTools from "@/Helpers/CommonTools";
import OpenreachWhereAbouts from "@/components/Modules/OpenreachPIA/components/modals/OpenreachWhereAbouts";
import AdditionalInfo from "@/components/partials/AdditionalInfo.vue";

export default {
  name: "LocationInfoPopup",
  mixins: [Helpers],
  data() {
    return {
      showButtonLoading: false,
      additionalInfoLoaded: false,
      additionalInfo: {},
      customProperty: {
        ORGANISATION_NAME: '',
        BUILDING_NAME: '',
        BUILDING_NUMBER: '',
        DEPENDENT_THOROUGHFARE: '',
        THOROUGHFARE: '',
        DOUBLE_DEPENDENT_LOCALITY: '',
        DEPENDENT_LOCALITY: '',
        POST_TOWN: '',
        project_id: null,
        lat: null,
        lng: null,
        POSTCODE: null
      },
      siteName: null,
    }

  },
  watch: {
    locationInfoAvailable: function (value) {
      if (value) {
        this.$root.$emit('hideLoader');
        this.$nextTick(() => {
          this.$refs.features.mapObject.openPopup([this.getLocationInfo.latitude, this.getLocationInfo.longitude]);
          this.customProperty.lat = this.getLocationInfo.latitude;
          this.customProperty.lng = this.getLocationInfo.longitude;
          this.customProperty.POSTCODE = this.getLocationInfo.postcode;
          this.customProperty.project_id = this.selectedProject.id;
        })
      } else {
        this.additionalInfoLoaded = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      getLocationInfo: 'Search/getLocationInfo',
      locationInfoAvailable: 'Search/getLocationInfoAvailable',
      selectedProject: 'Projects/selectedProject',
      loggedIn: 'Auth/isLoggedIn'
    }),
    kmlUrl() {
      return process.env.VUE_APP_API_URL + "/api/kml/" + this.getLocationInfo.latitude + '/' + this.getLocationInfo.longitude;
    },
    googleMapsUrl() {
      return "comgooglemapsurl://?q=" + this.getLocationInfo.latitude + ',' + this.getLocationInfo.longitude + "&zoom=19&t=k&";
    },
    appleMapsUrl() {
      return "http://maps.apple.com/?ll=" + this.getLocationInfo.latitude + ',' + this.getLocationInfo.longitude + "&z=19&t=k&q=Here";
    }

  },
  methods: {
    copyCoordinates(lat, lng) {
      const el = document.createElement('textarea');
      el.value = lat + "," + lng;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

    },
    copyURL() {
      const el = document.createElement('textarea');
      const currentZoom = this.$store.getters['State/currentZoom']
      el.value = process.env.VUE_APP_BASE_URL + '/set_location/' + this.getLocationInfo.latitude + '/' + this.getLocationInfo.longitude + '/' + currentZoom;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

    },
    loadAdditionalLocation() {
      this.showButtonLoading = true;
      this.additionalInfo = {};
      axios.get(process.env.VUE_APP_API_URL + '/api/location/additional/' + this.getLocationInfo.latitude + '/' + this.getLocationInfo.longitude).then(response => {
        this.showButtonLoading = false;
        this.additionalInfoLoaded = true;
        this.additionalInfo = response.data.data;
      });
    },

    saveCustomProperty() {
      axios.post(process.env.VUE_APP_API_URL + '/api/project/' + this.selectedProject.id + '/customproperty', this.customProperty).then(() => {
        this.$swal("Success", "Custom Property has been added successfully", "success");
        this.$store.commit('Search/setLocationInfoAvailable', false)
        this.$store.dispatch("Search/searchCustomPropertiesInDistance", {
          center: {
            lat: this.customProperty.lat,
            lng: this.customProperty.lng
          }
        }, {root: true});
        this.$store.dispatch('LayersList/activateSubLayer', 'properties')
      })
    },
    beforeNewPropertyModalShow() {
      for (const key in this.customProperty) {
        if (key !== 'lat' && key !== 'lng' && key !== 'project_id') {
          this.customProperty[key] = null;
        }
      }
      axios.get(process.env.VUE_APP_API_URL + '/api/firstindistance/' + this.getLocationInfo.latitude + '/' + this.getLocationInfo.longitude + '/km/1').then(response => {
        let propertyData = response.data;
        this.customProperty.THOROUGHFARE = propertyData.thoroughfare;
        this.customProperty.DEPENDENT_THOROUGHFARE = propertyData.dependent_thoroughfare;
        this.customProperty.DOUBLE_DEPENDENT_LOCALITY = propertyData.double_dependent_locality;
        this.customProperty.DEPENDENT_LOCALITY = propertyData.dependent_locality;
        this.customProperty.POST_TOWN = propertyData.post_town;
        this.customProperty.POSTCODE = propertyData.postcode;
      });
    },
    showLocationPhotoModal() {
      this.$store.commit('LocationPhotos/setCurrentLocation', [this.getLocationInfo.latitude, this.getLocationInfo.longitude])
      this.$bvModal.show('locationImagesList')
    },
    closestProperty() {
      const self = this;
      const polygon = btoa(CommonTools.createPolygonFromBounds(Lmap.getBounds(), Lmap.getCenter()));
      this.$root.$emit('showLoader', 'Searching closest property');
      axios.get(process.env.VUE_APP_API_URL + '/api/nearest/property/' + this.getLocationInfo.latitude + '/' + this.getLocationInfo.longitude + '/' + polygon).then(response => {
        const uprn = response.data.data.uprn;
        this.$store.dispatch('Search/loadMultiUprnInfo', {
          uprn: uprn,
          clear: true,
          bounce: true,
          clearPostcode: true,
          all_properties: true,
        }).then(resp => {
          if (resp.data.data) {
            self.$root.$emit('bounceMarker', resp.data.data.key);
            self.$root.$emit('setCenter', {center: resp.data.data.location, zoom: 19});
          } else {
            self.$root.$emit('bounceMarker', resp.data[0].key);
            self.$root.$emit('setCenter', {center: resp.data[0].location, zoom: 19});
          }
        }).finally(() => {
          this.$root.$emit('hideLoader');
        });
      });
    },
    saveSite(e) {
      e.preventDefault();
      axios.post(process.env.VUE_APP_API_URL + '/api/sites',
          {
            name: this.siteName,
            lat: this.getLocationInfo.latitude,
            lng: this.getLocationInfo.longitude
          }
      ).then(response => {
        this.$swal("Success", "Site has been added successfully", 'success');
        this.$store.dispatch('Sites/getSites');
        this.$store.dispatch('LayersList/activateSubLayer', 'sites');
      }).catch(() => {
        this.$swal("Error", "Error while adding site", 'error');
      }).finally(() => {
        this.$bvModal.hide('add_sites')
        this.siteName = null;

      });
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()

      console.log(event)
      this.$refs.draggableContainerLocationPopup.style.top = (event.clientY - 10) + 'px'
      this.$refs.draggableContainerLocationPopup.style.left = (event.clientX - 60) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    closePopup() {
      this.additionalInfoLoaded = false;
      this.additionalInfo = {};
      this.$store.commit('Search/setLocationInfoAvailable', false)
    }
  },
  components: {
    AdditionalInfo,
    OpenreachWhereAbouts,
    LocationsPhotoList,
    LPopup,
  }

}
</script>

<style scoped>
.btn {
  font-size: 12px;
}

.infowindow p {
  font-size: 10px;
}

.kml-btn {
  color: white !important;
  text-align: center;
  float: none;
}

.popup-window {
  text-align: center;
}

.popup-window .result_item {
  text-align: center;
}

img, div {
  outline: none;
}
</style>