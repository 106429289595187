<template>
  <div id="app" class="h-100">
    <div class="container container-fluid mt-5">
      <div class="form-group mb-5 pb-5">
    <textarea class="form-control" v-model="uprn_store" rows="10">

    </textarea>
        <b-form-checkbox
            id="reorder"
            v-model="reorder"
            name="reorder"
            :value="true"
            :unchecked-value="false"
        >
          Reorder
        </b-form-checkbox>
        <button type="button" @click="getDirections" class="float-right btn btn-primary mt-3">Submit</button>
      </div>
      <div class="mt-5">
        <table class="mt-5 table table-striped">
          <thead>
          <tr>
            <th>UPRN</th>
            <th>Address</th>
            <th>Link</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="location in this.locations" :key="location.uprn">
            <td>{{location.uprn}}</td>
            <td>{{location.text_normal}}, <strong>{{location.text_bold}}</strong></td>
            <td>
              <a v-if="!isMobile()" :href="'https://www.google.com/maps/@'+location.lat+','+location.lon+',16z'" target="_blank" class="btn btn-info">View On Map</a>
              <div v-else-if="isIphone()">
              <a
                  :href='"http://maps.apple.com/?ll="+location.lat+","+location.lon+"&z=lon&t=k&q="+location.uprn'
                  class='btn btn-info' target='_blank'>Apple Maps</a>
              <a  :href="'comgooglemapsurl://?q='+location.lat+','+location.lon+'&zoom=19&t=k&'"
                 class="btn btn-info ml-2" target="_blank">Google Maps</a>
              </div>
                <a v-else :href="'geo:'+location.lat+','+location.lon+'?q='+location.lat+','+location.lon+'&z=19'"
                   class="btn btn-info" target="_blank">Google Maps</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Utils from "@/assets/js/libs/Utils";
import Vue from "vue";
export default {
  name: "Directions",
  data: function () {
    return {
      uprn_store: null,
      reorder: null,
      uprn_list: [],
      locations: [],
      url:'',
      userAgent:navigator.userAgent,
    }
  },
  methods: {
    getDirections() {
      this.showLoader = true;
      if (this.validateUprns()) {


        axios.post(process.env.VUE_APP_API_URL + '/api/property_list_uprn', {
          uprns: this.uprn_list,
          reorder: this.reorder
        }).then(response => {
          this.locations = response.data.locations;
          this.url = response.data.url;
          this.showLoader = false;
          this.uprn_store = '';
          for(let index in response.data.locations){
            this.uprn_store += response.data.locations[index].uprn+'\n';
          }
        });
      }
    },
    validateUprns() {
      this.uprn_list = this.uprn_store.split("\n");
      const self = this
      this.uprn_list.map(function (value, index) {
        if (isNaN(value)) {
          self.uprn_list.splice(index, 1);
        }
      })
      if (this.uprn_list.length > 0) {
        return true
      } else {
        this.$root.$emit('hideLoader')
        this.postcodes = null;
        self.$swal("Error", "No valid UPRN found in the list", "error");
        return false
      }
    },
    isMobile: function() {
      const pattern = /(samsungbrowser|SamsungBrowser|android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i;
      return pattern.test(navigator.userAgent)
    },
    isIphone: function() {
      return navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad");
    },
  }

}
</script>

<style scoped>

</style>