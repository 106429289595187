const state = {
    polygon: {},
    style: {},
    currentPolygon: {},
    currentLayer: {
        borderColor: '#0000FF',
        backgroundColor: '#0000FF',
        backgroundOpacity: 0.2,
        borderWidth: 1
    },
    current_request_id: null,
    redrawPolygons: false
};
const actions = {
    get(ctx, data) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/polygon/' + data.id, data)
    },
    list(ctx, bounds) {
        const self = this;
        if (this.current_request_id) {
            axios.cancel(this.current_request_id);
        }
        this.current_request_id = 'list_project_polygon';
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/polygon/search/' + selectedProject.id + '/' + btoa(bounds), {
                requestId: self.current_request_id
            }).then(response => {
                ctx.commit('setPolygon', response.data.data);
                this.current_request_id = null;
                resolve(response.data.data)
            }).catch(error => {
                this.current_request_id = null;
                reject(error);
            })
        })
    },
    store(ctx, data) {
        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/polygon', data);
    },
    update(ctx, data) {
        return axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/polygon/' + data.id, data.params);
    },
    delete(ctx, polygonId) {
        axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/polygon/' + polygonId).then(() => {
            ctx.commit('setRedrawPolygons', true);
            ctx.commit('setPolygon', {});
        });
    },
    properties(ctx, polygonId) {
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return axios.get(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject.id + /polygon/ + polygonId + '/properties');
    }
};
const getters = {

    polygon: state => {
        return state.polygon;
    },
    currentStyle: state => {
        return state.currentLayer;
    },
    backgroundColor: state => {
        return state.currentLayer.backgroundColor
    },
    backgroundOpacity: state => {
        return state.currentLayer.backgroundOpacity
    },
    redrawPolygons: state => {
        return state.redrawPolygons
    },
    borderColor: state => {
        return state.currentLayer.borderColor
    },
    borderWidth: state => {
        return state.currentLayer.borderWidth
    },
    currentPolygon: state => {
        return state.currentPolygon;
    }
};
const mutations = {
    setPolygon(state, data) {
        state.polygon = data;
    },
    setRedrawPolygons(state, data) {
        state.redrawPolygons = data;
    },
    setBackgroundColor(state, data) {
        state.currentLayer.backgroundColor = data;
    },
    setBackgroundOpacity(state, data) {
        state.currentLayer.backgroundOpacity = data;
    },
    setBorderColor(state, data) {
        state.currentLayer.borderColor = data;
    },
    setBorderWidth(state, data) {
        state.currentLayer.borderWidth = data;
    },
    setCurrentPolygon(state, data) {
        state.currentPolygon = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}