const MergeDuctsToolbar = {
    data: function () {
        return {
            mergeDuctsActive: false,
            firstDuctSelected: false
        }
    },
    methods: {

        addMergeDuctsControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'mergeDucts',
                block: 'custom',
                title: 'Merge Ducts',
                actions: [],
                className: 'fas fa-bacon mergeDucts-controll',
                onClick: () => {
                    self.mergeDuctsActive = !self.mergeDuctsActive;
                    if(self.mergeDuctsActive == false) {
                        self.$store.commit("Duct/setRedrawDuctsForMobile", false);
                        self.firstDuctSelected = false
                    }else{
                        self.$store.commit("Duct/setRedrawDuctsForMobile", true);
                    }
                },
                toggle: true
            });
        },
    }
}
export default MergeDuctsToolbar;