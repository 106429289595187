<script>

export default {
  name: "RelatedPartyList",
  data() {
    return {
      localRelatedParties: this.relatedParties
    }
  },
  props: {
    relatedParties: {
      type: Array,
      required: true
    },
    allowedRoles: {
      type: Array,
      required: false,
      default: ["Customer", "Agent Contact", "Alternate Contact", "DFE Contact", "Job Contact"],
    },
    isCreatedAllowed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    currentRelatedParties: {
      get() {
        return this.relatedParties;
      },
      set(value) {
        this.localRelatedParties.push(value)
      }

    },
    buildCompleteUsedRoles: function () {
      return this.localRelatedParties.map(relatedParty => relatedParty.role);
    }
  },
  methods: {
    updateRelatedParty(relatedParty, index) {
      this.$editRelatedParty({
        relatedParty: relatedParty,
        title: null,
        callback: (updated) => {
          this.localRelatedParties[index] = updated
        },
        roles: this.allowedRoles,
        usedRelatedParty: []
      })
    },
    showCreateRelatedParty() {
      this.$createRelatedParty({
        callback: (relatedParty) => {
          this.localRelatedParties.push(relatedParty);
        },
        roles: this.allowedRoles,
        usedRelatedParty: this.buildCompleteUsedRoles,
      });
    },
  }
}
</script>

<template>
  <div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Role</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(relatedParty, index) in currentRelatedParties" :key="'rp'+index">
        <td>{{ relatedParty.role }}</td>
        <td>{{ relatedParty.firstName }}</td>
        <td>{{ relatedParty.lastName }}</td>
        <td>{{ relatedParty.email }}</td>
        <td>
          <b-button size="sm" variant="primary"
                    @click="updateRelatedParty(relatedParty, index)">
            Edit
          </b-button>
        </td>
      </tr>
      </tbody>
    </table>
    <b-button v-if="isCreatedAllowed" class="float-right" variant="primary" size="sm" @click="showCreateRelatedParty">
      Add Related Party
    </b-button>
  </div>
</template>

<style scoped>

</style>