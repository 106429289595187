<template>
  <div>
    <div v-if="this.duct.parent_duct_id != undefined || this.duct.parent_tube_id != undefined" :style="titleIndent"
         class="ductTitle"><i :class="iconClasses"></i><span
        @click="toggleChildren">Duct {{ this.ductTitle }}  ({{ this.ductLength }}m)</span> <span
        @click="removeSubDuct(duct)"><i v-if="this.initialduct.parent_duct_id != duct.id"
                                        class="fas fa-times"></i></span>
    </div>
    <div :style="titleIndent" v-if="showChildren">
      <div class="tubesList">
        <TubeInTree :depth="depth" :initialduct="initialduct" v-for="tube in duct.tubes" :key="tube.duct_id+'_'+tube.id"
                    :tube="tube"></TubeInTree>
      </div>
      <DuctTree v-if="duct.childs.length>0" :initialduct="initialduct" v-for="child in duct.childs"
                :key="'child_'+duct.id+'_'+child.id" :duct="child" :depth="depth+1"></DuctTree>
    </div>
  </div>
</template>

<script>
import CommonTools from "@/Helpers/CommonTools";
import TubeInTree from "@/components/modals/Duct/Info/TubeInTree";
import SubDuctStandAlone from "@/components/modals/Duct/SubDuct/SubDuctStandAlone";

export default {
  name: "DuctTree",
  components: {SubDuctStandAlone, TubeInTree},
  props: ['duct', 'depth', 'initialduct', 'parentDuct'],
  data() {
    return {
      showChildren: true
    }
  },
  computed: {
    ductTitle: {
      get() {
        let title = this.duct.product_code
        if (title !== undefined && title !== null && title.length > 0 && this.duct.manufacturer_code.length > 0) {
          if (this.duct.manufacturer_code) {
            title += ' / ' + this.duct.manufacturer_code
          }
        }
        return title;
      }
    },
    ductLength() {
      return CommonTools.round(this.duct.length, 2)
    },
    titleIndent() {
      return {transform: `translate(${this.depth * 10}px)`}
    },
    iconClasses() {
      return {
        'fas fa-caret-right': !this.showChildren,
        'fas fa-caret-down': this.showChildren
      }
    },
    labelClasses() {
      return {'has-children': this.duct.child}
    },

  },
  methods: {
    removeSubDuct(duct) {
      if (duct.id != this.initialduct.parent_duct_id) {
        this.$store.dispatch("Duct/delete", duct.id).then(() => {
          this.$root.$emit('updateDuctInfo')
        });
      }
    },
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    showAddSubDuctWindow() {
      delete this.initialduct.parent_duct_id;
      this.initialduct.parent_duct_id = this.duct.id;
      this.$store.commit('SubDuctInfoModal/setParams', this.initialduct, {root: true});
      this.$bvModal.show('SubDuctStandAlone')
      // this.$store.dispatch('Duct/ductInfo')
    },

  }
}
</script>

<style scoped>
.ductTitle {
  font-size: 11px;
  cursor: pointer;
}

.tubesList {
  /*margin:0;*/
  padding: 0;
  margin-left: 5px;
}

.tubesList div.li {
  list-style: none;
  font-size: 10px;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.btn-xs {
  font-size: 10px;
  margin-left: 5px;
  padding: 1px 2px;
}
</style>