/*
*  Simple navigation control that allows back and forward navigation through map's view history
*/

import Utils from "@/assets/js/libs/Utils";

L.Control.Reset = L.Control.extend({
    options: {
        position: 'topleft',
        homeTitle: 'Go to home map view'
    },

    onAdd: function (map) {

        // Set options
        if (!this.options.center) {
            this.options.center = map.getCenter();
        }
        if (!this.options.zoom) {
            this.options.zoom = map.getZoom();
        }
        let options = this.options;

        // Create toolbar
        let controlName = 'leaflet-control-reset',
            container = L.DomUtil.create('div', controlName + ' leaflet-bar');

        // Add toolbar buttons
        this._homeButton = this._createButton(options.homeTitle, controlName + '-home', container, this._goHome);

        // Set initial view to home
        map.setView(options.center, options.zoom);

        return container;
    },

    onRemove: function (map) {
    },

    _goHome: function () {

        this.caller.$store.commit("Search/clearSearch")
        this.caller.$root.$emit("setCenter", {
            center: {lat: Number(53.4291738804146), lng: Number(-1.3732910156250002)},
            zoom: 7
        })
        //currentMap.resetMap();
    },
    _createButton: function (title, className, container, fn) {
        // Modified from Leaflet zoom control

        var link = L.DomUtil.create('a', className, container);
        link.href = '#';
        link.title = title;

        L.DomEvent
            .on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this)
            .on(link, 'click', this._refocusOnMap, this);

        return link;
    },

});
L.Control.reset = function (options) {
    const resetObject = new L.Control.Reset(options);
    resetObject.caller = options.caller;
    return resetObject
};