<template>
  <b-modal id="locationImagesList" @shown="loadLocationPhotos" @hidden="hideLocationPhotos" centered title="Location Photos">

    <div class="photo_storage">
    <b-overlay class="photo_storage_overlay" :show="this.loading" rounded="sm">

    </b-overlay>
      <b-form-file multiple
                   v-model="image"
                   accept="image/jpeg, image/png, image/gif"
                   placeholder="Choose a file or drop it here..."
                   drop-placeholder="Drop file here..."
      >
        <template slot="file-name" slot-scope="{ names }">
          <b-badge variant="dark">{{ names[0] }}</b-badge>
          <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
            + {{ names.length - 1 }} More files
          </b-badge>
        </template>
      </b-form-file>
      <button v-if="image != null && image.length > 0" @click="uploadPhoto" class="mt-2 pull-right btn btn-success">Upload</button>
      <div class="row">
        <div v-viewer="{options, movable: false, title:[1, this.getImageTitle]}" class="images" >
          <div class="col-3 p-2 mt-3" v-for="(src,index) in this.photos" :key="index">
            <div v-if="status == 'not_accepted'">
              <img class="img-fluid img-thumbnail"  :alt="src.comment" :data-src="src.source" :src="src.source" >
              <i @click="removeLocationImage(src.id)" class="delete_location_image fas fa-times-circle text-danger"></i>
              <i  v-b-modal.locationImage @click="selectPhoto(src)" class="edit_location_image fas fa-pen text-warning"></i>
            </div>
            <div v-else class="checkboxes">
              <img class="img-fluid img-thumbnail"  :alt="src.comment" :data-src="src.source" :src="src.source" >
              <i  v-b-modal.locationImage @click="selectPhoto(src)" class="edit_location_image"></i>
              <b-form-checkbox
                  :id="src.id.toString()"
                  v-model="checkedImages"
                  :value="src.id"
                  class="edit_location_image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-form-checkbox  v-if="photos != null && photos.length > 0"
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
      >
        Split Mode
      </b-form-checkbox>
      <b-button v-if="status == 'accepted' && checkedImages.length > 0 && photos != null && photos.length > 0"  @click="split()" variant="warning" class="mr-2">Split</b-button>
      <b-button v-if="photos != null && photos.length > 0" variant="danger" @click="removeLocationImages()" class="mr-2">Delete all images</b-button>
      <b-button v-if="photos != null && photos.length > 0" variant="success" @click="downloadImages()" class="mr-2">Download</b-button>
      <b-button variant="info" @click="close()" class="mr-2">OK</b-button>
    </template>
    <b-modal id="locationImage"  centered title="Edit Photo">
      <div class="column">
        <b-row class="my-1">
          <b-col>
            <b-form-input  v-if="Boolean(selectedPhoto)"  v-model="selectedPhoto.comment" id="input-large" size="lg"  placeholder="photo"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <button  @click="editLocationImage(selectedPhoto.comment)" class="pull-right col-3 p-2 mt-3 btn btn-success">Submit</button>
      </template>
    </b-modal>
    <div   v-if="Boolean(progress)">
      <vue-ellipse-progress style="position:absolute;top:40%;left:40%" dot="5% blue" :size="100":progress="progress"/>
    </div>
  </b-modal>

</template>

<script>
import {mapGetters} from "vuex";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import VueEllipseProgress from 'vue-ellipse-progress';
import CommonTools from "@/Helpers/CommonTools";
Vue.use(VueEllipseProgress);
Vue.use(VueViewer)
export default {
  name: "LocationsPhotoList",
  watch:{

  },
  computed:{
    ...mapGetters({
      progress: 'LocationPhotos/getProgress',
      currentLocation:'LocationPhotos/currentLocation',
      photos:'LocationPhotos/locationPhotos',
      loading:'LocationPhotos/loading',
    })
  },
  data:function(){
    return {
      options: {
        ready: () => {
          this.$viewer = this.$el.querySelector(".images").$viewer;
        },
        url: "data-src",
        toolbar: {
          oneToOne: true,
          prev: () => {
            this.$viewer.prev(true);
          },
          play: true,
          next: () => {
            this.$viewer.next(true);
          },
        }
      },
      checkedImages: [],
      status: 'not_accepted',
      inline: true,
      image:[],
      selectedPhoto: null,
      comment: null
    }
  },
  methods:{
    downloadImages() {
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      axios.post(process.env.VUE_APP_API_URL + "/api/location_images/download", {
        'images' : this.photos,
        'lng' : String(this.currentLocation[1]),
        'lat' : String(this.currentLocation[0]),
        'project_id' : selectedProject.id
      }, {
        responseType: 'blob'
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'images.zip');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    split() {
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      axios.post(process.env.VUE_APP_API_URL + "/api/location_images/split", {
        'images' : this.checkedImages,
        'lon' : this.currentLocation[1] + 0.0001,
        'lat' : this.currentLocation[0],
        'project_id' : selectedProject.id
      }).then(()=>{
        this.$store.dispatch('LocationPhotos/loadPhotoLocationsForPolygon');
        this.$store.commit('LocationPhotos/setCurrentLocationsPhotos')
        this.$bvModal.hide('locationImagesList')
        this.checkedImages = []
        this.status = 'not_accepted'
      })
    },
    close(){
      this.$store.commit('LocationPhotos/setCurrentLocationsPhotos')
      this.$bvModal.hide('locationImagesList')
    },
    getImageTitle(image,imagedata){
      return image.alt
    },
    show() {
      this.$viewerApi({
        images: this.photos,
      })
    },
    uploadPhoto(){
      let formData = new FormData();
      formData.append('image', this.image);
      for( var i = 0; i < this.image.length; i++ ){
        let file = this.image[i];
        formData.append('images[' + i + ']', file);
      }
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      formData.append('project_id', selectedProject.id);
      formData.append('lat', this.currentLocation[0]);
      formData.append('lng', this.currentLocation[1]);
      this.$store.dispatch('LocationPhotos/uploadPhotoForLocations',formData)
      this.image = null;
    },
    selectPhoto(image) {
      this.selectedPhoto = image
    },
    editLocationImage(comment) {
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      this.$store.dispatch('LocationPhotos/editImage',{
        comment: comment,
        id:this.selectedPhoto.id,
        project_id: selectedProject.id
      })
      this.$bvModal.hide('locationImage');

    },
    loadLocationPhotos(){
      this.$store.dispatch('LocationPhotos/getPhotosForLocation',{
        lat:this.currentLocation[0],
        lng:this.currentLocation[1]
      });
    },
    getImagePath(image) {
      return process.env.VUE_APP_LOCATION_IMAGES_URL+image;
    },
    removeLocationImages(){
      const selectedProject = this.$store.getters["Projects/selectedProject"];
      CommonTools.deleteConfirmation().then(()=>{
      this.$store.dispatch('LocationPhotos/deleteImages',{
        lat:this.currentLocation[0],
        lng:this.currentLocation[1],
        id:selectedProject.id
        })
      }).then(() => {
          this.close()
      })
    },
    removeLocationImage(id){
      this.$store.dispatch('LocationPhotos/deleteImage',{
        lat:this.currentLocation[0],
        lng:this.currentLocation[1],
        id:id
      })
    },
    hideLocationPhotos() {
      this.$store.commit('LocationPhotos/setCurrentLocationsPhotos')
    }
  }
}
</script>

<style scoped>
.photo_storage{
  min-height: 300px;
}
.photo_storage_overlay{
  top:150px;
}
.location_image{
  min-height: 64px;
  min-width: 64px;
}
.delete_location_image{
  position: absolute;
  right: -2px;
  top:-2px;
  cursor: pointer;
}

.edit_location_image{
  position: absolute;
  right: 15px;
  top:-2px;
  cursor: pointer;
}

.images {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}

</style>