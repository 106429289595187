import vue from "vue";

const state = {
    tileLocations: {
        lat: null,
        lng: null,
    },
    tilePlanIsAvailable: false,
    features: [],

};
const actions = {};
const getters = {
    tileLocations: state => {
        return state.tileLocations;
    },

    tilePlanIsAvailable: state => {
        return state.tilePlanIsAvailable
    },
    features: state => {
        return [...new Map(state.features.map(item => [item.properties.inspireid, item])).values()];
    }
};
const mutations = {
    setTilePlanIsAvailable(state, data) {
        vue.set(state, 'tilePlanIsAvailable', data);
    },
    setLatLng(state, data) {
        vue.set(state.tileLocations, 'lat', data.lat);
        vue.set(state.tileLocations, 'lng', data.lng);
    },
    setFeatures(state, data) {
        vue.set(state, 'features', data);
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}