<template>
  <div>
    <b-modal id="Fibre2FibreConnection" ok-title="Connect"
             @hidden="reset"
             title="Connect Fibres"
             :ok-disabled="!connectionAllowed"
             size="xl"
             @ok="connectFibres">
      <b-overlay :show="loading">
        <div class="d-flex justify-content-around">
          <div class="form-group col-6">
            <label for="source-cable-1">Source</label>
            <select id="source-cable-1" class="form-control form-control-sm" v-model="selectedSource">
              <option v-for="item in sourceCables" :key="'SOURCE_'+item.name" :value=item>{{ item.name }}
                ({{ item.cable_type }})
              </option>
            </select>
          </div>
          <div class="form-group col-6">
            <label for="destination-cable-1">Destination</label>
            <select id="destination-cable-1" class="form-control form-control-sm" v-model="selectedDestination">
              <option v-for="item in destinationCables" :key="'DESTINATION_'+item.name" :value="item">{{
                  item.name
                }} ({{ item.cable_type }})
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="form-group col-6">
            <label v-if="availableSourceCables.length>0" for="source-cable-fibre-cables-2">Fibre Cable</label>
            <select v-if="availableSourceCables.length>0" id="source-cable-fibre-cables-2"
                    class="form-control form-control-sm"
                    v-model="selectedSourceFibreCable">
              <option v-for="source_fibre_cable in availableSourceCables"
                      :key="'SOURCE_FIBRE_CABLE_'+source_fibre_cable.id" :value="source_fibre_cable">
                C-{{ source_fibre_cable.sequence_id }} <strong>({{ source_fibre_cable.cable_type }})</strong>
              </option>
            </select>
          </div>
          <div class="form-group col-6">
            <label v-if="availableDestinationCables.length>0" for="destination-cable-fibre-cables-2">Fibre Cable</label>
            <select v-if="availableDestinationCables.length>0" id="destination-cable-fibre-cables-2"
                    class="form-control form-control-sm"
                    v-model="selectedDestinationFibreCable">
              <option v-for="destination_fibre_cable in availableDestinationCables"
                      :key="'SOURCE_FIBRE_CABLE_'+destination_fibre_cable.id" :value="destination_fibre_cable">
                C-{{ destination_fibre_cable.sequence_id }} <strong>({{ destination_fibre_cable.cable_type }})</strong>
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="form-group col-6">
            <b-table-simple v-if="availableSourceFibres.length>0" hover fixed responsive bordered class="bg-primary">
              <b-tbody id="1-body">
                <b-tr>
                  <b-td style="text-align: center">
                    <b-button variant="primary">
                      {{ selectedSource.name }} ({{ selectedSource.cable_type }})
                    </b-button>
                    <div>
                      <b-collapse class="mt-2" visible>
                        <b-card>
                          <b-list-group v-for="fibre in availableSourceFibres" :key="fibre.fibre_id">
                            <b-list-group-item
                                v-if="!isInDestinationFibre(fibre)"
                                class="cursor-pointer"
                                @click="pushSourceConnection(fibre)">
                              <div class="d-flex mx-auto justify-content-center">
                                <span v-if="isInSourceFibre(fibre)"
                                      class="fas fa-check-circle text-black-50 my-auto">
                                </span>
                                <span class="ml-3 p-0"><FibreTubeBadge :parent-device-id="objectId" :fibre="fibre"
                                                                       :show-trace="false"/></span>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div class="form-group col-6">
            <b-table-simple v-if="availableDestinationFibres.length>0" class="bg-primary">
              <b-tbody id="1-body">
                <b-tr>
                  <b-td style="text-align: center">
                    <b-button variant="primary">
                      {{ selectedDestination.name }} ({{ selectedDestination.cable_type }})
                    </b-button>
                    <div>
                      <b-collapse class="mt-2" visible>
                        <b-card>
                          <b-list-group v-for="fibre in availableDestinationFibres" :key="fibre.fibre_id">
                            <b-list-group-item
                                v-if="sourceFibres.length>0 && !isInSourceFibre(fibre)"
                                class="cursor-pointer"
                                @click="pushDestinationConnection(fibre)">
                              <div class="d-flex mx-auto justify-content-center">
                                  <span
                                      v-if="isInDestinationFibre(fibre)"
                                      class="fas fa-check-circle text-black-50 my-auto"/>
                                <span class="ml-3"><FibreTubeBadge :parent-device-id="objectId" :fibre="fibre"
                                                                   :show-trace="false"/></span>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div>
            <div class="flex" v-for="(fibre,index) in sourceFibres">
              <span><FibreTubeBadge :parent-device-id="objectId" :fibre="fibre" :show-trace="false"/></span>
            </div>
          </div>
          <div>
            <div v-if="sourceFibres.length>=destinationFibres.length" class="flex"
                 v-for="(fibre,index) in sourceFibres">
              <span v-if="destinationFibres[index]" class="text-success fas fa-arrows-alt-h"></span>
              <span v-if="!destinationFibres[index]" class="text-danger fas fa-times-circle"></span>
            </div>
            <div v-if="sourceFibres.length<destinationFibres.length" class="flex"
                 v-for="(fibre,index) in destinationFibres">
              <span v-if="sourceFibres[index]" class="text-success fas fa-arrows-alt-h"></span>
              <span v-if="!sourceFibres[index]" class="text-danger fas fa-times-circle"></span>
            </div>
          </div>
          <div>
            <div class="flex" v-for="fibre in destinationFibres">
              <span>
                              <span><FibreTubeBadge :parent-device-id="objectId" :fibre="fibre"
                                                    :show-trace="false"/></span>

              </span>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import FibreTubeBadge from "@/components/UI/FibreTubeBadge.vue";

export default {
  name: "Fibre2FibreConnection",
  components: {FibreTubeBadge},
  props: {
    objectId: {type: Number},
    sourceCables: {type: Array},
    projectId: {type: Number}

  },
  data() {
    return {
      selectedSource: {},
      selectedDestination: {},
      availableSourceCables: [],
      availableDestinationCables: [],
      selectedSourceFibreCable: {},
      selectedDestinationFibreCable: {},
      availableSourceFibres: [],
      availableDestinationFibres: [],
      selectedSourceFibre: null,
      selectedDestinationFibre: null,
      loading: false,
      sourceFibres: [],
      destinationFibres: [],

    };
  },
  methods: {

    reset() {
      this.selectedSource = {};
      this.selectedDestination = {};
      this.availableSourceCables = [];
      this.availableDestinationCables = [];
      this.selectedSourceFibreCable = {};
      this.selectedDestinationFibreCable = {};
      this.availableSourceFibres = [];
      this.availableDestinationFibres = [];
      this.selectedSourceFibre = null;
      this.selectedDestinationFibre = null;
      this.sourceFibres = [];
      this.destinationFibres = [];
      this.loading = false;
    },
    pushSourceConnection(fibre) {
      const exists = this.isInSourceFibre(fibre)
      if (!exists) {
        this.sourceFibres.push(fibre);
      } else {
        const indexOfObject = this.sourceFibres.findIndex(object => {
          return object.id === fibre.id;
        });
        if (indexOfObject != null) {
          this.sourceFibres.splice(indexOfObject, 1);
        }
      }
    },
    pushDestinationConnection(fibre) {
      const exists = this.isInDestinationFibre(fibre)
      if (!exists) {
        this.destinationFibres.push(fibre);
      } else {
        const indexOfObject = this.destinationFibres.findIndex(object => {
          return object.id === fibre.id;
        });
        if (indexOfObject != null) {
          this.destinationFibres.splice(indexOfObject, 1);
        }
      }
    },
    isInSourceFibre(fibre) {
      return this.sourceFibres.some(function (elem) {
        return elem.id === fibre.id;
      });
    },
    isInDestinationFibre(fibre) {
      return this.destinationFibres.some(function (elem) {
        return elem.id === fibre.id;
      });
    },
    getFibresForFibreCable(cableId, attachTo) {
      this.loading = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/' + cableId + '/fibres/' + this.objectId).then(response => {
        if (response.data.data.length === 0) {
          this.$swal('', 'There are no Fibres available for the Fibre Cable', 'info');
        }
        this[attachTo] = response.data.data;
      }).finally(() => {
        this.loading = false;
      });

    },
    connectFibres(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.loading = true;
      let connections = [];

      for (let i = 0; i < this.sourceFibres.length; i++) {
        const newObj = {
          first_fibre: this.sourceFibres[i].id,
          second_fibre: this.destinationFibres[i].id
        };
        connections.push(newObj);
      }
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/fibre_to_fibre_connection_bulk', {
        connections: connections,
        object_id: this.objectId
      }).then(() => {
        this.$bvModal.hide('Fibre2FibreConnection');
        this.sourceFibres = [];
        this.destinationFibres = [];
        this.loading = false;
      })
    }
  },
  watch: {
    selectedSource(value) {
      if (!value || !value.id) {
        return;
      }
      if (value.type === "fibre_cable") {
        this.getFibresForFibreCable(value.id, 'availableSourceFibres')
      }
    },
    selectedDestination(value) {
      if (!value || !value.id) {
        return;
      }
      if (value.type === "fibre_cable") {
        this.getFibresForFibreCable(value.id, 'availableDestinationFibres')
      }
    },
    selectedSourceFibreCable(value) {
      if (!value || !value.id) {
        return;
      }
      this.getFibresForFibreCable(value.id, 'availableSourceFibres');
    },
    selectedDestinationFibreCable(value) {
      if (!value || !value.id) {
        return;
      }
      this.getFibresForFibreCable(value.id, 'availableDestinationFibres');
    }
  },
  computed: {
    destinationCables: {
      get() {
        return this.sourceCables
      }
    },
    connectionAllowed: {
      get() {
        return this.sourceFibres.length > 0 && this.sourceFibres.length === this.destinationFibres.length;
      }
    }
  }
}
</script>

<style scoped>

</style>