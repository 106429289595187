<template>
  <div>
    <b-modal id="shared_sub_accounts" size="lg" centered no-close-on-backdrop title="Sub Accounts"
             @show="getSubAccounts">
      <div>
        <b-overlay :show="loading">
          <b-table v-if="accounts.length>0" :fields="header" :items="accounts" striped small>
            <template #cell(allow_edit)="data">
              <b-badge v-if="data.item.allow_edit" variant="success">Yes</b-badge>
              <b-badge v-else variant="danger">No</b-badge>
            </template>
            <template #cell(edit)="data">
              <div v-if="!data.item.disabled" @click="disable(data.item)"
                   class="badge badge-danger cursor-pointer mr-2 p-2">Suspend
              </div>
              <div v-if="data.item.disabled" @click="enable(data.item)"
                   class="badge badge-success cursor-pointer mr-2 p-2">Unsuspend
              </div>
              <b-icon @click="openEditModal(data.item)" style="cursor:pointer;" icon="pencil-square"
                      aria-hidden="true"></b-icon>
              <b-icon v-if="data.item.id !== authenticatedUser.id && data.item.is_master===false"
                      @click="deleteSubAccount(data.item)"
                      style="cursor:pointer;" class="ml-2" icon="trash"
                      aria-hidden="true"></b-icon>
            </template>
          </b-table>
          <div v-if="accounts.length===0" class="text-center text-muted">You have no sub-accounts.</div>
        </b-overlay>
      </div>
      <div slot="modal-footer">
        <div class="w-100">
          <b-button @click="openNewSubAccountModal" :disabled="loading" variant="primary"
                    size="sm" class="float-right">
            Add New Sub Account
          </b-button>
        </div>
      </div>

    </b-modal>
    <b-modal ref="new_sub_account" id="new_sub_account" centered hide-footer
             no-close-on-backdrop
             title="Add New Sub Account">
      <b-overlay :show="loadingCreate">
        <FormsUpdateProfile :is-create="true" :account-data="subAccount" @ok="saveNewSubAccount"/>
      </b-overlay>
    </b-modal>
    <b-modal ref="edit_sub_account" id="edit_sub_account" size="md"
             centered
             no-close-on-backdrop
             hide-footer
             title="Edit Sub Account">
      <b-overlay :show="loadingUpdate">
        <FormsUpdateProfile :account-data="editSubAccount"
                            :show-account-limit="editSubAccount.id !== authenticatedUser.id && editSubAccount.is_master!==false"
                            :show-allow-edit="editSubAccount.id !== authenticatedUser.id" @ok="saveUpdatedSubAccount"/>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>

import CommonTools from "@/Helpers/CommonTools";
import FormsUpdateProfile from "@/components/Modules/Account/forms/FormsUpdateProfile.vue";


export default {
  name: "Accounts",
  components: {FormsUpdateProfile},
  data() {
    return {
      header: ['first_name', 'last_name', 'email', 'phone', {
        key: 'allow_edit',
        label: 'Allow type create/delete/edit'
      }, 'edit'],
      loading: true,
      loadingCreate: false,
      loadingUpdate: false,
      subAccount: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        allow_edit: false,
        password: null,
      },
      editSubAccount: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        allow_edit: false,

      },
      password: '',
      repeat_password: ''
    }
  },
  computed: {
    authenticatedUser() {
      return this.$store.state.Profile.user;
    },
    showAllowEditAndAccountLimit(account) {
      return account.id !== this.authenticatedUser.id;
    },
    allowedToCreateSubAccount() {
      let usedSlot = this.authenticatedUser.sub_account_count;
      let limit = this.authenticatedUser.sub_account_limit;
      if (this.$store.state.SubAccounts.forUser) {
        usedSlot = this.$store.state.SubAccounts.forUser.sub_account_count
        limit = this.$store.state.SubAccounts.forUser.sub_account_limit
      }
      return usedSlot < limit;
    },
    accounts() {
      return this.$store.state.SubAccounts.subAccounts;
    },
    formValid: {
      get() {
        if (this.password.length > 0 || this.repeat_password.length > 0) {
          return this.password === this.repeat_password;
        }
        return true;
      }

    },
  },
  methods: {
    disable(user) {
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to suspend this account?', 'Suspend', 'Cancel').then(() => {
        this.loading = true;
        if (this.authenticatedUser.is_admin) {
          this.$UserManagementApiClient.updateUser(user.id, {disabled: true}).then(() => {
            this.$swal('Success', 'User disabled', 'success');
            // this.users = [];
            this.getSubAccounts();
          }).catch(() => {
            this.loading = false;
          })
        } else {
          this.$UserManagementApiClient.changeUserStatus(user.id, 0).then(() => {
            this.$swal('Success', 'User disabled', 'success');
            // this.users = [];
            this.getSubAccounts();
          }).catch(() => {
            this.loading = false;
          })
        }
      }).catch(() => {
      })
    },
    enable(user) {
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to Unsuspend this account?', 'Unsuspend', 'Cancel').then(() => {
        this.loading = true;
        if (this.authenticatedUser.is_admin) {
          this.$UserManagementApiClient.updateUser(user.id, {disabled: false}).then(() => {
            this.$swal('Success', 'User enabled', 'success');
            this.getSubAccounts()
          }).catch(() => {
            this.loading = false;
          })
        } else {
          this.$UserManagementApiClient.changeUserStatus(user.id, 1).then(() => {
            this.$swal('Success', 'User enabled', 'success');
            this.getSubAccounts()
          }).catch(() => {
            this.loading = false;
          })
        }

      }).catch(() => {
      })
    },
    openNewSubAccountModal() {
      if (!this.allowedToCreateSubAccount) {
        let usedSlot = this.authenticatedUser.sub_account_count;
        console.log(this.authenticatedUser, this.$store.state.SubAccounts.forUser)
        if (this.$store.state.SubAccounts.forUser) {
          usedSlot = this.$store.state.SubAccounts.forUser.sub_account_count;
        }

        this.$swal('Limit exceed', 'Sorry but you only have permission to create ' + usedSlot + ' accounts which includes your main account. Please contact support.', 'warning')
        return;
      }
      this.$bvModal.show('new_sub_account')
    },
    getSubAccounts() {
      this.loading = true;
      this.$store.dispatch('SubAccounts/getSubAccounts').then(response => {
        console.log(response.data);
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    deleteSubAccount(account) {
      CommonTools.deleteConfirmation('Are you sure you wish to delete this sub account?', 'All projects will be moved to this account.').then(() => {
        this.loading = true;
        this.$store.dispatch('Accounts/deleteSubAccount', account).then(() => {
          this.$swal('Success', 'Sub Account deleted successfully', 'success')
          this.getSubAccounts();
        }).catch(() => {
          this.loading = false;
        })
      });

    },
    openEditModal(account) {

      this.editSubAccount = account;
      this.$refs.edit_sub_account.show();
    },
    saveNewSubAccount(subAccountInfo) {
      this.loadingCreate = true;
      if (subAccountInfo.password.length > 0) {
        subAccountInfo.password_confirmation = subAccountInfo.password;
        if (!this.formValid) {
          this.$swal("Validation Error", "Passwords should be the same", "warning");
          this.loading = false;
          return
        }
      } else {
        subAccountInfo.subAccount.password = null;
        subAccountInfo.subAccount.password_confirmation = null;
      }
      // this.subAccount.allow_edit = this.allow_edit;
      let url = process.env.VUE_APP_API_URL + '/api/user/accounts';
      console.log(this.$store.state.SubAccounts.forUser);
      if (this.$store.state.SubAccounts.forUser !== null) {
        console.log('Creading user foruser')
        const userId = this.$store.state.SubAccounts.forUser.id;
        url = process.env.VUE_APP_API_URL + '/api/admin/users/' + userId + '/subAccount';
      }
      console.log(url);
      axios.post(url, subAccountInfo).then(() => {
        this.$swal("Success", "Account created successfully", "success");
        this.$bvModal.hide('new_sub_account');
        this.reset();
        this.loadingCreate = false;
        this.getSubAccounts()
      }).catch(() => {
        this.loadingCreate = false;
      })
    },
    saveUpdatedSubAccount(subAccountInfo) {
      let url = process.env.VUE_APP_API_URL + '/api/user/accounts/' + subAccountInfo.id;
      if (this.authenticatedUser.is_admin) {
        url = process.env.VUE_APP_API_URL + '/api/admin/users/' + subAccountInfo.id;
      }
      this.loadingUpdate = true;
      // this.editSubAccount.allow_edit = this.allow_edit;
      axios.put(url, subAccountInfo).then(() => {
        this.$swal("Success", "Account updated successfully", "success");
        this.$bvModal.hide('edit_sub_account');
        this.loadingUpdate = false;
        this.$store.dispatch('SubAccounts/getSubAccounts');
      }).finally(() => {
        this.loadingUpdate = false;
      })
    },
    reset() {
      this.subAccount.first_name = '';
      this.subAccount.last_name = '';
      this.subAccount.email = '';
      this.subAccount.allow_edit = false;
      this.password = null;
      this.repeat_password = null;
    }
  }
}
</script>

<style scoped>

</style>