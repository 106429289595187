const saveLocationToolbar = {
    watch: {
        imageGroupsShown(value) {
            const controls = document.getElementsByClassName('leaflet-right');
            if (value) {
                controls[0].style.right = '350px';
            } else {
                controls[0].style.right = '0';
            }
        },
    },
    computed: {
        saveLocationShown: {
            get: function () {
                return this.$store.state.State.saveLocationShown;
            },
            set(value) {
                this.$store.commit("State/setSaveLocationShown", value, {root: true});
            }
        }
    },
    methods:{
        addSaveLocationControl() {
            const self = this;
            this.map.pm.Toolbar.createCustomControl({
                name: 'saveLocations',
                block: 'custom',
                title: 'Save Location',
                actions: [],
                className: 'fas fa fa-save project_info-control',
                onClick: function (e) {
                    self.saveLocationShown = !self.saveLocationShown;
                },
                toggle: false

            })
        },
    }
}
export default saveLocationToolbar;