import axios from 'axios'
import Vue from 'vue'

const state = {
    maps: [],
    activeMapUrl: '',
    activeLayers: [],
    distance: 0,
    mapIsLoaded: false,

};
const mutations = {
    setMaps(state, data) {
        Vue.set(state, 'maps', data)
    },
    setMapUrl(state, data) {
        Vue.set(state, 'activeMapUrl', data);
    },
    setMapLoaded(state, data) {
        Vue.set(state, 'mapIsLoaded', data)
    }


};
const actions = {
    list({commit, dispatch}) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/maps').then(response => {
            commit('setMaps', response.data.data);
        });
    },
    setActiveMapUrl({commit, dispatch}, map) {
        commit('setMapUrl', map.url);
        dispatch('State/setActiveMap', map, {root: true});

    },


    activateVectorLayer({getters, rootGetters, commit}, data) {
        // if zoom level is good
        Vue.nextTick(function () {
            const zoomLevel = rootGetters["State/currentZoom"];
            if (data.map !== undefined && data.map != null && data.map.wmsLayerName !== null && data.map.wmsLayerName.length > 0 && zoomLevel != undefined && zoomLevel != null) {
                if (zoomLevel >= 18) {
                    commit('State/setShowVectorLayer', false, {root: true});
                    Vue.nextTick(function () {
                        commit('State/setShowVectorLayer', true, {root: true});
                    });
                } else {
                    commit('State/setShowVectorLayer', false, {root: true});
                }
            }
            if (data.map !== undefined && data.map != null) {
                commit('State/setZoomMinMax', {min: data.map.minZoom, max: data.map.maxZoom}, {root: true});
            }

        });
    },
    setActiveMapByCode({commit, state}, code) {
        let newMap = [];
        if (state.maps.length > 0) {
            state.maps.map(function (item) {
                if (item.code == code) {
                    item.active = 1;
                } else {
                    item.active = 0;
                }
                newMap.push(item);
            })
            commit('setMaps', item);
        }
    }


};
const getters = {
    getActiveMapUrl: state => {
        if (state.maps.length > 0) {
            if (state.activeMapUrl != '') {
                return state.activeMapUrl;
            } else {

                const map = Object.keys(state.maps).map(key => state.maps[key]).filter(function (i) {
                    return i.active == 1;
                });
                if (map.length > 0) {
                    return map[0].url;
                } else {

                }
            }
        }
    },
    getMaps: state => {
        const mapUrlFromStorage = localStorage.getItem('state.topLayer')
        let foundActive = false;
        const maps = state.maps.map(function (item) {
            if (mapUrlFromStorage != undefined) {
                if (item.code === mapUrlFromStorage) {
                    item.active = true
                    foundActive = true;
                } else {
                    item.active = false
                }
            }

            return item;

        })

        return maps;
    },
    getActiveMap: state => {
        return state.maps.filter(item => item.active)[0];
    },
    mapIsLoaded: state => {
        return state.mapIsLoaded;
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
