import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import { LIcon, LLayerGroup, LMap, LMarker, LPopup, LTileLayer, LWMSTileLayer } from 'vue2-leaflet';
import './assets/js/libs/leaflet.smooth_marker_bouncing/dist/bundle'
import store from './stores'
import Default from "@/Helpers/Default";
import { GT_OSGB } from "@/assets/js/libs/geotools2";
import { Icon } from 'leaflet';
import '@geoman-io/leaflet-geoman-free'
import './assets/js/libs/Location/L.Control.PCLocation.css'
import './assets/js/libs/Reset/Control.Reset';
import './assets/js/libs/PrintVector/Control.PrintVector';
import './assets/js/libs/HeatMap/leaflet-heat'
import JQuery from 'jquery'
import VueHead from 'vue-head'
import VueMeta from 'vue-meta';
import VueExpandableImage from 'vue-expandable-image'
import DuctInfoShort from "@/components/modals/Duct/Info/DuctInfoShort";
import SearchSelect from "@/components/SearchSelect";
import DuctsInfoShort from "@/components/modals/Duct/Info/DuctsInfoShort";
import BetterWMS from "@/components/mapcomponents/BetterWMS/BetterWMS.vue";
import Dropdown from 'vue-simple-search-dropdown';
import PropertyFinder from "./PropertyFinder";
import Map from "./components/Map";
import axiosCancel from 'axios-cancel';
import VueRouter from "vue-router";
import '../node_modules/leaflet.browser.print/dist/leaflet.browser.print.min.js';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import '../node_modules/leaflet.export/leaflet_export';
import SubDuctStandAlone from "@/components/modals/Duct/SubDuct/SubDuctStandAlone";
import Directions from "@/Directions";
import SelectSingleFibre from "@/InternalPlugins/SelectSingleFibre/SelectSingleFibre";
import SelectSingleCableType from "@/InternalPlugins/SelectSingleCableType/SelectSingleCableType";
import SelectFromList from "@/InternalPlugins/SelectFromList";
import SelectDate from "@/InternalPlugins/SelectDate/SelectDate";
import RelatedPartyCreate from "@/InternalPlugins/Openreach/RelatedParty/Create/RelatedPartyCreate";
import RelatedPartyEdit from "@/InternalPlugins/Openreach/RelatedParty/Edit/RelatedPartyEdit";
import moment from 'moment'

Vue.prototype.moment = moment
window.leafletImage = require('leaflet-image')

window.$ = JQuery
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
window.axios = axios;
Vue.config.productionTip = false
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2, { backdrop: false });
Vue.use(VueExpandableImage)

Vue.use(VueMeta);
Vue.use(VueHead);
Vue.use(SelectSingleFibre);
Vue.use(SelectSingleCableType);
Vue.use(SelectFromList);
Vue.use(SelectDate);
Vue.use(RelatedPartyCreate);
Vue.use(RelatedPartyEdit);
Vue.prototype.$osgb = GT_OSGB;
Vue.prototype.$WhatTreeWords = require('./assets/js/libs/WhatTreeWords');
window.Utils = require('./assets/js/libs/Utils').default;
Vue.prototype.$Utils = require('./assets/js/libs/Utils').default;
Vue.prototype.$http = axios;
axiosCancel(window.axios, {
  debug: false // default
});
Vue.prototype.$http.interceptors.response.use((response) => {
  return response;
}, error => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }
  if (error.response.status === 422) {
    let errorString = '';
    for (let errorItem in error.response.data.errors) {
      error.response.data.errors[errorItem].map(function(item) {
        errorString = item + "\n";
      })
    }
    Vue.prototype.$swal(error.response.data.message, errorString, "warning")
  }
  if (error.response.status === 403) {
    Vue.prototype.$swal('Not authorized', 'User is not authorized to make this request', "warning")
  }
  if (error.response.status === 415) {
    let errorString = '';
    for (let errorItem in error.response.data.errors) {
      error.response.data.errors[errorItem].map(function(item) {
        errorString = item + "\n";
      })
    }
    Vue.prototype.$swal(error.response.data.message, errorString, "error")
  }
  if (error.response.status === 500 && error.response.data.error !== undefined) {
    error.response.data.error_displayed = true;
    Vue.prototype.$swal("Error", error.response.data.error, "warning")
  }
  return Promise.reject(error);
});
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + token
}
Vue.prototype.$storage = Storage;
Vue.prototype.$defaults = Default;
Vue.prototype._map = {};
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-icon', LIcon);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('l-better-wms-tile-layer', BetterWMS);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('sub-duct-stand-alone', SubDuctStandAlone);
Vue.component('DuctInfoShort', DuctInfoShort);
Vue.component('DuctsInfoShort', DuctsInfoShort);
Vue.component('SearchSelect', SearchSelect);
Vue.component('Dropdown', Dropdown);
Vue.component('Map', Map);
const NotFound = { template: '<p>Page not found</p>' }
const routes = {
  '': App,
  '/': App,
  '/find-address': PropertyFinder,
  '/directions': Directions,
  '/site/:id': App,
  '/set_location/:lat/:lon/:zoom': App,
  '/property_uprn/:uprn/:lat/:lon': App
}
const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: App,
    },
    {
      path: '/find-address',
      name: 'Find Address',
      component: PropertyFinder,
    },
    {
      path: '/set_location/:lat/:lon/:zoom',
      name: 'Set Location',
      component: App,
    },
    {
      path: '/property_uprn/:uprn/:lat/:lon',
      name: 'Copy uprn url',
      component: App,
    },
    {
      path: '/site/:id',
      name: 'Site Location',
      component: App,
    },
    {
      path: '/directions',
      name: 'Directions',
      component: Directions,
    },
  ],
  mode: 'history',
});
Vue.use(VueRouter)
if (process.env.VUE_APP_MODE === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://d09f621324e94112842ec23a8f3a50c1@o1168240.ingest.sentry.io/6260019",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "localhost:8080", "dev.postcodegenie.com", /^\//],
      }),

    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  });
}


// helper methods
Vue.prototype.openModal = function(modalId) {
  this.$bvModal.show(modalId);
};
Vue.prototype.closeModal = function(modalId) {
  this.$bvModal.close(modalId);
};

new Vue({
  router,
  store,
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent() {
      return routes[this.$route.matched[0].path] || NotFound
    }
  },
  render(h) {
    return h(this.ViewComponent)
  }
}).$mount('#app')
