<template>
  <div id="app" class="h-100">
    <div class="container container-fluid">
      <b-overlay rounded="sm" :show="showLoader">
        <div class="form-group mt-5 mx-auto">
          <div class="row">
            <input v-model="postcode" class="form-control col-sm-12 col-md-6 mt-5 mt-md-0"/>
            <div class="px-0 px-md-2 col-sm-12 col-md-5 mt-5 mt-md-0">
              <b-button :disabled="disabled" @click="search" variant="info" class="w-100 float-right">Search</b-button>
            </div>
          </div>
        </div>
      </b-overlay>
      <div class="mt-5">

        <div class="col-12 row p-2" v-for="property in properties" :key="property.key">
          <div class="col-xs-12 col-md-6">
            <div class="col-12 row">{{ property.display_text_normal }} <strong>{{ property.display_text_bold }}</strong></div>
            </div>
          <div class="d-flex justify-content-around row col-xs-12 col-md-6">
            <b-button variant="info" class="" :href="appleMapsUrl(property)">Apple Map</b-button>
            <b-button variant="info" class=" mr-0 mr-md-2" :href="googleMapsUrl(property)">Google Map</b-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PropertyFinder",
  data: function () {
    return {
      postcode: '',
      properties: [],
      showLoader: false,
    }
  },
  methods: {
    search() {
      this.showLoader = true;
      axios.get(process.env.VUE_APP_API_URL + '/api/postcode/' + this.postcode).then(response => {
        this.properties = response.data.data;
        this.showLoader = false;
      });
    },
    googleMapsUrl(property) {
      return "comgooglemapsurl://?q=" + property.location.lat + ',' + property.location.lng + "&zoom=19&t=k&";
    },
    appleMapsUrl(property) {
      return "http://maps.apple.com/?ll=" + property.location.lat + ',' + property.location.lng + "&z=19&t=k&q=Here";
    }
  },
  computed: {
    disabled: function () {
      const regexp = new RegExp(process.env.VUE_APP_POSTCODE_PATTERN);
      return !regexp.test(this.postcode);

    }
  },
  head: {
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        id: 'viewport'
      },
    ]
  },
}
</script>

<style scoped>

</style>