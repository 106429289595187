const state = {
    selectedObjectMarker: {},
    selectedObjectCode: null,
    isInSelectMode: false,
    objectMarkers: [],
    markerInfo: {},
    current_request_id: null,
    cabinetConnections: null,


};
const actions = {
    list(ctx, visibleArea) {
        const self = this;
        if (self.current_request_id) {
            axios.cancel(self.current_request_id);
        }
        self.current_request_id = 'list_project_objects';
        return new Promise((resolve, reject) => {
            let selectedProject = ctx.rootGetters["Projects/selectedProject"];
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + selectedProject.id + '/object/search/' + btoa(visibleArea), {
                requestId: self.current_request_id
            }).then(response => {
                ctx.commit('setObjectMarkers', response.data.data);
                self.current_request_id = null;
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        })
    },
    storeChild(ctx, data) {
        let selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/add_child_object', data);
    },
    store(ctx, data) {
        let selectedProject = ctx.rootGetters["Projects/selectedProject"];
        data['project_id'] = selectedProject.id;
        return axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object', data);
    },
    update(ctx, data) {
        return axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + data.id, data.params);
    },
    changeImplementedStatus(ctx, data) {
        return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/status/' + data.id + '/' + data.status);
    },
    delete(ctx, objectMarkerId) {
        return axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + objectMarkerId);
    },
    getInfo(ctx, data) {
        return new Promise((resolve, reject) => {
            ctx.commit('setMakerInfo', {});
            return axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/info/' + data.id).then(response => {

                ctx.commit('setMakerInfo', response.data.data);
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        })
    }
};
const getters = {
    isInSelectMode: state => {
        return state.isInSelectMode;
    },
    selectedObjectMarker: state => {
        return state.selectedObjectMarker;
    },
    selectedObjectCode: state => {
        return state.selectedObjectCode;
    },
    objectMarkers: state => {
        return state.objectMarkers;
    },
    isInElevationMode: state => {

    },
    makerInfo: state => {
        return state.markerInfo;
    },
    cabinetConnections: state => {
        return state.cabinetConnections;
    }
};
const mutations = {
    setSelectedObjectMarker(state, data) {
        state.selectedObjectMarker = data;
    },
    setSelectedObjectCode(state, data) {
        state.selectedObjectCode = data;
    },
    setIsInSelectMode(state, data) {
        state.isInSelectMode = data;
    },
    setObjectMarkers(state, data) {
        state.objectMarkers = data;
    },
    setMakerInfo(state, data) {
        state.markerInfo = data;
    },
    setCabinetConnections(state, data) {
        state.cabinetConnections = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}