import Vue from "vue";
import {BModal} from "bootstrap-vue";

const SingleCableTypeModal = Vue.extend({
    components: {
        BModal
    },
    props: {
        title: {
            type: String,
            default: "Select Cable Type",
        },
        cableTypes: Array,
        meth: {
            type: Function
        },
    },
    data() {
        return {
            selectedCableType: null
        }
    },
    methods: {
        ok() {
            this.meth(this.selectedCableType)
        }
    },
    template: `
      <b-modal @ok="ok" :ok-disabled="selectedCableType===null" :title="title" id="single-cable-type-modal">
        <select class="form-control" v-model="selectedCableType">
          <option :value="cableType.id" v-for="cableType in cableTypes">{{ cableType.type_name }}</option>
        </select>
      </b-modal>
    `
});
export default {
    install: (app, options) => {
        app.prototype.$selectSingleCableType = (config) => {
            // retrieve a nested property in `options`
            // using `key` as the path
            const instance = new SingleCableTypeModal({
                propsData: {
                    title: config.title,
                    cableTypes: config.cableTypes,
                    meth: config.meth
                }
            });
            instance.$mount();
            instance.$bvModal.show('single-cable-type-modal');
            document.body.appendChild(instance.$el);
            return null;
        }
    }
}