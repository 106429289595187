<script>

import { mapGetters } from "vuex";
import Utils from "@/assets/js/libs/Utils";

export default {
  name: "HeaderMenu",
  data() {
    return {
      mobileProviders: {
        last_updated: null,
        finished: null,
      }
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    isMasterUser: {
      get() {
        return this.$store.state.Profile.user.parent_user_id === null;
      }
    },
    ...mapGetters({
      getLocationInfo: 'Search/getLocationInfo',
      isLoggedIn: 'Auth/isLoggedIn',
      isUserLoading: 'Auth/isUserLoading',
      mapIsLoaded: 'Maps/mapIsLoaded',
      isAdmin: 'Auth/isAdmin'
    }),
  },
  methods: {

    logout() {
      const self = this;
      this.$store.dispatch('Auth/logout').then(() => {
        self.$store.dispatch('LayersList/list');
        self.$store.dispatch('Maps/list');
        self.$store.commit("Duct/setDuctsList", [], { root: true });
        self.$store.commit("ObjectMarker/setObjectMarkers", [], { root: true });
        self.$store.commit("ObjectTypes/setObjectTypes", [], { root: true });
        self.$root.$emit('logout');
      })
    },
    showOpenreachModal() {
      const piaData = this.$store.getters["Openreach/piaCredentialsValid"];
      if (piaData) {
        this.$bvModal.show('OpenreachPia');
      } else {
        this.$swal('Openreach credentials', 'Please add openreach credentials in your profile', 'warning')
        this.$bvModal.show('profile_update_modal')
      }
    }
  }
}
</script>

<template>
  <div>

    <b-dropdown v-if="isLoggedIn" id="profile_dropdown" menu-class="user_drop" variant="link"
      toggle-class="text-decoration-none pt-0 pb-0" no-caret>
      <template #button-content>
        <div class="user_profile_dropdown h3">
          <b-button class="bg-white text-dark overlay-object">
            <b-icon-person-circle v-if="!Utils.isMobile()" class=""></b-icon-person-circle>

            <b-icon-list v-else class=""></b-icon-list>
          </b-button>
        </div>
      </template>
      <b-dropdown-group class="right">
        <b-dropdown-item href="#" v-b-modal.profile_update_modal>Profile</b-dropdown-item>
        <b-dropdown-item v-if="isMasterUser" v-b-modal.settings href="#">Settings</b-dropdown-item>
        <b-dropdown-divider v-if="Utils.isMobile()"></b-dropdown-divider>
        <b-dropdown-item v-b-modal.advanced_search href="#">Advanced Search</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-if="isMasterUser && !isAdmin" v-b-modal.shared_sub_accounts href="#">Users
        </b-dropdown-item>
        <b-dropdown-item v-if="isAdmin && isMasterUser" v-b-modal.usersManagement href="#">Users
        </b-dropdown-item>
        <b-dropdown-item v-if="isAdmin" v-b-modal.mobile-coverage-report>
          Mobile Coverage
        </b-dropdown-item>


        <b-dropdown-divider v-if="isAdmin"></b-dropdown-divider>

        <b-dropdown-item v-b-modal.projects_list href="#">Projects</b-dropdown-item>
        <b-dropdown-item v-b-modal.import_polygons href="#">Import Polygons</b-dropdown-item>
        <b-dropdown-item v-b-modal.bulk_flag_change href="#">Bulk Flag Change</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.duct_type_list href="#">Duct Types</b-dropdown-item>
        <b-dropdown-item v-b-modal.object_type_list>Object Types</b-dropdown-item>
        <b-dropdown-item v-b-modal.cableTypeList>Cable Types</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.address_matching_list href="#">Address Matching</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.postcode_information href="#">Postcode Information</b-dropdown-item>
        <b-dropdown-item v-b-modal.export-properties-history-modal>Export Properties</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.sites_list href="#">Sites</b-dropdown-item>
        <b-dropdown-item v-b-modal.locationImagesGeoTagList href="#">Upload Photos</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.opportunities_list href="#">Opportunities</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="showOpenreachModal">Openreach PIA</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="logout" href="#">Logout</b-dropdown-item>
      </b-dropdown-group>
    </b-dropdown>
  </div>
</template>

<style scoped>
.user_profile_dropdown {
  color: #6c757d !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
</style>
