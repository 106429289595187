<template>
  <div>
    <b-modal id="FibreCableModal" @ok="addFibreCable" title="Add Fibre Cable" no-close-on-backdrop no-close-on-esc>
      <form ref="fibre_cable_modal_form">
        <b-form-group
            label="Fibre Cable"
            label-for="fibre_cable_id"
            invalid-feedback="Fibre Cable is required"
        >
          <b-select v-model="fibreCable" text-field="type_name" value-field="id" track-by="title" :options="cableTypes"
                    :option-height="104">

          </b-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "FibreCableModal",
  computed: {
    ...mapGetters({
      cableTypes: 'DuctTypes/cableTypes',
      parentTube: 'DuctTypes/parentTube'
    })
  },
  data: function () {
    return {
      fibreCable: null
    }
  },
  methods: {
    addFibreCable() {
      if (this.fibreCable === null || this.fibreCable.length === 0) {
        this.$swal('Warning', 'Fibre cable is not selected', 'error');
        return;
      }
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/fibre_cable_to_tube/type/' + this.fibreCable + '/tube/' + this.parentTube).then(response => {
        this.$root.$emit('updateDuctInfo')
      }).catch(error => {
        console.log(error);
      });
    },
  }
}
</script>

<style scoped>

</style>