import ObjectTypes from "@/Api/ObjectTypes";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

const state = {
    objectId: null,
    objectCode: null,
    objectInfo: null,
    objectModalInfo: null,
};
const actions = {
    showObjectInfo(ctx, data) {
        if (data.code && data.code === ObjectTypesEnum.OT_SPLITTER) {
            ctx.commit('setObjectModalInfo', data);
            return;
        }
        if (data.code && data.code === ObjectTypesEnum.OT_CHAMBER) {
            ctx.commit('setObjectModalInfo', data);
            return;
        }
        if (data.code && data.code === ObjectTypesEnum.OT_STREET_CABINET) {
            ctx.commit('setObjectModalInfo', data);
            return;
        }
        if (data.code && data.code === ObjectTypesEnum.OT_POLE) {
            ctx.commit('setObjectModalInfo', data);
            return;
        }
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/info/' + data.id).then(response => {
                ctx.commit('setObjectCode', response.data.data.object_type.code)
                ctx.commit('setObjectId', data.id)
                if (response.data.data.object_type.code === 'OT_SPLITTER') {
                    ObjectTypes.getObjectTypeInfo(ObjectTypesEnum.OT_SPLITTER, data.id).then(response => {
                        ctx.commit('setObjectInfo', response.data);
                    });
                } else {

                    ctx.commit('setObjectInfo', response.data.data);
                    ctx.commit('ObjectMarker/setMakerInfo', response.data.data, {root: true});
                }
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            })
        })
    }
};
const getters = {
    objectId: state => state.objectId,
    objectCode: state => state.objectCode,
    objectInfo: state => state.objectInfo,
    objectModalInfo: state => state.objectModalInfo,
};
const mutations = {
    setObjectCode(state, data) {
        state.objectCode = data;
    },
    setObjectId(state, data) {
        state.objectId = data;
    },
    setObjectInfo(state, data) {
        state.objectInfo = data;
    },
    setObjectModalInfo(state, data) {
        state.objectModalInfo = data;
    }
}
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}