<script>
import {defineComponent} from 'vue'
import FormsOpenreachCredentials from "@/components/Modules/OpenreachPIA/forms/FormsOpenreachCredentials.vue";
import FormsUpdateProfile from "@/components/Modules/Account/forms/FormsUpdateProfile.vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "EditUserAccount",
  components: {FormsUpdateProfile, FormsOpenreachCredentials},

  data() {
    return {
      infoLoaded: false,
      accountInfo: {
        first_name: null,
        last_name: null,
        phone: null,
        sub_account_limit: null,
        email: null,
        password: null,
        allow_edit: null,
        is_master: null,
        is_admin: null,

      },
      openreachRawInfo: {
        company: null,
        duns_id: null,
        secret: null,
        key: null,
        state: null,
        transflex_email: null,
        transflex_passphrase: null,
        transflex_client_id: null,
        transflex_client_secret: null,
      },
      openreachInfo: {
        company: null,
        duns_id: null,
        secret: null,
        key: null,
        state: null,
        transflex_email: null,
        transflex_passphrase: null,
        transflex_client_id: null,
        transflex_client_secret: null,
      },
      password_confirmation: '',
      loadingUserProfile: false,
      loadingOpenreach: false
    }
  },
  methods: {
    updateOpenreachData(params) {
      let data = {...params};
      this.openreachInfo = params;
      delete data.transflex_client_id;
      delete data.transflex_client_secret;
      this.loadingOpenreach = true;
      this.$OpenreachPiaApiClient.saveCredentials(this.accountInfo.id, data).then(() => {
        this.loadingOpenreach = false;
        this.$swal('Updated', 'Openreach credentials has been updated successfully', 'success')
        this.getOpenreachCredentials();
      }).catch(error => {
        const errorData = error.response.data;
        if (errorData.result && errorData.result === 'error') {
          this.$swal('Error', errorData.message, 'warning');
        }
        this.loadingOpenreach = false;
      })
    },
    cancelUpdateUser() {
      this.loadingUserProfile = true;
      this.infoLoaded = false;
      this.openreachInfo = this.openreachRawInfo
      this.loadingOpenreach = false;
      this.$root.$emit('admin-edit-account-closed');
    },
    updateUser(data) {
      this.loadingUserProfile = true;


      this.$UserManagementApiClient.updateUser(data.id, data).then(() => {
        this.$swal('Done!', 'User Updated', 'success');
        this.loadingUserProfile = false;
        this.accountInfo = {};
        this.infoLoaded = false;
        this.$root.$emit('admin-update-users-list');
        if (this.authenticatedUser.id === data.id) {
          this.$store.dispatch('Auth/getUserInfo');
        }

      }).catch(() => {
        this.loadingUserProfile = false;
      })
    },
    getOpenreachCredentials() {
      this.loadingOpenreach = true;
      this.$OpenreachPiaApiClient.getCredentials(this.accountInfo.id).then(data => {
        this.openreachInfo = data.data.data;
        this.loadingOpenreach = false;
      }).catch(() => {
        this.loadingOpenreach = false;
      });
    },
    tabChanged(currentTab, previousTab) {
      if (1 === currentTab) {
        this.getOpenreachCredentials();
      } else {
        this.openreachInfo = this.openreachRawInfo;
      }
    }
  },
  computed: {
    ...mapGetters({
      authenticatedUser: 'Profile/getUser',
    })
  },
  mounted() {
    this.$root.$on('set-editable-user-account', (user) => {
      this.accountInfo = user;
      this.infoLoaded = true;
      this.loadingUserProfile = false;
    })
  }
})
</script>

<template>
  <div>
    <b-modal id="editAccount" title="Edit User" :visible="this.infoLoaded" ok-only ok-variant="secondary"
             ok-title="Close"
             @cancel="cancelUpdateUser" @close="cancelUpdateUser" no-close-on-backdrop no-close-on-esc hide-footer
    >
      <div>
        <b-tabs @activate-tab="tabChanged">
          <b-tab title="Profile" class="pt-3">
            <b-overlay :show="loadingUserProfile">
              <FormsUpdateProfile :account-data="accountInfo" @ok="updateUser"
                                  :show-account-limit="accountInfo.is_master"
                                  :show-allow-edit="!accountInfo.is_master"
                                  :show-company="accountInfo.is_master"/>
            </b-overlay>
          </b-tab>
          <b-tab title="Openreach" class="pt-3">
            <b-overlay :show="loadingOpenreach">
              <FormsOpenreachCredentials :openreach-data="openreachInfo" @ok="updateOpenreachData"/>
            </b-overlay>
          </b-tab>
        </b-tabs>


      </div>
    </b-modal>
  </div>
</template>

<style scoped>

</style>