import axios from "axios";
var OpenreachOrders = /** @class */ (function () {
    function OpenreachOrders() {
    }
    OpenreachOrders.getUrl = function () {
        return process.env.VUE_APP_API_URL + '/api/openreach/pia/orders';
    };
    OpenreachOrders.getOrders = function (projectId, piaNoiNumber) {
        return axios.get(OpenreachOrders.getUrl() + '/' + projectId + '/' + piaNoiNumber);
    };
    return OpenreachOrders;
}());
export default OpenreachOrders;
