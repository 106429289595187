import {mapGetters} from "vuex";
import CommonTools from "@/Helpers/CommonTools";
import SplitLineString from "@/Helpers/SplitLineString";
import L from "leaflet";
import Vue from "vue";

const DuctToolBar = {
    data: function () {
        return {
            drawColorOptions: {},
            currentLine: null,
            typeIsSelected: false,
            ductNetworkInfoAvailable: true,
            tmpDuctType: {},
            ductLayerGroup: L.layerGroup()
        }
    },
    watch: {
        redrawDuctsForMobile(state) {
            if (state && this.$Utils.isIphone()) {
                this.drawDucts(true, true)
            } else if (!state && this.$Utils.isIphone()) {
                this.drawDucts(true, false)
            } else {
                this.$store.commit("Duct/setRedrawDuctsForMobile", false);
            }
        },
        selectedDuctType(type) {
            const self = this;
            if (type) {
                this.typeIsSelected = true;
                this.tmpDuctType = type;
                Vue.nextTick(function () {
                    self.map.pm.enableDraw('Line', {
                        templineStyle: {
                            color: self.tmpDuctType.color,
                            dashArray: '5,5',
                        },
                        hintlineStyle: {
                            color: self.tmpDuctType.color,
                            dashArray: '5,5',
                        },
                        pathOptions: {
                            color: self.tmpDuctType.color,
                            fillColor: self.tmpDuctType.color,
                            dashArray: '5,5',
                        },
                        continueDrawing: true,
                        tooltips: true
                    });
                });
            }
        },

    },
    computed: {
        ...mapGetters({
            ducts: 'Duct/ducts',
            selectedDuctType: 'Duct/selectedDuct',
            ductTypeSelectorWindowIsOpened: 'Duct/isInSelectMode',
            newDuctTmpInfo: 'Duct/newDuctTmpInfo',
            redrawDuctsForMobile: 'Duct/redrawDuctsForMobile',
        }),
    },
    methods: {
        correctDuctDrawControl() {
            const self = this;
            this.map.pm.Toolbar.changeActionsOfControl('Polyline', [{
                text: 'Cancel',
                onClick: () => {
                    self.$store.commit("Duct/setIsInSelectMode", false);
                    self.map.pm.disableDraw('line')
                }
            }
            ]);
        },
        /**
         * Get duct info
         * @param e
         * @param layer
         * @param duct
         * @returns {boolean}
         */
        getDuctInfo(e, layer, duct) {
            if (this.livePendingVisible || this.mergeDuctsActive || this.splitLinesActive || this.map.pm.globalDragModeEnabled() || this.map.pm.globalEditModeEnabled() || this.map.pm.globalRemovalModeEnabled()) {
                return true;
            }
            const self = this;
            this.$store.dispatch('Duct/getDuctInfo', duct.id).then(info => {
                self.$bvModal.show('ductInfoModal');
            })
        },
        /**
         * Split duct line
         * @param e
         * @param layer
         * @param duct
         * @returns {boolean}
         */
        splitLines(e, layer, duct) {
            if (!this.splitLinesActive) {
                return false;
            }
            const self = this;
            const sp = new SplitLineString(layer._latlngs, e.latlng)
            const segments = sp.splitLine();
            new Promise((resolve) => {
                const APolyLIne = L.polyline(segments.A).toGeoJSON()
                self.saveDuct(APolyLIne, {
                    duct_type_id: duct.duct_type_id,
                    is_implemented: duct.is_implemented
                }, false, true)
                const BPolyLIne = L.polyline(segments.B).toGeoJSON()
                self.saveDuct(BPolyLIne, {
                    duct_type_id: duct.duct_type_id,
                    is_implemented: duct.is_implemented
                }, false, true)
                resolve();
            }).then(() => {
                self.$store.dispatch("Duct/delete", duct.id).then(() => {
                    self.clearDucts();
                });
            })
        },
        /**
         * Save Duct
         * @param geoJson
         * @param config
         * @param updateCurrentLine
         * @param redrawDucts
         * @param isImplemented
         */
        saveDuct(geoJson, config = {}, updateCurrentLine = false, redrawDucts = false, isImplemented = false) {
            const self = this;
            const length = CommonTools.calculateLength(geoJson.geometry.coordinates);
            const params = {
                duct_type_id: this.selectedDuctType.id,
                geometry: btoa(JSON.stringify(geoJson)),
                project_id: this.$store.state.Projects.selected_project.id,
                is_implemented: isImplemented,
                length,
            };
            self.$store.dispatch('Duct/store', {...params, ...config}).then(response => {
                if (updateCurrentLine) {
                    self.currentLine.id = response.data.data.id
                    self.currentLine.shapeType = 'Duct';
                }
                if (redrawDucts) {
                    self.drawDucts();
                }
            });
        },

        /**
         *
         * @param redraw
         * @param mobile
         */
        drawDucts: function (redraw = true, mobile = false) {
            const self = this;
            self.$store.dispatch('Duct/list', CommonTools.createPolygonFromBounds(self.map.getBounds(), self.map.getCenter())).then(response => {
                self.ductLayerGroup.clearLayers();
                response.map(function (duct) {
                    L.geoJSON(duct.feature, {
                        style: function (feature, layer) {
                            return {
                                color: duct.duct_type.color,
                                weight: mobile === true || self.redrawDuctsForMobile ? 6 : 3,
                                dashArray: duct.is_implemented == 0 ? '5.5' : 0
                            }
                        },
                        onEachFeature: function (feature, layer) {
                            layer.id = duct.id
                            layer.sequence_id = duct.sequence_id
                            layer.shapeType = 'Duct';
                            layer.is_implemented = duct.is_implemented
                            layer.pm.disableLayerDrag()
                            layer.ductType = duct.duct_type;
                            layer.options.preventDragging = true
                            layer.pm.setOptions({draggable: false});
                            layer.on('pm:update', (event) => {
                                try {
                                    self.localEventUpdateLayer(event);
                                } catch (error) {
                                    console.log(error);
                                }
                            })
                            layer.on('pm:remove', self.eventRemoveLayer);
                            self.ductLayerGroup.addLayer(layer)
                        }
                    })
                        .eachLayer(function (layer) {
                            if (layer.feature.geometry.type === 'LineString') {
                                self.livePendingHover(layer, "Duct", duct);
                                layer.bindTooltip('D-' + layer.sequence_id + ' <strong>(' + duct.duct_type.name + ')</strong>')
                                layer.options.preventDragging = true
                                layer.pm.disableLayerDrag()
                                layer.pm.setOptions({draggable: false});

                                layer.on('click', (e) => {
                                    self.splitLines(e, layer, duct)
                                    self.getDuctInfo(e, layer, duct)
                                    self.mergeDucts(e, layer, duct)
                                })


                                layer.on('mouseover', function () {
                                    layer.openTooltip();
                                });
                                layer.on('mouseout', function () {
                                    layer.closeTooltip();
                                });
                            }
                        });
                })
                self.ductLayerGroup.addTo(self.map);
            }).catch(error => {
                console.log(error);
                if (axios.isCancel(error)) {
                    return;
                }
            });
        },
        /**
         * Clean ducts
         * @returns {Promise<unknown>}
         */
        clearDucts() {
            const self = this;

            return new Promise((success, error) => {
                self.ductLayerGroup.clearLayers();
                self.$store.commit("Duct/setDuctsList", []);
                if (self.ductLayerGroup.getLayers().length == 0) {
                    return success("OK");
                }
            })

        },
        /**
         * Action when duct is created
         * @param e
         */
        ductCreatingAction(e) {
            const self = this;

            e.layer.setStyle({
                color: this.selectedDuctType.color,
                fillColor: this.selectedDuctType.color,
                dashArray: [5, 5]
            });
            self.currentLine = e.layer
            const params = {
                duct_type_id: self.selectedDuctType.id,
                geometry: btoa(JSON.stringify(e.layer.toGeoJSON())),
                project_id: self.$store.state.Projects.selected_project.id,
                tubes_count: this.tmpDuctType.tubesCount,
                duct_type: this.tmpDuctType.ductType,
                manufacturer_code: this.tmpDuctType.manufacturerName,
                product_code: this.tmpDuctType.productCode

            };
            self.$store.dispatch('Duct/store', params).then(response => {
                self.currentLine.id = response.data.data.id
                self.currentLine.shapeType = 'Duct';
                e.layer.remove()
                self.drawDucts(true)
            }).catch(() => {
                console.log('catch');
                e.layer.remove()
                self.$store.commit("Duct/setIsInSelectMode", false);
                self.map.pm.disableDraw('line')
                return;
            });
            self.$store.commit("Duct/setIsInSelectMode", true);
            self.typeIsSelected = true;

        },
        /**
         * Action when duct draw started
         * @param e
         */
        ductStartAction(e) {
            if (!this.typeIsSelected) {
                this.drawShowDuctTypeListChooseModal();
                this.$store.commit("Duct/setIsInSelectMode", true);
                return;
            }

            this.typeIsSelected = false;

        },
        changeDuctImplementationStatus(obj, status) {
            let self = this;
            this.$store.dispatch("Duct/changeImplementedStatus", {id: obj.id, status: status}).then(() => {
                self.drawDucts(true)
            })
        },
        /**
         * Show duct type list modal
         */
        drawShowDuctTypeListChooseModal() {
            this.$store.commit("Duct/setIsInSelectMode", true);
            this.$bvModal.show("duct_type_list");
        },

        clearExtraDucts(ducts) {
            const self = this;
            self.ductLayerGroup.eachLayer(function (layer) {
                if (!ducts.includes(layer.id)) {
                    layer.removeFrom(self.map)
                }
            });
        },

        mergeDucts(e, layer, duct) {
            if (!this.mergeDuctsActive) {
                this.firstDuctSelected = false
                return false;
            }
            if (this.firstDuctSelected == false) {
                this.showHelperWindow('Duct Selected, select second duct to merge')
                this.firstDuctSelected = duct.id;
                this.$store.dispatch('Duct/getDuctsForMerging', duct.id).then(response => {
                    this.clearExtraDucts(response)
                })
            } else if (this.firstDuctSelected == duct.id) {
                this.$swal("Warning", "Duct already selected", "warning");
                this.hideHelperWindow()
            } else {
                this.showLoader('Merging Ducts...')
                this.$store.dispatch('Duct/mergeDucts', {
                    ductId: this.firstDuctSelected,
                    secondDuctId: duct.id
                }).then(response => {
                    this.firstDuctSelected = false;
                    this.$swal("Success", "Ducts merged successfully", "success")
                    this.hideHelperWindow()
                    this.hideLoader()
                    this.drawDucts(true)
                })
            }
        },


        // ==================================================
        // Events
        // ==================================================
        localEventUpdateLayer(event) {
            this.$store.commit("Duct/setIsInSelectMode", false);
            const length = CommonTools.calculateLength(event.layer.toGeoJSON().geometry.coordinates);
            this.$store.dispatch("Duct/update", {
                id: event.layer.id,
                params: {geometry: btoa(JSON.stringify(event.layer.toGeoJSON())), length: length}
            }).then(() => {
            }).catch(() => {
                this.drawDucts(true, false);
            })
        },
        eventRemoveLayer(event) {
            this.$store.commit("Duct/setIsInSelectMode", false);
            this.$store.dispatch("Duct/delete", event.layer.id).catch(() => {
                this.map.addLayer(event.layer);
            }).then(data => {
                console.log(data)
            })

        }
    }
}
export default DuctToolBar;