<template>
  <b-modal id="advanced_search" centered title="Advanced Search" @ok="search">

    <b-form-group id="postcode_search-group" label="Postcode" label-for="postcode_search-group">
      <b-form-input id="postcode_search" v-model="form.postcode" type="text" required placeholder="M1 1AE"
      >
      </b-form-input>
    </b-form-group>
    <b-form-group id="address_search-group" label="Partial Address" label-for="address_search-group">
      <b-form-input id="address_search" v-model="form.address_search" type="text" required
                    placeholder="123 London Road">
      </b-form-input>
    </b-form-group>
    <b-form-group id="city_search-group" label="City / Town / Village" label-for="city_search-group">
      <b-form-input id="city_search" v-model="form.city_search" type="text" required
                    placeholder="City / Town / Village Name">
      </b-form-input>
    </b-form-group>
    <b-form-group id="uprn_search-group" label="UPRN" label-for="uprn_search-group">
      <b-form-input id="uprn_search" v-model="form.uprn" type="text" required
                    placeholder="123456">
      </b-form-input>
    </b-form-group>
    <b-form-group id="property-type-group" label="Property Type" label-for="property-type-group">
      <b-form-select v-model="property_type">
        <b-form-select-option value="properties">Properties</b-form-select-option>
        <b-form-select-option value="all">All UPRNs</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group id="grid_reference-group" label="Grid Reference" label-for="grid_reference-group">
      <b-form-input id="grid_reference" v-model="form.grid_reference" type="text" required placeholder="SD6585544525"
      >
      </b-form-input>
    </b-form-group>


    <b-form-group id="location-group" label="Latitude and Longitude" label-for="location-group">
      <b-input-group>
        <b-form-input id="lat_search" v-model="form.location.lat" type="text" required
                      placeholder="Latitude"></b-form-input>
        <b-form-input id="lon_search" v-model="form.location.lon" type="text" required
                      placeholder="Longitude"></b-form-input>
      </b-input-group>

    </b-form-group>

    <b-form-group id="coordinates" label="Easting / Northing" label-for="coordinates">
      <b-input-group>
        <b-form-input id="easting_search" v-model="form.eastNorth.x" type="text" required placeholder="X"
        ></b-form-input>
        <b-form-input id="northing_search" v-model="form.eastNorth.y" type="text" required
                      placeholder="Y"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group id="What3Words" label="What3Words" label-for="What3Words">
      <b-input-group>
        <b-form-input id="w1_search" v-model="form.word.w1" type="text" required placeholder="Word"
        ></b-form-input>
        <b-form-input id="w2_search" v-model="form.word.w2" type="text" required placeholder="Word"
        ></b-form-input>
        <b-form-input id="w3_search" v-model="form.word.w3" type="text" required placeholder="Word"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

  </b-modal>
</template>

<script>
export default {
  name: "AdvancedSearch",
  data() {
    return {
      property_type: 'properties',
      form: {
        uprn: '',
        postcode: '',
        address_search: '',
        city_search: '',
        grid_reference: '',
        location: {
          lat: '',
          lon: ''
        },
        eastNorth: {
          x: '',
          y: '',
        },
        word: {
          w1: '',
          w2: '',
          w3: ''
        },
      },
      groups: {
        uprn: {
          single: true,
          errorMessage: "Invalid Uprn",
          regexp: "^\\d{1,20}$"
        },
        postcode: {
          single: true,
          errorMessage: "Invalid Postcode",
          regexp: "^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$"
        },
        address_search: {single: true, errorMessage: "Error while validating Postcode", regexp: ""},
        city_search: {single: true, errorMessage: "Error while validating Postcode", regexp: ""},
        grid_reference: {
          single: true,
          errorMessage: "Error while validating Grid Reference",
          regexp: "^[a-zA-Z]{2}\s?[0-9]{5}\s?[0-9]{5}$",//SJ 90492 98396
          glue: ","
        },
        location: {
          single: false,
          errorMessageEmpty: "Latitude or Longitude is empty",
          regexp: "^(-?\\d+\.\\d+)$",
          errorMessage: "Error while validating Location",
          glue: ","
        },
        eastNorth: {
          single: false,
          errorMessageEmpty: "X or Y is empty",
          regexp: "^(\\d{5,8})$",
          errorMessage: "Error while validating Easting and Northing",
          glue: ","
        },
        word: {single: false, errorMessageEmpty: "Non of 3 words can be empty", regexp: "", glue: "."},
      }
    }
  },
  methods: {
    search(e) {
      if (this.checkForMultipleGroups(e) !== true) {
        e.preventDefault();
        return;
      }
      const group = this.getGroup();
      if (group === null || group.length === null) {
        this.$swal("Empty", "All search inputs are empty", "info")
        e.preventDefault();
        return;
      }
      let searchString = '';
      const groupConfig = this.groups[group];
      if (groupConfig !== undefined && groupConfig.single === true) {
        if (groupConfig.regexp.length > 0) {
          const reg = new RegExp(groupConfig.regexp);
          if (!reg.test(this.form[group])) {
            this.$swal("Empty", groupConfig.errorMessage, "warning")
            e.preventDefault();
            return;
          }
        }
        searchString = this.form[group];
      } else {
        for (let nestedGroup in this.form[group]) {
          if (this.form[group][nestedGroup].length === 0) {
            this.$swal("Empty", groupConfig.errorMessageEmpty, "warning")
            e.preventDefault();
            return;
          }
          if (groupConfig.regexp.length > 0) {
            const reg = new RegExp(groupConfig.regexp);
            if (!reg.test(this.form[group][nestedGroup])) {
              this.$swal("Empty", groupConfig.errorMessage, "warning")
              e.preventDefault();
              return;
            }
          }
          searchString += this.form[group][nestedGroup] + groupConfig.glue;
        }
        searchString = this.trim(searchString, groupConfig.glue);
      }
      if (searchString.length === 0) {
        this.$swal("Empty", "Search query is empty", "info");
        e.preventDefault();
        return
      }
      let all_properties = this.property_type === 'all';
      this.$root.$emit("search", {query: searchString, all_properties: all_properties});
      return;

    },
    getGroup() {
      let nonEmptyCount = 0
      for (let property in this.form) {
        if (this.groups[property] !== undefined && this.groups[property].single === false) {
          for (let nestedProperty in this.form[property]) {
            if (this.form[property][nestedProperty].length > 0) {
              return property
            }
          }
        } else {
          if (this.form[property].length > 0) {
            return property
          }
        }
      }
      return null;
    },
    checkForMultipleGroups(e) {
      let nonEmpty = 0;
      let input = '';
      for (let group in this.groups) {
        if (this.groups[group].single === true) {
          if (this.form[group].length > 0) {
            nonEmpty++
            if (nonEmpty > 1) {
              this.$swal("Not Allowed", "You can only search using one method", "info")
              this.form[group] = '';
              e.preventDefault()
              return;
            }
          }
        } else {
          let nonEmptyCountInGroup = 0;
          for (let nestedGroupItem in this.form[group]) {
            if (this.form[group][nestedGroupItem].length) {
              nonEmptyCountInGroup++;
            }

          }
          if (nonEmptyCountInGroup > 0) {
            nonEmpty++;
          }
          if (nonEmpty > 1) {
            this.$swal("Not Allowed", "You can only search using one method", "info")
            e.preventDefault()
            return;
          }

        }

      }
      return true;
    },
    trim(str, ch) {
      let start = 0, end = str.length;
      while (start < end && str[start] === ch)
        ++start;

      while (end > start && str[end - 1] === ch)
        --end;

      return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }
  }
}
</script>

<style>
#advanced_search {
  z-index: 99999;
}
</style>

