<template>
  <div>
    <b-modal id="ChamberInfo" :title="title">

      <b-overlay rounded="sm" :show="loading">
        <b-tabs>
          <b-tab title="Child Devices">
            <SubObjectsList v-if="markerInfo" :parent-object-id="markerInfo.id" :project-id="markerInfo.project_id"
                            :items="markerInfo.children"></SubObjectsList>
          </b-tab>
          <b-tab title="Cables" style="padding-top:10px;">
            <div v-if="markerInfo && markerInfo.fibre_cables" v-for="fibre_cable in markerInfo.fibre_cables"
                 :key="fibre_cable.id">
              <CableInTree :depth="0" :key="fibre_cable.id"
                           :cable="fibre_cable"></CableInTree>
            </div>
          </b-tab>
        </b-tabs>
      </b-overlay>
      <template #modal-footer>
        <b-button @click="addObject" variant="info">Add Object</b-button>
      </template>
      <AddObject v-if="markerInfo" :parent-object-id="markerInfo.id"/>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DuctsInfoShort from "@/components/modals/Duct/Info/DuctsInfoShort";
import AddObject from "../Object/AddObject";
import CommonTools from "../../../../Helpers/CommonTools";
import SubObjectsList from "@/components/modals/Objects/Object/SubObjectsList.vue";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";
import DuctTree from "@/components/modals/Duct/Info/DuctTree.vue";
import CableInTree from "@/components/modals/Duct/Info/CableInTree.vue";

export default {
  name: "ChamberInfo",
  components: {CableInTree, DuctTree, SubObjectsList, AddObject, DuctsInfoShort, CommonTools},
  data: () => {
    return {
      objectTypes: null,
      activeTab: 'connections',
      ducts: [],
      clonedDucts: [],
      connections: [],
      loading: false,
      selectedSTubes: [],
      BDucts: [],
      key: 0,
      markerInfo: {
        ducts: [],
      },
    }
  },
  watch: {
    modalObjectInfo(value) {
      if (value && value.code === ObjectTypesEnum.OT_CHAMBER) {
        console.log(value);
        this.load(value.id);
      }
    }
  },
  computed: {
    ObjectTypesEnum() {
      return ObjectTypesEnum
    },
    ...mapGetters({
      modalObjectInfo: 'ObjectInfo/objectModalInfo'
    }),
    title: {
      get() {
        if (this.markerInfo && this.markerInfo.object_type !== undefined) {
          return this.markerInfo.generated_identifier + ' (' + this.markerInfo.object_type.name + ')';
        } else {
          if (this.loading) {
            return;
          }
          return '';
        }
      }
    }
  },
  methods: {
    load(id) {
      this.loading = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/info/' + id).then(response => {
        this.markerInfo = response.data.data
        const modalId = ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_CHAMBER);
        this.$bvModal.show(modalId);
      }).finally(() => this.loading = false);
    },
    addObject() {
      this.loading = true
      this.$store.dispatch('ObjectTypes/getConnectableObjects', {
        objectId: this.markerInfo.id,
        default_type_id: this.markerInfo.object_type.default_type_id,
      }).then(() => {
        this.loading = false
        this.$bvModal.show('addObject');
      })
    },
    ductInfoSmall(duct) {
      return {
        parent_duct_id: duct.id,
        length: duct.length,
        geometry: btoa(duct.feature),
        project_id: duct.project_id,
        duct_type_id: duct.duct_type_id
      }

    },
  },
  mounted() {
    this.$root.$on('refresh_ot_chamber', (id) => {
      this.load(id);
    })
  }
}
</script>

<style scoped>
</style>