import CommonTools from "@/Helpers/CommonTools";
import {mapActions, mapGetters} from "vuex";
import L from "leaflet";

const TextToolbar = {
    data() {
        return {
            textLayerGroup: L.layerGroup(),
            drawnLayer: null,
            texts: [],
        }
    },
    watch: {
        textLayerIsActive: function (value) {
            if (value) {
                this.drawProjectText()
            } else {
                this.clearProjectText();
            }
        },
    },
    computed: {
        ...mapGetters({
            textLayerIsActive: 'LayersList/getTextStatus'
        })
    },
    methods: {
        saveText(data) {
            this.activateTextLayer('texts')
            this.drawnLayer = data.layer;
            axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/text', {
                text: data.layer.options.text,
                lat: data.layer._latlng.lat,
                lng: data.layer._latlng.lng,
                project_id: this.$store.state.Projects.selected_project.id,
            }).then(() => {
                this.drawProjectText();
            })
        },
        drawProjectText() {
            if (!this.textLayerIsActive) {
                return;
            }
            if (this.drawnLayer !== null) {
                this.drawnLayer.remove();
            }
            this.textLayerGroup.clearLayers();
            if (!this.map.hasLayer(this.textLayerGroup)) {
                console.log('map has not textLayerGroup');
                this.textLayerGroup.addTo(this.map);
            }
            const polygon = CommonTools.createPolygonFromBounds(this.map.getBounds(), this.map.getCenter());
            this.listTextComponents(polygon).then(data => {
                this.texts = data;
                data.map(item => {
                    const marker = L.marker({lat: item.lat, lng: item.lng}, {
                        textMarker: true,
                        text: item.text,
                        id: item.id,
                    });
                    marker.on('pm:update', (event) => {
                        this.eventUpdateLayer(event);
                    })
                    marker.on('pm:remove', (e) => {
                        L.DomEvent.stopPropagation(e);
                        this.deleteTextComponent(e.target.options.id)

                    });
                    this.textLayerGroup.addLayer(marker);
                })
            })
        },
        eventUpdateLayer(event) {
            console.log('text update')
            if (event.layer.options.id === undefined) {
                return;
            }
            const data = {
                text: event.target.options.text,
                lat: event.target._latlng.lat,
                lng: event.target._latlng.lng,
                id: event.layer.options.id
            }
            this.updateTextComponent(data).then(() => {
                this.drawProjectText();
            })
        },
        clearProjectText() {
            this.textLayerGroup.clearLayers();
        },
        ...mapActions({
            listTextComponents: 'MapComponentText/list',
            updateTextComponent: 'MapComponentText/update',
            deleteTextComponent: 'MapComponentText/delete',
            activateTextLayer: 'LayersList/activateSubLayer',
        }),
    }
}
export default TextToolbar;