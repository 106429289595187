import Vue from "vue";
import {BModal} from "bootstrap-vue";

const SingleFibreModal = Vue.extend({
    components: {
        BModal
    },
    props: {
        title: {
            type: String,
            default: "Select Fibre",
        },
        fibres: Array,
        meth: {
            type: Function
        },
    },
    data() {
        return {
            selectedFibre: null
        }
    },
    methods: {
        ok() {
            this.meth(this.selectedFibre)
        }
    },
    template: `
      <b-modal @ok="ok" :ok-disabled="selectedFibre===null" :title="title" id="single-fibre-modal">
        <select class="form-control" v-model="selectedFibre">
          <option :value="fibre.id" v-for="fibre in fibres">C-{{ fibre.cable_with_type.sequence_id }}
            ({{ fibre.cable_with_type.cable_type.type_name }}) / Tube {{ fibre.tube_number }} /
            Fibre {{ fibre.fibre_number }}
          </option>
        </select>
      </b-modal>
    `
});
export default {
    install: (app, options) => {
        // inject a globally available $selectSingleFibre() method
        app.prototype.$selectSingleFibre = (config) => {
            // retrieve a nested property in `options`
            // using `key` as the path
            const instance = new SingleFibreModal({
                propsData: {
                    title: config.title,
                    fibres: config.fibres,
                    meth: config.meth
                }
            });
            instance.$mount();
            instance.$bvModal.show('single-fibre-modal');
            document.body.appendChild(instance.$el);
            return null;
        }
    }
}