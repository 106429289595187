import axios from "axios";
var ExportHistoryClient = /** @class */ (function () {
    function ExportHistoryClient() {
    }
    ExportHistoryClient.getUrl = function () {
        return process.env.VUE_APP_API_URL + '/api/export/properties';
    };
    ExportHistoryClient.getOptions = function () {
        return axios.get(ExportHistoryClient.getUrl() + '/options');
    };
    ExportHistoryClient.postExport = function (data) {
        return axios.post(ExportHistoryClient.getUrl(), data);
    };
    ExportHistoryClient.listExportHistory = function () {
        return axios.get(ExportHistoryClient.getUrl());
    };
    ExportHistoryClient.deleteHistoryItem = function (historyItemId) {
        return axios.delete(ExportHistoryClient.getUrl() + '/' + historyItemId);
    };
    ExportHistoryClient.getFilterPropertiesCount = function (data) {
        return axios.post(ExportHistoryClient.getUrl() + '/filter/count', data);
    };
    return ExportHistoryClient;
}());
export default ExportHistoryClient;
