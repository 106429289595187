<template>
  <b-modal id="moveStreetCabinetObject" title="Move Object">
    <b-form>
      <b-form-group>
        <select class="form-control" v-model="side">
          <option value="front">Front</option>
          <option value="rear">Rear</option>
        </select>
      </b-form-group>
      <b-form v-if="side!==null">
        <div v-for="module in listModules">
          <div :class="['row', 'port-item', allowedToPutObject(port,module) ? 'allowed' : 'not-allowed' ] "
               v-for="port in module.used_modules">
            <span v-if="allowedToPutObject(port,module)" class="w-100 h-100"
                  @click="putModule(port,module)">Module# {{ port }}</span>
            <span v-else>Module #{{ port }} - <strong>O-{{
                module.connected_object_sequence
              }} ({{ module.type }})</strong></span>
          </div>
        </div>

      </b-form>
    </b-form>
  </b-modal>
</template>
<script>
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: "StreetCabinetMoveObject",
  data() {
    return {
      side: null,
      listModules: [],
    }
  },
  computed: {
    ...mapGetters({
      movableObject: 'StreetCabinet/movableObject',
      streetCabinetId: 'StreetCabinet/streetCabinetId'
    }),
  },
  watch: {
    side(value) {
      if (value !== null) {
        console.log('Side changed');
        this.getModules();
      }
    }
  },
  methods: {
    getModules() {
      if (this.side === 'front') {
        this.listModules = this.$store.getters["StreetCabinet/front"]
      }
      if (this.side === 'rear') {
        this.listModules = this.$store.getters["StreetCabinet/rear"]
      }
    },
    allowedToPutObject(port, currentModule) {
      console.log(currentModule);
      if (currentModule.connected_object !== null) {
        if (this.movableObject.connected_object === currentModule.connected_object) {
          return true;
        }
        return false;
      }
      return true;
    },
    putModule(port, currentModule) {
      this.portsAreFree([]);
      let lastPort = port + this.movableObject.used_modules.length - 1;
      const range = (start, stop) => Array.from({length: stop - start + 1}, (_, i) => start + i)

      const newPorts = range(port, lastPort);
      const areFree = this.portsAreFree(newPorts);
      if (!areFree) {
        this.$swal('Error', 'There is no free space to put an object inside module(s)', 'warning');
        return;
      }
      axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.streetCabinetId + '/ot_street_cabinet/move_module/' + this.movableObject.connected_object, {
        side: this.side,
        new_position: newPorts
      }).then(response => {
        Vue.nextTick(() => {
          this.$store.dispatch('StreetCabinet/getConnections', {id: this.streetCabinetId})
          this.$bvModal.hide('moveStreetCabinetObject');
          this.side = null;
          this.listModules = [];
          this.$store.commit('StreetCabinet/setMovableObject', null)
          this.$store.commit('StreetCabinet/setFrontPorts', [])
          this.$store.commit('StreetCabinet/setRearPorts', [])
          this.$store.commit('StreetCabinet/setStreetCabinetId', null)
        })
      })
    },
    portsAreFree(ports) {
      const rawPorts = this.listModules.flatMap(obj => {
            let value = obj.connected_object === null;
            if (obj.connected_object === this.movableObject.connected_object) {
              value = true;
            }
            return obj.used_modules.map(module => ({
              key: module,
              value: value
            }))
          }
      );
      for (const port of ports) {
        const indexExist = rawPorts.findIndex(item => {
          return item.key === port
        });
        if (indexExist === -1) {
          return false;
        }
        for (const modulePort of rawPorts) {
          // console.log(port, modulePort);
          if (modulePort.key === port && modulePort.value !== true) {
            // console.log('Returned false', modulePort.key === port, modulePort.value === true)
            return false;
          }
        }
      }
      return true;
    }
  }
}
</script>
<style scoped>
.port-item {
  border-radius: 5px;
  padding: 4px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
  font-size: 13px;
}

.allowed {
  border: 1px solid green;
  cursor: pointer;
}

.allowed:hover {
  background-color: #289d28;
}

.not-allowed {
  border: 1px solid red;
  background-color: red;
  color: white;
}
</style>