<template>
  <div>
<l-marker
    v-if="sites.length>0"
    v-for="site in sites"
    :key="site.uuid"
    :options="{preventDragging:true}"
    :lat-lng="[site.lat,site.lng]"
    @click="siteInfo(site)"
>
  <l-icon
      :icon-size="[32,32]"
      icon-url="https://assets.postcodegenie.com/assets/img/sites_32.png" >
  </l-icon>
</l-marker>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SitesOnLocationMarkers",
  computed:{
    ...mapGetters({sites:'Sites/sites'})
  },
  methods:{
    siteInfo(site){
      this.$store.commit('Sites/setModalSite',site.id);
      this.$bvModal.show('siteInfo')
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>