<script>

export default {
  name: "FormsOpenreachCredentials",
  props: {
    openreachData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      openreachRawInfo: {
        company: null,
        duns_id: null,
        secret: null,
        key: null,
        state: null,
        transflex_email: null,
        transflex_passphrase: null,
        transflex_client_id: null,
        transflex_client_secret: null,
        account_number: null,
      },
      openreachInfo: this.openreachData,
    }
  },
  methods: {
    emitUpdate() {
      this.$emit('ok', this.openreachInfo)
    }
  }
}
</script>

<template>
  <b-form>
    <div class="row">
      <div class="col-6">
        <b-form-group label="Company" label-for="openreach_company">
          <b-form-input id="openreach_company" v-model="openreachInfo.company"></b-form-input>
        </b-form-group>
        <b-form-group label="Account Number" label-for="account_number">
          <b-form-input id="account_number" v-model="openreachInfo.account_number"></b-form-input>
        </b-form-group>
        <b-form-group label="Openreach DUNS ID" label-for="openreach_duns_id">
          <b-form-input id="openreach_duns_id" v-model="openreachInfo.duns_id"></b-form-input>
        </b-form-group>
        <b-form-group label="Openreach APIGee Secret" label-for="openreach_secret">
          <b-form-input id="openreach_secret" v-model="openreachInfo.secret"></b-form-input>
        </b-form-group>
        <b-form-group label="Openreach APIGee Key" label-for="openreach_key">
          <b-form-input id="openreach_key" v-model="openreachInfo.key"></b-form-input>
        </b-form-group>
        <b-form-group label="Openreach Environment" label-for="openreach_state">
          <b-form-select id="openreach_state" v-model="openreachInfo.state">
            <b-form-select-option value="live">Live</b-form-select-option>
            <b-form-select-option value="sandbox">Sandbox</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group>
          <b-button @click="emitUpdate" class="right" variant="success">Update Openreach Data
          </b-button>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Transflex E-Mail" label-for="transflex_email">
          <b-form-input id="transflex_email" v-model="openreachInfo.transflex_email"></b-form-input>
        </b-form-group>
        <b-form-group label="Transflex Passphrase" label-for="transflex_passphrase">
          <b-form-input id="transflex_passphrase"
                        v-model="openreachInfo.transflex_passphrase"></b-form-input>
        </b-form-group>
        <b-form-group label="Transflex Client ID" label-for="transflex_client_id">
          <b-form-input id="transflex_client_id" disabled
                        v-model="openreachInfo.transflex_client_id"></b-form-input>
        </b-form-group>
        <b-form-group label="Transflex Secret" label-for="transflex_secret">
          <b-form-input id="transflex_secret" disabled
                        v-model="openreachInfo.transflex_client_secret"></b-form-input>
        </b-form-group>
      </div>
    </div>
  </b-form>
</template>

<style scoped>

</style>