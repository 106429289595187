<template>
  <div>
    <b-modal size="lg" id="opportunities_list" @shown="getOpportunities"  ok-only ok-title="Close" title="Opportunities" centered>
      <template #modal-footer>
        <b-col>
          <b-pagination v-if="totalRows>0 && totalRows>perPage && !showOverlay"
                        aria-controls="my-table"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        class="my-0"
          ></b-pagination>
        </b-col>
      </template>
      <b-overlay :show="showOverlay" rounded="sm">
        <b-table
            :items="Opportunities"
            :fields="fields"
            v-show="totalRows>0"
            id="op-table"
            ref="op-table">
          <template #cell(actions)="data">
            <b-button size="sm" class="mr-2" @click="showOpportunity(data.item.cluster_id)" variant="outline-success">Show cluster</b-button>
            <b-button size="sm"  @click="downloadPropertiesCsv(data.item.cluster_id)" variant="outline-success">Export CSV</b-button>
          </template>
        </b-table>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>

import axios from "axios";
import convexHull from "@/Mixins/Draw/convexhull.js";
import L from "leaflet";

export default {
  name: "Opportunities",

  data() {
    return {
      isInSelectMode: false,
      showOverlay: false,
      selectedOpportunityId: 0,
      totalRows: 100,
      perPage: 10,
      currentPage: 1,
      Opportunities: [],
      Circles: [],
      bounds: [],
      convexHullPoints: [],
      leafletHull: [],
      convexHullPolygon: [],
      fields: [{key: 'cluster_id', label: 'Cluster ID'}, {key: 'property_count', label: 'Count'}, {key: 'postcode_prefix', label: 'Postcode Prefix'},{
        key: 'actions',
        label: 'Actions',
        class: 'text-right'
      }],
    }
  },
  methods: {
    showOpportunity(Id) {
      //check if properties and polygons already exist
      if(this.convexHullPoints.length>0){
        this.convexHullPolygon = L.polygon(this.leafletHull).removeFrom(window.Lmap);
        this.convexHullPoints = [];
        this.leafletHull = [];
      }
      if(this.Circles.length>0){
        for(let i=0; i<this.Circles.length; i++){
          this.Circles[i].removeFrom(window.Lmap)
        }
      }
      //create new request
      this.selectedOpportunityId = Id;
      this.showOverlay = true;
      axios.get(process.env.VUE_APP_API_URL + '/api/opportunity/' + this.selectedOpportunityId).then(response => {
          this.latlngs = response.data
          const bounds = new L.LatLngBounds(this.latlngs);
          this.drawCircleGroup(this.latlngs)
          this.drawClusterPolygon(this.latlngs)
          window.Lmap.fitBounds(bounds);
      }).finally(() => {
        this.showOverlay = false;
        this.$bvModal.hide('opportunities_list')
      });
    },
    downloadPropertiesCsv(Id) {
      const self = this;
      this.selectedOpportunityId = Id;
      axios.get(process.env.VUE_APP_API_URL + '/api/opportunities/csv/' + this.selectedOpportunityId, {responseType: "blob"}).then(response => {
            let blob = new Blob([response.data], {
              type: "application/vnd.ms-excel"
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download =  this.selectedOpportunityId + ".csv";
            a.click();
            window.URL.revokeObjectURL(url);
      });
    },

    getOpportunities() {
      const self = this;
      this.showOverlay = true;
      axios.get(process.env.VUE_APP_API_URL + '/api/opportunities/' + this.currentPage).then(response => {
        self.Opportunities = response.data.items
        self.totalRows = response.data.total[0].total_rows;
      }).finally(() => {
        this.showOverlay = false;
      });
    },

    drawCircleGroup(latlngs) {

      const style = {
        radius: 4,
        fillColor: "#ff3c00",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.5
      };
      for(let i=0; i <latlngs.length; i++) {
        const circle = L.circle([latlngs[i][0], latlngs[i][1]], style);
        this.Circles.push(circle)
        circle.addTo(window.Lmap);
      }
    },

    drawClusterPolygon(latlngs) {
      var latlngArray = [];
      for(let i=0; i <latlngs.length; i++) {
        latlngArray.push(L.latLng(latlngs[i][0], latlngs[i][1]));
      }
      var points = latlngArray.map(function (element) {
            return {
              x: element.lat,
              y: element.lng
            }
          }
      )
      this.convexHullPoints = convexHull(points);
      this.leafletHull = this.convexHullPoints.map(function (element) {return ([element.x,element.y])})
      this.convexHullPolygon = L.polygon(this.leafletHull).addTo(window.Lmap);

      this.bounds = this.convexHullPolygon.getBounds();
      Lmap.fitBounds(this.bounds);
    }
  },
  watch: {
     currentPage: function(value) {
        this.currentPage = value
        this.getOpportunities(value)
     }
  },
}
</script>

<style scoped>
.duct-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}

.surface_name {
  font-size: 12px;
}
</style>

