<template>
  <div>
    <b-modal size="xl" id="usersManagement" :title="title" @show="loadUsers">
      <b-overlay :show="loading" :class="[loading?'p-5':'']">
        <div v-if="users.length>0">
          <table class="table table-striped">
            <thead>
            <tr>
              <th v-if="isAdmin">Company</th>
              <th v-if="!isAdmin">User</th>
              <th>Email</th>
              <th>Sub Account Usage</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in masterUsers">
              <td>
                <div class="d-flex flex-row">
                  <div>
                    <span v-if="isAdmin">{{ user.company }}</span>
                    <span v-else-if="!isAdmin && user.parent_user_id===null">{{ user.full_name }}</span>
                    <span v-else><b-badge variant="info"> {{ user.parent_user }}</b-badge> {{
                        user.full_name
                      }}</span>
                  </div>
                  <div class="pl-3">
                    <b-badge v-if="user.is_admin" variant="primary">Admin</b-badge>
                    <b-badge v-if="user.is_master" variant="danger" class="ml-2">Master</b-badge>
                  </div>
                </div>
              </td>
              <td>{{ user.email }}</td>

              <td>
                <span v-if="user.is_master">{{ user.sub_account_count }} / {{ user.sub_account_limit }}</span>
                <span v-else>-/-</span>
              </td>
              <td>
                <div class="d-flex float-right">
                  <b-button v-if="!user.disabled " @click="disable(user)" :disabled="user.id == currentUser.id"
                            size="sm" variant="danger">Suspend
                  </b-button>
                  <b-button v-if="user.disabled" @click="enable(user)" size="sm" variant="success">Unsuspend</b-button>
                  <b-button size="sm" :disabled="!(!user.is_admin && user.id !== currentUser.id)"
                            @click="remove(user)" variant="danger" class="ml-1">Delete
                  </b-button>
                  <b-button size="sm" variant="info" @click="openEditAccountModalFor(user)"
                            class="ml-1">Edit
                  </b-button>
                  <b-button size="sm"
                            @click="showSubAccounts(user)" variant="info"
                            class="ml-1">Sub Accounts
                  </b-button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
      <template #modal-footer>
        <b-button variant="success" v-b-modal.new-master-account>Add new master account</b-button>
      </template>
    </b-modal>
    <EditUserAccount/>
    <b-modal id="createSubAccountModal" :visible="subAccountFor!==null" hide-footer
             title="Create Sub Account">
      <b-overlay :show="loadingCreateSubAccount">
        <FormsUpdateProfile :account-data="subAccountForm" @ok="createSubAccount" :is-create="true"/>
      </b-overlay>
    </b-modal>
    <AddNewMasterAccountModal/>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import CommonTools from "@/Helpers/CommonTools";
import EditUserAccount from "@/Admin/Modules/UserManagement/SubModules/EditUserAccount.vue";
import FormsUpdateProfile from "@/components/Modules/Account/forms/FormsUpdateProfile.vue";
import AddNewMasterAccountModal from "@/Admin/Modules/UserManagement/AddNewMasterAccountModal.vue";
import Vue from "vue";

export default {
  components: {AddNewMasterAccountModal, FormsUpdateProfile, EditUserAccount},
  data() {
    return {
      loadingCreate: false,
      loadingUpdate: false,
      users: [],
      accounts: [],
      subAccount: {},
      editSubAccount: {},
      selectedUser: null,
      loading: true,
      subAccountFor: null,
      subAccountForm: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: null,
        allow_edit: false,
        sub_account_limit: 1,
      },
      loadingCreateSubAccount: false,
      header: ['user', 'email',
        'Actions'
      ],
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "Auth/isAdmin",
      currentUser: "Profile/getUser"
    }),
    title: function () {
      if (this.isAdmin) {
        return 'Company Management';
      }
      return 'Users Management';
    },
    masterUsers: function () {
      return this.users.filter(user => user.is_master);
    }
  },
  methods: {
    openEditAccountModalFor(user) {
      this.$root.$emit('set-editable-user-account', user);
      this.$bvModal.show('editAccount')
    },
    createSubAccount(accountData) {
      this.loadingCreateSubAccount = true;
      this.$UserManagementApiClient.addSubAccount(this.subAccountFor.id, accountData).then(() => {
        this.loadingCreateSubAccount = false;
        this.loadUsers();
        this.subAccountFor = null;
        this.$swal('Success', 'Sub account has been added successfully', 'success');
      }).catch(() => {
        this.loadingCreateSubAccount = false;
      })

    },
    remove(user) {
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to delete this account?', 'Delete', 'Cancel').then(() => {
        this.loading = true;
        this.$UserManagementApiClient.deleteUser(user.id).then(() => {
          this.loading = false;
          this.$swal('Success', 'User deleted', 'success');
          this.loadUsers();
        }).catch(() => {
          this.loading = false;
        })
      }).catch(() => {
      })
    },
    disable(user) {
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to suspend this account?', 'Suspend', 'Cancel').then(() => {
        this.loading = true;
        this.$UserManagementApiClient.updateUser(user.id, {disabled: true}).then(() => {
          this.$swal('Success', 'User disabled', 'success');
          // this.users = [];
          this.loadUsers();
        }).catch(() => {
          this.loading = false;
        })
      }).catch(() => {
      })
    },
    enable(user) {
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to Unsuspend this account?', 'Unsuspend', 'Cancel').then(() => {
        this.loading = true;
        this.$UserManagementApiClient.updateUser(user.id, {disabled: false}).then(() => {
          this.$swal('Success', 'User enabled', 'success');
          this.loadUsers()
        }).catch(() => {
          this.loading = false;
        })
      }).catch(() => {
      })
    },
    loadUsers() {
      if (this.selectedUser !== null) {
        this.getSubAccounts();
      }
      this.users = [];

      this.loading = true;
      this.$UserManagementApiClient.getUsers().then(response => {
        this.users = response.data.data;
        this.loading = false;
      })
    },
    showSubAccounts(user) {
      this.selectedUser = user;
      this.$store.commit('SubAccounts/setForUser', user);
      const self = this;
      Vue.nextTick(function () {
        self.$bvModal.show('shared_sub_accounts');
      })
    },
    getSubAccounts() {
      this.loading = true;
      this.$UserManagementApiClient.getSubAccounts(this.selectedUser.id).then(response => {
        this.accounts = response.data.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    saveNewSubAccount(data) {
      data['parent_user_id'] = this.selectedUser.id;
      this.$UserManagementApiClient.addSubAccount(this.selectedUser.id, data).then(() => {
        this.loadingCreateSubAccount = false;
        this.loadUsers();
        this.$swal('Success', 'Sub account has been added successfully', 'success');
        this.$bvModal.hide('new_sub_account_for_user')
      }).catch(() => {
        this.loadingCreateSubAccount = false;
      })
    },
    saveUpdatedSubAccount(data) {
      console.log(data);
    }
  },
  mounted() {
    this.$root.$on('admin-update-users-list', () => {
      this.users = [];
      this.loadUsers();
    })
  }
}
</script>
<style scoped>

</style>