<script>
import {mapGetters} from "vuex";
import L from "leaflet";

export default {
  data() {
    return {
      show: false,
      popup: null,
      geojson: null,
    }
  },
  computed: {
    ...mapGetters({
      available: 'StaticPopupStore/available',
      location: 'StaticPopupStore/getLatLng',
      content: 'StaticPopupStore/getContent',
      closeCallBack: 'StaticPopupStore/getCloseEventHandler',
      clickCallBack: 'StaticPopupStore/getClickEventHandler',
      geoJsonData: 'StaticPopupStore/getGeoJson',
      showGeoJson: 'StaticPopupStore/getShowGeoJson'
    })
  },
  watch: {
    available(value) {
      if (value) {
        let content = this.content;
        if (this.clickCallBack) {
          content = L.DomUtil.create('div', 'clickablePopup');
          content.innerHTML = this.content;
          L.DomEvent.addListener(content, 'click', (event) => {
            this.click();
          });
        }
        this.popup = L.popup()
            .setLatLng(this.location)
            .setContent(content)
            .openOn(window.Lmap);
        if (this.showGeoJson) {
          this.geojson = L.geoJSON(this.geoJsonData).addTo(window.Lmap)
        }
        this.popup.on('remove', () => {
          this.close();
          if (this.geojson) {
            this.geojson.remove();
            this.geojson = null;
          }
        })


      } else {
        if (this.popup) {
          this.popup.remove();
          this.popup = null;
          this.close();
        }

        if (this.geojson) {
          this.geojson.remove();
          this.geojson = null;
        }
      }

    }
  },
  methods: {
    close() {
      if (this.closeCallBack) {
        this.closeCallBack();
      }
      this.$store.dispatch('StaticPopupStore/close')
    },
    click() {
      if (this.clickCallBack) {
        this.clickCallBack(this.$store, this.geoJsonData);
      }
    }
  },
  render(createElement, context) {
  }
}
</script>