<template>
  <b-modal ref="flagSelector" centered id="flagSelector" hide-footer hide-header>
    <ul id='flag_icons'>
      <li data-toggle='tooltip' data-placement='top' title='Blue' @click='flagProperty("blue")'><img
          src='/assets/img/icons/flags/flag_blue.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Green' @click='flagProperty("green")'><img
          src='/assets/img/icons/flags/flag_green.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Orange' @click='flagProperty("orange")'><img
          src='/assets/img/icons/flags/flag_orange.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Pink' @click='flagProperty("pink")'><img
          src='/assets/img/icons/flags/flag_pink.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Purple' @click='flagProperty("purple")'><img
          src='/assets/img/icons/flags/flag_purple.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Red' @click='flagProperty("red")'><img
          src='/assets/img/icons/flags/flag_red.png'/></li>
      <li data-toggle='tooltip' data-placement='top' title='Yellow' @click='flagProperty("yellow")'><img
          src='/assets/img/icons/flags/flag_yellow.png'/></li>
    </ul>
  </b-modal>
</template>

<script>

export default {
  name: "FlagSelector",
  props: ['uprnInfo'],
  methods: {
    flagProperty(color) {
      this.$store.dispatch('Properties/addFlag', {
        uprn: this.uprnInfo.uprn,
        icon: color,
        isCustom: this.uprnInfo.is_custom
      }, {root: true}).then(() => {
        this.$bvModal.hide('flagSelector');
        this.uprnInfo.isFlagged = 1;
        this.uprnInfo.flag = process.env.VUE_APP_REMOTE_HOST + "/assets/img/icons/flags/flag_" + color + ".png";
        this.$store.commit('Properties/addFlag', {
          uprn: this.uprnInfo.uprn,
          icon: color
        }, {root: true})
        this.$store.commit('Search/setUprnInfo', this.uprnInfo, {root: true})
        this.$root.$emit('changeMarkerIcon', {uprn: this.uprnInfo.uprn, color: color, key: this.uprnInfo.key})


      });
    }
  },
  // computed: {
  //   ...mapGetters({
  //     uprnInfo: 'Search/getCustomUprnInfo'
  //   })
  // }
}
</script>

<style scoped>
#flag_icons {
  list-style: none;
  float: left;
  margin-top: 20px
}

#flag_icons li {
  float: left;
  padding: 5px 12px;
  text-align: center;
  border: 1px solid lightblue;
  margin-left: 9px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>