<script>

import {mapMutations} from "vuex";

export default {
  name: "TubesInCable",
  data() {
    return {
      opened: false,
    }
  },
  props: ['tube', 'depth'],
  methods: {
    ...mapMutations({
      setFibreId: "Tracing/setFibreId"
    }),
  },
  computed: {
    getTubeNumber: function () {
      return this.tube[0].tube_number;
    },
    getTubeColor: function () {
      return this.tube[0].tube_color;
    },
    getFibres: function () {
      return this.tube;
    },
    iconClasses() {
      return {
        'fas fa-caret-right': !this.opened,
        'fas fa-caret-down': this.opened,
      }
    },
    titleIndent() {
      return {transform: `translate(${this.depth * 10}px)`}
    },
  }
}
</script>

<template>
  <div :style="titleIndent" :class="['cableTitle']">
    <div class="d-block" @click="opened=!opened">
      <span :class="iconClasses"></span>

      <svg width="24" height="24">
        <path
            style="fill-rule: evenodd; stroke: rgb(0, 0, 0); stroke-width: 0; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
            d="m6.1153753,9.071917382812444 c-1.3034685000000001,0 -2.3528109,1.1759 -2.3528109,2.6365 l0,0.21880000000000002 c0,1.4607 1.0493424,2.6365 2.3528109,2.6365 l10.6198167,0 c1.303468,0 2.352811,-1.1758 2.352811,-2.6365 l0,-0.21880000000000002 c0,-1.4606 -1.049343,-2.6365 -2.352811,-2.6365 l-10.6198167,0 zm10.8128197,0.6179000000000001 a1.5240276000000001,2.0647589 0 0 1 1.5233539999999999,2.0649 a1.5240276000000001,2.0647589 0 0 1 -1.5233539999999999,2.065 a1.5240276000000001,2.0647589 0 0 1 -1.525651,-2.065 a1.5240276000000001,2.0647589 0 0 1 1.525651,-2.0649 z"
            id="rect3334" inkscape:connector-curvature="0" fill-opacity="1" :fill="getTubeColor"/>
      </svg>

      <span> Tube {{ getTubeNumber }}</span>
    </div>
    <div v-if="opened" class="ml-2">
      <div class="d-flex" style="height: 18px;margin-left: 20px;" v-for="fibre in getFibres"
           @click="setFibreId(fibre.id)">
        <span :style="'margin-top:5px;height: 2px; width: 24px; background-color: '+fibre.fibre_color"></span>
        <span class="pl-1">Fibre {{ fibre.fibre_number }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>