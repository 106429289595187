<template>
<b-modal title="Add new contact" id="addSiteContact"  @ok="saveContact" ok-title="Save Contact">
  <b-overlay :show="showLoader">
  <b-form-group>
    <label for="name">Name:</label>
    <b-form-input
        id="name"
        v-model="contact.name"
        trim
    ></b-form-input>
  </b-form-group>
  <b-form-group>
    <label for="phone">Phone:</label>
    <b-form-input
        id="phone"
        v-model="contact.phone"
        trim
    ></b-form-input>
  </b-form-group>
  <b-form-group>
    <label for="name">Email:</label>
    <b-form-input
        id="email"
        v-model="contact.email"
        trim
    ></b-form-input>
  </b-form-group>
  <b-form-group>
    <label for="note">Notes:</label>
    <b-form-input
        id="note"
        v-model="contact.note"
        trim
    ></b-form-input>
  </b-form-group>
  </b-overlay>
</b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AddContract",
  data: ()=>{
    return {
      contact: {
        name: null,
        email: null,
        phone: null,
        note: null,
      }
    }
  },
  computed:{
    ...mapGetters({
      siteId:'Sites/siteIdForInfo',
      showLoader:'Sites/isLoading',
    })
  },
  methods:{
    saveContact(e){
      e.preventDefault();
      this.isLoading = true;
      axios.post(process.env.VUE_APP_API_URL+'/api/sites/'+this.siteId+'/contacts',this.contact).then(response=>{
        this.$store.dispatch('Sites/getContacts',this.siteId);
        this.$bvModal.hide('addSiteContact');
        this.contact.name = null;
        this.contact.email = null;
        this.contact.phone = null;
        this.contact.note = null;
      })
    }
  }
}
</script>

<style scoped>

</style>