<template>
  <b-modal id="siteLegalInstrumentUpload" title="New Legal Instrument" @ok="save" ok-title="Upload" @hidden="hidden">
    <b-overlay :show="showLoader">
      <b-form-group>
        <label for="image">Document:</label>
        <b-form-file
            id="document"
            v-model="form.document"
            trim
        ></b-form-file>
      </b-form-group>
      <b-form-group>
        <label for="document_name">Title:</label>
        <b-form-input
            id="document_name"
            v-model="form.name"
            trim
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label>Expire Date:</label>
        <b-form-datepicker v-model="form.expires_at"></b-form-datepicker>
      </b-form-group>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LegalInstrumentUpload",
  data: () => {
    return {
      form: {
        document: null,
        name: null,
        expires_at: null
      }
    }

  },
  computed: {
    ...mapGetters({
      siteId: 'Sites/siteIdForInfo',
      showLoader: 'Sites/isLoading',
    })
  },
  methods: {
    save(e) {
      e.preventDefault();
      const form = new FormData();
      form.append('document', this.form.document);
      form.append('name', this.form.name);
      form.append('expires_at', this.form.expires_at);
      this.$store.dispatch('Sites/addLegalInstrument', form).then(() => {
        this.$swal('Success', 'Document for Site is saved successfully', 'success');
        this.$bvModal.hide('siteLegalInstrumentUpload');
      })
    },
    hidden() {
      this.form.name = null;
      this.form.document = null;
      this.form.expires_at = null;
    }
  }
}
</script>

<style scoped>

</style>