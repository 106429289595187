<template>
  <div>
    <b-overlay
        :show="busy"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
    >
      <button
          :disabled="busy || disabled"
          :class="cssClass"
          @click="onClick"
      >
        {{ text }}
      </button>
    </b-overlay>
  </div>
</template>
<script>
export default {
  name: "OverlayButton",
  data() {
    return {
      busy: false
    }
  },
  props: {
    disabled: {type: Boolean, default: false},
    clickHandler: {type: Function},
    methodParam: {type: String},
    text: {type: String},
    cssClass: {type: String}
  },
  methods: {
    onClick() {
      this.busy = true;
      if (typeof this.clickHandler === 'function') {
        this.clickHandler(this.methodParam).finally(() => {
          this.busy = false;
        })
      } else {
        this.busy = false;
        console.warn('Click handler is wrong or empty')
      }
    }
  }
}
</script>

<style scoped>

</style>