<template>
  <b-modal id="chamber" ok-title="Save" cancel-title="Reset" @ok="onSubmit($event)" @cancel="onReset($event)"
           @close="close($event)"
           body-class="object_type_modal_body"
           title="Chamber">
    <div>
      <b-form>
        <b-form-group id="input-group-1" label="Object name:" label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="object.name"
              placeholder="Enter object name"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-7" label="Color" label-for="input-7">
          <v-swatches v-model="object.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
      </b-form>
    </div>

  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";


export default {
  components: {VSwatches},
  name: "Chamber",
  //OT_CHAMBER
  data() {
    return {
      object: {
        name: '',
        color: null,
        code: ObjectTypesEnum.OT_CHAMBER.toLowerCase()
      },
    }
  },
  methods: {
    close(event) {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)

    },
    onSubmit(event) {
      const self = this
      event.preventDefault()
      this.$store.dispatch('ObjectTypes/store', this.object).then(() => {
        self.$bvModal.hide("chamber");
      })
    },
    onReset(event, prevent = true) {
      if (prevent) {
        event.preventDefault()
      }
      // Reset our object values
      this.object.name = ''
      this.object.color = null
    }
  },
  created() {
    const self = this
    this.$root.$on('OT_CHAMBER', function () {
      self.$bvModal.hide("create_object_type_modal");
      self.$bvModal.show("chamber");
    })
  }
}
</script>

<style scoped>
</style>