<template>
  <div :style="titleIndent" class="cableTitle" @mouseover="hovered=true" @mouseleave="hovered=false">
    <div class="d-inline" @click="opened=!opened">
      <svg width="24" height="24">
        <path
            style="fill-rule: evenodd; stroke: rgb(0, 0, 0); stroke-width: 0; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
            d="m6.1153753,9.071917382812444 c-1.3034685000000001,0 -2.3528109,1.1759 -2.3528109,2.6365 l0,0.21880000000000002 c0,1.4607 1.0493424,2.6365 2.3528109,2.6365 l10.6198167,0 c1.303468,0 2.352811,-1.1758 2.352811,-2.6365 l0,-0.21880000000000002 c0,-1.4606 -1.049343,-2.6365 -2.352811,-2.6365 l-10.6198167,0 zm10.8128197,0.6179000000000001 a1.5240276000000001,2.0647589 0 0 1 1.5233539999999999,2.0649 a1.5240276000000001,2.0647589 0 0 1 -1.5233539999999999,2.065 a1.5240276000000001,2.0647589 0 0 1 -1.525651,-2.065 a1.5240276000000001,2.0647589 0 0 1 1.525651,-2.0649 z"
            id="rect3334" inkscape:connector-curvature="0" fill-opacity="1" :fill="cable.cable_type.color"/>
      </svg>
    </div>
    <span
        @click="opened=!opened">  C-{{ cable.sequence_id }} <strong>({{
        cable.cable_type.type_name
      }})</strong> </span>
    <span @click="removeCable(cable)"><i class="fas fa-times"></i></span>
    <div class="ml-2">
      <div class="d-flex" v-for="(tubes) in groupByTubeNumber">
        <span>
          <TubesInCable :tube="tubes" :depth="depth"/>
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CommonTools from "@/Helpers/CommonTools";
import TubesInCable from "@/components/modals/Duct/Info/TubesInCable.vue";

export default {
  name: "CableInTree",
  components: {
    DuctTree: () => {
      return import('./DuctTree')
    },
    TubesInCable

  },
  props: ['cable', 'depth',],
  data: function () {
    return {
      hovered: false,
      opened: false,
    }
  },
  computed: {
    iconClasses() {
      return {
        'fas fa-caret-right': !this.opened,
        'fas fa-caret-down': this.opened,
        '': !this.cable.ducts_in_cabbles
      }
    },
    groupByTubeNumber() {
      return this.cable.fibres.reduce((acc, item) => {
        const tubeNumber = item.tube_number;
        if (!acc[tubeNumber]) {
          acc[tubeNumber] = [];
        }
        acc[tubeNumber].push(item);
        return acc;
      }, {});
    }
  },

  methods: {

    titleIndent() {
      return {transform: `translate(${this.depth * 10}px)`}
    },
    removeCable(cable) {
      if (cable.hasConnection) {
        CommonTools.deleteConfirmation('Are you sure you wish to delete Cable?', 'This cable has connection.').then(() => {
          axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/' + cable.id).then(response => {
            this.$root.$emit('updateDuctInfo')
          }).catch(error => {
            console.log(error);
          });
        });
      } else {
        axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/' + cable.id).then(response => {
          this.$root.$emit('updateDuctInfo')
        }).catch(error => {
          console.log(error);
        });
      }

    }
  }
}
</script>

<style scoped>

.add_duct_button {
  padding: 1px;
  margin-left: 10px;
  font-size: 8px;
}

.cableTitle {
  font-size: 11px;
  cursor: pointer;
}
</style>