<template>
  <b-modal title="Pole" ok-title="Save" cancel-title="Reset" @ok="onSubmit($event)" @cancel="onReset($event)" id="pole"
           @close="close()" body-class="object_type_modal_body">
    <div>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group id="input-group-1" label="Object name:" label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="object.name"
              placeholder="Enter object name"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-7" label="Color" label-for="input-7">
          <v-swatches v-model="object.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
      </b-form>
    </div>

  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  components: {VSwatches},
  name: "Pole",
  //OT_PATCH_PANEL
  data() {
    return {
      object: {
        name: '',
        color: null,
        code: ObjectTypesEnum.OT_POLE.toLowerCase()
      },
    }
  },
  methods: {
    close() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)
    },
    onSubmit(event) {
      const self = this
      event.preventDefault()
      this.$store.dispatch('ObjectTypes/store', this.object).then(() => {
        self.$bvModal.hide("pole");
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our object values
      this.object.name = ''
      this.object.color = ''
      // Trick to reset/clear native browser form validation state
    }
  },
  created() {
    const self = this
    this.$root.$on('OT_POLE', function (event) {
      self.$bvModal.hide("create_object_type_modal");
      self.$bvModal.show("pole");
    })
  }
}
</script>

<style scoped>
.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}
</style>