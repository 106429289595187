let Default = {
    debug: true,
    zoom: 7,
    minZoom: 1,
    maxZoom: 20,
    propertyViewZoom: 17,
    ductsMinZoomLevel: 15,
    center: {lat: 53.4291738804146, lng: -1.3732910156250002},
    topLayer: 'vectormap',
    postCodePattern: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/,
    uprnPattern: /^\d{1,20}$/,
    cityZoom: 13,
    what3wordRegex: /^\/{0,}[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[・.。][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[・.。][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}$/,
    pixelsPerCluster: 20,
    api_key: 'AIzaSyCCfmhy6unjRrHcBStGrA9l9eOv7-djwns',
    ToolBar: {
        position: 'topright',
        drawCircle: false,
        drawMarker: false,
        drawCircleMarker: false,
        drawRectangle: false,
        tooltips: true,
        cutPolygon: false,
        dragMode: true,
        rotateMode: false
    }
}
export default Default;