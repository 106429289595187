import * as L from "leaflet";

L.Control.PrintVector = L.Control.extend({
    layers: [],
    options: {
        position: 'topleft',
        homeTitle: 'Export Vector',
    },
    onAdd: function (map) {

        let options = this.options;
        const controlName = 'leaflet-control-print_vector';
        const self = this;
        let parent = L.DomUtil.create('div');

        map.on('layeradd layerremove zoom activeMapChanged', function () {
            let hasVectorLayer = false
            self.layers = [];
            map.eachLayer(function (l) {
                if (l.options.layers !== undefined && l.options.layers.length > 0) {
                    self.layers.push(l.options.layers)
                }
            });
            if (self.caller.$store.getters["Maps/getActiveMap"] !== undefined) {
                const {wmsLayerName, wmsUrl} = self.caller.$store.getters["Maps/getActiveMap"];
                if (wmsUrl !== null && wmsUrl.length > 0) {
                    self.layers.push(wmsLayerName)
                    hasVectorLayer = true;
                }
            }
            const zoom = Lmap.getZoom();
            if (zoom < 15) {
                if (self.container !== undefined && self.container.length !== 0) {
                    self.container.remove()
                    self.container = undefined;
                }
                return parent;
            }
            if (hasVectorLayer) {
                if (!self.container) {
                    self.container = L.DomUtil.create('div', controlName + ' leaflet-bar', parent);
                    self._homeButton = self._createButton(options.homeTitle, controlName + '-print_vector', self.container, self._export);
                }
            } else {
                if (self.container !== undefined && self.container.length !== 0) {
                    self.container.remove()
                    self.container = undefined;
                }
            }
        });
        return parent;

    },
    onRemove: function () {
        if (this._homeButton) {
            this._homeButton.remove();
        }
    },
    _export: function () {
        this.caller.$root.$emit('showLoader', 'Preparing map for export');
        this._handleTileLayer()
    },
    _createButton: function (title, className, container, fn) {
        let link = L.DomUtil.create('a', className, container);
        link.href = '#';
        link.title = title;
        link.className = 'fas fa-file-pdf'
        L.DomEvent
            .on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this)
            .on(link, 'click', this._refocusOnMap, this);

        return link;
    },
    _handleTileLayer: function () {
        const self = this;

        if (self.layers.length === 0) {
            self.caller.$root.$emit('hideLoader');
            return;
        }
        const scale = Lmap.getZoomScale(19, Lmap.getZoom());
        const bounds = Lmap.getBounds();
        const bottom = L.Projection.SphericalMercator.project(bounds._northEast);
        const top = L.Projection.SphericalMercator.project(bounds._southWest);
        const mapSize = Lmap.getSize();
        let width = mapSize.x * scale;
        let height = mapSize.y * scale;
        let proportion = width / height;
        if (width >= 19200) {
            width = 19000;
            height = Math.floor(width / proportion);
        }
        const generateRandomFilename = () => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let filename = '';

            for (let i = 0; i < 32; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                filename += characters.charAt(randomIndex);
            }

            return filename;
        };
        axios.post(process.env.VUE_APP_API_URL + '/api/export/vector', {
            width,
            height,
            bbox: top.x + '%2C' + top.y + '%2C' + bottom.x + '%2C' + bottom.y,
            layers: self.layers
        }, {responseType: 'blob'}).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = generateRandomFilename() + '.pdf';
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        }).finally(() => {
            this.caller.$root.$emit('hideLoader');
        })

    },


})
L.Control.printVector = function (options) {
    const obj = new L.Control.PrintVector(options);
    obj.caller = options.caller;
    return obj
};