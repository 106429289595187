<template>
<div>
  <span v-if="this.tube.connected_tube!==0" ><span @click="showTubesInfo(tube)">T-{{tube.id}}</span> -> <span class="connected-duct">T-{{this.tube.connected_tube}}</span></span>
  <span v-else @click="showTubesInfo(tube)">T-{{tube.id}}</span>
  <b-button v-show="connectionAvailable" @click="createConnection(tube)" size="sm" class="btn-xs">
    <i class="fas fa-plug fa-rotate-90"/></b-button>
  <b-button v-show="!connectionAvailable && this.tube.connected_tube===0"  size="sm" class="btn-xs">
    <i class="fas fa-link"/></b-button>

  <DuctsInfoShort v-if="this.tubeInfo !== null && this.tubeInfo.ducts!==null && this.tubeInfo.ducts.length>0"
                  :ducts="this.tubeInfo.ducts"/>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TubesListItem",
  props:['tube'],
  computed:{
    ...mapGetters({
      tubeA:'NetworkConnection/tubeAConnection',
      tubeB:'NetworkConnection/tubeBConnection',
    }),
    connectionAvailable:{
      get(){
        return this.showConnectionButton  && this.tube.connected_tube===0
      }
    }
  },
  data: () => {
    return {
      showConnectionButton: true,
      tubeInfo:null,
    }
  },
  methods:{
    showTubesInfo(tube) {
      this.tubeInfo = tube;
    },
    createConnection(tube) {

      this.showConnectionButton = false;
      if(this.tubeA==null)
      {
        this.$store.commit('NetworkConnection/setATubeConnection',tube.id);
      }
      else{
        this.$store.commit('NetworkConnection/setATubeConnection',tube.id);
        this.$swal('Connection',"Connections succeed");
        return;
      }
      this.$swal("Connection", "Choose second tube to create connection")

    }
  }

}
</script>

<style scoped>

.btn-xs {
  font-size: 10px;
  margin-left: 5px;
  padding: 1px 2px;
}
.connected-duct{
  text-decoration: underline;
  text-decoration-color: #4d4db3;
  cursor: pointer;
}
</style>