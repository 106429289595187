<template>
  <b-modal title="New Project" ref="newProjectModal" ok-only ok-title="Save" @ok="addNewProject" ok-variant="primary"
           id="new_project">
    <b-overlay :show="showOverlay" rounded="sm">

      <b-form-group label="Project Name" label-for="project_name">
        <b-input v-model="project_name" type="text" required/>
      </b-form-group>
      <b-form-group>
        <b-checkbox v-model="is_private_project">This is private project</b-checkbox>
      </b-form-group>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  name: "NewProject",
  data() {
    return {
      showOverlay: false,
      project_name: null,
      is_private_project: false
    }
  },
  methods: {
    addNewProject(e) {
      e.preventDefault();
      this.showOverlay = true;
      const data = {name: this.project_name, is_shared: !this.is_private_project};
      this.$store.dispatch('Projects/addNewProject', data).then(() => {
        this.showOverlay = false;
        this.$store.dispatch('Projects/getProjects');
        this.$bvModal.hide('new_project');
      }).catch(() => {
        this.showOverlay = false;
      });
    }
  }
}
</script>

<style scoped>

</style>