<template>
  <div>
    <b-modal v-if="ductInfo.length>0" id="ductInfoModal" centered size="lg" :title="this.title">
      <b-overlay :show="isLoading">
        <div class="treeBody">
          <DuctTree :initialduct="ductInfoSmall" :depth="1" :duct="ductInfo"></DuctTree>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button variant="success" @click="listFibreCables">Add Passthrough Fibre Cable</b-button>
        <b-button class="float-right"
                  @click="cancel()">Close
        </b-button>
      </template>
    </b-modal>
    <sub-duct-stand-alone/>
    <FibreCableModal/>
    <b-modal v-if="ductInfo.length>0" id="passThroughCableModal" title="Passthrough cable"
             @hide="clearPassThroughCableModal" ok-title="Save" @ok="savePassTroughCable"
             :ok-disabled="notAllTubesSelected || passThroughCable.ducts.length <= 1">
      <div>
        <div class="form-group d-flex jus" v-for="duct in passThroughCable.ducts">
          <div class="col-2 pt-1">{{ duct.generated_identifier }}</div>
          <select class="custom-select custom-select-sm" v-model="duct.selected_tube">
            <option value="0" disabled selected hidden>Choose a Tube</option>

            <option v-for="tube in duct.tubes" :value="tube.id">{{ tube.tube_number }}</option>
          </select>
        </div>
        <button class="btn btn-success" @click="undo" :disabled="passThroughCable.ducts.length<=1"><i
            class="fas fa-undo"/></button>
        <button class="btn btn-success ml-2" @click="getNextDuct" :disabled="notAllTubesSelected"><i
            class="fas fa-plus"/>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DuctTree from "@/components/modals/Duct/Info/DuctTree";
import FibreCableModal from "@/components/modals/Duct/FibreCableModal";


export default {
  name: "DuctInfo",
  components: {DuctTree, FibreCableModal},
  data: function () {
    return {
      isLoading: false,
      passThroughCable: {
        ducts: [],
        coordinates: [],
        cableTypeId: null,
      }

    }
  },
  methods: {
    ...mapActions({
      cableTypes: 'DuctTypes/getCables',
      getDuctInfo: 'Duct/getDuctInfo',
    }),
    listFibreCables() {
      let self = this;
      this.cableTypes().then(data => {
        self.$selectSingleCableType({
          title: 'Select Cable Type',
          cableTypes: data.data.data,
          meth: (cableType) => {
            this.setCableTypeForPassthroughDuct(cableType)
            this.passThroughCable.cableTypeId = cableType;
          }
        })
      });
    },
    setCableTypeForPassthroughDuct() {
      let feature = JSON.parse(this.ductInfo.feature)
      const first = feature.geometry.coordinates[0];
      const last = feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
      let selectedTube = 0;
      if (this.ductInfo.tubes.length === 1) {
        selectedTube = this.ductInfo.tubes[0].id;
      }
      this.passThroughCable.ducts.push({
        duct_id: this.ductInfo.id,
        generated_identifier: 'D-' + this.ductInfo.sequence_id,
        tubes: this.ductInfo.tubes,
        selected_tube: selectedTube,
        coordinates: {
          first: first,
          last: last,
        }
      });
      this.passThroughCable.coordinates.push(first);
      this.passThroughCable.coordinates.push(last);
      this.$bvModal.show('passThroughCableModal');
    },
    undo() {
      // remove from this.passThroughCable.ducts
      if (this.passThroughCable.ducts.length > 1) {
        const duct = this.passThroughCable.ducts.pop();
        const currentFirst = this.passThroughCable.coordinates[0];
        const lastIndex = this.passThroughCable.coordinates.length - 1;
        const currentLast = this.passThroughCable.coordinates[lastIndex];
        if (this.pointEquals(currentFirst, duct.coordinates.first)) {
          this.passThroughCable.coordinates.shift();
        }
        if (this.pointEquals(currentFirst, duct.coordinates.last)) {
          this.passThroughCable.coordinates.shift();
        }
        if (this.pointEquals(currentLast, duct.coordinates.first)) {
          this.passThroughCable.coordinates.pop();
        }
        if (this.pointEquals(currentLast, duct.coordinates.last)) {
          this.passThroughCable.coordinates.pop();
        }
      }
      // remove from this.passThroughCable.coordinates
    },
    getNextDuct() {
      let points = [];

      points = [this.passThroughCable.coordinates[0], this.passThroughCable.coordinates[this.passThroughCable.coordinates.length - 1]];
      const self = this;
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_chamber/search/ducts', {
        points: points,
        excludedDucts: this.passThroughCable.ducts.map(item => item.duct_id)
      }).then(result => {
        if (result.data.data.length === 0) {
          this.$swal('Info', 'There is no ducts available', 'info');
          return;
        }
        this.$selectFromList({
          'title': 'Select Duct',
          'list': result.data.data.map(item => {
            return {
              key: item.id,
              value: item.generated_identifier,
            }
          }),
          meth: function (selected) {
            console.log(selected);
            const s = result.data.data.filter(item => item.id === selected)[0]
            console.log(s);
            s.selected_tube = 0
            if (s.tubes.length === 1) {
              s.selected_tube = s.tubes[0].id;
            }
            self.passThroughCable.ducts.push({
              duct_id: s.id,
              generated_identifier: s.generated_identifier,
              tubes: s.tubes,
              selected_tube: s.selected_tube,
              coordinates: {
                first: s.coordinates.first,
                last: s.coordinates.last,
              }
            });
            const currentFirst = self.passThroughCable.coordinates[0];
            const lastIndex = self.passThroughCable.coordinates.length - 1;
            const currentLast = self.passThroughCable.coordinates[lastIndex];
            if (self.pointEquals(currentFirst, s.coordinates.first)) {
              self.passThroughCable.coordinates.unshift(s.coordinates.last);
            }
            if (self.pointEquals(currentFirst, s.coordinates.last)) {
              self.passThroughCable.coordinates.unshift(s.coordinates.first);
            }
            if (self.pointEquals(currentLast, s.coordinates.first)) {
              self.passThroughCable.coordinates.push(s.coordinates.last);
            }
            if (self.pointEquals(currentLast, s.coordinates.last)) {
              self.passThroughCable.coordinates.push(s.coordinates.first);
            }
            // console.log(self.passThroughCable.coordinates[0], s.coordinates.first);
            // console.log(self.passThroughCable.coordinates[0], s.coordinates.last);
            // console.log(self.passThroughCable.coordinates[lastIndex], s.coordinates.first)
            // console.log(self.passThroughCable.coordinates[lastIndex], s.coordinates.last)
          }
        });
      });


    },
    pointEquals(pointOne, pointTwo) {
      return pointOne[0] === pointTwo[0] && pointOne[1] === pointTwo[1];
    },
    savePassTroughCable() {
      const data = {
        ducts: this.passThroughCable.ducts.map(item => {
          return {id: item.duct_id, tube: item.selected_tube}
        }),
        cableTypeId: this.passThroughCable.cableTypeId
      }
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/duct/passthrought', data).then(() => {
        this.$swal('Done!', 'Cable Saved', 'success');
        this.$bvModal.hide('passThroughCableModal')
        this.getDuctInfo(this.ductInfo.id);
      })
    },
    clearPassThroughCableModal() {
      this.passThroughCable = {
        ducts: [],
        coordinates: [],
        cableTypeId: null,
      };
    }
  },
  computed: {
    ...mapGetters({
      ductInfo: 'Duct/ductInfo',
    }),
    title: {
      get() {
        return 'D-' + this.ductInfo.sequence_id + ' (' + this.ductInfo.type.name + ') - ' + Math.round(this.ductInfo.length) + 'm'
      }
    },
    ductInfoSmall: {
      get() {
        return {
          parent_duct_id: this.ductInfo.id,
          length: this.ductInfo.length,
          geometry: btoa(this.ductInfo.feature),
          project_id: this.ductInfo.project_id,
          duct_type_id: this.ductInfo.duct_type_id
        }
      }
    },
    notAllTubesSelected() {
      return this.passThroughCable.ducts.filter(item => item.selected_tube === 0).length > 0;
    }
  },
  created() {
    const self = this
    this.$root.$on('updateDuctInfo', function () {
      self.isLoading = true;
      this.$store.dispatch('Duct/getDuctInfo', self.ductInfo.id).finally(() => {
        self.isLoading = false;
      })
    })
  }
}
</script>

<style scoped>
.treeBody {
  max-height: 400px;
  min-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>