class StaticPopup {
    constructor(location, data, showGeoJson, VueStore, content = null) {
        if (!location) {
            throw new Error('Location is not valid');
        }
        this.location = location;
        this.data = data;
        if (!(data.features) || data.features.length === 0) {
            throw new Error('Features are empty')
        }
        this.vueStore = VueStore;
        this.showGeoJson = showGeoJson;
        this.content = content;
    }

    open() {
        this.vueStore.commit('StaticPopupStore/setContent', this.getContent());
        this.vueStore.commit('StaticPopupStore/setCloseEventHandler', this.close)
        this.vueStore.commit('StaticPopupStore/setClickEventHandler', this.click)
        this.vueStore.commit('StaticPopupStore/setShowGeoJson', true)
        this.vueStore.commit('StaticPopupStore/setGeojson', this.data)
        this.vueStore.commit('StaticPopupStore/setLatLng', this.location);
    }

    getContent() {
        return this.content;
    }

    close() {
        // Empty method
    }

    click(store, data) {
        // Empty method
    }
}

export default StaticPopup;