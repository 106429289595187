<template>
<div>
  <b-modal id="imageUploadModal"  title="Site Images" @ok="uploadImage" ok-title="Upload" @hidden="clearForm">

  <b-overlay :show="showLoader">

  <b-form-group>
    <label for="image">Image:</label>
    <b-form-file
        id="image"
        v-model="form.image"
        trim
    ></b-form-file>
  </b-form-group>
    <b-form-group>
      <label for="image_name">Title:</label>
      <b-form-input
          id="image_name"
          v-model="form.image_name"
          trim
      ></b-form-input>
    </b-form-group>
    </b-overlay>
  </b-modal>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ImageUpload",
  data:()=>{
    return{
    form:{
      image:null,
      image_name:null,
    },

    }
  },
  methods:{
    ...mapActions({
      saveImage:'Sites/uploadImage'
    }),
    uploadImage(e){
      const self = this;
      e.preventDefault();
      const form = new FormData();
      form.append('image', this.form.image);
      form.append('name', this.form.image_name);
      this.saveImage(form).then(()=>{
        this.$store.dispatch('Sites/getImages');
        this.$bvModal.hide('imageUploadModal');
      });
    },
    clearForm(){
      this.form.image = null;
      this.form.image_name = null;
    }
   
  },
  computed:{
    ...mapGetters({
      showLoader:'Sites/isLoading',
      siteId: 'Sites/siteIdForInfo',

    })
  }
}
</script>

<style scoped>

</style>