<script>

import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "Reports",
  data() {
    return {
      advancedSearchState: false,
      mobileProviders: {
        last_updated: null,
        finished: null,
      }
    }
  },
  methods: {
    getMobileProviderStatus() {
      axios.get(process.env.VUE_APP_API_URL + '/api/admin/mobile_providers/status').then(response => {
        this.mobileProviders.finished = response.data.finished;
        this.mobileProviders.last_updated = response.data.last_updated;
      })
    },
  },
  computed: {
    summaryUrl() {
      if (this.mobileProviders.finished === false) {
        return '#';
      }
      return process.env.VUE_APP_API_URL + '/api/admin/reports/mobile_providers/coverage/summary';
    },
    noCoverageQgis() {
      if (this.mobileProviders.finished === false) {
        return '#';
      }
      return process.env.VUE_APP_API_URL + '/exports/nocoverage/qgis?token=' + this.token;
    },
    noCoverageKml() {
      if (this.mobileProviders.finished === false) {
        return '#';
      }
      return process.env.VUE_APP_API_URL + '/exports/nocoverage/kml?token=' + this.token;
    },
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      token: 'Auth/token'
    }),
  }
}
</script>

<template>
  <b-modal id="mobile-coverage-report" ref="mobile-coverage-report" title="Mobile Coverage Reports" size="sm"
           ok-title="Close" ok-only @show="getMobileProviderStatus()">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column justify-content-center mb-3">
        <b-badge v-if="!mobileProviders.finished" variant="warning" class="text-center my-auto mx-auto">
          Calculation in progress
        </b-badge>
        <b-badge class="text-center my-auto mx-auto" v-if="mobileProviders.finished"
                 variant="success">Updated: {{ mobileProviders.last_updated }}
        </b-badge>
      </div>
      <b-button variant="info" v-if="isAdmin" :href="summaryUrl" :disabled="!mobileProviders.finished">
        Summary Report
      </b-button>
      <b-button variant="primary" v-if="isAdmin" class="mt-2" :href="noCoverageQgis"
                :disabled="!mobileProviders.finished">No Coverage QGIS
      </b-button>
      <b-button variant="primary" v-if="isAdmin" class="mt-2" :href="noCoverageKml"
                :disabled="!mobileProviders.finished">No Coverage KML
      </b-button>

    </div>
  </b-modal>
</template>

<style scoped>

</style>