<template>
  <div>
    <b-modal size="lg" id="siteInfo" ignore-enforce-focus-selector=".swal2-input" hide-header @show="loadSiteInfo"
             :title="this.siteName" @hide="hide">

      <b-tabs content-class="mt-3">
        <b-tab title="Site Info" active>
          <b-overlay :show="showLoader">
            <div v-if="siteInfo!==null">
              <b-form-group>
                <label for="site_name">Site name:</label>
                <b-form-input
                    id="site_name"
                    v-model="siteInfo.name"
                    trim
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="address_line_1">Address Line 1:</label>
                <b-form-input
                    id="address_line_1"
                    v-model="siteInfo.address_line_1"
                    trim
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="address_line_1">Address Line 2:</label>
                <b-form-input
                    id="address_line_1"
                    v-model="siteInfo.address_line_2"
                    trim
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="town_city">Town City:</label>
                <b-form-input
                    id="town_city"
                    v-model="siteInfo.town_city"
                    trim
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="county">County:</label>
                <b-form-input
                    id="county"
                    v-model="siteInfo.county"
                    trim
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label for="postcode">Postcode:</label>
                <b-form-input
                    id="town_city"
                    v-model="siteInfo.postcode"
                    trim
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Payment</label>
                <b-input-group prepend="£" class="mb-2">
                  <b-form-input aria-label="Amount" v-model="siteInfo.payment_amount" type="number" step='0.01'
                                value='0.00' placeholder='0.00'></b-form-input>
                  <b-form-select v-model="siteInfo.payment_frequency" aria-label="Frequency">
                    <b-form-select-option value="Monthly">Monthly</b-form-select-option>
                    <b-form-select-option value="Quarterly">Quarterly</b-form-select-option>
                    <b-form-select-option value="Biannually">Biannually</b-form-select-option>
                    <b-form-select-option value="Annually">Annually</b-form-select-option>
                  </b-form-select>
                  <b-form-datepicker date-format="dd/MM/yyyy"
                                     :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                     v-model="siteInfo.payment_paid_until" placeholder="Paid Until"
                                     aria-label="Paid Until"></b-form-datepicker>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <label for="note">Notes: </label>
                <b-form-textarea
                    id="note"
                    v-model="siteInfo.notes"
                    trim
                ></b-form-textarea>
              </b-form-group>
            </div>
          </b-overlay>
        </b-tab>
        <b-tab title="Location" @click="loadLocationInfoRaw">
          <b-overlay :show="showLoader">
            <div v-if="this.locationInfo">
              <div class="d-flex p-5">
                <div>
                  Latitude: {{ this.locationInfo.latitude }} <br/>
                  Longitude: {{ this.locationInfo.longitude }} <br/>
                  <br/>
                  X (Easting): {{ this.locationInfo.easting }} <br/>
                  Y (Northing): {{ this.locationInfo.northing }} <br/>
                  <br/>
                  Grid Reference: {{ this.locationInfo.reference_letter }} {{ this.locationInfo.x_coordinate }}
                  {{ this.locationInfo.x_coordinate }}
                  <br/>
                  <br/>
                  What3Words :
                  {{
                    this.$WhatTreeWords.latlon2words(parseFloat(this.locationInfo.latitude), parseFloat(this.locationInfo.longitude))
                  }}
                  <div class="d-flex align-content-around mt-2">
                    <b-button variant="info" class="" :href="appleMapsUrl(this.locationInfo)">Apple Map</b-button>
                    <b-button variant="info" class="ml-2" :href="googleMapsUrl(this.locationInfo)">Google Map</b-button>
                  </div>
                </div>
                <div class="ml-5">
                  <img id="qrcode" :src="qrcodeUrl"/>
                </div>
              </div>
            </div>
          </b-overlay>
        </b-tab>
        <b-tab title="Contacts" @click="loadContacts">
          <b-overlay :show="showLoader">
            <div class="d-flex flex-column">
              <table v-if="contacts.length>0" class="table table-bordered">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>E-Mail</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="contact in contacts" :key="contact.id">
                  <td>{{ contact.name }}</td>
                  <td>{{ contact.phone }}</td>
                  <td>{{ contact.email }}</td>
                  <td>
                    <i class="fas fa-pen text-info cursor-pointer" @click="editContact(contact)"></i>
                    <i class="cursor-pointer fas fa-trash text-danger ml-2" @click="deleteContact(contact.id)"></i></td>
                </tr>
                </tbody>
              </table>
              <button class="btn btn-info" v-b-modal.addSiteContact>Add Contact</button>
            </div>
          </b-overlay>
        </b-tab>
        <b-tab title="Photos" @click="loadImages">
          <b-overlay :show="showLoader">
            <div class="d-flex flex-column">
              <div class="col-12 row">
                <div class="col-3 p-2 mt-3" v-for="photo in this.images">
                  <p class="photo-icons">
                    <i @click="editPhoto(photo)" class="text-info fas fa-pen cursor-pointer ml-1"/>
                    <i @click="deletePhoto(photo)" class="text-danger fas fa-trash cursor-pointer ml-1"/>
                    <i @click="downloadPhoto(photo)" class="text-success fas fa-save cursor-pointer ml-1"/>
                  </p>
                  <expandable-image class="img-fluid img-thumbnail img-responsive" :src="getWebPath(photo.image)"/>
                  <p>{{ photo.name }}</p>
                </div>
              </div>
              <button class="btn btn-info" v-b-modal.imageUploadModal>Add New Image</button>
            </div>
          </b-overlay>
        </b-tab>
        <b-tab title="Legal Instruments" @click="loadLegalInstruments">
          <b-overlay :show="showLoader">
            <div class="d-flex flex-column">
              <b-table striped hover responsive sticky-header="400px" v-if="legalInstruments.length>0" :items="legalInstruments" :fields="legalInstrumentFields" class="h-50">
                <template v-slot:cell(action)="data"> <!-- `data` -->
                <i @click="openDocument(data.item)" class="fas fa-save cursor-pointer text-success"></i>
                <i @click="editDocument(data.item)" class="fas fa-pen cursor-pointer text-info ml-1"></i>
                <i @click="deleteDocument(data.item)" class="fas fa-trash text-danger cursor-pointer ml-1"></i>
              </template>
              </b-table>
              <button class="btn btn-info" v-b-modal.siteLegalInstrumentUpload>Add New Legal Instrument</button>
            </div>
          </b-overlay>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <div class="d-flex justify-content-end">

          <div>
            <b-button variant="info" v-if="siteInfo!=null" @click="copyLink" class="mr-2">Copy Link</b-button>
            <b-button variant="danger" v-if="page==='siteinfo'" @click="remove" class="mr-2">Delete</b-button>
            <b-button variant="primary" v-if="page==='siteinfo'" @click="updateSite" class="mr-2">Update</b-button>
            <b-button variant="secondary" @click="$bvModal.hide('siteInfo')">Cancel</b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <AddContact/>
    <EditSiteContact/>
    <ImageUpload/>
    <LegalInstrumentUpload/>
    <EditLegalInstrument/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddContact from "@/components/modals/Site/AddContact";
import EditSiteContact from "@/components/modals/Site/EditSiteContact";
import ImageUpload from "@/components/modals/Site/ImageUpload";
import LegalInstrumentUpload from "@/components/modals/Site/LegalInstrumentUpload";
import EditLegalInstrument from "@/components/modals/Site/EditLegalInstrument";
import CommonTools from "@/Helpers/CommonTools";
import {LatLng} from "leaflet";

export default {
  name: "SiteInfoModal",
  components: {EditLegalInstrument, LegalInstrumentUpload, ImageUpload, EditSiteContact, AddContact},
  data: () => {
    return {
      siteInfo: null,
      siteName: '',
      locationInfo: null,
      page: 'siteinfo',
      legalInstrumentFields:[
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'expires_at',
          label: 'Expires At',
          sortable: false
        },
        {
          key: 'action',
          label: '',
          sortable: false
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      siteId: 'Sites/siteIdForInfo',
      contacts: 'Sites/contacts',
      showLoader: 'Sites/isLoading',
      images: 'Sites/images',
      legalInstruments: 'Sites/legalInstruments',
      legalInstrument: 'Sites/legalInstrument',

    }),
    qrcodeUrl() {
      return "https://chart.googleapis.com/chart?cht=qr&chs=150x150&chld=L&choe=UTF-8&chl=geo:" + this.locationInfo.latitude + ',' + this.locationInfo.longitude;
    },
  },
  methods: {
    ...mapActions({
      loadImages: 'Sites/getImages',
    }),
    loadSiteInfo() {
      this.page = 'siteinfo';
      this.$store.dispatch('Sites/getSiteInfo', this.siteId).then(response => {
        this.siteInfo = response.data.site;
        this.siteName = response.data.site.name;
      })
    },
    loadContacts() {
      this.page = 'contacts';
      this.$store.dispatch('Sites/getContacts', this.siteId).finally(() => {
      })
    },
    loadLegalInstruments() {
      this.page = 'instruments';
      this.$store.dispatch('Sites/getLegalInstruments');
    },
    remove() {
      const self = this;
      CommonTools.deleteConfirmation().then(() => {
        axios.delete(process.env.VUE_APP_API_URL + '/api/sites/' + self.siteInfo.id).then(() => {
          self.$bvModal.hide('siteInfo');
          self.$store.dispatch('Sites/getSites');
        })
      })
    },
    updateSite() {
      axios.put(process.env.VUE_APP_API_URL + '/api/sites/' + this.siteInfo.id, this.siteInfo).then(() => {
        this.$swal('Success', 'Site updated', 'success');
      });
    },
    deleteContact(contactId) {
      CommonTools.deleteConfirmation().then(() => {
        this.$store.dispatch('Sites/deleteContact', {siteId: this.siteInfo.id, contactId});
      });
    },
    editContact(contact) {
      this.$store.commit('Sites/setContact', contact);
      this.$bvModal.show('editSiteContact')
    },
    hide() {
      this.siteInfo = null;
      this.siteName = '';
    },
    getWebPath(image) {
      return process.env.VUE_APP_LOCATION_IMAGES_URL + image;
    },
    editPhoto(photo) {
      const self = this;
      let inputValue = '';
      this.$swal({
        title: 'Photo Name',
        input: 'text',
        inputValue: photo.name,
        showCancelButton: true,

      }).then(result => {
        if (result.value !== photo.name && result.value.length > 0) {
          this.$store.dispatch('Sites/updateImage', {id: photo.id, name: result.value})
        }
      })
    },
    deletePhoto(photo) {
      CommonTools.deleteConfirmation().then(() => {
        this.$store.dispatch('Sites/deleteImage', photo.id);
      });

    },
    downloadPhoto(photo) {
      window.open(this.getWebPath(photo.image), '_blank')
    },
    copyLink() {

      const url = process.env.VUE_APP_BASE_URL + '/site/' + this.siteInfo.id;
      const clipboardData =
          event.clipboardData ||
          window.clipboardData ||
          navigator.clipboard;

      clipboardData.writeText(url);
    },
    openDocument(legalDocument) {
      window.open(this.getWebPath(legalDocument.document), '_blank')
    },
    editDocument(legalDocument) {
      this.$store.commit('Sites/setLegalInstrument', legalDocument);
      this.$bvModal.show('editLegalInstrument');
    },
    deleteDocument(legalDocument) {
      CommonTools.deleteConfirmation().then(() => {
        this.$store.dispatch('Sites/deleteLegalInstrument', legalDocument.id)
      })
    },
    loadLocationInfoRaw() {
      this.page = 'location';
      this.locationInfo = null;
      this.$store.commit('Sites/setIsLoading', true);
      this.$store.dispatch('Search/loadLocationInfoRaw', new LatLng(this.siteInfo.lat, this.siteInfo.lng)).then(response => {

        this.locationInfo = response.data.data;
        this.$store.commit('Sites/setIsLoading', false);
      });
    },
    googleMapsUrl(locationInfo) {
      return "comgooglemapsurl://?q=" + locationInfo.latitude + ',' + locationInfo.longitude + "&zoom=19&t=k&";
    },
    appleMapsUrl(locationInfo) {
      return "http://maps.apple.com/?ll=" + locationInfo.latitude + ',' + locationInfo.longitude + "&z=19&t=k&q=Here";
    },
    setPage(page) {
      this.page = page;
    }
  }
}
</script>

<style scoped>
.photo-name, .photo-icons {
  font-size: 11px;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.photo-icons {
  margin-top: 0px;
  right: 10px;
  position: absolute;
  z-index: 9;
}
</style>