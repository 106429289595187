<script>

import FormsUpdateProfile from "@/components/Modules/Account/forms/FormsUpdateProfile.vue";

export default {
  name: "AddNewMasterAccountModal",
  components: {FormsUpdateProfile},

  data() {
    return {
      loading: false,
      account: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        sub_account_limit: 1,
        password: null,
      }
    }
  },
  methods: {
    createMasterAccount(accountData) {
      this.loading = true;
      this.$UserManagementApiClient.addMasterAccount(accountData).then(response => {
        this.loading = false;
        this.$swal('Crated', 'New Master Account has been created successfully', 'success');
        this.$bvModal.hide('new-master-account')
        this.$root.$emit('admin-update-users-list');
      }).catch(() => {
        this.loading = false;
      });

    },
    reset() {
      this.account = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        sub_account_limit: 1,
        password: null,
      };
    }
  }
}
</script>

<template>
  <b-modal id="new-master-account" title="Add new master account" hide-footer @show="reset">
    <b-overlay :show="loading">
      <FormsUpdateProfile :account-data="account" :is-create="true" @ok="createMasterAccount"
                          :show-account-limit="true" :show-company="true" :show-allow-edit="false"></FormsUpdateProfile>
    </b-overlay>
  </b-modal>
</template>

<style scoped>

</style>