import vue from "vue";

const state = {
    connectionTubes:{
        a:null,
        b:null
    }
}
const mutations = {
    setATubeConnection(state,data){
        state.connectionTubes.a = data;
        // vue.set(state,'connectionTubes.a',data)
    },
    setBTubeConnection(state,data){
        vue.set(state,'connectionTubes.b',data)
    }
}
const actions = {}
const getters = {
    tubeAConnection:state=>{
        return state.connectionTubes.a
    },
    tubeBConnection:state=>{
        return state.connectionTubes.b
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}