import axios from "axios";

const state = {
    user: {},
};
const actions = {
    updateProfile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.put(process.env.VUE_APP_API_URL + '/api/user/profile', data)
                .then(resp => {
                    commit('setUser', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    updatePassword({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.put(process.env.VUE_APP_API_URL + '/api/user/password', data)
                .then(resp => {
                    // commit('setUser',resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};
const mutations = {
    setUser(state, data) {
        state.user = data;
    }
};
const getters = {
    getUser: state => {
        return state.user
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}