<template>
  <div :class="styles" @click="showTraceModal()">
    <span v-if="localCableId && localCableType" class="p-1  rounded"
          :style="'border:1px solid #cbc0c0; color: '+TextColor.getTextColor(localCableColor)+';background-color:' + localCableColor"><small>C-{{
        localCableId
      }} <strong>({{ localCableType }})</strong></small></span>
    <span v-if="localCableId && localCableType" class="p-1">-</span>
    <span class="p-1 rounded"
          :style="'border:1px solid #cbc0c0; color: '+TextColor.getTextColor(localTubeColor)+';background-color:' + localTubeColor"><small>Tube {{
        localTubeNumber
      }}</small></span>
    <span class="p-1">-</span>
    <span class="p-1 rounded"
          :style="'border:1px solid #cbc0c0; color: '+TextColor.getTextColor(localFibreColor)+';background-color:' + localFibreColor"><small>Fibre {{
        localFibreNumber
      }}</small></span>
  </div>
</template>

<script>
import TextColor from "@/Helpers/TextColor";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "FibreTubeBadge",
  computed: {
    localCableId() {
      if (this.fibre) return this.fibre.cable_with_type.sequence_id;
      if (this.cableId) return this.cableId;
      return null;
    },
    localCableType() {
      if (this.fibre) return this.fibre.cable_with_type.cable_type.type_name;
      if (this.cableType) return this.cableType;
      return null;
    },
    localCableColor() {
      if (this.fibre) return this.fibre.cable_with_type.cable_type.color;
      if (this.cableColor) return this.cableColor;
      return "#ffffff";
    },
    localTubeNumber() {
      if (this.fibre) return this.fibre.tube_number;
      if (this.tubeNumber) return this.tubeNumber;
      return null;
    },
    localTubeColor() {
      if (this.fibre) return this.fibre.tube_color;
      if (this.tubeColor) return this.tubeColor;
      return "#ffffff";
    },
    localFibreNumber() {
      if (this.fibre) return this.fibre.fibre_number;
      if (this.fibreNumber) return this.fibreNumber;
      return null;
    },
    localFibreColor() {
      if (this.fibre) return this.fibre.fibre_color;
      if (this.fibreColor) return this.fibreColor;
      return '#ffffff';
    },
    localFibreId() {
      if (this.fibre) return this.fibre.id;
      if (this.fibreId) return this.fibreId;
      return null;
    },

    TextColor() {
      return TextColor
    },
    shouldShowTrace() {
      return this.showTrace && this.localFibreId;
    },
    styles() {
      let s = 'd-flex';
      if (this.shouldShowTrace) {
        s += ' cursor-pointer';
      }
      return s;
    }
  },
  props: {
    cableId: null,
    cableColor: null,
    cableType: null,
    fibreId: null,
    fibreNumber: null,
    fibreColor: null,
    tubeNumber: null,
    tubeColor: null,
    showTrace: {
      type: Boolean,
      default: true
    },
    fibre: null,
    parentDeviceId: null
  },
  methods: {
    ...mapMutations({
      setFibreId: "Tracing/setFibreId"
    }),
    ...mapActions({
      traceWithDeviceAndFibre: "Tracing/traceWithDeviceAndFibre"
    }),
    showTraceModal() {
      if (!this.shouldShowTrace) {
        return;
      }
      this.traceWithDeviceAndFibre({
        device_id: this.parentDeviceId,
        fibre_id: this.localFibreId
      })

    }
  },
}
</script>

<style scoped>

</style>