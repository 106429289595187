<template>
  <div>
    <b-modal id="openreachOrdersList" size="xl" body-class="modal-body-custom" title="Openreach Orders">
      <b-overlay :show="showOverlay">
        <table class="table table-striped" v-if="orders.length>0">
          <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Order Date</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="orders.length === 0">
            <td colspan="8" class="text-center">No records</td>
          </tr>
          <tr v-for="order in orders">
            <td>{{ order.externalId }}</td>
            <td>{{ order.order_type }}</td>
            <td>{{ order.state }}</td>
            <td>{{ order.order_date }}</td>
            <td>
              <b-dropdown :id="'dropdown-orders-'+order.id" variant="link" size="sm" menu-class="dropdown-menu-custom"
                          toggle-class="text-decoration-none p-0"
                          no-caret>
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="">View</b-dropdown-item>
                <b-dropdown-item @click="">Cancel</b-dropdown-item>
                <b-dropdown-item @click="" v-if="order.order_type==='Network Adjustment'">Edit</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          </tbody>
        </table>
      </b-overlay>
    </b-modal>
    <b-modal id="OpenreachPia" size="xl" @show="getNOIItems()" body-class="modal-body-custom">
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <div>
          <b-button @click="submitNOIsButtonClick()" class="btn btn-success">Submit NOIs</b-button>
          <b-button @click="importNOIsButtonClick()" class="btn btn-success ml-4">Import NOIs</b-button>
          <b-button @click="getNOIItems()" variant="primary" class="ml-5"><i class="fas fa-sync-alt"></i></b-button>

        </div>

        <span @click="close()" class="cursor-pointer">
        X
      </span>
      </template>
      <b-overlay :show="showOverlay" rounded="sm">
        <table class="table table-striped overflow-auto">
          <thead>
          <tr>
            <th>Internal Reference</th>
            <th>Openreach Reference</th>
            <th>Start Date</th>
            <th>Completion Date</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="noiList.length === 0">
            <td colspan="5" class="text-center">No records</td>
          </tr>
          <tr v-for="noi in noiList">
            <td>
              <div class="d-flex justify-content-between"><span>{{ noi.reference }}</span> <i
                  @click="editReference(noi.reference,noi.id)"
                  class="edit-reference cursor-pointer text-muted fas fa-pen ml-2"></i></div>
            </td>
            <td>{{ noi.openreach_id }}</td>
            <td>{{ noi.requestedStartDate ? noi.requestedStartDate : 'Unknown' }}</td>
            <td>{{ noi.requestedCompletionDate ? noi.requestedCompletionDate : 'Unknown' }}</td>
            <td v-b-tooltip.hover :title="noi.code+ ' ' + noi.result"><span
                :class="{'text-danger': noi.code && noi.code.length>0 && noi.code !=='I-0001', 'text-success':noi.code && noi.code.length>0 && noi.code ==='I-0001'}">{{
                noi.openreach_status
              }}</span></td>
            <td>
              <b-dropdown :id="'dropdown-'+noi.openreach_id" variant="link" size="sm" menu-class="dropdown-menu-custom"
                          toggle-class="text-decoration-none p-0"
                          no-caret>
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="deleteRequest(noi)">Delete</b-dropdown-item>
                <b-dropdown-item
                    v-if="noi.openreach_id.length > 0 && noi.openreach_status.toLowerCase()!=='canceled'"
                    @click="cancelNoi(noi.id)">Cancel
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item v-if="noi.openreach_id.length > 0 " @click="viewNOI(noi.id)">
                  Refresh
                </b-dropdown-item>
                <b-dropdown-item v-if="isNoiUpdatable(noi)" @click="editNoi(noi.id)">Edit
                </b-dropdown-item>

                <b-dropdown-divider v-if="noi.openreach_id.length > 0"></b-dropdown-divider>
                <b-dropdown-item @click="buildCompleteModal(noi)">Mark as Build Complete</b-dropdown-item>
                <b-dropdown-item v-if="noi.openreach_id.length > 0 && noi.openreach_status.toLowerCase()!=='canceled'"
                                 @click="openPiaEvidenceModal(noi.openreach_id)">Evidence
                </b-dropdown-item>
                <b-dropdown-item v-if="noi.openreach_id.length > 0"
                                 @click="openListWhereAboutsModal(noi.id)">WhereAbouts
                </b-dropdown-item>
                <b-dropdown-item v-if="noi.openreach_id.length > 0" @click="openLeadInListModal(noi.openreach_id)">
                  LeadIns
                </b-dropdown-item>
                <b-dropdown-item @click="submitDraft(noi)" v-if="noi.openreach_status.toLowerCase()==='draft'">
                  Submit
                </b-dropdown-item>
                <b-dropdown-item @click="showOpenreachOrdersModal(noi)" v-if="noi.openreach_id.length > 0">
                  Orders
                </b-dropdown-item>
              </b-dropdown>


            </td>
          </tr>
          </tbody>
        </table>
      </b-overlay>
    </b-modal>
    <b-modal id="OpenreachPiaImportNoi" title="Import NOIs" ok-title="Import" @ok="importNoisAction">
      <b-overlay :show="showOverlayImportNois" rounded="sm">
        <b-form-group label="List of NOIs">
          <b-form-textarea id="list_of_nois" v-model="noisForImport" required rows="15"></b-form-textarea>
        </b-form-group>
      </b-overlay>
    </b-modal>
    <OpenreachPiaEvidence/>
    <b-modal id="editReference" ok-title="Save" @ok="saveReference" title="Edit Internal Reference">
      <b-overlay :show="showOverlayUpdateReference" rounded="sm">
        <b-form-group label="Internal Reference">
          <b-form-input v-model="reference.reference" required></b-form-input>
        </b-form-group>
      </b-overlay>
    </b-modal>
    <b-modal id="whereAboutList" title="List of WhereAbout for the NOI" size="xl" @hidden="clearWhereABoutList">
      <b-overlay :show="showWhereAboutOverlay">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Internal ID</th>
            <th>Whereabouts ID</th>
            <th>Start Date Time</th>
            <th>End Date Time</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="whereabout in whereAbouts" :key="whereabout.externalTransactionReference">
            <td>{{ whereabout.externalId }}</td>
            <td>{{ whereabout.response_id }}</td>
            <td>{{ whereabout.startDate }}</td>
            <td>{{ whereabout.endDate }}</td>
            <td>{{ whereabout.status }}</td>
            <td>
              <div class="button-groups btn-group-sm">
                <button class="btn btn-info" @click="refreshWhereAbout(whereabout)">Refresh</button>
                <button class="btn btn-info ml-2" @click="updateWhereAbout(whereabout)">Edit</button>
                <button class="btn btn-danger ml-2" @click="deleteWhereAbout(whereabout)">Delete</button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </b-overlay>
    </b-modal>
    <b-modal id="LeadInListForNoi" title="Leadin List" @hide="leadIns=[]">
      <b-overlay :show="loadLeadInsOverlay">
        <table v-if="leadIns.length > 0" class="table table-striped">
          <thead>
          <tr>
            <th>UPRN</th>
            <th>Quantity</th>
            <th>Start Date</th>
            <th>Status</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="leadIn in leadIns">
            <td>{{ leadIn.UPRN }}</td>
            <td>{{ leadIn.quantity }}</td>
            <td>{{ leadIn.startDate }}</td>
            <td>{{ leadIn.response_status }}</td>
            <td>
              <b-dropdown :id="'leadin_dropdown'+leadIn.id" variant="link" size="sm" menu-class="dropdown-menu-custom"
                          toggle-class="text-decoration-none p-0"
                          no-caret>
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click="resubmit(leadIn)" :disabled="leadIn.response_status === 'Active'">Resubmit
                </b-dropdown-item>
                <b-dropdown-item @click="cease(leadIn)" :disabled="leadIn.response_status !== 'Active'">Cease
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item link-class="text-danger" @click="deleteLeadInRequest(leadIn)">Delete</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-if="leadIns.length === 0" class="text-center text-muted">No Lead ins for this NOI</div>
      </b-overlay>
    </b-modal>

    <b-modal id="submit-build-complete-modal" @ok="submitBuildComplete" title="Mark as Build Complete">
      <b-form-group label="Note">
        <b-textarea v-model="buildComplete.note" placeholder="Note"></b-textarea>
      </b-form-group>
      <RelatedPartyList :related-parties="buildComplete.relatedParty"
                        :allowed-roles='["Agent","Customer", "Agent Contact", "Alternate Contact", "DFE Contact", "Job Contact"]'/>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import OpenreachPiaEvidence from "@/components/Modules/OpenreachPIA/components/modals/OpenreachPiaEvidence";
import WhereAboutAPIClient from "@/Api/Openreach/WhereAboutAPIClient";
import LeadInsClient from "@/Api/Openreach/LeadInsClient";
import CommonTools from "@/Helpers/CommonTools";
import RelatedPartyList from "@/InternalPlugins/Openreach/RelatedParty/List/RelatedPartyList.vue";
import BuildCompleteClient from "@/Api/Openreach/BuildCompleteClient";
import OpenreachOrders from "@/Api/Openreach/OpenreachOrders";

export default {
  name: "OpenreachPia",
  components: {RelatedPartyList, OpenreachPiaEvidence},
  data() {
    return {
      noiList: [],
      whereAbouts: [],
      showOverlay: false,
      loadLeadInsOverlay: false,
      showOverlayImportNois: false,
      showOverlayUpdateReference: false,
      showWhereAboutOverlay: false,
      noisForImport: null,
      reference: {
        reference: null,
        id: null,
      },
      state: 'create',
      leadIns: [],
      buildComplete: {
        resourceOrder: null,
        relatedParty: [],
        note: null,
      },
      orders: [],
    }
  },
  computed: {
    ...mapGetters({
      nois: "Openreach/nois",
      project: "Projects/selectedProject",
      piaCredentialsValid: "Openreach/piaCredentialsValid",

    }),

  },
  methods: {
    showOpenreachOrdersModal(noi) {
      this.showOverlay = true;
      OpenreachOrders.getOrders(this.project.id, noi.openreach_id).then(response => {
        this.orders = response.data.data;
        this.$bvModal.show('openreachOrdersList');
      }).finally(() => {
        this.showOverlay = false;
      });

    },
    submitBuildComplete() {
      BuildCompleteClient.createBuildComplete(this.project.id, this.buildComplete).then(() => {
        this.$bvModal.hide('submit-build-complete-modal');
        this.$swal('Success', 'Build Complete submitted successfully', 'success');
      }).catch(() => {
      })
    },

    buildCompleteModal(noi) {
      this.buildComplete.resourceOrder = noi.openreach_id;
      this.$bvModal.show('submit-build-complete-modal');
    },

    ...mapMutations({
      openNoisWindow: 'OpenreachNOIs/activateWindow',
    }),
    importNOIsButtonClick() {
      this.$bvModal.show('OpenreachPiaImportNoi');
    },
    importNoisAction(e) {
      e.preventDefault()
      this.showOverlayImportNois = true;
      let nois = this.noisForImport.split("\n");
      this.$store.dispatch('Openreach/importNois', {nois: nois, projectId: this.project.id}).then(response => {
        this.showOverlay = false;
        this.displayImportNoiResult(response.data.data);
        this.showOverlayImportNois = false;
      }).catch(() => {
        this.showOverlayImportNois = false;
      })
    },
    displayImportNoiResult(resultArray) {
      let string = '<div class="float-left w-100" style="font-size:13px;">';
      for (let index in resultArray) {
        const result = resultArray[index];
        if (result.status !== 'error') {
          string += '<div class="float-left "><i class="fas fa-check-circle"></i> ' + result.id + ' ' + result.result + '</div><br />';
        } else {
          string += '<div class="float-left "><i class="fas fa-times-circle"></i> ' + result.id + ' ' + result.result + '</div><br />';
        }
      }
      string += "</div>";
      this.$swal('', string, 'success').then(() => {
        this.noisForImport = null;
        this.$bvModal.hide('OpenreachPiaImportNoi');
        this.getNOIItems();
      });
    },
    submitDraft(noi) {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/submitDraftNoi', {project_id: this.project.id, id: noi.id}).then(response => {
        this.showOverlay = false;
        this.getNOIItems();
      })

    },
    submitNOIsButtonClick() {
      this.openNoisWindow();
      this.$bvModal.hide('OpenreachPia')
    },
    getNOIItems() {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/getOpenreachNOIItems', {project_id: this.project.id}).then(response => {
        this.showOverlay = false;
        this.noiList = response.data;
      })
    },
    cancelNoi(id) {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/cancelNoi', {projectId: this.project.id, id: id}).then(response => {
        this.getNOIItems();
        this.$swal('Success', 'NOI canceled successfully', 'success');
      }).catch(() => {
        this.showOverlay = false;
      })
    },
    viewNOI(id) {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/viewNoi', {projectId: this.project.id, id: id}).then(response => {
        this.getNOIItems();
      }).catch(() => {
        this.showOverlay = false;
      })
    },
    editNoi(id) {
      this.$store.commit('Openreach/setEditId', id)
      this.$bvModal.hide('OpenreachPia');
    },
    isNoiUpdatable(noi) {
      if (noi.openreach_status.toLowerCase() === 'draft' || noi.openreach_status.toLowerCase() === 'noi inprogress') {
        return true;
      }
      if (noi.openreach_id === undefined || noi.openreach_id === null || noi.openreach_id.length === 0) {
        return false;
      }
      const allowedToEditStatuses = ['canceled', 'noi inprogress']
      if (allowedToEditStatuses.includes(noi.openreach_status.toLowerCase())) {
        return false;
      }
      return true;
    },
    editReference(reference, id) {
      this.reference.reference = reference;
      this.reference.id = id;
      this.$bvModal.show('editReference');
    },
    saveReference(e) {
      e.preventDefault();
      this.showOverlayUpdateReference = true;
      this.$store.dispatch('Openreach/updateNoiReference', {
        id: this.reference.id,
        project_id: this.project.id,
        reference: this.reference.reference
      }).then(response => {
        this.getNOIItems();
        this.showOverlayUpdateReference = false;
        this.$bvModal.hide('editReference');
      }).catch(() => {
        this.showOverlayUpdateReference = false;
      })
    },
    openPiaEvidenceModal(id) {
      if (!this.piaCredentialsValid) {
        this.$swal("Credentials missing", "Transflex credentials are missing in users profile", "warning")
        return;
      }
      this.$store.commit('Openreach/setEvidencePiaNumber', id);
      this.$bvModal.show('OpenreachPiaEvidenceListModal')
    },
    deleteRequest(noi) {
      const self = this;
      this.$swal({
        title: 'Are you sure?',
        text: 'This will only delete locally in this system and not on Openreach systems. Are you sure you wish to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No, Keep it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          self.showOverlay = true;
          self.$store.dispatch('Openreach/deleteRequest', {
            projectId: this.project.id,
            piaNumber: noi.id
          }).finally(() => {
            self.showOverlay = false;
            this.getNOIItems();
          });
        }
      })
    },
    openListWhereAboutsModal(id) {
      this.getWhereAboutsDataForNoi(id);
      this.$bvModal.show('whereAboutList');
    },
    getWhereAboutsDataForNoi(id) {
      this.showOverlay = true;
      this.showWhereAboutOverlay = true;
      WhereAboutAPIClient.list(id).then(response => {
        if (response.data.data.length === 0) {
          this.whereAbouts = response.data.data;
          this.$swal('No Data', 'There are no whereabouts for this request', 'info');
          return;
        }
        this.whereAbouts = response.data.data;

      }).finally(() => {
        this.showOverlay = false;
        this.showWhereAboutOverlay = false;
      })
    },
    deleteWhereAbout(item) {
      this.showWhereAboutOverlay = true;
      WhereAboutAPIClient.delete(item.id).then(() => {
        this.getWhereAboutsDataForNoi(item.noi_request_id);
      }).finally(() => {
        this.showWhereAboutOverlay = false;
      })
    },
    refreshWhereAbout(item) {
      this.showWhereAboutOverlay = true;
      WhereAboutAPIClient.get(item.id).then(() => {
        this.getWhereAboutsDataForNoi(item.noi_request_id);
      }).finally(() => {
        this.showWhereAboutOverlay = false;
      })
    },
    stateUpdate() {
      this.state = 'update'
    },
    stateCreate() {
      this.state = 'create';
    },
    updateWhereAbout(item) {
      this.stateUpdate();
      this.$store.commit('Openreach/setUpdatableWhereAbout', item);
    },
    clearWhereABoutList() {
      this.whereAbouts = [];
    },
    openLeadInListModal(piaNoiNumber) {
      this.$bvModal.show('LeadInListForNoi');
      this.loadLeadInList(piaNoiNumber)
    },
    cease(leadin) {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const startDate = new Date(today)
      startDate.setDate(startDate.getDate())
      this.$selectDate({
        title: 'Choose Cease Date', minDate: startDate, meth: (date) => {
          LeadInsClient.cease(leadin.id, date).then(() => {
            this.$swal('', 'Lead in terminated', 'success');
          }).catch(error => {
            if (!CommonTools.displayCatch(error)) {
              this.$swal('Error', 'There was an error while submitting cease request', 'error');
            }
          }).finally(() => {
            this.loadLeadInList(leadin.NOI)
          })
        }
      });
    },
    resubmit(leadIn) {
      LeadInsClient.resubmit(leadIn.id).then(() => {
        this.$swal('', 'Lead in resubmitted', 'success')
      }).catch(error => {
        if (!CommonTools.displayCatch(error)) {
          this.$swal('Error', 'There was an error while re-submitting cease request', 'error');
        }
      }).finally(() => {
        this.loadLeadInList(leadIn.NOI)
      })
    },
    loadLeadInList(NOI) {
      this.loadLeadInsOverlay = true;
      LeadInsClient.list(NOI).then(data => {
        this.leadIns = data.data.data;
      }).finally(() => {
        this.loadLeadInsOverlay = false;
      })
    },
    deleteLeadInRequest(leadIn) {
      CommonTools.deleteConfirmation(null, 'Are you sure you wish to delete this LeadIn Request?', 'Delete', 'Cancel').then(() => {
        this.loading = true;
        LeadInsClient.delete(leadIn.id).then(() => {
          this.$swal('Success', 'User deleted', 'success');
        }).finally(() => {
          this.loadLeadInList(leadIn.NOI)
        })
      }).catch(() => {
      })
    }
  },
}
</script>

<style>
.modal-body-custom {
  min-height: 70vh;
  max-height: 80vh;
  overflow: auto;
}

.dropdown-menu-custom {
  font-size: 12px !important;
}
</style>