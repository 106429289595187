<template>
  <div>
    <b-modal centered no-close-on-esc no-close-on-backdrop id="cableTypeList" @show="show" size="xl" class="p-2">
      <template v-slot:modal-title>
        Cable Types
        <b-button v-if="currentUser.parent_user_id == null || currentUser.allow_edit === 1" variant="success" size="sm"
                  class="float-right ml-5" v-b-modal.addCableTypeList>Add Cable Type
        </b-button>
      </template>
      <div>
        <b-table :busy="tableLoader" striped :fields="fields" :items="cableTypeList">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(color)="data">
            <span class='cable-type-color' :style="{backgroundColor: data.item.color}"></span>
          </template>
          <template v-if="currentUser.parent_user_id == null || currentUser.allow_edit === 1" #cell(action)="data">
            <i role='button' class="fas fa-pen text-info" @click="showUpdate(data)"></i>
            <i role='button' class="fas fa-trash text-danger ml-2" @click="remove(data)"></i>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal title="Edit cable type" @ok="update" @hidden="resetEditForm" centered no-close-on-backdrop no-close-on-esc
             id="editCableType"
             body-class="has-vue-swatches">
      <b-form ref="editCableTypeForm" @submit.prevent.stop="submitEditCableTypeForm">
        <b-form-group label="Cable Type Name">
          <b-form-input ref="editCableTypeInput" @change="validateEditCableTypeForm"
                        aria-describedby="input-editCable-type-feedback" :state="type_nameEditState"
                        v-model="editableCableType.type_name" required></b-form-input>
          <b-form-invalid-feedback id="input-editCable-type-feedback">
            Enter at least 3 letters
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Tubes">
          <b-form-input number ref="tubes_EditInput" @change="validateEditCableTypeForm" :state="tubes_EditState"
                        aria-describedby="input-editTubes-feedback" v-model="editableCableType.tubes"
                        required></b-form-input>
          <b-form-invalid-feedback id="input-editTubes-feedback">
            Value should be greater than 0
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <v-swatches :value="editableCableType.color"
                      @input="setCableTypeColor($event,editableCableType)" popover-x="left"
                      swatches="text-advanced" shapes="circles"></v-swatches>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary"
                    :disabled="editableCableType.tubes===null || parseInt(editableCableType.tubes)===0"
                    v-b-modal.edit_cable_type_tube_assign_colors>
            Assign Colors
          </b-button>
        </b-form-group>
        <b-form-group label="Fibres per tube">
          <b-form-input number ref="fibres_per_tube_EditInput" @change="validateEditCableTypeForm"
                        :state="fibres_per_tube_EditState"
                        aria-describedby="input-editFibres_per_tube-feedback"
                        v-model="editableCableType.fibres_per_tube"
                        required></b-form-input>
          <b-form-invalid-feedback id="input-editFibres_per_tube-feedback">
            Value should be greater than 0
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary"
                    :disabled="editableCableType.fibres_per_tube===null || parseInt(editableCableType.fibres_per_tube)===0"
                    v-b-modal.edit_cable_type_fibres_assign_colors>
            Assign Fibre Colors
          </b-button>
        </b-form-group>
        <b-form-group label="Manufacturer Name">
          <b-form-input ref="manufacturer_nameEditInput" @change="validateEditCableTypeForm"
                        :state="manufacturer_nameEditState" aria-describedby="input-editManufacturer-name-feedback"
                        v-model="editableCableType.manufacturer_name" min="3" required></b-form-input>
          <b-form-invalid-feedback id="input-editManufacturer-name-feedback">
            Enter at least 3 letters
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Product Code">
          <b-form-input ref="product_codeEditInput" @change="validateEditCableTypeForm" :state="product_codeEditState"
                        aria-describedby="input-editProduct-code-feedback" v-model="editableCableType.product_code"
                        required></b-form-input>
          <b-form-invalid-feedback id="input-editProduct-code-feedback">
            Enter at least 3 letters
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>

    </b-modal>
    <b-modal title="Add new cable type" @ok="saveNewCableType" @hidden="resetNewCableTypeForm" centered no-close-on-esc
             no-close-on-backdrop
             id="addCableTypeList" body-class="has-vue-swatches">
      <b-form ref="newCableTypeForm" @submit.prevent.stop="submitNewCableTypeForm">
        <b-form-group label="Cable Type Name">
          <b-form-input ref="newCableTypeInput" @change="validateNewCableTypeForm"
                        aria-describedby="input-newCable-type-feedback" :state="type_nameState"
                        v-model="newCableType.type_name" required></b-form-input>
          <b-form-invalid-feedback id="input-newCable-type-feedback">
            Enter at least 3 letters
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <v-swatches :value="newCableType.color"
                      @input="setCableTypeColor($event,newCableType)" popover-x="left"
                      swatches="text-advanced" shapes="circles"></v-swatches>
        </b-form-group>
        <b-form-group label="Tubes Count">
          <b-form-input
              ref="newCableTubes"
              v-model="newCableType.tubes"
              aria-describedby="input-tubes-feedback"
              @change="validateNewCableTypeForm"
              :state="tubes_State"></b-form-input>
          <b-form-invalid-feedback id="input-tubes-feedback">
            Value should be greater than 0
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary" :disabled="newCableType.tubes===null || parseInt(newCableType.tubes)===0"
                    v-b-modal.new_cable_type_tube_assign_colors>
            Assign Tube Colors
          </b-button>
        </b-form-group>
        <b-form-group label="Fibres per tube">
          <b-form-input
              ref="newCableFibresPerTube"
              v-model="newCableType.fibres_per_tube"
              aria-describedby="input-fibres_per_tube-feedback"
              @change="validateNewCableTypeForm"
              :state="fibres_per_tube_State"
          ></b-form-input>
          <b-form-invalid-feedback id="input-fibres_per_tube-feedback">
            Value should be greater than 0
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary"
                    :disabled="newCableType.fibres_per_tube===null || parseInt(newCableType.fibres_per_tube)===0"
                    v-b-modal.new_cable_type_fibres_assign_colors>
            Assign Fibre Colors
          </b-button>
        </b-form-group>
        <b-form-group label="Manufacturer Name">
          <b-form-input ref="newCableManufacturer_nameStateInput" @change="validateNewCableTypeForm"
                        :state="manufacturer_nameState" aria-describedby="input-manufacturer-name-feedback"
                        v-model="newCableType.manufacturer_name" required></b-form-input>
          <b-form-invalid-feedback id="input-manufacturer-name-feedback">
            Enter at least 3 letters
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Product Code">
          <b-form-input ref="newCableProduct_codeInput" @change="validateNewCableTypeForm" :state="product_codeState"
                        aria-describedby="input-product-code-feedback" v-model="newCableType.product_code"
                        required></b-form-input>
          <b-form-invalid-feedback id="input-product-code-feedback">
            Enter at least 3 letters
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal id="new_cable_type_tube_assign_colors" title="Assign Color for tubes" body-class="has-vue-swatches">
      <div v-if="newCableType.tubes !==null">
        <div class="d-flex justify-content-between"
             v-for="tubeItem in parseInt(newCableType.tubes)">
          <span class="flex-grow-1">Tube #{{ tubeItem }}</span>
          <span><v-swatches v-model="tubeColors[tubeItem]" @input="setNewTubeColor($event,tubeItem)" popover-x="left"
                            swatches="text-advanced" shapes="circles"></v-swatches></span>
          <div style="width: 50px;" class="ml-4 my-auto align-items-center"><i
              v-if="tubeColors[tubeItem]"
              @click="removeNewCableTypeTubeColor(tubeItem)"
              class="text-center cursor-pointer fas fa-times"></i>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit_cable_type_tube_assign_colors" title="Assign Color for tubes" body-class="has-vue-swatches">
      <div v-if="editableCableType.tubes !==null">
        <div class="d-flex justify-content-between"
             v-for="tubeItem in parseInt(editableCableType.tubes)">
          <span class="flex-grow-1">Tube #{{ tubeItem }}</span>
          <span><v-swatches v-model="editTubeColors[tubeItem]" @input="setEditedTubeColor($event,tubeItem)"
                            popover-x="left"
                            swatches="text-advanced" shapes="circles"></v-swatches></span>
          <div style="width: 50px;" class="ml-4 my-auto align-items-center"><i
              v-if="editTubeColors[tubeItem]"
              @click="removeEditedCableTypeTubeColor(tubeItem)"
              class="text-center cursor-pointer fas fa-times"></i>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="new_cable_type_fibres_assign_colors" title="Assign Color for Fibres" body-class="has-vue-swatches">
      <div v-if="newCableType.fibres_per_tube !==null">
        <div class="d-flex justify-content-between"
             v-for="tubeItem in parseInt(newCableType.fibres_per_tube)">
          <span class="flex-grow-1">Fibre #{{ tubeItem }}</span>
          <span><v-swatches v-model="fibreColors[tubeItem]" @input="setNewFibreColor($event,tubeItem)" popover-x="left"
                            swatches="text-advanced" shapes="circles"></v-swatches></span>
          <div style="width: 50px;" class="ml-4 my-auto align-items-center"><i
              v-if="fibreColors[tubeItem]"
              @click="removeNewCableTypeFibreColor(tubeItem)"
              class="text-center cursor-pointer fas fa-times"></i>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="edit_cable_type_fibres_assign_colors" title="Assign Color for Fibres" body-class="has-vue-swatches">
      <div v-if="editableCableType.fibres_per_tube !==null">
        <div class="d-flex justify-content-between"
             v-for="tubeItem in parseInt(editableCableType.fibres_per_tube)">
          <span class="flex-grow-1">Fibre #{{ tubeItem }}</span>
          <span><v-swatches v-model="editFibreColors[tubeItem]" @input="setEditFibreColor($event,tubeItem)"
                            popover-x="left"
                            swatches="text-advanced" shapes="circles"></v-swatches></span>
          <div style="width: 50px;" class="ml-4 my-auto align-items-center"><i
              v-if="editFibreColors[tubeItem]"
              @click="removeEditedCableTypeFibreColor(tubeItem)"
              class="text-center cursor-pointer fas fa-times"></i>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import VSwatches from 'vue-swatches'

export default {
  name: "CableTypeList",
  components: {VSwatches},
  watch: {
    newCableTypeTubesCount(value) {
      this.newTubeColors = [];
      if (value > 0) {
        for (let i = 1; i <= value; i++) {
          Vue.set(this.tubeColors, i, null)
        }
      }
    },
    newCableTypeFibresCount(value) {
      this.fibreColors = [];
      if (value > 0) {
        for (let i = 1; i <= value; i++) {
          if (this.predefinedFibreColors[(i - 1)]) {
            Vue.set(this.fibreColors, i, this.predefinedFibreColors[(i - 1)])
          } else {
            Vue.set(this.fibreColors, i, null)
          }
        }
      }
    }
  },
  data: () => {
    return {
      fibreColors: [],
      tubeColors: [],
      newTubeColors: [],
      editTubeColors: [],
      editFibreColors: [],
      tableLoader: false,
      predefinedFibreColors: [
        '#7A9BFF',
        '#C1A182',
        '#6E926E',
        '#C1A182',
        '#BDBDBD',
        '#FFFFFF',
        '#DE5959',
        '#000000',
        '#FFFF7E',
        '#954F95',
        '#FFA3C2',
        '#5ADEDE',

      ],
      newCableType: {
        tube_colors: [],
        name: null,
        color: null,
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: null,
        fibres_per_tube: null
      },
      editableCableType: {
        id: null,
        name: null,
        color: null,
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: null,
        fibres_per_tube: null
      },
      newCableTypeInputStates: {
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: null,
        fibres_per_tube: null
      },
      editCableTypeInputStates: {
        type_name: null,
        manufacturer_name: null,
        product_code: null,
        tubes: null,
        fibres_per_tube: null
      },
      cableTypeList: [],
      fields: [
        {key: 'id', label: 'ID'},
        {key: 'type_name', label: 'Type Name'},
        {key: 'color', label: 'Type Color'},
        {key: 'manufacturer_name', label: 'Manufacturer Name'},
        {key: 'product_code', label: 'Product Code'},
        {key: 'tubes', label: 'Tubes'},
        {key: 'fibres_per_tube', label: 'Fibres Per Tube'},
        {key: 'action', label: 'Action'}
      ],
    }
  },
  computed: {
    newCableTypeTubesCount() {
      return this.newCableType.tubes;
    },
    newCableTypeFibresCount() {
      return this.newCableType.fibres_per_tube;
    },
    type_nameState: function () {
      if (this.newCableTypeInputStates.type_name === false) {
        return false;
      }
      if (this.newCableType.type_name === null) {
        return null;
      }
      return this.newCableType.type_name.length > 3;
    },
    type_nameEditState: function () {
      if (this.editCableTypeInputStates.type_name === false) {
        return false;
      }
      if (this.editableCableType.type_name === null) {
        return null;
      }
      return this.editableCableType.type_name.length > 3;
    },
    fibres_per_tube_State: function () {
      if (this.newCableType.fibres_per_tube === false) {
        return false;
      }
      if (this.newCableType.fibres_per_tube === null) {
        return null;
      }
      return this.newCableType.fibres_per_tube > 0;
    },
    fibres_per_tube_EditState: function () {
      if (this.editCableTypeInputStates.fibres_per_tube === false) {
        return false;
      }
      if (this.editCableTypeInputStates.fibres_per_tube === null) {
        return null;
      }
      return this.editCableTypeInputStates.fibres_per_tube > 0;
    },
    tubes_State: function () {
      if (this.newCableType.tubes === false) {
        return false;
      }
      if (this.newCableType.tubes === null) {
        return null;
      }
      return this.newCableType.tubes > 0;
    },
    tubes_EditState: function () {
      if (this.editCableTypeInputStates.tubes === false) {
        return false;
      }
      if (this.editableCableType.tubes === null) {
        return null;
      }
      return this.editableCableType.tubes > 0;
    },
    manufacturer_nameState: function () {
      if (this.newCableTypeInputStates.manufacturer_name === false) {
        return false;
      }
      if (this.newCableType.manufacturer_name === null) {
        return null;
      }
      return this.newCableType.manufacturer_name.length >= 3;
    },
    manufacturer_nameEditState: function () {
      if (this.editCableTypeInputStates.manufacturer_name === false) {
        return false;
      }
      if (this.editableCableType.manufacturer_name === null) {
        return null;
      }
      return this.editableCableType.manufacturer_name.length >= 3;
    },
    product_codeState: function () {
      if (this.newCableTypeInputStates.product_code === false) {
        return false;
      }
      if (this.newCableType.product_code === null) {
        return null;
      }
      return this.newCableType.type_name.length >= 3;
    },
    product_codeEditState: function () {
      if (this.editCableTypeInputStates.product_code === false) {
        return false;
      }
      if (this.editableCableType.product_code === null) {
        return null;
      }
      return this.editableCableType.type_name.length >= 3;
    },
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
  },
  methods: {
    setEditFibreColor(value, tubeIndex) {
      Vue.set(this.editFibreColors, tubeIndex, value)
    },
    removeEditedCableTypeFibreColor(tubeItem) {
      Vue.set(this.editFibreColors, tubeItem, null)
    },
    setNewFibreColor(value, tubeIndex) {
      Vue.set(this.fibreColors, tubeIndex, value)
    },
    removeNewCableTypeFibreColor(tubeItem) {
      Vue.set(this.fibreColors, tubeItem, null)
    },
    setEditedTubeColor(value, tubeIndex) {
      Vue.set(this.editTubeColors, tubeIndex, value)
    },
    removeEditedCableTypeTubeColor(tubeItem) {
      Vue.set(this.editTubeColors, tubeItem, null)
    },
    setNewTubeColor(value, tubeIndex) {
      Vue.set(this.tubeColors, tubeIndex, value)
    },
    removeNewCableTypeTubeColor(tubeItem) {
      Vue.set(this.tubeColors, tubeItem, null)
    },
    submitEditCableTypeForm() {
      const id = this.editableCableType.id;
      let tube_colors = [];
      for (let i = 1; i < this.editTubeColors.length; i++) {
        tube_colors.push({tube_index: i, tube_color: this.editTubeColors[i]});
      }
      let fibre_colors = [];
      for (let i = 1; i < this.editFibreColors.length; i++) {
        fibre_colors.push({fibre_index: i, fibre_color: this.editFibreColors[i]});
      }
      this.editableCableType.tube_colors = tube_colors;
      this.editableCableType.fibre_colors = fibre_colors;
      this.updateCableType(id, this.editableCableType);
    },
    submitNewCableTypeForm() {
      this.tableLoader = true;
      let tube_colors = [];
      for (let i = 1; i < this.tubeColors.length; i++) {
        tube_colors.push({tube_index: i, tube_color: this.tubeColors[i]});
      }
      this.newCableType.tube_colors = tube_colors;
      let fibre_colors = [];
      for (let i = 1; i < this.fibreColors.length; i++) {
        fibre_colors.push({fibre_index: i, fibre_color: this.fibreColors[i]});
      }
      this.newCableType.fibre_colors = fibre_colors;
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/type', this.newCableType).then(() => {
        this.resetNewCableTypeForm();
        this.$bvModal.hide('addCableTypeList');
        this.getCableTypeList();
      })
    },
    remove(data) {
      axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/type/' + data.item.id).then(() => {
        this.getCableTypeList();
      });
    },
    showUpdate(data) {
      this.editableCableType = {...data.item};
      for (let i = 0; i < data.item.tube_colors.length; i++) {
        this.editTubeColors[data.item.tube_colors[i].tube_index] = data.item.tube_colors[i].tube_color;
      }
      for (let i = 0; i < data.item.fibre_colors.length; i++) {
        this.editFibreColors[data.item.fibre_colors[i].fibre_index] = data.item.fibre_colors[i].fibre_color;
      }
      this.$bvModal.show('editCableType');
    },
    update(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.validateEditCableTypeForm()) {
        this.submitEditCableTypeForm();
      }

    },
    validateNewCableTypeForm() {
      const valid = this.$refs.newCableTypeForm.checkValidity();
      this.newCableTypeInputStates.type_name = this.$refs.newCableTypeInput.checkValidity();
      this.newCableTypeInputStates.manufacturer_name = this.$refs.newCableManufacturer_nameStateInput.checkValidity();
      this.newCableTypeInputStates.product_code = this.$refs.newCableProduct_codeInput.checkValidity();
      this.newCableTypeInputStates.tubes = this.$refs.newCableTubes.checkValidity();
      this.newCableTypeInputStates.fibres_per_tube = this.$refs.newCableFibresPerTube.checkValidity();
      return valid;
    },
    validateEditCableTypeForm() {
      const valid = this.$refs.editCableTypeForm.checkValidity();
      this.editCableTypeInputStates.type_name = this.$refs.editCableTypeInput.checkValidity();
      this.editCableTypeInputStates.manufacturer_name = this.$refs.manufacturer_nameEditInput.checkValidity();
      this.editCableTypeInputStates.product_code = this.$refs.product_codeEditInput.checkValidity();
      this.editCableTypeInputStates.tubes = this.$refs.tubes_EditInput.checkValidity();
      return valid;
    },
    saveNewCableType(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.validateNewCableTypeForm()) {
        this.submitNewCableTypeForm();
      }
    },
    getCableTypeList() {
      this.tableLoader = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/type').then(response => {
        this.tableLoader = false;
        this.cableTypeList = response.data.data;
      })
    },
    updateCableType(id, data) {
      axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/cable/type/' + id, data).then(() => {
        this.$bvModal.hide('editCableType');
        this.resetEditForm();
        this.getCableTypeList();
      }).catch(() => {

      })
    },
    show() {
      this.getCableTypeList();
    },
    setCableTypeColor(value, item) {
      item.color = value;
    },
    resetEditForm() {
      this.editableCableType.id = null;
      this.editableCableType.type_name = null;
      this.editableCableType.manufacturer_name = null;
      this.editableCableType.product_code = null;
      this.editableCableType.tubes = null;
      this.editableCableType.fibres_per_tube = null;
      this.editTubeColors = [];
      this.editFibreColors = [];
    },
    resetNewCableTypeForm() {
      this.newCableType.type_name = null;
      this.newCableType.manufacturer_name = null;
      this.newCableType.product_code = null;
      this.newCableType.tubes = null;
      this.newCableType.fibres_per_tube = null;
      this.newCableType.color = null;
      this.newTubeColors = null;
      this.tubeColors = [];
      this.fibreColors = [];
    }
  }
}
</script>

<style scoped>
.cable-type-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}

.vue-swatches__trigger {
  border: 2px solid #bfbfbf;
}
</style>