const state = {
    accounts: []
};
const mutations = {
    setAccounts(state, data) {
        state.accounts = data;
    }
};
const actions = {
    getAccounts(ctx) {
        return axios.get(process.env.VUE_APP_API_URL + '/api/user/accounts')
    },
    deleteSubAccount(ctx, data) {
        return axios.delete(process.env.VUE_APP_API_URL + '/api/user/accounts/' + data.id)
    }
};
const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}