<template>
  <div>
    <b-modal id="OpenreachPiaEvidenceImage" @hidden="hideEvidenceImageModal">
      <b-overlay :show="loadingImageOverlay" rounded="sm">
        <img class="img img-thumbnail" v-if="evidenceImage" :src="evidenceImage.image"/>
      </b-overlay>
    </b-modal>
    <b-modal id="OpenreachPiaEvidenceListModal" size="xl" @hidden="evidenceListHidden">

      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <div class="w-100 d-flex justify-content-between">
          <span>List of evidences for {{ evidencePiaNumber }}</span>

          <b-button @click="$bvModal.show('OpenreachPiaEvidenceModal')" class="btn btn-success mr-5">Create new
            evidence
          </b-button>
        </div>
        <span @click="close()" class="cursor-pointer">
        X
      </span>

      </template>
      <b-overlay :show="showOverlay" rounded="sm">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>UUID</th>
            <th>Evidence Type</th>
            <th>Image</th>
            <th>Create Date</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="evidence in evidences" :key="evidences.id">
            <td>{{ evidence.evidence_uuid }}</td>
            <td>{{ evidence.evidence_type }}</td>
            <td>{{ evidence.photo_original_name }}</td>
            <td>{{ evidence.created_at }}</td>
            <td>
              <button @click="removeEvidence(evidencePiaNumber,evidence.id)" class="btn btn-light btn-sm"><i
                  class="fas fa-trash text-danger"></i></button>
              <button v-if="evidence.evidence_uuid.length>0" @click="viewEvidence(evidencePiaNumber,evidence.id)"
                      class="btn btn-light btn-sm"><i class="fas fa-eye"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-overlay>
    </b-modal>
    <b-modal id="OpenreachPiaEvidenceModal" @shown="showEvidence" @hidden="hideEvidence" centered
             title="Openreach PIA Evidence" ok-title="Upload" @ok="uploadEvidence" :ok-disabled="!allowedUpload">
      <b-overlay :show="showOverlay" rounded="sm">
        <b-form-select :options="evidenceTypes.options" v-model="evidenceTypes_selected" label-field="Evidence Type">
          <template #first>
            <b-form-select-option :value="null" disabled>-- Please select an Evidence type --</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-file multiple
                     v-model="images"
                     accept="image/jpeg, image/png, image/gif"
                     placeholder="Choose a file or drop it here..."
                     drop-placeholder="Drop file here..."
                     browse-text="Select Image(s)"

                     class="mt-5"
        >
          <template slot="file-name" slot-scope="{ names }">
            <b-badge variant="dark">{{ names[0] }}</b-badge>
            <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
              + {{ names.length - 1 }} More files
            </b-badge>
          </template>
        </b-form-file>
        <div class="row mt-5">
          <div class="col-4" v-for="(image,index) in images" :key="index">
            <img :src="getImageUrl(image)" class="img img-thumbnail"/>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OpenreachPiaEvidence",

  watch: {
    evidenceTypes_selected(value) {
      this.checkAllowedUpload();
    },
    images() {
      this.checkAllowedUpload();
    },
    evidencePiaNumber(value) {
      if (value === undefined || value === null || value.length === 0) {
        this.evidences = [];
      } else {
        this.loadEvidences(value);
      }
    }
  },
  data() {
    return {
      showOverlay: false,
      loadingImageOverlay: false,
      images: [],
      evidences: [],
      evidenceTypes_selected: null,
      allowedUpload: false,
      evidenceImage: null,
      evidenceTypes: {
        options: [
          {value: 'NetworkAdjustment', text: 'NetworkAdjustment'},
          {value: 'BuildComplete', text: 'BuildComplete'},
          {value: 'Co-Op_Ancillary', text: 'Co-Op Ancillary'},
          {value: 'CaseManagement', text: 'CaseManagement'},
          {value: 'Whereabouts', text: 'Whereabouts'},
          {value: 'Lead-ins', text: 'Lead-ins'},

        ],
      }
    }
  },
  computed: {
    ...mapGetters({
      evidencePiaNumber: 'Openreach/evidencePiaNumber'
    })
  },
  methods: {
    evidenceListHidden() {
      this.$store.commit('Openreach/setEvidencePiaNumber', null);
      this.evidences = [];
    },
    getImageUrl(image) {
      return URL.createObjectURL(image);
    },
    showEvidence() {
    },
    hideEvidence() {
      this.images = [];
      this.evidenceTypes_selected = null;
    },
    hideEvidenceImageModal() {
      this.evidenceImage = null;
    },
    uploadEvidence(event) {
      event.preventDefault();
      this.showOverlay = true;
      const formData = new FormData()
      // formData.append('images', this.images)
      for (let i = 0; i < this.images.length; i++) {
        let image = this.images[i]
        formData.append('images[' + i + ']', image)
      }

      formData.append('evidence_type', this.evidenceTypes_selected)

      this.$store.dispatch('Openreach/uploadEvidence', {
        piaNumber: this.evidencePiaNumber,
        form: formData
      }).finally(() => {
        this.showOverlay = false;
        this.loadEvidences(this.evidencePiaNumber)
        this.$bvModal.hide('OpenreachPiaEvidenceModal')
      })
    },
    checkAllowedUpload() {
      if (this.evidencePiaNumber === null) {
        this.allowedUpload = false;
        return;
      }
      if (this.evidenceTypes_selected === null) {
        this.allowedUpload = false;
        return;
      }
      if (this.images.length === 0) {
        this.allowedUpload = false;
        return;
      }
      this.allowedUpload = true;
    },
    loadEvidences(piaNumber) {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/listEvidences', {piaNumber: piaNumber})
          .then(data => {
            this.evidences = data.data;
          })
          .finally(() => {
            this.showOverlay = false;
          })
    },
    removeEvidence(piaNumber, evidenceId) {
      this.showOverlay = true;
      this.$store.dispatch('Openreach/removeEvidence', {piaNumber, evidenceId}).finally(() => {
        this.showOverlay = false;
        this.loadEvidences(this.evidencePiaNumber);
      })
    },
    viewEvidence(piaNumber, evidenceId) {
      this.$bvModal.show('OpenreachPiaEvidenceImage')
      this.loadingImageOverlay = true;
      this.$store.dispatch('Openreach/viewEvidence', {piaNumber, evidenceId}).then(data => {
        this.evidenceImage = data.data;
      }).finally(() => {
        this.loadingImageOverlay = false;
      })
    }
  }
}
</script>

<style scoped>

</style>