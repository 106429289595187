<template>
  <div>
    <b-modal id="select_splitter" title="Select Splitter" @show="loadSplitters" @ok="addSplitterToObject"
             @hidden="hidden"
             ok-title="Add Splitter">
      <b-overlay :show="selectSplitterModalBusy">
        <b-form-group>
          <select :disabled="splitters.length===0" v-model="selectedNewSplitter" class="form-control">
            <option v-for="splitter in splitters" :value="splitter">{{ splitter.name }} ({{ splitter.output_ports }}
              output fibres)
            </option>
          </select>
        </b-form-group>

      </b-overlay>
    </b-modal>
    <b-card>

      <b-card-title>
        <div class="d-flex justify-content-between">
          <span>Splitter</span>
          <button class="btn btn-info btn-sm" @click="$bvModal.show('select_splitter')">Add new splitter</button>
        </div>
      </b-card-title>

      <b-card-body>
        <b-overlay :show="loadingObjects">
          <SubObjectsList :parent-object-id="parentObjectId" :items="items"></SubObjectsList>
        </b-overlay>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ObjectTypes from "@/Api/ObjectTypes";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";
import SubObjectsList from "@/components/modals/Objects/Object/SubObjectsList.vue";

export default {
  name: "SplitterList",
  components: {SubObjectsList},
  props: {
    parentObjectId: {type: Number},
    loadParentObjectFibres: false,
  },
  data() {
    return {
      items: [],
      loadingObjects: false,
      splitters: [],
      attachedObjects: [],
      selectedNewSplitter: null,
      selectSplitterModalBusy: true,
      availableSourceFibres: [],
      selectedOutputFibres: [],
      loadingFreeFibres: false,
      selectedSourceFibre: null,
    }
  },
  methods: {
    addSplitterToObject(event) {
      event.preventDefault();
      this.selectSplitterModalBusy = true;
      const data = {
        'parent_id': this.parentObjectId,
        'default_type_id': this.selectedNewSplitter.default_type_id,
        'child_id': this.selectedNewSplitter.id,
      }
      ObjectTypes.addChildObject(this.projectId, data).then(response => {
        this.selectSplitterModalBusy = false;
        this.$bvModal.hide('select_splitter')
        this.loadObjects();
      })
    },
    loadObjects() {
      this.loadingObjects = true;
      ObjectTypes.getChilds(this.parentObjectId).then(response => {
        this.items = response.data.data[ObjectTypesEnum.OT_SPLITTER];
      }).finally(() => {
        this.loadingObjects = false;
      });
    },
    loadSplitters() {
      this.selectSplitterModalBusy = true;
      this.selectedNewSplitter = null;
      ObjectTypes.getObjectsByTypeCode(ObjectTypesEnum.OT_SPLITTER).then(response => {
        this.splitters = response.data.data;
      }).finally(() => {
        this.selectSplitterModalBusy = false;
      })
    },
    hidden() {
      this.selectedNewSplitter = null;
    }
  },
  mounted() {
    this.loadObjects()
  }
}
</script>

<style scoped>
</style>