const state = {
    location: {
        lat: null,
        lng: null,
    },
    features: [],
    dataAvailable: false
};
const actions = {};
const getters = {
    available: state => state.dataAvailable,
    features: state => state.features,
    location: state => state.location,
};
const mutations = {
    setAvailable(state, data) {
        state.dataAvailable = data
    },
    setFeatures(state, data) {
        state.features = data;
    },
    setLocation(state, data) {
        state.location = data;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}