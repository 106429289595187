<template>
  <div>
    <div id="mySidenavUserLocationsGroup" class="sidenav" v-bind:class="{ active: isActive}">
      <div class="userLocations">
        <div class="project-title">User Locations</div>
        <div class="col mt-2">
          <ul id="groups">
            <li v-for="(location,index) in this.userLocations" :key="index">
              <span @click="goToLocation(location)">{{ location.name }}</span> <i class="fas fa-times"
                                                                                  @click="deleteLocation(location)"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import {mapGetters} from "vuex";

export default {
  name: "UserLocationsList",
  data: function () {
    return {
      userLocations: [],
    }
  },
  methods: {
    loadLocations() {
      this.$root.$emit('showLoader', 'Loading User Locations');
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + this.project.id + '/location').then(response => {
        this.userLocations = response.data.data;
      }).then(() => {
        this.$root.$emit('hideLoader')
      })
    },
    deleteLocation(item) {
      axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + this.project.id + '/location/' + item.id).then(() => {
        this.loadLocations();
        this.$root.$emit('hideLoader')
      });
    },
    goToLocation(data) {
      let center = L.latLng(data.location.coordinates[0], data.location.coordinates[1])
      this.$store.commit("State/setZoom", data.zoom, {root: true})
      this.$root.$emit('setCenter', {center: center})
    }
  },
  computed: {
    isActive: {
      get() {
        return this.$store.state.State.userLocationsShown;
      }
    },
    ...mapGetters({
      project: 'Projects/selectedProject'
    }),
  },
  watch: {
    isActive: function (data) {
      if (data) {
        this.loadLocations();
      }
    }
  }
}
</script>

<style scoped>
.sidenav {
  display: none;
  width: 350px;
  position: absolute;
  z-index: 9999;
  top: 80px;
  right: 0;
  overflow-x: hidden;
  height: calc(100% - 80px);
  box-shadow: 0px 20px 15px -7px #000000, 0px 5px 13px 5px rgba(0, 0, 0, 0);
}


.sidenav.active {
  display: block !important;
}

#mySidenavUserLocationsGroup {
  padding: 5px;
}

.userLocations {
  border: 1px solid #dad7d7;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  background-color: white;

}

.project-title {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #dad7d7;

}

#groups {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

#groups li {
  padding-left: 0;
  margin-left: 0;
  font-size: 11px;
  cursor: pointer;
}

</style>