<template>
  <b-modal id="patch_panel" ok-title="Save" cancel-title="Reset" @ok="onSubmit($event)"
           @cancel="onReset($event)" @close="close()" title="Patch Panel" body-class="object_type_modal_body">
    <div>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group id="input-group-1" label="Object name:" label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="object.name"
              placeholder="Enter object name"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Row number:" label-for="input-2">
          <b-form-input
              id="input-2"
              v-model="object.rows"
              placeholder="Enter row number"
              type="number"
              min="0"
              step="1"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Ports per row:" label-for="input-3">
          <b-form-input
              id="input-3"
              v-model="object.ports_per_row"
              placeholder="Enter port number per row"
              type="number"
              min="0"
              step="1"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="Modules:" label-for="input-4">
          <b-form-input
              id="input-4"
              v-model="object.modules"
              placeholder="Enter used modules number"
              type="number"
              min="0"
              step="1"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-5" label="Port Direction" label-for="input-5">
          <b-form-select v-model="object.port_direction" :options="directions"></b-form-select>
        </b-form-group>
        <b-form-group id="input-group-7" label="Color" label-for="input-7">
          <v-swatches v-model="object.color" popover-x="left"
                      swatches="text-advanced"></v-swatches>
        </b-form-group>
      </b-form>
    </div>

  </b-modal>
</template>

<script>
import VSwatches from 'vue-swatches'
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  components: {VSwatches},
  name: "PatchPanel",
  //OT_PATCH_PANEL
  data() {
    return {
      directions: ['Left to Right', 'Top to Bottom'],
      object: {
        name: '',
        rows: null,
        ports_per_row: null,
        port_direction: '',
        modules: null,
        color: null,
        code: ObjectTypesEnum.OT_PATCH_PANEL.toLowerCase()
      },
    }
  },
  methods: {
    close() {
      this.$store.commit('ObjectTypes/setTypeListOverlay', false)
    },
    onSubmit(event) {
      event.preventDefault()
      this.$store.dispatch('ObjectTypes/store', this.object).then(() => {
        this.$bvModal.hide("patch_panel");
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our object values
      this.object.name = ''
      this.object.rows = ''
      this.object.ports_per_row = ''
      this.object.modules = ''
      this.object.port_direction = ''
      this.object.color = null
      // Trick to reset/clear native browser form validation state
    }
  },
  created() {
    const self = this
    this.$root.$on('OT_PATCH_PANEL', function () {
      self.$bvModal.hide("create_object_type_modal");
      self.$bvModal.show("patch_panel");
    })
  }
}
</script>

<style scoped>
.object-color {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  float: left;
}
</style>