import vue from "vue";

const state = {
    current_request_id: null,
    texts: [],
};
const actions = {
    list(ctx, bounds) {
        if (this.current_request_id) {
            axios.cancel(this.current_request_id);
        }
        this.current_request_id = 'list_project_text';
        const selectedProject = ctx.rootGetters["Projects/selectedProject"];
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/text/search/' + selectedProject.id + '/' + btoa(bounds), {
                requestId: self.current_request_id
            }).then(response => {
                ctx.commit('setTexts', response.data.data);
                this.current_request_id = null;
                resolve(response.data.data)
            }).catch(error => {
                this.current_request_id = null;
                reject(error);
            })
        })
    },
    update(ctx, data) {
        return axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/text/' + data.id, data);
    },
    delete(ctx, id) {
        return axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/text/' + id);
    }
}
const getters = {}
const mutations = {
    setTexts(state, data) {
        vue.set(state, 'texts', data);
    }
}
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}