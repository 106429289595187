<template>
  <b-modal @ok="saveLocation()" @hidden="clearNearestLocation" id="saveLocation" centered title="Save Location"
           v-bind:class="{ active: isActive}">
    <b-form-group>
      <b-form-input
          id="location_name" v-model="nearestLocation" type="text"
      ></b-form-input>
    </b-form-group>
  </b-modal>
</template>

<script>

import CommonTools from "../../Helpers/CommonTools";
import axios from "axios";

export default {
  name: "SaveLocations",
  computed: {
    isActive: {
      get() {
        return this.$store.state.State.saveLocationShown;
      }
    },
    nearestLocation: {
      get: function () {
        return this.$store.getters['Search/getNearestLocation']
      },
      set: function (newValue) {
        this.$store.commit('Search/setNearestLocation', newValue, {root: true})
      }
    },
  },
  methods: {
    saveLocation() {
      let currentZoom = this.$store.getters['State/currentZoom']
      let selectedProject = this.$store.getters["Projects/selectedProject"];
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/project/' + selectedProject.id + '/location', {
        center: window.Lmap.getCenter(),
        zoom: currentZoom,
        name: this.nearestLocation,
        project_id: selectedProject.id
      }).then(() => {
        this.$swal('Done!', 'Location Saved', 'success');
      })
    },
    clearNearestLocation() {
      this.$store.commit("State/setSaveLocationShown", false, {root: true});
      this.$store.commit('Search/setNearestLocation', '', {root: true})
    }
  },
  watch: {
    isActive: function (data) {
      if (data) {
        const self = this;
        let center = this.map.getCenter();
        const polygon = btoa(CommonTools.createPolygonFromBounds(Lmap.getBounds(), Lmap.getCenter()));
        axios.get(process.env.VUE_APP_API_URL + '/api/nearest/property/' + center.lat + '/' + center.lng + '/' + polygon).then(response => {
          self.$store.commit('Search/setNearestLocation', response.data.data.towncity)
        })
        this.$bvModal.show('saveLocation')
      } else {
        this.$bvModal.hide('saveLocation')
      }

    }
  }
}
</script>

<style scoped>

</style>