import axios from 'axios';
import vue from 'vue';
import Vue from 'vue';
import CommonTools from "@/Helpers/CommonTools";

const state = {
    markerToBounce: null,
    locations: {},
    cities: [],
    streets: [],
    fuzzy: [],
    buildings: [],
    properties: {},
    uprns: {},
    postcodes: [],
    locationInfo: {
        easting: 0,
        latitude: null,
        longitude: null,
        northing: null,
        postcode: null,
        reference_letter: null,
        x_coordinate: null,
        y_coordinate: null,
        street: null,
    },
    locationInfoAvailable: false,
    uprnInfo: {},
    uprnClicked: null,
    multiUprnInfo: [],
    customUprnInfo: {},
    uprnInfoAvailable: false,
    multiUprnInfoAvailable: false,
    customUprnInfoAvailable: false,
    uprnInfoLoading: true,
    customUprnInfoLoading: true,
    postcodeSearchIsActive: false,
    postcode: '',
    showSearch: false,
    showOverlay: false,
    latLngsForBounds: [],
    propertyMarkers: [],
    customPropertyMarkers: [],
    nearestLocation: ''
};
const actions = {
    loadLocationInfoRaw(ctx, latLng) {
        // this.map.showLoader("Fetching Location data");
        return axios.get(process.env.VUE_APP_API_URL + '/api/location/info/' + latLng.lat + '/' + latLng.lng)
    },
    loadLocationInfo(ctx, latLng) {
        return new Promise((resolve, reject) => {
            // this.map.showLoader("Fetching Location data");
            ctx.commit('searchWithPostcode', {active: false, postcode: ''})
            axios.get(process.env.VUE_APP_API_URL + '/api/location/info/' + latLng.lat + '/' + latLng.lng).then(response => {
                // show popup
                ctx.commit('Search/setLocationInfo', CommonTools.setFlags(ctx, [response.data.data], true), {root: true})
                resolve(response.data);
            }).catch(error => {
                reject(error);

            });
        });
    },
    setFlagsOnMarkers(ctx, flagged) {
        setTimeout(() => {
            const markers = ctx.state.propertyMarkers
            ctx.commit('Search/setPropertyMarkers', CommonTools.setFlags(ctx, markers, false, flagged), {root: true})
        }, 2000)
    },
    loadUprnInfo(ctx, data) {
        if (data.clearPostcode) {
            ctx.commit('searchWithPostcode', {active: false, postcode: ''})
        }
        // this.map.showLoader("Fetching Location data");
        ctx.commit('setUprnLoadingState', true);
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/uprn/' + data.uprn).then(response => {
                ctx.commit('Search/setUprnInfo', CommonTools.setFlags(ctx, [response.data.data], true), {root: true})
                if (data.clear) {
                    ctx.commit('setPropertyMarkers', CommonTools.setFlags(ctx, [response.data.data]));
                }
                resolve(response)
            })
        });
    },
    loadMultiUprnInfo(ctx, data) {
        if (data.clearPostcode) {
            ctx.commit('searchWithPostcode', {active: false, postcode: ''})
        }
        // this.map.showLoader("Fetching Location data");
        ctx.commit('setUprnLoadingState', true);
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/multiuprn/' + data.uprn + '/' + data.all_properties).then(response => {
                if (response.data.length > 0) {
                    ctx.commit('setUprnClicked', data.uprn);
                    let uprnList = [];
                    response.data.forEach(function (property) {
                        let prop = CommonTools.setFlags(ctx, [property], true);
                        uprnList.push(prop)
                        if (data.uprn === property.uprn) {
                            if (property.source === 'LPI') {
                                console.log('Source is LPI');
                                ctx.dispatch('LayersList/activateSubLayer', 'all_uprns', {root: true}).then(() => {
                                    ctx.commit('Search/setMarkerToBounce', property.key, {root: true})
                                });
                            }
                            ctx.commit('Search/setMarkerToBounce', property.key, {root: true})
                        }
                    })
                    ctx.commit('Search/setMultiUprnInfo', CommonTools.setFlags(ctx, [uprnList], true), {root: true})
                    resolve(response)
                }
            })
        });
    },
    loadMultiUprnInfoRaw(ctx, data) {

        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/multiuprn/' + data.uprn + '/' + data.all_properties).then(response => {
                if (response.data.length > 0) {
                    resolve(response)
                }
            }).catch(err => reject(err))
        });
    },
    loadCustomUprnInfo(ctx, data) {
        if (data.clearPostcode) {
            ctx.commit('searchWithPostcode', {active: false, postcode: ''})
        }
        // this.map.showLoader("Fetching Location data");
        ctx.commit('setUprnLoadingState', true);
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/customuprn/' + data.uprn).then(response => {
                // console.log(response.data.data)
                ctx.commit('Search/setCustomUprnInfo', CommonTools.setFlags(ctx, [response.data.data], true), {root: true})
                // ctx.commit('Search/setCustomUprnInfo', response.data.data, {root: true})
                if (data.clear) {
                    ctx.commit('setCustomPropertyMarkers', [response.data.data]);
                }
                resolve(response)
            })
        });
    },
    loadByPostcode(ctx, postcodeData) {
        let latLngs = [];
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/postcode/' + postcodeData.postcode + '/' + postcodeData.all_properties).then(response => {
                ctx.commit('setPostcodes', response.data.data)
                ctx.dispatch('setPropertyMarkers', CommonTools.setFlags(ctx, response.data.data))

                response.data.data.map(function (item) {
                    latLngs.push(item.location);
                });
                ctx.commit('setLatLngsForBounds', latLngs);
                ctx.commit('setShowSearch', true);
                resolve(response.data);
            });
        })
    },
    loadByPostcodeRaw(ctx, postcodeData) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + '/api/postcode/' + postcodeData.postcode + '/' + postcodeData.all_properties).then(response => {
                resolve(response.data);
            });
        })
    },
    autocomplete(ctx, query) {
        axios.post(process.env.VUE_APP_API_URL + '/api/autocomplete', {query, size: 20}).then((response => {
            const cityData = response.data.data.cityData;
            const streetData = response.data.data.streetData;
            const fuzzy = response.data.data.fuzzy;
            ctx.commit('setCities', cityData);
            ctx.commit('setStreets', streetData);
            ctx.commit('setFuzzy', CommonTools.setFlags(ctx, fuzzy));
            ctx.commit('setShowSearch', true);
        }));
    },
    autocompleteRaw(ctx, query) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/autocomplete', {query, size: 20})
    },
    restAutocomplete({commit}, hideSearch = false) {
        commit('setCities', [])
        commit('setStreets', [])
        commit('setFuzzy', [])
        if (hideSearch) {
            commit('setShowSearch', false);
        }
    },
    searchStreet({commit, dispatch}, data) {
        commit('setOverlay', true);
        dispatch('restAutocomplete');
        axios.post(process.env.VUE_APP_API_URL + '/api/search/street', data).then(response => {
            commit('setOverlay', false);
            commit('setFuzzy', response.data.data)
        });
    },
    getBuildings(ctx, data) {
        axios.get(process.env.VUE_APP_API_URL + '/api/buildings/' + data.lat + '/' + data.lng + '/' + data.size).then(response => {
            ctx.commit('setBuildings', response.data.data)
        })
    },
    setPropertyMarkers(ctx, markers) {
        ctx.commit('setPropertyMarkers', CommonTools.setFlags(ctx, markers));
    },
    setCustomPropertyMarkers(ctx, markers) {
        ctx.commit('setCustomPropertyMarkers', markers);
    },
    searchCustomPropertiesInDistance({rootGetters, dispatch, commit}, data) {
        const selectedProject = rootGetters["Projects/selectedProject"];
        const distance = rootGetters["State/getDistance"];
        if (selectedProject.id !== undefined) {
            axios.get(process.env.VUE_APP_API_URL + '/api/project/' + selectedProject.id + '/customproperty/indistance/' + data.center.lat + '/' + data.center.lng + '/km/' + distance).then(response => {
                let customPropertyData = response.data.data;
                dispatch('Search/setCustomPropertyMarkers', customPropertyData, {root: true});
            });
        }
    }


};
const getters = {
    clickedUprn() {
        return state.uprnClicked;
    },
    getNearestLocation() {
        return state.nearestLocation;
    },
    getMarkerToBounce: state => {
        return state.markerToBounce;
    },
    getLocationInfo: state => {
        return state.locationInfo;
    },
    getLocationInfoAvailable: state => {
        return state.locationInfoAvailable;
    },
    getUprnInfoAvailable: state => {
        return state.uprnInfoAvailable;
    },
    getMultiUprnInfoAvailable: state => {
        return state.multiUprnInfoAvailable;
    },
    getCustomUprnInfoAvailable: state => {
        return state.customUprnInfoAvailable;
    },
    getUprnInfo: state => {
        return state.uprnInfo;
    },
    getMultiUprnInfo: state => {
        return state.multiUprnInfo;
    },
    getCustomUprnInfo: state => {
        return state.customUprnInfo;
    },
    getUprnInfoLoading: state => {
        return state.uprnInfoLoading;
    },
    getCustomUprnInfoLoading: state => {
        return state.customUprnInfoLoading;
    },
    postcodeSearchIsActive: state => {
        return state.postcodeSearchIsActive;
    },
    postcode: state => {
        return state.postcode;
    },
    showSearch: state => {
        return state.showSearch;
    },
    postcodes: state => {
        return state.postcodes
    },
    cities: state => {
        return state.cities;
    },
    streets: state => {
        return state.streets;
    },
    fuzzy: state => {
        return state.fuzzy;
    },
    showOverlay: state => {
        return state.showOverlay;
    },
    buildings: state => {
        return state.buildings;
    },
    getLatLngForBounds: state => {
        return state.latLngsForBounds;
    },
    getPropertyMarkers: state => {
        return state.propertyMarkers;
    },
    getCustomPropertyMarkers: state => {
        return state.customPropertyMarkers;
    },

};
const mutations = {
    setUprnClicked(state, uprn) {
        vue.set(state, 'uprnClicked', uprn);
    },
    setNearestLocation(state, data) {
        vue.set(state, 'nearestLocation', data);
    },
    setMarkerToBounce(state, data) {
        vue.set(state, 'markerToBounce', data);
    },
    setLocationInfo(state, data) {
        vue.set(state, 'locationInfoAvailable', false);
        vue.nextTick(function () {
            vue.set(state.locationInfo, 'postcode', data.postcode);
            vue.set(state.locationInfo, 'latitude', data.latitude);
            vue.set(state.locationInfo, 'longitude', data.longitude);
            vue.set(state.locationInfo, 'x_coordinate', data.x_coordinate);
            vue.set(state.locationInfo, 'y_coordinate', data.y_coordinate);
            vue.set(state.locationInfo, 'easting', data.easting);
            vue.set(state.locationInfo, 'northing', data.northing);
            vue.set(state.locationInfo, 'street', data.street);
            vue.set(state.locationInfo, 'reference_letter', data.reference_letter);
            vue.set(state, 'locationInfoAvailable', true);

        });
    },
    setLocationInfoAvailable(state, data) {
        vue.set(state, 'locationInfoAvailable', data);
    },
    setUprnInfo(state, data) {
        vue.set(state, 'uprnInfo', data);
        vue.set(state, 'uprnInfoAvailable', true);
        vue.set(state, 'uprnInfoLoading', false);
    },
    setMultiUprnInfo(state, data) {
        vue.set(state, 'multiUprnInfo', data);
        vue.set(state, 'multiUprnInfoAvailable', true);
        vue.set(state, 'uprnInfoLoading', false);
    },
    setCustomUprnInfo(state, data) {
        vue.set(state, 'customUprnInfo', data);
        vue.set(state, 'customUprnInfoAvailable', true);
        vue.set(state, 'customUprnInfoLoading', false);
    },
    setUprnLoadingState(state, data) {
        vue.set(state, 'uprnInfoLoading', data);
    },
    setCustomUprnLoadingState(state, data) {
        vue.set(state, 'customUprnInfoLoading', data);
    },
    setUprnInfoAvailable(state, data) {
        vue.set(state, 'uprnInfoAvailable', data);
    },
    setMultiUprnInfoAvailable(state, data) {
        vue.set(state, 'multiUprnInfoAvailable', data);
    },
    setCustomUprnInfoAvailable(state, data) {
        vue.set(state, 'customUprnInfoAvailable', data);
    },

    searchWithPostcode(state, data) {
        vue.set(state, 'postcodeSearchIsActive', data.active);
        vue.set(state, 'postcode', data.postcode);
    },
    setPostcodes(state, data) {
        vue.set(state, 'postcodes', data);
    },
    setShowSearch(state, data) {
        vue.set(state, 'showSearch', data);
    },
    setCities(state, data) {
        vue.set(state, 'cities', data);
    },
    setStreets(state, data) {
        vue.set(state, 'streets', data);
    },
    setFuzzy(state, data) {
        vue.set(state, 'fuzzy', data);
    },
    setOverlay(state, data) {
        vue.set(state, 'showOverlay', data);
    },
    setBuildings(state, data) {
        vue.set(state, 'buildings', data);
    },
    clearSearch(state, data) {
        vue.set(state, 'postcode', '');
        vue.set(state, 'postcodes', []);
        vue.set(state, 'cities', []);
        vue.set(state, 'streets', []);
        vue.set(state, 'fuzzy', []);
        vue.set(state, 'showSearch', false);
        vue.set(state, 'searchWithPostcode', false)
        vue.set(state, 'propertyMarkers', [])
        vue.set(state, 'uprnInfoAvailable', false);
        vue.set(state, 'locationInfoAvailable', false);
        vue.set(state, 'uprnInfo', {});
        vue.set(state, 'locationInfo', {});
        vue.set(state, 'postcodeSearchIsActive', false)
    },
    setLatLngsForBounds(state, data) {
        vue.set(state, 'latLngsForBounds', data);
    },
    setPropertyMarkers(state, data) {
        Vue.set(state, 'propertyMarkers', data);
    },
    setCustomPropertyMarkers(state, data) {
        Vue.set(state, 'customPropertyMarkers', data);
    },
    removeFlagFromMarker(state, data) {
        const index = state.propertyMarkers.findIndex(markers => markers.uprn == data.uprn);

        vue.set(state.propertyMarkers[index], 'isFlagged', 0);
        vue.set(state.propertyMarkers[index], 'flag', '');
        state.propertyMarkers[index].isFlagged = 0;
        state.propertyMarkers[index].flag = '';
    },
    removeFlagFromCustomMarker(state, data) {
        const index = state.customPropertyMarkers.findIndex(markers => markers.UPRN == data.uprn);
        if (index === -1) {
            return;
        }
        vue.set(state.customPropertyMarkers[index], 'isFlagged', 0);
        vue.set(state.customPropertyMarkers[index], 'flag', '');
        state.customPropertyMarkers[index].isFlagged = 0;
        state.customPropertyMarkers[index].flag = '';
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}

