import axios from "axios";
var BuildCompleteClient = /** @class */ (function () {
    function BuildCompleteClient() {
    }
    BuildCompleteClient.getUlr = function () {
        return process.env.VUE_APP_API_URL + '/api/openreach/pia/orders/buildcomplete/';
    };
    BuildCompleteClient.createBuildComplete = function (projectId, data) {
        return axios.post(BuildCompleteClient.getUlr() + projectId, data);
    };
    return BuildCompleteClient;
}());
export default BuildCompleteClient;
